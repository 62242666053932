<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="4">
                <v-card outlined class="fill-height">
                    <v-card-title>
                        Account
                    </v-card-title>
                    <v-card-subtitle>
                        Username: {{ user.username }} <br>
                        Full name: {{ user.first_name }} {{ user.last_name }}<br>
                        Email: {{ user.email }} </v-card-subtitle>

                    <v-card-text>

                        <br />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <ButtonTheme />
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card outlined class="fill-height">
                    <v-card-title> 2FA </v-card-title>
                    <v-card-subtitle>
                        Enabled:
                        <IconBoolean :value="user.auth.otp_enabled" />
                    </v-card-subtitle>
                    <v-card-text>
                        Two Factor Auth increses the security of your account. You can setup the OTP (One Time
                        Passoword) in your smartphone with any OPT enabled app, just like Google Authenticator or
                        Authy.
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <Button2FASetup v-if="!user.auth.otp_enabled" />
                        <Button2FADisable v-else />
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card outlined class="fill-height">
                    <v-card-title> Notifications </v-card-title>
                    <v-card-text>
                        Get emails from the system.
                    </v-card-text>
                    <br><br><br>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <ButtonNotificationsSetup />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-card outlined class="fill-height">
                    <v-card-title> Signals setup </v-card-title>
                    <v-card-subtitle> Drag and drop your signals sorting preference. </v-card-subtitle>
                    <v-card-text>
                        <SortSymbols />
                    </v-card-text>
                    <br><br><br>
                    <!-- <v-card-actions>
                        <v-spacer></v-spacer>
                        <ButtonNotificationsSetup />
                    </v-card-actions> -->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="scss" scoped></style>

<script>
import SortSymbols from "./signals/SortSymbols.vue";
import IconBoolean from "@/components/renderers/IconBoolean";
import ButtonTheme from "@/components/account/ButtonTheme";
import Button2FASetup from "@/components/account/Button2FASetup";
import Button2FADisable from "@/components/account/Button2FADisable";
import ButtonNotificationsSetup from "@/components/account/ButtonNotificationsSetup.vue";
export default {
    components: {
        IconBoolean,
        ButtonTheme,
        Button2FASetup,
        Button2FADisable,
        ButtonNotificationsSetup,
        SortSymbols
    },
    data: () => ({}),
    computed: {
        user() {
            return this.$store.state.session.user_object;
        },
    },
};
</script>