<template>
    <div>
        <draggable :list="symbols" class="list-group" ghost-class="ghost">
            <v-sheet outlined class="title ma-1 pa-1 outlined font-weight-light" v-for="symbol in symbols" :key="symbol">
                <v-icon class="mb-1"> mdi-finance </v-icon>
                {{ symbol }}
            </v-sheet>
        </draggable>
    </div>
</template>

<script>
const SYMBOLS = [
    "EUA_F_ICEYc1",
    "G_TTF_F_TRMc1",
    "G_TTF_F_TRMc2",
    "G_TTF_F_TRMc3",
    "BRENT_F_ICE_Mc1",
    "BRENT_F_ICE_Mc2",
    "BRENT_F_ICE_Mc3",
]

import draggable from "vuedraggable"
export default {
    components: { draggable },
    data: () => ({ symbols: SYMBOLS }),
}
</script>