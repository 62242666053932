<template>
    <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text color="accent">

                <v-icon left> mdi-theme-light-dark </v-icon> Theme
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                Select your theme preference
            </v-card-title>
            <v-card-text> This preference will be saved locally in this device for all users </v-card-text> <v-card-text>
                <v-select outlined dense v-model="theme" :items="options" label="Theme">
                </v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="dialog = false">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        loading: false,
        dialog: false,
        theme: localStorage.getItem("theme"),
    }),
    computed: {
        options() {
            return [
                { value: "auto", text: "Auto Dark" },
                { value: "light", text: "Light mode" },
                { value: "dark", text: "Dark mode" },
            ];
        },
    },
    watch: {
        theme(theme) {
            localStorage.setItem("theme", theme);
            if (theme == "dark") {
                this.$vuetify.theme.dark = true;
            } else if (theme == "light") {
                this.$vuetify.theme.dark = false;
            } else if (theme == "auto") {
                const web_dark = window.matchMedia(
                    "(prefers-color-scheme: dark)"
                ).matches;
                this.$vuetify.theme.dark = web_dark;
            }
        },
    },
};
</script>