<template>
    <div :id="chart_id" style="height:450px;"></div>
</template>
  
<script>
import Plotly from 'plotly.js-dist'

export default {
    props: ["ohlc"],
    data: () => ({
    }),
    computed: {
        chart_id() {
            return String(this._uid)
        },
        chart_data() {
            return [{
                increasing: {
                    line: { color: this.$vuetify.theme.dark ? '#80CBC4' : '#80CBC4', width: 0.5 },
                    fillcolor: this.$vuetify.theme.dark ? '#D4E157' : '#D4E157'
                },
                decreasing: {
                    width: 1,
                    line: { color: this.$vuetify.theme.dark ? '#EF9A9A' : '#EF9A9A', width: 0.5 },
                    fillcolor: this.$vuetify.theme.dark ? '#FF8A80' : '#FF8A80'
                },
                type: 'candlestick',
                xaxis: 'x',
                yaxis: 'y',
                ...this.ohlc
            }]
        },
        chart_layout() {
            let rangex = [
                this.ohlc.x[0],
                this.ohlc.x[-1]
            ]
            let rangey = [85, 90]
            return {
                margin: {
                    r: 10,
                    t: 25,
                    b: 40,
                    l: 60
                },
                paper_bgcolor: 'rgba(0,0,0,0)',
                showlegend: false,
                plot_bgcolor: this.$vuetify.theme.dark ? '#000' : '#feffeb',
                xaxis: {
                    autorange: true,
                    domain: [0, 1],
                    range: rangex,
                    // title: {
                    //     text: 'Date',
                    //     font: {
                    //         color: this.$vuetify.theme.dark ? '#FFF' : '#061938'
                    //     }
                    // },
                    tickfont: {
                        color: this.$vuetify.theme.dark ? '#FFF' : '#061938'
                    },
                    type: 'date',
                },
                yaxis: {
                    autorange: true,
                    domain: [0, 1],
                    // title: {
                    //     text: 'Value',
                    //     font: {
                    //         color: this.$vuetify.theme.dark ? '#FFF' : '#061938'
                    //     }
                    // },
                    tickfont: {
                        color: this.$vuetify.theme.dark ? '#FFF' : '#061938'
                    },
                    range: rangey,
                    type: 'linear',
                    color: this.$vuetify.theme.dark ? '#FFF' : 'white',
                    width: 5
                }
            }
        }
    },
    watch: {
        chart_data: {
            handler() {
                this.touch_chart()
            },
            deep: true
        },
        chart_layout: {
            handler() {
                this.touch_chart()
            },
            deep: true
        }
    },
    methods: {
        mount_chart() {
            Plotly.newPlot(this.chart_id, this.chart_data, this.chart_layout, { responsive: true })
        },
        touch_chart() {
            Plotly.react(this.chart_id, this.chart_data, this.chart_layout, { responsive: true })
        },
    },
    mounted() {
        this.mount_chart()
    }
}
</script>
