
export function round(value, decimals = 0) {
    let num = Number(value)
    if (isNaN(num)) num = 0
    return num.toFixed(decimals)
}


export function perc(value, decimals = 0) {
    let num = Number(value)
    if (isNaN(num)) num = 0
    return (num / 100).toFixed(decimals) + "%"
  }