<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text>
                users
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="accent" text>
                <v-icon left> mdi-plus </v-icon>
                new
            </v-btn>
        </v-card-title>
        <v-simple-table>
            <thead>
                <tr>
                    <th class="text-left">
                        username
                    </th>
                    <th class="text-left">
                        phone
                    </th>
                    <th class="text-left">
                        email
                    </th>
                    <th class="text-left">
                        telegram
                    </th>
                    <th class="text-right">
                        actions
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.username">
                    <td>{{ user.username }} </td>
                    <td>{{ user.phone }}</td>
                    <td>{{ user.email }}</td>
                    <td> {{ user.telegram }} </td>
                    <td class="text-right">
                        <v-btn icon>
                            <v-icon> mdi-pencil </v-icon>
                        </v-btn>
                        <v-btn icon>
                            <v-icon> mdi-delete </v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>

<script>
export default {
    props: [],
    components: {
    },
    data: () => ({
        users: [
            { "username": "alice.alison", "phone": "+00000000000", "email": "random@random.com", "telegram": "@random" },
            { "username": "bob.buster", "phone": "+00000000000", "email": "random@random.com", "telegram": "@random" },
            { "username": "craigh.cristoph", "phone": "+00000000000", "email": "random@random.com", "telegram": "@random" },
        ]
    }),
    computed: {

    },
    methods: {

    },
};
</script>