
<template>
    <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text color="accent">
                Notifications
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                Notifications
            </v-card-title>

            <v-card-text>
                Get emails from the system.
                <br />
                <br />
                <v-switch color="accent" class="ma-0" dense v-for="nop in notification_options" :key="nop.key"
                    v-model="noti_setup[nop.key]" :label="nop.text"> </v-switch>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading" :loading="loading" color="warning" @click="save">
                    Save
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { backend_api } from "@/utils/api";
export default {
    data: () => ({
        loading: false,
        dialog: false,
        noti_setup: {},
        notification_options: [
            { key: "sl_reached", text: "Stop Loss Reached" },
        ]
    }),
    computed: {
        user() {
            return this.$store.state.session.user_object;
        },
    },
    watch: {
        dialog(d) {
            if (d) this.refresh()
        }
    },
    methods: {
        save() {
            this.loading = true;
            return backend_api
                .post("/my_user/patch_noti_setup/", { noti_setup: this.noti_setup })
                .then(() => {
                    this.dialog = false;
                    this.$store.dispatch("session/getUserObject");
                })
                .catch((e) => console.log(`Cant update notification setup: ${e}`))
                .finally(() => (this.loading = false));
        },
        refresh() {
            this.noti_setup = { ...this.user.auth?.meta?.noti_setup };
        }
    },
    mounted() {
        this.refresh()
    },
};
</script>