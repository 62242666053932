<template>
    <v-container fluid class="pa-2 fill-height">
        <v-row class="fill-height ma-6" justify="center">
            <v-col align-self="center" cols="12" md="3">
                <v-img v-if="dark" class="ma-5" src="../../assets/Logo.EIP.white.png" />
                <v-img v-else class="ma-5" src="../../assets/logo.EIP.blue.png" />
            </v-col>
            <br />
            <v-col align-self="center" width="400" cols="12" md="3">
                <v-form v-if="token_enter_mode && recovery_mode">
                    <h3 class="font-weight-light">
                        Check your email and enter the token
                    </h3>
                    <v-text-field outlined v-model="token" label="Token" name="token" />
                    <v-text-field class="mx-1" outlined dense label="Password" v-model="password_A" type="password"
                        autocomplete="new-password" :rules="[rules.password]">
                    </v-text-field>
                    <v-text-field class="mx-1" outlined dense label="Repeat password" v-model="password_B" type="password"
                        autocomplete="new-password" :rules="[rules.password, rules.matchPassword]">
                    </v-text-field>
                    <v-row class="mx-0">
                        <v-btn :loading="loading" :disabled="loading" color="secondary" @click="token_enter_mode = false">
                            <v-icon left> mdi-arrow-left </v-icon>
                            Back
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn :loading="loading" color="accent" @click="post_new_password"> Save </v-btn>
                    </v-row>
                </v-form>
                <v-form @keyup.enter.native="launch_recovery" v-else-if="recovery_mode">
                    <v-text-field outlined v-model="email" label="Email" name="email" autocomplete="email"
                        append-icon="mdi-email" type="email" />
                    <v-row class="mx-0">
                        <v-btn :loading="loading" :disabled="loading" color="secondary" @click="recovery_mode = false">
                            <v-icon left> mdi-arrow-left </v-icon>
                            Back
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn :loading="loading" color="accent" @click="launch_recovery"> Recovery </v-btn>
                    </v-row>
                </v-form>
                <v-form @keyup.enter.native="login" v-else>
                    <v-text-field outlined v-model="username" autocomplete="username" label="login" name="login"
                        append-icon="mdi-account" type="text" :error="error" />
                    <v-text-field outlined v-model="password" autocomplete="password" id="password" label="password"
                        name="password" append-icon="mdi-lock" type="password" :error="error" :error-messages="error
                                ? 'Wrong credentials'
                                : ''
                            " />
                    <v-text-field class="mx-1" outlined append-icon="mdi-qrcode" v-if="otp_needed" label="OTP"
                        v-model="otp">
                    </v-text-field>
                    <v-row class="mx-0">
                        <a @click="recovery_mode = true">
                            Password recovery
                        </a>
                        <v-spacer></v-spacer>
                        <v-btn :loading="loading" :disabled="loading" color="accent" @click="login"> Login </v-btn>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="scss" scoped></style>

<script>
import bus from "@/utils/event_bus";
import { backend_api } from "@/utils/api";
export default {
    props: ["redirect_route"],
    components: {},
    data: () => ({
        loading: false,
        username: localStorage.getItem("last_username"),
        password: "",
        otp: "",
        password_A: "", // for recovery
        password_B: "", // for recovery
        email: "",
        token: "",
        error: false,
        recovery_mode: false,
        token_enter_mode: false,
        otp_needed: false,
    }),
    computed: {
        dark() {
            return this.$vuetify.theme.dark;
        },
        authenticated() {
            return this.$store.state.session.authenticated;
        },
        session_username() {
            return this.$store.state.session.user_object
                ? this.$store.state.session.user_object.username
                : "";
        },
        rules() {
            return {
                password: (v) =>
                    (v && v.length >= 8) ||
                    "Your password must contain at least 8 characters.",
                matchPassword: () =>
                    this.password_A == this.password_B ||
                    "Passwords not matching.",
            };
        },
    },
    watch: {
        username(u) {
            this.error = false;
            localStorage.setItem("last_username", u);
        },
        password() {
            this.error = false;
        },
    },
    methods: {
        login() {
            this.loading = true;
            bus.$emit("notification", {
                timeout: 1000,
                text: "Trying to login",
                color: "info",
            });
            this.dialog = false;
            let payload = {
                username: this.username,
                password: this.password,
            };
            if (this.otp != "") payload.otp = this.otp;
            this.$store
                .dispatch("session/login", payload)
                .then(() => {
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: "Loged In",
                        color: "success",
                    });
                    this.error = false;
                    this.loading = false;
                    this.redirect();
                })
                .catch((e) => {
                    if (e.response?.status == 401) {
                        bus.$emit("notification", {
                            timeout: 1000,
                            text: "Wrong credentials.",
                            color: "warning",
                        });
                        this.error = true;
                    } else if (
                        e.response?.status == 400 &&
                        "otp" in e.response.data
                    ) {
                        console.log("[i] OPT Needed.");
                        bus.$emit("notification", {
                            timeout: 1000,
                            text: e.response.data.otp[0],
                            color: "info",
                        });
                        this.otp_needed = true;
                    } else if (
                        e.response?.status == 400 &&
                        "otp_invalid" in e.response.data
                    ) {
                        console.log("[i] OPT Invalid.");
                        bus.$emit("notification", {
                            timeout: 1000,
                            text: "OTP invalid",
                            color: "error",
                        });
                        this.otp_needed = true;
                    } else {
                        bus.$emit("notification", {
                            timeout: 3000,
                            text: "Can't perform a login now. Try again or contact support.",
                            color: "error",
                        });
                        throw e;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        launch_recovery() {
            this.loading = true;
            backend_api
                .post("/password_reset/", { email: this.email })
                .then(() => {
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: "Token sended.",
                        color: "success",
                    });
                    this.token_enter_mode = true;
                })
                .catch((e) => {
                    let message = "Can't send token.";
                    if (e.response.status == 400)
                        message = e.response.data.email[0];
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: message,
                        color: "error",
                    });
                })
                .finally(() => (this.loading = false));
        },
        post_new_password() {
            this.loading = true;
            backend_api
                .post("/password_reset/validate_token/", {
                    token: this.token,
                    password: this.password_A,
                })
                .then(() => {
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: "Password changed.",
                        color: "success",
                    });
                    this.token_enter_mode = false;
                    this.recovery_mode = false;
                })
                .catch((e) => {
                    let message = "Can't change password.";
                    if (e.response.status == 404) message = "Invalid token";
                    if (e.response.status == 400)
                        message = e.response.data.password[0];
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: message,
                        color: "error",
                    });
                })
                .finally(() => (this.loading = false));
        },
        redirect() {
            if (this.redirect_route) {
                console.log("redirecting!");
                this.$router
                    .push(this.redirect_route)
                    .catch(() => console.log("[!] Can't update route."));
            } else {
                this.$router
                    .push("/")
                    .catch(() => console.log("[!] Can't update route."));
            }
        },
    },
    mounted() {
        if (this.authenticated) this.redirect();
    },
};
</script>
