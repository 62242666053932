<template>
    <div>
        <v-simple-table height="400px" dense fixed-header>
            <thead>
                <tr>
                    <th class="text-left">
                        Date
                    </th>
                    <th class="text-right">
                        Position
                    </th>
                    <th class="text-right">
                        Ref. Price
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr :style="'background-color:' + signal.color" v-for="signal in signals" :key="signal.uuid">
                    <td>{{ signal.created }}</td>
                    <td class="text-right">{{ signal.side }}</td>
                    <td class="text-right">{{ signal.amount }} €</td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>
  
<script>
import { round } from "/src/utils/math.js"

export default {
    data: () => ({}),
    computed: {
        signals() {
            let signals = this.$store.state.signals.signals.map(s => {
                let color = ""
                if (this.$vuetify.theme.dark) {
                    if (s.side == "Long") color = "#38473a"
                    else if (s.side == "Short") color = "#4a373b"
                } else {
                    if (s.side == "Long") color = "#bbedc833"
                    else if (s.side == "Short") color = "#FFCDD233"
                }
                return {
                    ...s,
                    amount: round(s.amount, 2),
                    color
                }
            })
            signals.sort((a, b) => {
                return new Date(b.created) - new Date(a.created);
            })
            return signals
        }
    }
}
</script>