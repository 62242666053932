export const DUMMY_OHLC = {
    "x": [
        "2022-07-25 07:00",
        "2022-07-25 08:00",
        "2022-07-25 09:00",
        "2022-07-25 10:00",
        "2022-07-25 11:00",
        "2022-07-25 12:00",
        "2022-07-25 13:00",
        "2022-07-25 14:00",
        "2022-07-25 15:00",
        "2022-07-25 16:00",
        "2022-07-26 07:00",
        "2022-07-26 08:00",
        "2022-07-26 09:00",
        "2022-07-26 10:00",
        "2022-07-26 11:00",
        "2022-07-26 12:00",
        "2022-07-26 13:00",
        "2022-07-26 14:00",
        "2022-07-26 15:00",
        "2022-07-26 16:00",
        "2022-07-27 07:00",
        "2022-07-27 08:00",
        "2022-07-27 09:00",
        "2022-07-27 10:00",
        "2022-07-27 11:00",
        "2022-07-27 12:00",
        "2022-07-27 13:00",
        "2022-07-27 14:00",
        "2022-07-27 15:00",
        "2022-07-27 16:00",
        "2022-07-28 07:00",
        "2022-07-28 08:00",
        "2022-07-28 09:00",
        "2022-07-28 10:00",
        "2022-07-28 11:00",
        "2022-07-28 12:00",
        "2022-07-28 13:00",
        "2022-07-28 14:00",
        "2022-07-28 15:00",
        "2022-07-28 16:00",
        "2022-07-29 07:00",
        "2022-07-29 08:00",
        "2022-07-29 09:00",
        "2022-07-29 10:00",
        "2022-07-29 11:00",
        "2022-07-29 12:00",
        "2022-07-29 13:00",
        "2022-07-29 14:00",
        "2022-07-29 15:00",
        "2022-07-29 16:00",
        "2022-08-01 07:00",
        "2022-08-01 08:00",
        "2022-08-01 09:00",
        "2022-08-01 10:00",
        "2022-08-01 11:00",
        "2022-08-01 12:00",
        "2022-08-01 13:00",
        "2022-08-01 14:00",
        "2022-08-01 15:00",
        "2022-08-01 16:00",
        "2022-08-02 07:00",
        "2022-08-02 08:00",
        "2022-08-02 09:00",
        "2022-08-02 10:00",
        "2022-08-02 11:00",
        "2022-08-02 12:00",
        "2022-08-02 13:00",
        "2022-08-02 14:00",
        "2022-08-02 15:00",
        "2022-08-02 16:00",
        "2022-08-03 07:00",
        "2022-08-03 08:00",
        "2022-08-03 09:00",
        "2022-08-03 10:00",
        "2022-08-03 11:00",
        "2022-08-03 12:00",
        "2022-08-03 13:00",
        "2022-08-03 14:00",
        "2022-08-03 15:00",
        "2022-08-03 16:00",
        "2022-08-04 07:00",
        "2022-08-04 08:00",
        "2022-08-04 09:00",
        "2022-08-04 10:00",
        "2022-08-04 11:00",
        "2022-08-04 12:00",
        "2022-08-04 13:00",
        "2022-08-04 14:00",
        "2022-08-04 15:00",
        "2022-08-04 16:00",
        "2022-08-05 07:00",
        "2022-08-05 08:00",
        "2022-08-05 09:00",
        "2022-08-05 10:00",
        "2022-08-05 11:00",
        "2022-08-05 12:00",
        "2022-08-05 13:00",
        "2022-08-05 14:00",
        "2022-08-05 15:00",
        "2022-08-05 16:00",
        "2022-08-08 07:00",
        "2022-08-08 08:00",
        "2022-08-08 09:00",
        "2022-08-08 10:00",
        "2022-08-08 11:00",
        "2022-08-08 12:00",
        "2022-08-08 13:00",
        "2022-08-08 14:00",
        "2022-08-08 15:00",
        "2022-08-08 16:00",
        "2022-08-09 07:00",
        "2022-08-09 08:00",
        "2022-08-09 09:00",
        "2022-08-09 10:00",
        "2022-08-09 11:00",
        "2022-08-09 12:00",
        "2022-08-09 13:00",
        "2022-08-09 14:00",
        "2022-08-09 15:00",
        "2022-08-09 16:00",
        "2022-08-10 07:00",
        "2022-08-10 08:00",
        "2022-08-10 09:00",
        "2022-08-10 10:00",
        "2022-08-10 11:00",
        "2022-08-10 12:00",
        "2022-08-10 13:00",
        "2022-08-10 14:00",
        "2022-08-10 15:00",
        "2022-08-10 16:00",
        "2022-08-11 07:00",
        "2022-08-11 08:00",
        "2022-08-11 09:00",
        "2022-08-11 10:00",
        "2022-08-11 11:00",
        "2022-08-11 12:00",
        "2022-08-11 13:00",
        "2022-08-11 14:00",
        "2022-08-11 15:00",
        "2022-08-11 16:00",
        "2022-08-12 07:00",
        "2022-08-12 08:00",
        "2022-08-12 09:00",
        "2022-08-12 10:00",
        "2022-08-12 11:00",
        "2022-08-12 12:00",
        "2022-08-12 13:00",
        "2022-08-12 14:00",
        "2022-08-12 15:00",
        "2022-08-12 16:00",
        "2022-08-15 07:00",
        "2022-08-15 08:00",
        "2022-08-15 09:00",
        "2022-08-15 10:00",
        "2022-08-15 11:00",
        "2022-08-15 12:00",
        "2022-08-15 13:00",
        "2022-08-15 14:00",
        "2022-08-15 15:00",
        "2022-08-15 16:00",
        "2022-08-16 07:00",
        "2022-08-16 08:00",
        "2022-08-16 09:00",
        "2022-08-16 10:00",
        "2022-08-16 11:00",
        "2022-08-16 12:00",
        "2022-08-16 13:00",
        "2022-08-16 14:00",
        "2022-08-16 15:00",
        "2022-08-16 16:00",
        "2022-08-17 07:00",
        "2022-08-17 08:00",
        "2022-08-17 09:00",
        "2022-08-17 10:00",
        "2022-08-17 11:00",
        "2022-08-17 12:00",
        "2022-08-17 13:00",
        "2022-08-17 14:00",
        "2022-08-17 15:00",
        "2022-08-17 16:00",
        "2022-08-18 07:00",
        "2022-08-18 08:00",
        "2022-08-18 09:00",
        "2022-08-18 10:00",
        "2022-08-18 11:00",
        "2022-08-18 12:00",
        "2022-08-18 13:00",
        "2022-08-18 14:00",
        "2022-08-18 15:00",
        "2022-08-18 16:00",
        "2022-08-19 07:00",
        "2022-08-19 08:00",
        "2022-08-19 09:00",
        "2022-08-19 10:00",
        "2022-08-19 11:00",
        "2022-08-19 12:00",
        "2022-08-19 13:00",
        "2022-08-19 14:00",
        "2022-08-19 15:00",
        "2022-08-19 16:00"
    ],
    "open": [
        76.12,
        76.7,
        75.88,
        76.0,
        78.14,
        78.05,
        77.41,
        77.22,
        76.98,
        76.25,
        76.69,
        76.12,
        76.9,
        76.79,
        76.9,
        77.22,
        76.35,
        76.15,
        76.56,
        76.95,
        76.61,
        76.92,
        76.81,
        77.22,
        77.48,
        77.79,
        76.92,
        76.82,
        76.68,
        75.95,
        76.25,
        76.92,
        77.05,
        77.32,
        77.67,
        78.61,
        78.79,
        78.86,
        79.63,
        79.0,
        78.96,
        78.95,
        78.6,
        78.66,
        78.39,
        78.23,
        78.26,
        77.79,
        77.79,
        78.54,
        78.5,
        78.79,
        79.24,
        79.25,
        79.46,
        79.55,
        79.76,
        79.56,
        80.35,
        80.31,
        80.15,
        80.6,
        80.55,
        80.82,
        80.88,
        81.88,
        81.72,
        82.08,
        81.76,
        81.72,
        81.83,
        81.83,
        83.0,
        83.5,
        83.56,
        83.29,
        83.51,
        83.51,
        83.71,
        83.9,
        83.6,
        84.04,
        84.57,
        84.6,
        84.09,
        84.37,
        84.64,
        84.41,
        84.6,
        84.56,
        84.31,
        84.51,
        84.92,
        85.35,
        85.2,
        85.07,
        84.73,
        84.34,
        84.93,
        84.95,
        85.0,
        85.14,
        84.73,
        85.05,
        84.93,
        84.59,
        85.23,
        84.46,
        84.3,
        83.77,
        84.18,
        84.63,
        84.52,
        84.09,
        84.6,
        84.91,
        85.04,
        84.84,
        85.54,
        86.06,
        85.5,
        85.92,
        86.15,
        85.58,
        85.1,
        85.0,
        85.03,
        84.55,
        85.52,
        85.77,
        85.98,
        85.71,
        85.82,
        86.81,
        87.12,
        87.59,
        88.02,
        87.58,
        87.79,
        87.66,
        86.9,
        87.35,
        87.56,
        87.62,
        87.7,
        88.27,
        88.19,
        88.56,
        88.62,
        89.05,
        89.35,
        88.95,
        89.38,
        89.79,
        89.35,
        89.84,
        89.87,
        89.43,
        89.76,
        90.72,
        90.07,
        90.4,
        90.98,
        91.12,
        91.23,
        92.04,
        91.97,
        92.04,
        92.57,
        92.18,
        92.35,
        93.08,
        94.07,
        94.94,
        94.67,
        95.2,
        94.82,
        94.77,
        95.16,
        96.02,
        96.25,
        96.36,
        95.83,
        94.5,
        94.65,
        94.83,
        95.33,
        95.66,
        95.93,
        95.7,
        96.3,
        96.09,
        96.29,
        96.6,
        97.76,
        97.64,
        98.1,
        97.73,
        98.21,
        97.96
    ],
    "high": [
        76.86,
        76.82,
        76.25,
        78.16,
        78.42,
        78.15,
        77.87,
        77.42,
        77.14,
        76.63,
        76.69,
        77.23,
        77.13,
        77.5,
        77.34,
        77.37,
        76.89,
        76.69,
        77.2,
        77.04,
        76.95,
        77.2,
        77.45,
        77.74,
        77.76,
        77.79,
        77.14,
        77.08,
        76.77,
        76.58,
        77.09,
        77.15,
        77.42,
        77.91,
        78.73,
        79.18,
        79.47,
        79.66,
        80.12,
        79.0,
        79.5,
        79.0,
        78.75,
        78.69,
        78.4,
        78.4,
        78.4,
        78.0,
        78.65,
        78.67,
        78.8,
        79.39,
        79.3,
        79.93,
        79.74,
        79.9,
        80.07,
        80.4,
        80.5,
        80.87,
        80.72,
        81.01,
        81.0,
        81.0,
        81.87,
        81.9,
        82.25,
        82.18,
        81.88,
        82.19,
        82.14,
        83.5,
        83.5,
        83.96,
        83.77,
        83.82,
        83.95,
        83.85,
        84.0,
        84.1,
        84.41,
        84.72,
        84.72,
        84.6,
        84.41,
        84.85,
        84.78,
        84.66,
        84.85,
        84.58,
        84.73,
        85.0,
        85.45,
        85.44,
        85.3,
        85.2,
        84.87,
        85.15,
        85.14,
        85.26,
        85.15,
        85.5,
        85.23,
        85.15,
        84.93,
        85.36,
        85.23,
        84.69,
        84.35,
        84.53,
        84.7,
        84.91,
        84.7,
        84.75,
        85.03,
        85.29,
        85.2,
        85.69,
        86.08,
        86.08,
        86.05,
        86.3,
        86.3,
        85.71,
        85.17,
        85.12,
        85.18,
        85.76,
        85.9,
        86.05,
        85.98,
        86.09,
        87.0,
        87.41,
        87.65,
        88.03,
        88.03,
        88.01,
        88.0,
        87.69,
        87.84,
        87.57,
        87.98,
        87.97,
        88.4,
        88.55,
        88.7,
        88.7,
        89.25,
        89.18,
        89.5,
        89.5,
        89.89,
        90.0,
        89.95,
        90.19,
        89.88,
        89.83,
        90.78,
        90.87,
        90.82,
        91.48,
        91.25,
        91.25,
        92.18,
        92.4,
        92.35,
        92.63,
        92.66,
        92.56,
        93.3,
        94.44,
        95.14,
        95.48,
        95.38,
        95.21,
        94.97,
        95.24,
        96.26,
        96.44,
        96.93,
        97.49,
        95.84,
        95.17,
        94.95,
        95.45,
        95.99,
        96.2,
        96.16,
        96.75,
        96.99,
        96.79,
        96.59,
        97.76,
        97.89,
        98.12,
        99.22,
        98.24,
        98.24,
        98.45
    ],
    "close": [
        76.71,
        75.85,
        76.0,
        78.16,
        78.06,
        77.41,
        77.17,
        76.97,
        76.25,
        76.34,
        76.17,
        76.91,
        76.8,
        76.9,
        77.2,
        76.35,
        76.15,
        76.57,
        76.97,
        76.76,
        76.87,
        76.86,
        77.29,
        77.5,
        77.75,
        76.93,
        76.82,
        76.68,
        75.98,
        76.27,
        76.88,
        77.05,
        77.29,
        77.65,
        78.6,
        78.79,
        78.87,
        79.64,
        78.99,
        78.7,
        78.97,
        78.6,
        78.62,
        78.36,
        78.22,
        78.25,
        77.78,
        77.8,
        78.53,
        78.67,
        78.79,
        79.2,
        79.2,
        79.47,
        79.54,
        79.77,
        79.57,
        80.35,
        80.3,
        80.63,
        80.58,
        80.55,
        80.85,
        80.91,
        81.87,
        81.75,
        82.05,
        81.75,
        81.62,
        81.98,
        81.81,
        82.99,
        83.45,
        83.6,
        83.25,
        83.55,
        83.51,
        83.71,
        83.86,
        83.82,
        84.02,
        84.57,
        84.6,
        84.05,
        84.32,
        84.65,
        84.38,
        84.6,
        84.54,
        84.4,
        84.61,
        84.94,
        85.3,
        85.21,
        85.06,
        84.73,
        84.34,
        84.89,
        84.9,
        85.12,
        85.08,
        84.74,
        85.02,
        84.95,
        84.61,
        85.1,
        84.48,
        84.3,
        83.74,
        84.52,
        84.7,
        84.53,
        84.08,
        84.6,
        84.91,
        85.04,
        84.84,
        85.51,
        86.06,
        85.5,
        85.9,
        86.15,
        85.68,
        85.1,
        85.03,
        85.04,
        84.56,
        85.56,
        85.75,
        85.98,
        85.71,
        85.79,
        86.8,
        87.15,
        87.57,
        88.02,
        87.61,
        87.82,
        87.67,
        87.06,
        87.42,
        87.57,
        87.6,
        87.69,
        88.23,
        88.18,
        88.52,
        88.61,
        89.05,
        89.07,
        88.87,
        89.39,
        89.76,
        89.39,
        89.89,
        89.87,
        89.48,
        89.8,
        90.74,
        90.68,
        90.35,
        90.95,
        91.16,
        91.2,
        92.01,
        91.91,
        92.03,
        92.56,
        92.16,
        92.47,
        92.99,
        94.06,
        95.11,
        94.69,
        95.18,
        94.81,
        94.74,
        95.16,
        95.97,
        96.35,
        96.36,
        95.93,
        94.5,
        94.65,
        94.84,
        95.35,
        95.64,
        95.96,
        95.72,
        96.53,
        96.13,
        96.3,
        96.56,
        97.76,
        97.62,
        98.1,
        97.79,
        98.21,
        97.98,
        98.43
    ],
    "low": [
        76.09,
        75.85,
        75.49,
        76.0,
        77.71,
        77.3,
        77.05,
        76.9,
        76.06,
        76.0,
        75.85,
        76.1,
        76.27,
        76.65,
        76.7,
        76.0,
        76.05,
        76.07,
        76.45,
        76.45,
        76.52,
        76.59,
        76.43,
        77.1,
        77.43,
        76.9,
        76.8,
        76.31,
        75.78,
        75.9,
        76.07,
        76.83,
        76.91,
        77.27,
        77.67,
        78.54,
        78.75,
        78.86,
        78.81,
        78.51,
        78.65,
        78.45,
        78.11,
        78.14,
        78.01,
        78.13,
        77.64,
        77.7,
        77.54,
        78.17,
        78.1,
        78.38,
        79.0,
        79.12,
        79.39,
        79.55,
        79.55,
        79.5,
        79.96,
        80.31,
        79.64,
        80.15,
        80.25,
        80.64,
        80.84,
        81.5,
        81.56,
        81.45,
        81.45,
        81.67,
        81.65,
        81.8,
        82.9,
        83.43,
        83.09,
        83.03,
        83.41,
        83.35,
        83.31,
        83.67,
        83.28,
        84.04,
        84.37,
        83.45,
        83.85,
        84.21,
        83.95,
        84.21,
        84.21,
        83.99,
        83.5,
        84.51,
        84.69,
        84.94,
        85.06,
        84.6,
        83.9,
        84.15,
        84.73,
        84.62,
        84.33,
        84.58,
        84.26,
        84.7,
        84.46,
        84.57,
        84.35,
        84.26,
        83.65,
        83.73,
        84.13,
        84.46,
        83.95,
        84.08,
        84.58,
        84.91,
        84.84,
        84.84,
        85.5,
        85.29,
        85.25,
        85.8,
        85.35,
        84.65,
        84.5,
        84.25,
        84.48,
        84.51,
        85.4,
        85.72,
        85.35,
        85.69,
        85.78,
        86.77,
        87.06,
        87.35,
        87.5,
        87.06,
        87.39,
        86.89,
        86.82,
        86.96,
        87.28,
        87.48,
        87.66,
        88.08,
        88.03,
        88.36,
        88.59,
        88.77,
        88.5,
        88.86,
        89.34,
        89.21,
        89.27,
        89.27,
        89.28,
        89.33,
        89.66,
        90.5,
        90.07,
        90.17,
        90.51,
        90.7,
        91.15,
        91.89,
        91.68,
        91.76,
        91.96,
        92.0,
        92.23,
        92.7,
        93.92,
        94.4,
        94.58,
        94.66,
        94.55,
        94.74,
        94.96,
        95.62,
        95.95,
        95.93,
        92.7,
        94.18,
        94.13,
        94.59,
        95.27,
        95.46,
        95.6,
        95.62,
        95.6,
        95.75,
        95.93,
        96.43,
        97.5,
        97.5,
        97.5,
        97.17,
        97.55,
        97.36
    ]
}


export const POPPING_ROWS = [
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-22 07:00', 'high': 98.69, 'open': 98.5, 'close': 97.52, 'low': 97.33, 'volume': 471, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.009153202, 'volatility_last_month': 0.00998303, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-22 08:00', 'high': 97.92, 'open': 97.44, 'close': 97.62, 'low': 96.77, 'volume': 1262, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.009104042, 'volatility_last_month': 0.009845961, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-22 09:00', 'high': 97.92, 'open': 97.62, 'close': 97.81, 'low': 96.5, 'volume': 1786, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.009086072, 'volatility_last_month': 0.009845922, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-22 10:00', 'high': 97.92, 'open': 97.79, 'close': 97.3, 'low': 97.12, 'volume': 1211, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.009130382, 'volatility_last_month': 0.009226369, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-22 11:00', 'high': 97.51, 'open': 97.27, 'close': 96.12, 'low': 95.2, 'volume': 1669, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.009656934, 'volatility_last_month': 0.009385689, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-22 12:00', 'high': 96.06, 'open': 96.06, 'close': 95.8, 'low': 95.19, 'volume': 1253, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.009714082, 'volatility_last_month': 0.009321204, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-22 13:00', 'high': 96.35, 'open': 95.75, 'close': 93.73, 'low': 93.62, 'volume': 2746, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.011089743, 'volatility_last_month': 0.00978145, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-22 14:00', 'high': 93.89, 'open': 93.72, 'close': 92.75, 'low': 92.5, 'volume': 2794, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.011396868, 'volatility_last_month': 0.009886472, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-22 15:00', 'high': 93.07, 'open': 92.72, 'close': 92.07, 'low': 90.85, 'volume': 2578, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.011301381, 'volatility_last_month': 0.009853001, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-22 16:00', 'high': 93.1, 'open': 92.03, 'close': 93.04, 'low': 91.5, 'volume': 2955, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.011554485, 'volatility_last_month': 0.009932502, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-23 07:00', 'high': 94.19, 'open': 92.51, 'close': 93.81, 'low': 92.4, 'volume': 749, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.011652758, 'volatility_last_month': 0.009968925, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-23 08:00', 'high': 94.0, 'open': 93.9, 'close': 93.11, 'low': 92.76, 'volume': 954, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01172548, 'volatility_last_month': 0.009966215, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-23 09:00', 'high': 94.0, 'open': 93.11, 'close': 93.49, 'low': 93.03, 'volume': 2072, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.011748909, 'volatility_last_month': 0.009969588, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-23 10:00', 'high': 93.51, 'open': 93.46, 'close': 90.74, 'low': 90.65, 'volume': 3334, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013786745, 'volatility_last_month': 0.010762939, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-23 11:00', 'high': 90.88, 'open': 90.72, 'close': 89.96, 'low': 89.26, 'volume': 2590, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013758937, 'volatility_last_month': 0.010826808, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-23 12:00', 'high': 90.61, 'open': 89.92, 'close': 90.04, 'low': 89.55, 'volume': 1536, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01376168, 'volatility_last_month': 0.010713171, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-23 13:00', 'high': 90.8, 'open': 90.03, 'close': 90.14, 'low': 89.96, 'volume': 1317, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013760359, 'volatility_last_month': 0.010703533, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-23 14:00', 'high': 90.68, 'open': 90.17, 'close': 89.64, 'low': 89.61, 'volume': 951, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013731078, 'volatility_last_month': 0.010718735, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-23 15:00', 'high': 90.5, 'open': 89.67, 'close': 89.2, 'low': 89.16, 'volume': 1371, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013740917, 'volatility_last_month': 0.010728881, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-23 16:00', 'high': 89.9, 'open': 89.2, 'close': 89.8, 'low': 88.85, 'volume': 1914, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013819939, 'volatility_last_month': 0.010747596, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-24 07:00', 'high': 90.41, 'open': 90.0, 'close': 90.26, 'low': 89.49, 'volume': 614, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '0', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013807578, 'volatility_last_month': 0.01076232, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-24 08:00', 'high': 91.1, 'open': 90.08, 'close': 91.08, 'low': 89.94, 'volume': 1117, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '1', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 0.0, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013698528, 'volatility_last_month': 0.010815995, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '-', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-24 09:00', 'high': 91.39, 'open': 91.1, 'close': 91.3, 'low': 90.65, 'volume': 1146, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '1', 'operations_last_3_months': '-', 'pnl_last_week': -0.22, 'pnl_last_month': -0.22, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013422182, 'volatility_last_month': 0.010799926, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.0, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0', 'winloss_ratio_last_month': '0', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-24 10:00', 'high': 91.28, 'open': 91.28, 'close': 90.63, 'low': 90.42, 'volume': 1192, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '1', 'operations_last_3_months': '-', 'pnl_last_week': 0.45, 'pnl_last_month': 0.45, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013485724, 'volatility_last_month': 0.01085065, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.522727273', 'winloss_ratio_last_month': '1.522727273', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-24 11:00', 'high': 90.6, 'open': 90.6, 'close': 89.52, 'low': 89.05, 'volume': 1792, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '1', 'operations_last_3_months': '-', 'pnl_last_week': 1.56, 'pnl_last_month': 1.56, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013673328, 'volatility_last_month': 0.010981727, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.712121212', 'winloss_ratio_last_month': '3.712121212', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-24 12:00', 'high': 90.25, 'open': 89.51, 'close': 89.99, 'low': 89.46, 'volume': 1179, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '1', 'operations_last_3_months': '-', 'pnl_last_week': 1.09, 'pnl_last_month': 1.09, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013742609, 'volatility_last_month': 0.010895445, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.133333333', 'winloss_ratio_last_month': '3.133333333', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-24 13:00', 'high': 90.58, 'open': 89.99, 'close': 89.62, 'low': 89.31, 'volume': 2002, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '1', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013761757, 'volatility_last_month': 0.010910679, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.12745098', 'winloss_ratio_last_month': '3.12745098', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-24 14:00', 'high': 89.99, 'open': 89.54, 'close': 89.61, 'low': 88.37, 'volume': 3351, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013698739, 'volatility_last_month': 0.010905922, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.124542125', 'winloss_ratio_last_month': '3.124542125', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-24 15:00', 'high': 89.68, 'open': 89.6, 'close': 89.41, 'low': 89.16, 'volume': 970, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013497774, 'volatility_last_month': 0.010833823, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.122807018', 'winloss_ratio_last_month': '3.122807018', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-24 16:00', 'high': 89.89, 'open': 89.39, 'close': 89.65, 'low': 89.1, 'volume': 2211, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013472012, 'volatility_last_month': 0.010829413, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.121654501', 'winloss_ratio_last_month': '3.121654501', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-25 07:00', 'high': 90.66, 'open': 89.2, 'close': 88.68, 'low': 88.42, 'volume': 933, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013650958, 'volatility_last_month': 0.010896289, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.120833333', 'winloss_ratio_last_month': '3.120833333', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-25 08:00', 'high': 89.5, 'open': 88.72, 'close': 89.46, 'low': 88.69, 'volume': 802, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013854379, 'volatility_last_month': 0.010945832, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.120218579', 'winloss_ratio_last_month': '3.120218579', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-25 09:00', 'high': 90.49, 'open': 89.43, 'close': 88.92, 'low': 88.91, 'volume': 1838, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013506005, 'volatility_last_month': 0.01097775, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.1197411', 'winloss_ratio_last_month': '3.1197411', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-25 10:00', 'high': 89.86, 'open': 88.91, 'close': 89.15, 'low': 88.77, 'volume': 1246, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013519998, 'volatility_last_month': 0.010968205, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.119359534', 'winloss_ratio_last_month': '3.119359534', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-25 11:00', 'high': 89.82, 'open': 89.2, 'close': 89.82, 'low': 89.01, 'volume': 622, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013656839, 'volatility_last_month': 0.010900262, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.119047619', 'winloss_ratio_last_month': '3.119047619', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-25 12:00', 'high': 89.86, 'open': 89.81, 'close': 89.1, 'low': 89.01, 'volume': 514, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013663673, 'volatility_last_month': 0.010957814, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.118787879', 'winloss_ratio_last_month': '3.118787879', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-25 13:00', 'high': 89.36, 'open': 89.09, 'close': 87.62, 'low': 87.2, 'volume': 3281, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014099062, 'volatility_last_month': 0.011193111, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.118568233', 'winloss_ratio_last_month': '3.118568233', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-25 14:00', 'high': 88.41, 'open': 87.63, 'close': 88.0, 'low': 87.56, 'volume': 805, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014121532, 'volatility_last_month': 0.011138507, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.118380062', 'winloss_ratio_last_month': '3.118380062', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-25 15:00', 'high': 89.95, 'open': 88.02, 'close': 89.78, 'low': 87.9, 'volume': 2058, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015035468, 'volatility_last_month': 0.011371519, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.118217054', 'winloss_ratio_last_month': '3.118217054', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-25 16:00', 'high': 89.75, 'open': 89.75, 'close': 88.5, 'low': 88.24, 'volume': 1479, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015162154, 'volatility_last_month': 0.011527744, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.118074478', 'winloss_ratio_last_month': '3.118074478', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-26 07:00', 'high': 90.2, 'open': 89.04, 'close': 89.49, 'low': 88.59, 'volume': 786, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015453605, 'volatility_last_month': 0.011604918, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117948718', 'winloss_ratio_last_month': '3.117948718', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-26 08:00', 'high': 89.59, 'open': 89.54, 'close': 88.5, 'low': 88.06, 'volume': 942, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015602718, 'volatility_last_month': 0.011686669, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117836965', 'winloss_ratio_last_month': '3.117836965', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-26 09:00', 'high': 89.87, 'open': 88.46, 'close': 89.71, 'low': 88.33, 'volume': 1794, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015988419, 'volatility_last_month': 0.011810706, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117737003', 'winloss_ratio_last_month': '3.117737003', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-26 10:00', 'high': 90.11, 'open': 89.67, 'close': 89.69, 'low': 89.1, 'volume': 1142, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015637518, 'volatility_last_month': 0.011799626, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117647059', 'winloss_ratio_last_month': '3.117647059', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-26 11:00', 'high': 90.02, 'open': 89.68, 'close': 89.57, 'low': 88.9, 'volume': 1110, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015637635, 'volatility_last_month': 0.011798143, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117565698', 'winloss_ratio_last_month': '3.117565698', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-26 12:00', 'high': 89.69, 'open': 89.56, 'close': 89.67, 'low': 89.16, 'volume': 687, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015570924, 'volatility_last_month': 0.011798219, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117491749', 'winloss_ratio_last_month': '3.117491749', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-26 13:00', 'high': 89.77, 'open': 89.7, 'close': 88.92, 'low': 88.73, 'volume': 968, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015646885, 'volatility_last_month': 0.011825785, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117424242', 'winloss_ratio_last_month': '3.117424242', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-26 14:00', 'high': 89.36, 'open': 88.96, 'close': 88.88, 'low': 88.86, 'volume': 619, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015579326, 'volatility_last_month': 0.011826579, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117362371', 'winloss_ratio_last_month': '3.117362371', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-26 15:00', 'high': 89.87, 'open': 88.91, 'close': 89.78, 'low': 88.73, 'volume': 880, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015840589, 'volatility_last_month': 0.011836352, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117305459', 'winloss_ratio_last_month': '3.117305459', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-26 16:00', 'high': 90.49, 'open': 89.86, 'close': 90.45, 'low': 89.82, 'volume': 1910, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015920552, 'volatility_last_month': 0.011868897, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117252931', 'winloss_ratio_last_month': '3.117252931', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-29 07:00', 'high': 90.5, 'open': 90.5, 'close': 88.7, 'low': 88.49, 'volume': 562, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016381808, 'volatility_last_month': 0.012163003, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117204301', 'winloss_ratio_last_month': '3.117204301', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-29 08:00', 'high': 89.76, 'open': 88.66, 'close': 89.53, 'low': 88.36, 'volume': 627, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016584061, 'volatility_last_month': 0.012202131, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.11715915', 'winloss_ratio_last_month': '3.11715915', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-29 09:00', 'high': 90.15, 'open': 89.45, 'close': 89.9, 'low': 89.14, 'volume': 879, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016619928, 'volatility_last_month': 0.012210583, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117117117', 'winloss_ratio_last_month': '3.117117117', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-29 10:00', 'high': 89.93, 'open': 89.93, 'close': 89.44, 'low': 89.25, 'volume': 780, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016618743, 'volatility_last_month': 0.012228497, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117077891', 'winloss_ratio_last_month': '3.117077891', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-29 11:00', 'high': 90.09, 'open': 89.41, 'close': 90.06, 'low': 89.35, 'volume': 689, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016542185, 'volatility_last_month': 0.012256643, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117041199', 'winloss_ratio_last_month': '3.117041199', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-29 12:00', 'high': 90.23, 'open': 90.03, 'close': 90.11, 'low': 89.65, 'volume': 404, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016540474, 'volatility_last_month': 0.012252983, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.117006803', 'winloss_ratio_last_month': '3.117006803', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-29 13:00', 'high': 90.5, 'open': 90.14, 'close': 88.47, 'low': 88.37, 'volume': 1349, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016309843, 'volatility_last_month': 0.012498214, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116974494', 'winloss_ratio_last_month': '3.116974494', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-29 14:00', 'high': 88.45, 'open': 88.45, 'close': 87.14, 'low': 86.5, 'volume': 3190, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016499154, 'volatility_last_month': 0.01260734, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116944089', 'winloss_ratio_last_month': '3.116944089', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-29 15:00', 'high': 87.47, 'open': 87.14, 'close': 86.57, 'low': 86.5, 'volume': 1246, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016483251, 'volatility_last_month': 0.012639927, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116915423', 'winloss_ratio_last_month': '3.116915423', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-29 16:00', 'high': 86.84, 'open': 86.57, 'close': 86.28, 'low': 85.69, 'volume': 2189, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016243011, 'volatility_last_month': 0.012639767, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116888351', 'winloss_ratio_last_month': '3.116888351', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-30 07:00', 'high': 86.55, 'open': 86.28, 'close': 86.0, 'low': 85.6, 'volume': 798, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016074258, 'volatility_last_month': 0.012647968, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116862745', 'winloss_ratio_last_month': '3.116862745', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-30 08:00', 'high': 86.89, 'open': 85.99, 'close': 86.44, 'low': 85.73, 'volume': 1189, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016093165, 'volatility_last_month': 0.012663209, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116838488', 'winloss_ratio_last_month': '3.116838488', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-30 09:00', 'high': 86.78, 'open': 86.38, 'close': 86.46, 'low': 86.16, 'volume': 973, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016042526, 'volatility_last_month': 0.012655404, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116815476', 'winloss_ratio_last_month': '3.116815476', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-30 10:00', 'high': 86.5, 'open': 86.43, 'close': 82.08, 'low': 81.68, 'volume': 6184, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018898244, 'volatility_last_month': 0.01440518, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116793616', 'winloss_ratio_last_month': '3.116793616', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-30 11:00', 'high': 82.67, 'open': 81.92, 'close': 81.8, 'low': 81.5, 'volume': 2868, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018833409, 'volatility_last_month': 0.01432869, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116772824', 'winloss_ratio_last_month': '3.116772824', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-30 12:00', 'high': 81.95, 'open': 81.75, 'close': 81.3, 'low': 81.05, 'volume': 2495, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018847342, 'volatility_last_month': 0.014350084, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116753022', 'winloss_ratio_last_month': '3.116753022', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-30 13:00', 'high': 82.24, 'open': 81.36, 'close': 81.69, 'low': 81.36, 'volume': 1565, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.0189029, 'volatility_last_month': 0.014355861, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116734143', 'winloss_ratio_last_month': '3.116734143', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-30 14:00', 'high': 81.89, 'open': 81.63, 'close': 80.9, 'low': 80.5, 'volume': 2495, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018974052, 'volatility_last_month': 0.014404562, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116716123', 'winloss_ratio_last_month': '3.116716123', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-30 15:00', 'high': 80.96, 'open': 80.91, 'close': 80.27, 'low': 79.92, 'volume': 3220, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019011734, 'volatility_last_month': 0.014439365, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116698904', 'winloss_ratio_last_month': '3.116698904', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-30 16:00', 'high': 81.58, 'open': 80.3, 'close': 81.06, 'low': 80.3, 'volume': 3376, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019108166, 'volatility_last_month': 0.014485825, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116682435', 'winloss_ratio_last_month': '3.116682435', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-31 07:00', 'high': 81.6, 'open': 81.06, 'close': 81.3, 'low': 80.5, 'volume': 1164, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01906852, 'volatility_last_month': 0.014488749, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116666667', 'winloss_ratio_last_month': '3.116666667', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-31 08:00', 'high': 81.61, 'open': 81.3, 'close': 81.15, 'low': 80.96, 'volume': 1579, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.46, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018876805, 'volatility_last_month': 0.014365846, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.116651556', 'winloss_ratio_last_month': '3.116651556', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-31 09:00', 'high': 81.34, 'open': 81.12, 'close': 79.8, 'low': 79.75, 'volume': 3300, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.68, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019148916, 'volatility_last_month': 0.014512366, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.75, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.157768446', 'winloss_ratio_last_month': '3.116637063', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-31 10:00', 'high': 80.69, 'open': 79.75, 'close': 80.36, 'low': 79.0, 'volume': 4491, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 1.01, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019250693, 'volatility_last_month': 0.014541008, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.15838047', 'winloss_ratio_last_month': '3.116623151', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-31 11:00', 'high': 80.36, 'open': 80.36, 'close': 80.16, 'low': 79.43, 'volume': 1157, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': -0.1, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019100066, 'volatility_last_month': 0.014534591, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.093380615', 'winloss_ratio_last_month': '3.116609784', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-31 12:00', 'high': 80.69, 'open': 80.1, 'close': 80.16, 'low': 80.08, 'volume': 1085, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.37, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019022857, 'volatility_last_month': 0.014525998, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.115233786', 'winloss_ratio_last_month': '3.116596932', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-31 13:00', 'high': 80.69, 'open': 80.14, 'close': 79.76, 'low': 79.75, 'volume': 1813, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019028839, 'volatility_last_month': 0.014539647, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.115218731', 'winloss_ratio_last_month': '3.116584565', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-31 14:00', 'high': 80.55, 'open': 79.75, 'close': 80.0, 'low': 79.75, 'volume': 2783, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019064144, 'volatility_last_month': 0.01454179, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.115203022', 'winloss_ratio_last_month': '3.116572656', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-31 15:00', 'high': 80.25, 'open': 80.03, 'close': 80.18, 'low': 79.75, 'volume': 1657, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019094324, 'volatility_last_month': 0.014543016, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.115186615', 'winloss_ratio_last_month': '3.116561181', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-08-31 16:00', 'high': 80.86, 'open': 80.21, 'close': 80.62, 'low': 80.0, 'volume': 1771, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019146706, 'volatility_last_month': 0.014562259, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.115169464', 'winloss_ratio_last_month': '3.116550117', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-01 07:00', 'high': 80.56, 'open': 80.41, 'close': 79.38, 'low': 78.91, 'volume': 1600, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01929931, 'volatility_last_month': 0.014696554, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.115151515', 'winloss_ratio_last_month': '3.11653944', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-01 08:00', 'high': 79.47, 'open': 79.36, 'close': 78.35, 'low': 78.3, 'volume': 3082, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019280919, 'volatility_last_month': 0.014764393, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.115132713', 'winloss_ratio_last_month': '3.116529132', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-01 09:00', 'high': 78.51, 'open': 78.35, 'close': 77.78, 'low': 77.76, 'volume': 2547, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019295866, 'volatility_last_month': 0.014792065, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.115112994', 'winloss_ratio_last_month': '3.116519174', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-01 10:00', 'high': 78.57, 'open': 77.75, 'close': 78.38, 'low': 77.45, 'volume': 3177, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019413671, 'volatility_last_month': 0.014808181, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.115092291', 'winloss_ratio_last_month': '3.116509548', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-01 11:00', 'high': 79.79, 'open': 78.38, 'close': 79.64, 'low': 78.2, 'volume': 2655, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019767098, 'volatility_last_month': 0.01495545, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.115070527', 'winloss_ratio_last_month': '3.116500238', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-01 12:00', 'high': 79.69, 'open': 79.62, 'close': 79.12, 'low': 78.88, 'volume': 1527, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019745666, 'volatility_last_month': 0.014967731, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.115047619', 'winloss_ratio_last_month': '3.116491228', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-01 13:00', 'high': 79.5, 'open': 79.06, 'close': 78.88, 'low': 78.82, 'volume': 1006, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019437048, 'volatility_last_month': 0.014967225, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.115023474', 'winloss_ratio_last_month': '3.116482505', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-01 14:00', 'high': 79.11, 'open': 78.9, 'close': 78.44, 'low': 78.0, 'volume': 2071, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.0193909, 'volatility_last_month': 0.014978051, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.11499799', 'winloss_ratio_last_month': '3.116474054', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-01 15:00', 'high': 81.03, 'open': 78.43, 'close': 80.22, 'low': 78.17, 'volume': 2975, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019558704, 'volatility_last_month': 0.015275611, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.11497105', 'winloss_ratio_last_month': '3.116465863', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-01 16:00', 'high': 81.35, 'open': 80.29, 'close': 80.94, 'low': 80.0, 'volume': 2001, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019511079, 'volatility_last_month': 0.015322251, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114942529', 'winloss_ratio_last_month': '3.116457921', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-02 07:00', 'high': 81.22, 'open': 80.75, 'close': 79.88, 'low': 79.75, 'volume': 770, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019439202, 'volatility_last_month': 0.015412652, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114912281', 'winloss_ratio_last_month': '3.116450216', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-02 08:00', 'high': 80.17, 'open': 79.89, 'close': 80.0, 'low': 79.75, 'volume': 1232, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019339215, 'volatility_last_month': 0.015404582, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114880145', 'winloss_ratio_last_month': '3.116442738', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-02 09:00', 'high': 80.0, 'open': 79.98, 'close': 79.46, 'low': 79.1, 'volume': 2132, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018995088, 'volatility_last_month': 0.015416308, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114845938', 'winloss_ratio_last_month': '3.116435477', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-02 10:00', 'high': 79.51, 'open': 79.46, 'close': 78.92, 'low': 78.51, 'volume': 2975, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019015649, 'volatility_last_month': 0.015439482, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114809455', 'winloss_ratio_last_month': '3.116428423', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-02 11:00', 'high': 79.26, 'open': 78.93, 'close': 78.54, 'low': 78.32, 'volume': 2122, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019020818, 'volatility_last_month': 0.015449498, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114770459', 'winloss_ratio_last_month': '3.116421569', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-02 12:00', 'high': 78.63, 'open': 78.56, 'close': 77.87, 'low': 77.8, 'volume': 1776, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019050137, 'volatility_last_month': 0.015480101, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114728682', 'winloss_ratio_last_month': '3.116414904', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-02 13:00', 'high': 78.99, 'open': 77.91, 'close': 78.05, 'low': 77.91, 'volume': 1492, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01903944, 'volatility_last_month': 0.015474289, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114683816', 'winloss_ratio_last_month': '3.116408423', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-02 14:00', 'high': 78.29, 'open': 78.06, 'close': 77.71, 'low': 77.45, 'volume': 1978, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019036607, 'volatility_last_month': 0.015456189, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114635504', 'winloss_ratio_last_month': '3.116402116', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-02 15:00', 'high': 78.46, 'open': 77.64, 'close': 77.7, 'low': 77.6, 'volume': 1104, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018795457, 'volatility_last_month': 0.015456067, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114583333', 'winloss_ratio_last_month': '3.116395978', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-02 16:00', 'high': 78.19, 'open': 77.67, 'close': 77.91, 'low': 77.6, 'volume': 1522, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018678922, 'volatility_last_month': 0.015456453, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114526823', 'winloss_ratio_last_month': '3.116390002', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-05 07:00', 'high': 77.49, 'open': 77.49, 'close': 75.83, 'low': 75.61, 'volume': 2215, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019142675, 'volatility_last_month': 0.015847959, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114465409', 'winloss_ratio_last_month': '3.116384181', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-05 08:00', 'high': 75.95, 'open': 75.83, 'close': 74.95, 'low': 74.6, 'volume': 3939, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01900542, 'volatility_last_month': 0.015908779, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114398422', 'winloss_ratio_last_month': '3.116378509', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-05 09:00', 'high': 75.3, 'open': 74.95, 'close': 74.5, 'low': 74.5, 'volume': 2290, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018921978, 'volatility_last_month': 0.015916157, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114325069', 'winloss_ratio_last_month': '3.116372981', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-05 10:00', 'high': 74.7, 'open': 74.5, 'close': 74.51, 'low': 74.1, 'volume': 2678, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018941317, 'volatility_last_month': 0.015916485, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114244396', 'winloss_ratio_last_month': '3.116367591', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-05 11:00', 'high': 75.25, 'open': 74.51, 'close': 75.04, 'low': 74.47, 'volume': 1720, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018947141, 'volatility_last_month': 0.015942729, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114155251', 'winloss_ratio_last_month': '3.116362334', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-05 12:00', 'high': 75.24, 'open': 75.08, 'close': 75.03, 'low': 74.98, 'volume': 1364, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018939048, 'volatility_last_month': 0.01592058, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.114056225', 'winloss_ratio_last_month': '3.116357206', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-05 13:00', 'high': 75.03, 'open': 75.03, 'close': 74.62, 'low': 74.62, 'volume': 1164, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018607294, 'volatility_last_month': 0.015909027, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.113945578', 'winloss_ratio_last_month': '3.116352201', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-05 14:00', 'high': 74.74, 'open': 74.62, 'close': 74.52, 'low': 74.37, 'volume': 1070, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018393344, 'volatility_last_month': 0.015908059, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.113821138', 'winloss_ratio_last_month': '3.116347316', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-05 15:00', 'high': 74.89, 'open': 74.5, 'close': 74.6, 'low': 73.73, 'volume': 3605, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018400701, 'volatility_last_month': 0.015889454, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.113680154', 'winloss_ratio_last_month': '3.116342545', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-05 16:00', 'high': 74.64, 'open': 74.6, 'close': 73.94, 'low': 73.7, 'volume': 3692, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018454114, 'volatility_last_month': 0.01587324, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.113519092', 'winloss_ratio_last_month': '3.116337886', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-06 07:00', 'high': 74.71, 'open': 74.0, 'close': 73.38, 'low': 73.25, 'volume': 1445, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018485278, 'volatility_last_month': 0.015895013, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.113333333', 'winloss_ratio_last_month': '3.116333333', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-06 08:00', 'high': 73.39, 'open': 73.36, 'close': 72.37, 'low': 71.5, 'volume': 4205, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018545133, 'volatility_last_month': 0.015987899, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.113116727', 'winloss_ratio_last_month': '3.116328884', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-06 09:00', 'high': 72.37, 'open': 72.35, 'close': 71.7, 'low': 71.0, 'volume': 3089, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018571358, 'volatility_last_month': 0.016016029, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.112860892', 'winloss_ratio_last_month': '3.116324536', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-06 10:00', 'high': 72.37, 'open': 71.71, 'close': 72.26, 'low': 71.36, 'volume': 2532, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014641316, 'volatility_last_month': 0.016029631, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.112554113', 'winloss_ratio_last_month': '3.116320284', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-06 11:00', 'high': 72.34, 'open': 72.26, 'close': 72.08, 'low': 71.71, 'volume': 1292, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014639804, 'volatility_last_month': 0.016020385, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.112179487', 'winloss_ratio_last_month': '3.116316125', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-06 12:00', 'high': 74.2, 'open': 72.07, 'close': 72.58, 'low': 71.96, 'volume': 3300, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014782315, 'volatility_last_month': 0.016049723, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.111711712', 'winloss_ratio_last_month': '3.116312057', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-06 13:00', 'high': 72.72, 'open': 72.52, 'close': 71.24, 'low': 70.1, 'volume': 3347, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015180442, 'volatility_last_month': 0.016219213, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.111111111', 'winloss_ratio_last_month': '3.116308076', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-06 14:00', 'high': 71.35, 'open': 71.2, 'close': 69.85, 'low': 69.4, 'volume': 3971, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01562389, 'volatility_last_month': 0.016365621, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.110311751', 'winloss_ratio_last_month': '3.11630418', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-06 15:00', 'high': 70.55, 'open': 69.84, 'close': 69.77, 'low': 69.2, 'volume': 4028, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015584205, 'volatility_last_month': 0.016336188, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.109195402', 'winloss_ratio_last_month': '3.116300366', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-06 16:00', 'high': 70.45, 'open': 69.74, 'close': 69.51, 'low': 69.27, 'volume': 2920, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015282849, 'volatility_last_month': 0.016324022, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.107526882', 'winloss_ratio_last_month': '3.116296632', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-07 07:00', 'high': 70.4, 'open': 69.51, 'close': 70.13, 'low': 69.51, 'volume': 749, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015481009, 'volatility_last_month': 0.016358971, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.104761905', 'winloss_ratio_last_month': '3.116292975', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-07 08:00', 'high': 70.83, 'open': 70.13, 'close': 70.49, 'low': 69.98, 'volume': 2224, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015597811, 'volatility_last_month': 0.016370783, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.09929078', 'winloss_ratio_last_month': '3.116289392', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-07 09:00', 'high': 71.11, 'open': 70.49, 'close': 70.84, 'low': 70.35, 'volume': 2772, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01532974, 'volatility_last_month': 0.016378731, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '2.361702128', 'winloss_ratio_last_month': '3.116285882', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-07 10:00', 'high': 71.03, 'open': 70.83, 'close': 70.75, 'low': 70.2, 'volume': 2867, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015163469, 'volatility_last_month': 0.016360586, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.574468085', 'winloss_ratio_last_month': '3.116282442', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-07 11:00', 'high': 70.91, 'open': 70.71, 'close': 70.59, 'low': 70.53, 'volume': 1504, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015163613, 'volatility_last_month': 0.01636153, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.11627907', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-07 12:00', 'high': 70.75, 'open': 70.6, 'close': 70.03, 'low': 69.96, 'volume': 1988, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015204705, 'volatility_last_month': 0.01638704, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116275764', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-07 13:00', 'high': 70.41, 'open': 70.01, 'close': 70.36, 'low': 69.6, 'volume': 2508, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015294661, 'volatility_last_month': 0.016392156, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116272523', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-07 14:00', 'high': 70.7, 'open': 70.33, 'close': 68.52, 'low': 68.38, 'volume': 4614, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01625814, 'volatility_last_month': 0.01664806, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116269343', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-07 15:00', 'high': 69.2, 'open': 68.52, 'close': 68.73, 'low': 68.0, 'volume': 3346, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016274668, 'volatility_last_month': 0.016651355, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116266225', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-07 16:00', 'high': 70.17, 'open': 68.77, 'close': 69.31, 'low': 68.76, 'volume': 2570, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016377969, 'volatility_last_month': 0.016690953, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116263165', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-08 07:00', 'high': 69.31, 'open': 69.31, 'close': 68.79, 'low': 68.42, 'volume': 636, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01613592, 'volatility_last_month': 0.016710353, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116260163', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-08 08:00', 'high': 68.87, 'open': 68.85, 'close': 66.47, 'low': 66.0, 'volume': 3654, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017629783, 'volatility_last_month': 0.017269274, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116257216', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-08 09:00', 'high': 67.8, 'open': 66.47, 'close': 67.42, 'low': 66.29, 'volume': 1986, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01808172, 'volatility_last_month': 0.01730483, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116254323', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-08 10:00', 'high': 67.47, 'open': 67.47, 'close': 66.55, 'low': 66.1, 'volume': 1844, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018054454, 'volatility_last_month': 0.017359029, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116251483', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-08 11:00', 'high': 67.0, 'open': 66.54, 'close': 66.84, 'low': 66.36, 'volume': 1362, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017546377, 'volatility_last_month': 0.017356301, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116248694', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-08 12:00', 'high': 67.26, 'open': 66.77, 'close': 66.89, 'low': 66.66, 'volume': 1307, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017559066, 'volatility_last_month': 0.017337442, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116245955', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-08 13:00', 'high': 67.28, 'open': 66.87, 'close': 67.11, 'low': 66.55, 'volume': 1595, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017629801, 'volatility_last_month': 0.017342705, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116243264', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-08 14:00', 'high': 68.28, 'open': 67.11, 'close': 67.96, 'low': 66.55, 'volume': 3039, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018014441, 'volatility_last_month': 0.017432356, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116240621', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-08 15:00', 'high': 68.2, 'open': 67.97, 'close': 67.19, 'low': 67.1, 'volume': 942, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017044031, 'volatility_last_month': 0.017482955, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116238023', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-08 16:00', 'high': 67.87, 'open': 67.22, 'close': 67.2, 'low': 67.01, 'volume': 2216, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016799535, 'volatility_last_month': 0.017468027, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116235471', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-09 07:00', 'high': 67.95, 'open': 67.05, 'close': 67.28, 'low': 67.0, 'volume': 696, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016679883, 'volatility_last_month': 0.017456449, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116232962', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-09 08:00', 'high': 67.39, 'open': 67.34, 'close': 66.3, 'low': 66.21, 'volume': 1674, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016846114, 'volatility_last_month': 0.017539958, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116230495', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-09 09:00', 'high': 66.3, 'open': 66.3, 'close': 66.06, 'low': 65.55, 'volume': 3461, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016830244, 'volatility_last_month': 0.017540905, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.11622807', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-09 10:00', 'high': 67.07, 'open': 66.06, 'close': 66.27, 'low': 65.6, 'volume': 2141, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016890713, 'volatility_last_month': 0.017548364, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116225686', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-09 11:00', 'high': 66.95, 'open': 66.23, 'close': 66.5, 'low': 66.22, 'volume': 1016, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016967729, 'volatility_last_month': 0.017531905, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116223341', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-09 12:00', 'high': 66.67, 'open': 66.48, 'close': 66.31, 'low': 65.72, 'volume': 1807, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016920337, 'volatility_last_month': 0.017532575, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116221034', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-09 13:00', 'high': 66.97, 'open': 66.26, 'close': 66.97, 'low': 66.07, 'volume': 1177, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017157693, 'volatility_last_month': 0.017582261, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116218765', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-09 14:00', 'high': 67.11, 'open': 66.95, 'close': 66.77, 'low': 66.55, 'volume': 1615, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017154785, 'volatility_last_month': 0.017580607, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116216532', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-09 15:00', 'high': 66.76, 'open': 66.75, 'close': 65.93, 'low': 65.87, 'volume': 1636, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017290351, 'volatility_last_month': 0.017622264, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116214335', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-09 16:00', 'high': 66.19, 'open': 65.93, 'close': 66.19, 'low': 65.82, 'volume': 1525, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017317482, 'volatility_last_month': 0.017635344, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116212174', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-12 07:00', 'high': 66.35, 'open': 65.99, 'close': 66.21, 'low': 65.8, 'volume': 669, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016344335, 'volatility_last_month': 0.017636614, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116210046', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-12 08:00', 'high': 66.64, 'open': 66.17, 'close': 66.26, 'low': 66.03, 'volume': 1663, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016218152, 'volatility_last_month': 0.017612671, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116207951', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-12 09:00', 'high': 66.3, 'open': 66.25, 'close': 65.93, 'low': 65.73, 'volume': 1587, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016206987, 'volatility_last_month': 0.017602863, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116205889', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-12 10:00', 'high': 68.19, 'open': 65.96, 'close': 68.05, 'low': 65.96, 'volume': 3218, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018113276, 'volatility_last_month': 0.018132459, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116203859', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-12 11:00', 'high': 68.21, 'open': 68.04, 'close': 67.86, 'low': 67.73, 'volume': 995, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017985258, 'volatility_last_month': 0.01811016, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116201859', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-12 12:00', 'high': 68.1, 'open': 67.87, 'close': 67.89, 'low': 67.55, 'volume': 868, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017989237, 'volatility_last_month': 0.018111125, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.11619989', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-12 13:00', 'high': 69.6, 'open': 67.87, 'close': 69.23, 'low': 67.87, 'volume': 3154, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018679731, 'volatility_last_month': 0.01831505, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116197951', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-12 14:00', 'high': 69.85, 'open': 69.07, 'close': 69.66, 'low': 68.51, 'volume': 1263, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018768938, 'volatility_last_month': 0.018330369, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.11619604', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-12 15:00', 'high': 71.3, 'open': 69.63, 'close': 71.07, 'low': 69.3, 'volume': 2354, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019438864, 'volatility_last_month': 0.018480133, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116194158', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-12 16:00', 'high': 72.62, 'open': 71.1, 'close': 72.04, 'low': 71.05, 'volume': 3969, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019642613, 'volatility_last_month': 0.018581951, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116192304', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-13 07:00', 'high': 72.14, 'open': 71.99, 'close': 70.99, 'low': 70.55, 'volume': 1296, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019860459, 'volatility_last_month': 0.01866423, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116190476', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-13 08:00', 'high': 71.53, 'open': 71.02, 'close': 70.54, 'low': 70.31, 'volume': 1913, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019652232, 'volatility_last_month': 0.018647685, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116188675', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-13 09:00', 'high': 71.27, 'open': 70.56, 'close': 71.25, 'low': 70.5, 'volume': 1449, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019690465, 'volatility_last_month': 0.018700876, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.1161869', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-13 10:00', 'high': 72.44, 'open': 71.2, 'close': 71.62, 'low': 70.96, 'volume': 3499, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019639953, 'volatility_last_month': 0.018718626, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116185151', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-13 11:00', 'high': 71.95, 'open': 71.57, 'close': 71.47, 'low': 71.31, 'volume': 888, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019637479, 'volatility_last_month': 0.018671745, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116183426', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-13 12:00', 'high': 71.38, 'open': 71.37, 'close': 70.42, 'low': 70.25, 'volume': 1408, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019862711, 'volatility_last_month': 0.018756416, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116181725', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-13 13:00', 'high': 70.54, 'open': 70.37, 'close': 69.13, 'low': 68.01, 'volume': 2984, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019855084, 'volatility_last_month': 0.018888241, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116180049', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-13 14:00', 'high': 70.69, 'open': 69.16, 'close': 70.2, 'low': 68.64, 'volume': 2223, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019658629, 'volatility_last_month': 0.01899379, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116178395', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-13 15:00', 'high': 70.18, 'open': 70.14, 'close': 69.41, 'low': 69.05, 'volume': 1506, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019843427, 'volatility_last_month': 0.019034826, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116176765', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-13 16:00', 'high': 70.78, 'open': 69.46, 'close': 70.23, 'low': 69.28, 'volume': 2018, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.020020297, 'volatility_last_month': 0.01910324, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116175156', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-14 07:00', 'high': 70.51, 'open': 70.49, 'close': 69.61, 'low': 69.18, 'volume': 900, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.020021435, 'volatility_last_month': 0.019106113, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.11617357', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-14 08:00', 'high': 70.25, 'open': 69.68, 'close': 69.57, 'low': 69.08, 'volume': 1561, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019980786, 'volatility_last_month': 0.019030403, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116172005', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-14 09:00', 'high': 69.8, 'open': 69.52, 'close': 68.8, 'low': 68.41, 'volume': 2416, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.020103953, 'volatility_last_month': 0.018998912, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116170461', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-14 10:00', 'high': 70.61, 'open': 68.89, 'close': 69.91, 'low': 68.81, 'volume': 1637, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.46, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.020485356, 'volatility_last_month': 0.01913597, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116168938', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-14 11:00', 'high': 70.05, 'open': 69.82, 'close': 69.99, 'low': 69.15, 'volume': 994, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 0.08, 'pnl_last_month': 1.54, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.020481951, 'volatility_last_month': 0.019118881, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.116935484', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-14 12:00', 'high': 70.43, 'open': 70.0, 'close': 70.01, 'low': 69.56, 'volume': 1044, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 0.1, 'pnl_last_month': 1.56, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.020394726, 'volatility_last_month': 0.019117824, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.714285714, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.11788269', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-14 13:00', 'high': 71.97, 'open': 70.01, 'close': 71.72, 'low': 69.48, 'volume': 1381, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 1.81, 'pnl_last_month': 3.27, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.021161097, 'volatility_last_month': 0.019412956, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.75, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.135019898', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-14 14:00', 'high': 73.8, 'open': 71.66, 'close': 72.14, 'low': 71.4, 'volume': 3923, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 2.23, 'pnl_last_month': 3.69, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.020166244, 'volatility_last_month': 0.019421219, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.777777778, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '3.155888167', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-14 15:00', 'high': 72.36, 'open': 72.14, 'close': 72.11, 'low': 71.56, 'volume': 1330, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.020162995, 'volatility_last_month': 0.019377895, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '215', 'winloss_ratio_last_month': '3.175596073', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-14 16:00', 'high': 72.67, 'open': 72.06, 'close': 72.05, 'low': 71.7, 'volume': 1876, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.020085866, 'volatility_last_month': 0.01936501, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '144.6666667', 'winloss_ratio_last_month': '3.195040401', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-15 07:00', 'high': 72.55, 'open': 72.38, 'close': 71.23, 'low': 70.66, 'volume': 881, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.020200347, 'volatility_last_month': 0.0194084, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '121.2222222', 'winloss_ratio_last_month': '3.214226405', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-15 08:00', 'high': 71.68, 'open': 71.24, 'close': 71.18, 'low': 70.75, 'volume': 1182, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018338117, 'volatility_last_month': 0.019404732, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '109.5', 'winloss_ratio_last_month': '3.233159197', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-15 09:00', 'high': 71.67, 'open': 71.14, 'close': 71.44, 'low': 70.65, 'volume': 3692, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018082802, 'volatility_last_month': 0.019333683, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '102.4666667', 'winloss_ratio_last_month': '3.251843759', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-15 10:00', 'high': 71.69, 'open': 71.37, 'close': 70.53, 'low': 70.22, 'volume': 3088, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018075257, 'volatility_last_month': 0.019387782, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '97.77777778', 'winloss_ratio_last_month': '3.270284939', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-15 11:00', 'high': 70.89, 'open': 70.56, 'close': 70.6, 'low': 70.3, 'volume': 697, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018058758, 'volatility_last_month': 0.019385081, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '94.42857143', 'winloss_ratio_last_month': '3.288487463', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-15 12:00', 'high': 70.71, 'open': 70.61, 'close': 70.32, 'low': 70.21, 'volume': 1404, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01809878, 'volatility_last_month': 0.019366703, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '91.91666667', 'winloss_ratio_last_month': '3.306455934', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-15 13:00', 'high': 70.76, 'open': 70.3, 'close': 70.5, 'low': 70.0, 'volume': 967, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01809432, 'volatility_last_month': 0.01936484, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '89.96296296', 'winloss_ratio_last_month': '3.324194836', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-15 14:00', 'high': 71.8, 'open': 70.53, 'close': 71.57, 'low': 70.53, 'volume': 1483, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018195084, 'volatility_last_month': 0.019477098, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '88.4', 'winloss_ratio_last_month': '3.341708543', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-15 15:00', 'high': 72.32, 'open': 71.61, 'close': 71.73, 'low': 71.61, 'volume': 1456, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017945203, 'volatility_last_month': 0.019482674, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '87.12121212', 'winloss_ratio_last_month': '3.359001314', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-15 16:00', 'high': 72.32, 'open': 71.73, 'close': 72.11, 'low': 71.66, 'volume': 1442, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017967558, 'volatility_last_month': 0.019459367, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '86.05555556', 'winloss_ratio_last_month': '3.376077305', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-16 07:00', 'high': 72.24, 'open': 71.85, 'close': 71.94, 'low': 71.5, 'volume': 359, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017989852, 'volatility_last_month': 0.01945648, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '85.15384615', 'winloss_ratio_last_month': '3.392940566', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-16 08:00', 'high': 72.16, 'open': 71.98, 'close': 71.93, 'low': 71.31, 'volume': 590, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017571245, 'volatility_last_month': 0.019452618, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '84.38095238', 'winloss_ratio_last_month': '3.409595048', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-16 09:00', 'high': 71.98, 'open': 71.94, 'close': 71.57, 'low': 71.11, 'volume': 1142, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.0175983, 'volatility_last_month': 0.019450428, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '83.71111111', 'winloss_ratio_last_month': '3.426044604', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-16 10:00', 'high': 73.32, 'open': 71.54, 'close': 72.8, 'low': 71.28, 'volume': 1600, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017973975, 'volatility_last_month': 0.019518127, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '83.125', 'winloss_ratio_last_month': '3.442292994', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-16 11:00', 'high': 73.32, 'open': 72.86, 'close': 73.1, 'low': 72.8, 'volume': 856, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017977924, 'volatility_last_month': 0.019531952, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '82.60784314', 'winloss_ratio_last_month': '3.458343885', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-16 12:00', 'high': 73.5, 'open': 73.15, 'close': 72.84, 'low': 72.83, 'volume': 935, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017989384, 'volatility_last_month': 0.019515828, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '82.14814815', 'winloss_ratio_last_month': '3.474200856', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-16 13:00', 'high': 73.16, 'open': 72.88, 'close': 72.97, 'low': 72.6, 'volume': 848, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017883862, 'volatility_last_month': 0.019519289, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '81.73684211', 'winloss_ratio_last_month': '3.489867401', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-16 14:00', 'high': 73.06, 'open': 72.98, 'close': 72.88, 'low': 72.61, 'volume': 640, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017862087, 'volatility_last_month': 0.019504517, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '81.36666667', 'winloss_ratio_last_month': '3.505346929', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-16 15:00', 'high': 73.93, 'open': 72.84, 'close': 73.46, 'low': 72.77, 'volume': 1322, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017572694, 'volatility_last_month': 0.019543451, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '81.03174603', 'winloss_ratio_last_month': '3.520642769', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-16 16:00', 'high': 73.51, 'open': 73.47, 'close': 73.26, 'low': 72.71, 'volume': 1495, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017605236, 'volatility_last_month': 0.01952793, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '80.72727273', 'winloss_ratio_last_month': '3.535758172', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-19 07:00', 'high': 73.8, 'open': 73.32, 'close': 72.77, 'low': 72.66, 'volume': 432, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017723328, 'volatility_last_month': 0.019512944, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '80.44927536', 'winloss_ratio_last_month': '3.550696311', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-19 08:00', 'high': 72.79, 'open': 72.7, 'close': 71.49, 'low': 71.36, 'volume': 1016, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018326377, 'volatility_last_month': 0.019627168, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '80.19444444', 'winloss_ratio_last_month': '3.565460287', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-19 09:00', 'high': 72.6, 'open': 71.55, 'close': 72.0, 'low': 70.83, 'volume': 1479, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018304205, 'volatility_last_month': 0.019654919, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '79.96', 'winloss_ratio_last_month': '3.580053127', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-19 10:00', 'high': 71.86, 'open': 71.86, 'close': 70.98, 'low': 70.58, 'volume': 1454, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017203555, 'volatility_last_month': 0.019720326, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '79.74358974', 'winloss_ratio_last_month': '3.594477791', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-19 11:00', 'high': 71.19, 'open': 70.93, 'close': 71.09, 'low': 70.42, 'volume': 762, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017181573, 'volatility_last_month': 0.019674405, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '79.54320988', 'winloss_ratio_last_month': '3.608737169', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-19 12:00', 'high': 71.18, 'open': 71.08, 'close': 70.86, 'low': 70.42, 'volume': 1019, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017209851, 'volatility_last_month': 0.019674257, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '79.35714286', 'winloss_ratio_last_month': '3.622834085', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-19 13:00', 'high': 70.95, 'open': 70.77, 'close': 70.5, 'low': 70.48, 'volume': 850, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016655322, 'volatility_last_month': 0.019495782, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '79.18390805', 'winloss_ratio_last_month': '3.6367713', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-19 14:00', 'high': 71.14, 'open': 70.52, 'close': 70.46, 'low': 70.25, 'volume': 1037, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016596396, 'volatility_last_month': 0.019459041, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '79.02222222', 'winloss_ratio_last_month': '3.650551514', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-19 15:00', 'high': 70.75, 'open': 70.5, 'close': 70.68, 'low': 70.25, 'volume': 733, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015905516, 'volatility_last_month': 0.01945181, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '78.87096774', 'winloss_ratio_last_month': '3.664177363', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-19 16:00', 'high': 71.35, 'open': 70.63, 'close': 70.34, 'low': 70.15, 'volume': 1874, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015592224, 'volatility_last_month': 0.01939443, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '78.72916667', 'winloss_ratio_last_month': '3.677651427', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-20 07:00', 'high': 71.5, 'open': 70.55, 'close': 70.78, 'low': 70.55, 'volume': 895, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015283552, 'volatility_last_month': 0.019378883, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '78.5959596', 'winloss_ratio_last_month': '3.690976229', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-20 08:00', 'high': 71.63, 'open': 70.82, 'close': 71.4, 'low': 70.71, 'volume': 1289, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015345354, 'volatility_last_month': 0.019407711, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '78.47058824', 'winloss_ratio_last_month': '3.704154235', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-20 09:00', 'high': 71.5, 'open': 71.4, 'close': 70.83, 'low': 70.26, 'volume': 1289, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015281756, 'volatility_last_month': 0.019414353, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '78.35238095', 'winloss_ratio_last_month': '3.717187857', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-20 10:00', 'high': 73.0, 'open': 70.82, 'close': 72.17, 'low': 70.74, 'volume': 2866, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015880553, 'volatility_last_month': 0.019226718, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '78.24074074', 'winloss_ratio_last_month': '3.730079455', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-20 11:00', 'high': 72.29, 'open': 72.18, 'close': 72.03, 'low': 71.7, 'volume': 600, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015879332, 'volatility_last_month': 0.019201536, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '78.13513514', 'winloss_ratio_last_month': '3.742831339', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-20 12:00', 'high': 72.12, 'open': 72.03, 'close': 72.09, 'low': 71.56, 'volume': 906, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015460043, 'volatility_last_month': 0.019201436, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '78.03508772', 'winloss_ratio_last_month': '3.755445767', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-20 13:00', 'high': 72.51, 'open': 72.12, 'close': 71.62, 'low': 71.62, 'volume': 1114, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014865347, 'volatility_last_month': 0.01921241, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '77.94017094', 'winloss_ratio_last_month': '3.76792495', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-20 14:00', 'high': 71.69, 'open': 71.64, 'close': 70.76, 'low': 70.6, 'volume': 1296, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014736541, 'volatility_last_month': 0.019257508, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '77.85', 'winloss_ratio_last_month': '3.780271051', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-20 15:00', 'high': 71.46, 'open': 70.75, 'close': 71.0, 'low': 70.73, 'volume': 1171, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01448897, 'volatility_last_month': 0.019260466, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '77.76422764', 'winloss_ratio_last_month': '3.792486188', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-20 16:00', 'high': 71.42, 'open': 71.01, 'close': 71.04, 'low': 70.87, 'volume': 1158, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014228241, 'volatility_last_month': 0.019233898, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '77.68253968', 'winloss_ratio_last_month': '3.804572434', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-21 07:00', 'high': 71.79, 'open': 71.79, 'close': 71.2, 'low': 70.38, 'volume': 630, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014063044, 'volatility_last_month': 0.019221336, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '77.60465116', 'winloss_ratio_last_month': '3.816531817', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-21 08:00', 'high': 71.46, 'open': 71.25, 'close': 70.7, 'low': 70.58, 'volume': 658, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014173896, 'volatility_last_month': 0.019188934, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '77.53030303', 'winloss_ratio_last_month': '3.828366326', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-21 09:00', 'high': 71.01, 'open': 70.73, 'close': 70.92, 'low': 69.84, 'volume': 2124, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.88, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013912528, 'volatility_last_month': 0.019191465, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.777777778, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '77.45925926', 'winloss_ratio_last_month': '3.852304798', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-21 10:00', 'high': 71.15, 'open': 70.86, 'close': 69.89, 'low': 69.7, 'volume': 1754, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 2.2, 'pnl_last_month': 3.21, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01386337, 'volatility_last_month': 0.0192553, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.75, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '77.39130435', 'winloss_ratio_last_month': '3.866469271', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-21 11:00', 'high': 70.54, 'open': 69.89, 'close': 70.16, 'low': 69.83, 'volume': 988, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 2.12, 'pnl_last_month': 2.1, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013891268, 'volatility_last_month': 0.019211795, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.75, 'win_ratio_last_month': 0.714285714, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '77.21276596', 'winloss_ratio_last_month': '3.864576577', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-21 12:00', 'high': 70.42, 'open': 70.18, 'close': 70.2, 'low': 69.95, 'volume': 998, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 2.1, 'pnl_last_month': 2.57, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013891712, 'volatility_last_month': 0.019194333, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.833333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '77.01388889', 'winloss_ratio_last_month': '3.888985444', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-21 13:00', 'high': 70.45, 'open': 70.24, 'close': 69.35, 'low': 69.1, 'volume': 3231, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 0.39, 'pnl_last_month': 2.2, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.012910968, 'volatility_last_month': 0.019244573, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.8, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '74.49659864', 'winloss_ratio_last_month': '3.908244197', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-21 14:00', 'high': 70.01, 'open': 69.35, 'close': 69.73, 'low': 69.31, 'volume': 1343, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 2, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': -0.03, 'pnl_last_month': 2.2, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.012900391, 'volatility_last_month': 0.019264433, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.8, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '71.52', 'winloss_ratio_last_month': '3.927688821', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-21 15:00', 'high': 70.03, 'open': 69.73, 'close': 69.54, 'low': 69.52, 'volume': 752, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 2, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 0.19, 'pnl_last_month': 2.39, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.012909366, 'volatility_last_month': 0.01926498, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.833333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '71.59183673', 'winloss_ratio_last_month': '3.948717949', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-21 16:00', 'high': 70.0, 'open': 69.57, 'close': 69.85, 'low': 69.57, 'volume': 1025, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': -0.12, 'pnl_last_month': 2.08, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.012967683, 'volatility_last_month': 0.01927266, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.714285714, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '58.97142857', 'winloss_ratio_last_month': '3.960886859', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-22 07:00', 'high': 70.09, 'open': 69.9, 'close': 69.13, 'low': 69.01, 'volume': 967, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': 0.6, 'pnl_last_month': 2.8, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.012917977, 'volatility_last_month': 0.019268283, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.75, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '50.18719212', 'winloss_ratio_last_month': '3.978435861', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-22 08:00', 'high': 70.07, 'open': 69.16, 'close': 69.87, 'low': 69.1, 'volume': 1172, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': -0.14, 'pnl_last_month': 2.06, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013191287, 'volatility_last_month': 0.019286938, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '32.9704918', 'winloss_ratio_last_month': '3.974300442', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-22 09:00', 'high': 70.48, 'open': 69.87, 'close': 70.32, 'low': 69.72, 'volume': 1288, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01325625, 'volatility_last_month': 0.019302531, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '21.95575221', 'winloss_ratio_last_month': '3.957113583', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-22 10:00', 'high': 70.75, 'open': 70.31, 'close': 70.1, 'low': 69.95, 'volume': 1365, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.012925629, 'volatility_last_month': 0.019297832, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '16.34724541', 'winloss_ratio_last_month': '3.940136747', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-22 11:00', 'high': 70.66, 'open': 70.12, 'close': 70.49, 'low': 70.11, 'volume': 637, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.012993401, 'volatility_last_month': 0.019284332, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '12.94906166', 'winloss_ratio_last_month': '3.923366108', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-22 12:00', 'high': 70.71, 'open': 70.54, 'close': 70.21, 'low': 70.07, 'volume': 764, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01299351, 'volatility_last_month': 0.019266673, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.66965286', 'winloss_ratio_last_month': '3.90679793', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-22 13:00', 'high': 70.66, 'open': 70.21, 'close': 70.46, 'low': 70.15, 'volume': 822, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013007114, 'volatility_last_month': 0.019166428, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '9.034615385', 'winloss_ratio_last_month': '3.890428571', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-22 14:00', 'high': 70.95, 'open': 70.43, 'close': 70.42, 'low': 70.2, 'volume': 914, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.012483273, 'volatility_last_month': 0.019153183, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '7.804549284', 'winloss_ratio_last_month': '3.874254473', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-22 15:00', 'high': 71.12, 'open': 70.42, 'close': 70.74, 'low': 70.42, 'volume': 718, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.012522327, 'volatility_last_month': 0.018963361, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '6.845577211', 'winloss_ratio_last_month': '3.858272163', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-22 16:00', 'high': 70.72, 'open': 70.72, 'close': 70.01, 'low': 69.92, 'volume': 1835, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.012673387, 'volatility_last_month': 0.01892244, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '6.076975017', 'winloss_ratio_last_month': '3.842478249', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-23 07:00', 'high': 70.1, 'open': 70.01, 'close': 69.84, 'low': 69.47, 'volume': 381, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.012673968, 'volatility_last_month': 0.018853164, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '5.447174447', 'winloss_ratio_last_month': '3.82686942', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-23 08:00', 'high': 70.3, 'open': 69.83, 'close': 69.83, 'low': 69.63, 'volume': 1015, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.012673959, 'volatility_last_month': 0.018808338, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '4.921690141', 'winloss_ratio_last_month': '3.811442441', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-23 09:00', 'high': 69.83, 'open': 69.83, 'close': 68.59, 'low': 68.37, 'volume': 2370, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013288999, 'volatility_last_month': 0.018834673, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '4.476586889', 'winloss_ratio_last_month': '3.796194153', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-23 10:00', 'high': 68.69, 'open': 68.62, 'close': 67.21, 'low': 66.75, 'volume': 4599, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013356547, 'volatility_last_month': 0.018998748, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '4.094731754', 'winloss_ratio_last_month': '3.78112147', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-23 11:00', 'high': 67.5, 'open': 67.22, 'close': 67.09, 'low': 66.54, 'volume': 1644, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01328462, 'volatility_last_month': 0.018998797, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.763537906', 'winloss_ratio_last_month': '3.766221374', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-23 12:00', 'high': 67.7, 'open': 67.21, 'close': 67.67, 'low': 66.93, 'volume': 707, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013500111, 'volatility_last_month': 0.019041857, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.473550571', 'winloss_ratio_last_month': '3.751490919', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-23 13:00', 'high': 67.89, 'open': 67.66, 'close': 67.37, 'low': 67.16, 'volume': 929, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013494672, 'volatility_last_month': 0.019023704, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.21752988', 'winloss_ratio_last_month': '3.736927224', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-23 14:00', 'high': 67.4, 'open': 67.4, 'close': 66.72, 'low': 66.37, 'volume': 1844, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013630608, 'volatility_last_month': 0.019054616, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '2.989838163', 'winloss_ratio_last_month': '3.722527473', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-23 15:00', 'high': 66.97, 'open': 66.75, 'close': 65.38, 'low': 65.35, 'volume': 2139, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014114726, 'volatility_last_month': 0.019153998, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '2.786019971', 'winloss_ratio_last_month': '3.708288913', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-23 16:00', 'high': 66.41, 'open': 65.4, 'close': 66.0, 'low': 65.35, 'volume': 2501, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014388326, 'volatility_last_month': 0.019172205, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '2.602507625', 'winloss_ratio_last_month': '3.694208852', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-26 07:00', 'high': 66.6, 'open': 65.15, 'close': 66.19, 'low': 64.1, 'volume': 2738, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014391134, 'volatility_last_month': 0.01903327, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '2.436410587', 'winloss_ratio_last_month': '3.68028466', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-26 08:00', 'high': 66.44, 'open': 66.1, 'close': 65.57, 'low': 65.5, 'volume': 819, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013993087, 'volatility_last_month': 0.019008251, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '2.285362096', 'winloss_ratio_last_month': '3.666513761', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-26 09:00', 'high': 66.09, 'open': 65.51, 'close': 65.95, 'low': 65.28, 'volume': 1433, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.013948204, 'volatility_last_month': 0.019018091, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '2.14740566', 'winloss_ratio_last_month': '3.652893639', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-26 10:00', 'high': 67.35, 'open': 65.96, 'close': 67.06, 'low': 65.93, 'volume': 1224, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014283883, 'volatility_last_month': 0.019162654, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '2.020909862', 'winloss_ratio_last_month': '3.63942183', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-26 11:00', 'high': 67.07, 'open': 67.07, 'close': 66.33, 'low': 65.92, 'volume': 858, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014456026, 'volatility_last_month': 0.019171304, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.904503527', 'winloss_ratio_last_month': '3.626095926', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-26 12:00', 'high': 66.88, 'open': 66.36, 'close': 66.4, 'low': 66.3, 'volume': 851, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014460308, 'volatility_last_month': 0.019172361, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.797025828', 'winloss_ratio_last_month': '3.612913568', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-26 13:00', 'high': 66.7, 'open': 66.4, 'close': 66.25, 'low': 66.0, 'volume': 900, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014433147, 'volatility_last_month': 0.019042993, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.697487437', 'winloss_ratio_last_month': '3.599872449', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-26 14:00', 'high': 66.3, 'open': 66.27, 'close': 65.89, 'low': 65.6, 'volume': 1225, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.014466532, 'volatility_last_month': 0.018964238, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.605039981', 'winloss_ratio_last_month': '3.586970312', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-26 15:00', 'high': 71.95, 'open': 65.86, 'close': 70.95, 'low': 65.73, 'volume': 4793, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023004474, 'volatility_last_month': 0.021387259, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.518951802', 'winloss_ratio_last_month': '3.574204947', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-26 16:00', 'high': 70.95, 'open': 70.9, 'close': 70.0, 'low': 69.9, 'volume': 3171, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023201788, 'volatility_last_month': 0.021448055, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.438588555', 'winloss_ratio_last_month': '3.56157419', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-27 07:00', 'high': 71.1, 'open': 70.33, 'close': 69.41, 'low': 68.96, 'volume': 826, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023233528, 'volatility_last_month': 0.021468325, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.363397548', 'winloss_ratio_last_month': '3.549075924', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-27 08:00', 'high': 69.53, 'open': 69.41, 'close': 68.8, 'low': 68.72, 'volume': 852, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023211177, 'volatility_last_month': 0.021476847, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.292895016', 'winloss_ratio_last_month': '3.536708075', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-27 09:00', 'high': 68.82, 'open': 68.79, 'close': 68.12, 'low': 68.01, 'volume': 2153, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023250228, 'volatility_last_month': 0.021507138, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.226655697', 'winloss_ratio_last_month': '3.524468611', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-27 10:00', 'high': 68.72, 'open': 68.15, 'close': 67.65, 'low': 67.5, 'volume': 1532, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022804301, 'volatility_last_month': 0.02044738, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.164304252', 'winloss_ratio_last_month': '3.512355545', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-27 11:00', 'high': 68.5, 'open': 67.66, 'close': 68.05, 'low': 67.66, 'volume': 644, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.0228674, 'volatility_last_month': 0.020464654, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.105508146', 'winloss_ratio_last_month': '3.500366928', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-27 12:00', 'high': 68.34, 'open': 68.08, 'close': 67.73, 'low': 67.5, 'volume': 759, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022877849, 'volatility_last_month': 0.020459227, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.049971714', 'winloss_ratio_last_month': '3.488500852', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-27 13:00', 'high': 68.09, 'open': 67.69, 'close': 67.72, 'low': 67.37, 'volume': 1334, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022843046, 'volatility_last_month': 0.020445622, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.997431193', 'winloss_ratio_last_month': '3.476755448', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-27 14:00', 'high': 68.41, 'open': 67.74, 'close': 67.88, 'low': 67.5, 'volume': 1598, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022701149, 'volatility_last_month': 0.020417141, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.947650527', 'winloss_ratio_last_month': '3.465128885', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-27 15:00', 'high': 68.21, 'open': 67.84, 'close': 67.73, 'low': 67.6, 'volume': 1083, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022680203, 'volatility_last_month': 0.020397315, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.900417827', 'winloss_ratio_last_month': '3.453619367', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-27 16:00', 'high': 68.29, 'open': 67.75, 'close': 68.21, 'low': 67.72, 'volume': 1361, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022757488, 'volatility_last_month': 0.020375688, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.855542353', 'winloss_ratio_last_month': '3.442225137', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-28 07:00', 'high': 68.27, 'open': 67.8, 'close': 67.51, 'low': 67.26, 'volume': 923, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.02285568, 'volatility_last_month': 0.020407181, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.812851938', 'winloss_ratio_last_month': '3.430944471', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-28 08:00', 'high': 67.55, 'open': 67.48, 'close': 66.51, 'low': 65.5, 'volume': 2263, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023050213, 'volatility_last_month': 0.020489708, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.772190784', 'winloss_ratio_last_month': '3.419775679', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-28 09:00', 'high': 66.51, 'open': 66.51, 'close': 65.63, 'low': 65.25, 'volume': 2396, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023202412, 'volatility_last_month': 0.020448429, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.733417562', 'winloss_ratio_last_month': '3.408717105', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-28 10:00', 'high': 65.83, 'open': 65.6, 'close': 65.51, 'low': 65.09, 'volume': 2033, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022985079, 'volatility_last_month': 0.020421549, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.696403766', 'winloss_ratio_last_month': '3.397767126', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-28 11:00', 'high': 65.89, 'open': 65.51, 'close': 65.68, 'low': 65.42, 'volume': 905, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022970722, 'volatility_last_month': 0.02042613, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.662239662', 'winloss_ratio_last_month': '3.386924151', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-28 12:00', 'high': 65.71, 'open': 65.7, 'close': 65.68, 'low': 65.45, 'volume': 770, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022968421, 'volatility_last_month': 0.02042613, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.629853831', 'winloss_ratio_last_month': '3.376186617', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-28 13:00', 'high': 65.89, 'open': 65.63, 'close': 65.23, 'low': 65.09, 'volume': 1843, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022858347, 'volatility_last_month': 0.020433998, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.623554913', 'winloss_ratio_last_month': '3.365552995', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-28 14:00', 'high': 65.39, 'open': 65.23, 'close': 65.08, 'low': 64.37, 'volume': 2716, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.02280184, 'volatility_last_month': 0.020427812, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.623461157', 'winloss_ratio_last_month': '3.355021784', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-28 15:00', 'high': 65.13, 'open': 64.98, 'close': 65.01, 'low': 64.45, 'volume': 1267, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.78, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022799591, 'volatility_last_month': 0.020423212, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.25, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.617846751', 'winloss_ratio_last_month': '3.344591511', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-28 16:00', 'high': 65.46, 'open': 65.01, 'close': 65.22, 'low': 64.84, 'volume': 1547, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.47, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022783533, 'volatility_last_month': 0.020412872, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.617659138', 'winloss_ratio_last_month': '3.334260731', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-29 07:00', 'high': 65.53, 'open': 65.53, 'close': 64.93, 'low': 64.64, 'volume': 707, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -1.19, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022692344, 'volatility_last_month': 0.020328554, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.597998106', 'winloss_ratio_last_month': '3.324028029', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-29 08:00', 'high': 65.42, 'open': 64.9, 'close': 64.46, 'low': 64.14, 'volume': 1481, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': -0.45, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022549854, 'volatility_last_month': 0.020283041, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.590481386', 'winloss_ratio_last_month': '3.313892013', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-29 09:00', 'high': 65.15, 'open': 64.5, 'close': 64.86, 'low': 64.14, 'volume': 1656, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.022545128, 'volatility_last_month': 0.020287483, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.590143394', 'winloss_ratio_last_month': '3.303851321', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-29 10:00', 'high': 66.51, 'open': 64.81, 'close': 65.76, 'low': 64.81, 'volume': 1522, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.02283585, 'volatility_last_month': 0.020348124, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.589790985', 'winloss_ratio_last_month': '3.293904613', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-29 11:00', 'high': 66.86, 'open': 65.74, 'close': 66.83, 'low': 65.72, 'volume': 992, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023154674, 'volatility_last_month': 0.020350909, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.589423217', 'winloss_ratio_last_month': '3.284050577', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-29 12:00', 'high': 66.83, 'open': 66.8, 'close': 65.25, 'low': 64.28, 'volume': 1802, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023775856, 'volatility_last_month': 0.020562335, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.589039061', 'winloss_ratio_last_month': '3.274287922', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-29 13:00', 'high': 65.39, 'open': 65.25, 'close': 65.29, 'low': 64.49, 'volume': 1749, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023750341, 'volatility_last_month': 0.020561538, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.588637399', 'winloss_ratio_last_month': '3.264615385', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-29 14:00', 'high': 65.32, 'open': 65.28, 'close': 64.55, 'low': 64.55, 'volume': 1050, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023864354, 'volatility_last_month': 0.020598495, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.588217006', 'winloss_ratio_last_month': '3.255031722', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-29 15:00', 'high': 65.99, 'open': 64.52, 'close': 65.95, 'low': 64.48, 'volume': 1158, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.02445211, 'volatility_last_month': 0.020579484, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.58777654', 'winloss_ratio_last_month': '3.245535714', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-29 16:00', 'high': 66.2, 'open': 65.95, 'close': 65.83, 'low': 65.16, 'volume': 1340, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.024355619, 'volatility_last_month': 0.020537977, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.587314528', 'winloss_ratio_last_month': '3.236126165', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-30 07:00', 'high': 66.27, 'open': 64.86, 'close': 66.01, 'low': 64.86, 'volume': 294, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.024371841, 'volatility_last_month': 0.020480883, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.58682935', 'winloss_ratio_last_month': '3.226801899', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-30 08:00', 'high': 66.65, 'open': 66.04, 'close': 66.55, 'low': 65.61, 'volume': 715, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.024470533, 'volatility_last_month': 0.020513359, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.586319218', 'winloss_ratio_last_month': '3.217561762', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-30 09:00', 'high': 67.9, 'open': 66.61, 'close': 67.72, 'low': 65.76, 'volume': 1597, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.024500821, 'volatility_last_month': 0.020639873, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.585782157', 'winloss_ratio_last_month': '3.208404619', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-30 10:00', 'high': 67.76, 'open': 67.76, 'close': 66.92, 'low': 66.1, 'volume': 1620, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.024182568, 'volatility_last_month': 0.020676269, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.585215977', 'winloss_ratio_last_month': '3.199329359', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-30 11:00', 'high': 67.55, 'open': 66.84, 'close': 66.98, 'low': 66.69, 'volume': 612, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.024180102, 'volatility_last_month': 0.020670712, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.584618243', 'winloss_ratio_last_month': '3.190334888', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-30 12:00', 'high': 67.75, 'open': 67.01, 'close': 67.56, 'low': 66.95, 'volume': 622, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.024180392, 'volatility_last_month': 0.020681992, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.583986241', 'winloss_ratio_last_month': '3.181420131', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-30 13:00', 'high': 68.66, 'open': 67.48, 'close': 67.07, 'low': 67.05, 'volume': 1550, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.024219547, 'volatility_last_month': 0.0206961, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.583316939', 'winloss_ratio_last_month': '3.172584034', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-30 14:00', 'high': 67.35, 'open': 67.07, 'close': 67.22, 'low': 66.2, 'volume': 1395, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.024112623, 'volatility_last_month': 0.020694302, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.582606933', 'winloss_ratio_last_month': '3.16382556', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-30 15:00', 'high': 67.68, 'open': 67.21, 'close': 67.28, 'low': 66.89, 'volume': 852, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.02359818, 'volatility_last_month': 0.020695249, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.581852394', 'winloss_ratio_last_month': '3.15514369', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-09-30 16:00', 'high': 67.27, 'open': 67.27, 'close': 66.13, 'low': 66.1, 'volume': 1277, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023870581, 'volatility_last_month': 0.020803855, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.581048996', 'winloss_ratio_last_month': '3.146537425', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-03 07:00', 'high': 66.73, 'open': 66.55, 'close': 65.03, 'low': 64.86, 'volume': 601, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.02419048, 'volatility_last_month': 0.020621481, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.580191836', 'winloss_ratio_last_month': '3.13800578', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-03 08:00', 'high': 65.0, 'open': 65.0, 'close': 64.82, 'low': 64.25, 'volume': 1589, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.024101619, 'volatility_last_month': 0.020573717, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.579275329', 'winloss_ratio_last_month': '3.12954779', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-03 09:00', 'high': 65.37, 'open': 64.73, 'close': 65.17, 'low': 64.64, 'volume': 1401, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.024096148, 'volatility_last_month': 0.02057737, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.578293091', 'winloss_ratio_last_month': '3.121162505', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-03 10:00', 'high': 65.75, 'open': 65.1, 'close': 65.1, 'low': 64.73, 'volume': 1679, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023744343, 'volatility_last_month': 0.020577163, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.577237788', 'winloss_ratio_last_month': '3.112848991', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-03 11:00', 'high': 66.06, 'open': 65.1, 'close': 65.72, 'low': 64.87, 'volume': 779, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023727276, 'volatility_last_month': 0.020595238, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.576100953', 'winloss_ratio_last_month': '3.104606331', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-03 12:00', 'high': 66.1, 'open': 65.71, 'close': 65.62, 'low': 65.44, 'volume': 1062, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023727423, 'volatility_last_month': 0.020595428, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.574872756', 'winloss_ratio_last_month': '3.096433623', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-03 13:00', 'high': 65.74, 'open': 65.52, 'close': 64.73, 'low': 64.65, 'volume': 1427, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023936947, 'volatility_last_month': 0.020656605, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.573541725', 'winloss_ratio_last_month': '3.08832998', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-03 14:00', 'high': 64.87, 'open': 64.71, 'close': 64.73, 'low': 64.43, 'volume': 1798, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.023906579, 'volatility_last_month': 0.020656646, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.572094378', 'winloss_ratio_last_month': '3.08029453', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-03 15:00', 'high': 66.4, 'open': 64.73, 'close': 65.9, 'low': 64.73, 'volume': 1180, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016394199, 'volatility_last_month': 0.020800085, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.570514773', 'winloss_ratio_last_month': '3.072326417', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-03 16:00', 'high': 66.68, 'open': 65.9, 'close': 65.76, 'low': 65.75, 'volume': 1043, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016135255, 'volatility_last_month': 0.020772362, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.568783913', 'winloss_ratio_last_month': '3.064424796', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-04 07:00', 'high': 68.33, 'open': 66.62, 'close': 67.25, 'low': 66.39, 'volume': 1348, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016984728, 'volatility_last_month': 0.020970139, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.566878981', 'winloss_ratio_last_month': '3.056588841', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-04 08:00', 'high': 67.57, 'open': 67.23, 'close': 66.44, 'low': 66.32, 'volume': 1029, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017093818, 'volatility_last_month': 0.020951998, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.564772326', 'winloss_ratio_last_month': '3.048817734', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-04 09:00', 'high': 66.65, 'open': 66.44, 'close': 66.47, 'low': 65.83, 'volume': 1433, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016948106, 'volatility_last_month': 0.020919428, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.562430116', 'winloss_ratio_last_month': '3.041110675', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-04 10:00', 'high': 67.21, 'open': 66.55, 'close': 66.33, 'low': 66.28, 'volume': 1618, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01688131, 'volatility_last_month': 0.020892793, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.559810501', 'winloss_ratio_last_month': '3.033466875', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-04 11:00', 'high': 66.52, 'open': 66.33, 'close': 65.99, 'low': 65.85, 'volume': 942, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016848047, 'volatility_last_month': 0.020900234, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.556861099', 'winloss_ratio_last_month': '3.025885559', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-04 12:00', 'high': 66.74, 'open': 65.95, 'close': 66.14, 'low': 65.87, 'volume': 873, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016831885, 'volatility_last_month': 0.020880746, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.55351545', 'winloss_ratio_last_month': '3.018365962', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-04 13:00', 'high': 66.44, 'open': 66.1, 'close': 66.3, 'low': 65.4, 'volume': 1024, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016845775, 'volatility_last_month': 0.020745396, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.54968795', 'winloss_ratio_last_month': '3.010907336', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-04 14:00', 'high': 68.35, 'open': 66.3, 'close': 67.75, 'low': 66.27, 'volume': 2213, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017633275, 'volatility_last_month': 0.020787654, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.545266425', 'winloss_ratio_last_month': '3.003508941', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-04 15:00', 'high': 67.81, 'open': 67.73, 'close': 67.09, 'low': 67.01, 'volume': 889, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017778383, 'volatility_last_month': 0.020825883, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.540100953', 'winloss_ratio_last_month': '2.99617005', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-04 16:00', 'high': 67.8, 'open': 67.03, 'close': 67.42, 'low': 66.69, 'volume': 1729, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017735066, 'volatility_last_month': 0.02083137, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.533986528', 'winloss_ratio_last_month': '2.988889949', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-05 07:00', 'high': 68.3, 'open': 67.72, 'close': 68.24, 'low': 67.02, 'volume': 587, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 0.82, 'pnl_last_month': 2.43, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017800132, 'volatility_last_month': 0.020859818, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.636363636, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.581928523', 'winloss_ratio_last_month': '2.98556231', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-05 08:00', 'high': 69.93, 'open': 68.23, 'close': 69.93, 'low': 67.83, 'volume': 2179, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 2.51, 'pnl_last_month': 4.12, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018322825, 'volatility_last_month': 0.021098344, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '0.76744186', 'winloss_ratio_last_month': '2.99025544', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-05 09:00', 'high': 71.36, 'open': 69.93, 'close': 70.78, 'low': 69.86, 'volume': 3112, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 3.36, 'pnl_last_month': 4.97, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018172431, 'volatility_last_month': 0.021148445, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.692307692, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.071851226', 'winloss_ratio_last_month': '2.998916321', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-05 10:00', 'high': 71.25, 'open': 70.77, 'close': 70.43, 'low': 70.3, 'volume': 1314, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 3.01, 'pnl_last_month': 4.62, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018220668, 'volatility_last_month': 0.021157839, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.75, 'win_ratio_last_month': 0.642857143, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.416666667', 'winloss_ratio_last_month': '3.002576957', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-05 11:00', 'high': 70.99, 'open': 70.43, 'close': 70.7, 'low': 70.28, 'volume': 622, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018227491, 'volatility_last_month': 0.021161784, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '1.873032529', 'winloss_ratio_last_month': '3.007455037', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-05 12:00', 'high': 70.77, 'open': 70.71, 'close': 69.96, 'low': 69.87, 'volume': 1062, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01844658, 'volatility_last_month': 0.021181196, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '2.454653938', 'winloss_ratio_last_month': '3.01227932', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-05 13:00', 'high': 69.97, 'open': 69.97, 'close': 68.82, 'low': 68.52, 'volume': 2458, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018825136, 'volatility_last_month': 0.021281657, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.221300138', 'winloss_ratio_last_month': '3.017050691', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-05 14:00', 'high': 69.09, 'open': 68.81, 'close': 67.3, 'low': 67.09, 'volume': 1992, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019616895, 'volatility_last_month': 0.02119876, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '4.277960526', 'winloss_ratio_last_month': '3.021770017', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-05 15:00', 'high': 67.69, 'open': 67.27, 'close': 67.17, 'low': 66.54, 'volume': 1899, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01962235, 'volatility_last_month': 0.021196034, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '5.866125761', 'winloss_ratio_last_month': '3.026438144', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-05 16:00', 'high': 67.43, 'open': 67.1, 'close': 66.89, 'low': 66.8, 'volume': 1607, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019645278, 'volatility_last_month': 0.021172708, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '7.782396088', 'winloss_ratio_last_month': '3.031055901', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-06 07:00', 'high': 67.64, 'open': 66.89, 'close': 66.85, 'low': 66.72, 'volume': 451, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019610501, 'volatility_last_month': 0.021150472, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.91076923', 'winloss_ratio_last_month': '3.035624098', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-06 08:00', 'high': 67.32, 'open': 66.78, 'close': 66.54, 'low': 66.3, 'volume': 1152, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019559972, 'volatility_last_month': 0.020671884, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '12.40952381', 'winloss_ratio_last_month': '3.04014353', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-06 09:00', 'high': 66.8, 'open': 66.47, 'close': 66.05, 'low': 65.5, 'volume': 1849, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019603979, 'volatility_last_month': 0.020609195, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '12.20571429', 'winloss_ratio_last_month': '3.044614973', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-06 10:00', 'high': 68.23, 'open': 66.05, 'close': 67.92, 'low': 66.01, 'volume': 1873, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.020431614, 'volatility_last_month': 0.020865218, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '12.03896104', 'winloss_ratio_last_month': '3.049039187', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-06 11:00', 'high': 69.31, 'open': 67.99, 'close': 68.18, 'low': 67.69, 'volume': 1227, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.020100468, 'volatility_last_month': 0.020863467, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.9', 'winloss_ratio_last_month': '3.053416917', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-06 12:00', 'high': 68.58, 'open': 68.22, 'close': 67.81, 'low': 67.81, 'volume': 720, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019274741, 'volatility_last_month': 0.020876, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.78241758', 'winloss_ratio_last_month': '3.057748891', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-06 13:00', 'high': 68.37, 'open': 67.8, 'close': 68.06, 'low': 67.55, 'volume': 797, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019287075, 'volatility_last_month': 0.020877126, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.68163265', 'winloss_ratio_last_month': '3.062035824', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-06 14:00', 'high': 68.92, 'open': 68.04, 'close': 68.05, 'low': 67.79, 'volume': 1330, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019060047, 'volatility_last_month': 0.020811482, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.59428571', 'winloss_ratio_last_month': '3.066278413', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-06 15:00', 'high': 68.53, 'open': 68.05, 'close': 68.14, 'low': 67.96, 'volume': 537, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018407877, 'volatility_last_month': 0.020757501, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.51785714', 'winloss_ratio_last_month': '3.070477343', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-06 16:00', 'high': 69.49, 'open': 68.12, 'close': 69.23, 'low': 68.05, 'volume': 1496, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018747628, 'volatility_last_month': 0.020861258, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.45042017', 'winloss_ratio_last_month': '3.074633286', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-07 07:00', 'high': 69.46, 'open': 69.2, 'close': 68.45, 'low': 68.33, 'volume': 348, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018969523, 'volatility_last_month': 0.02091532, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.39047619', 'winloss_ratio_last_month': '3.078746897', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-07 08:00', 'high': 69.16, 'open': 68.44, 'close': 69.1, 'low': 68.35, 'volume': 645, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019001408, 'volatility_last_month': 0.020859953, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.33684211', 'winloss_ratio_last_month': '3.082818821', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-07 09:00', 'high': 69.49, 'open': 69.15, 'close': 68.03, 'low': 67.82, 'volume': 1435, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018952342, 'volatility_last_month': 0.020957497, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.28857143', 'winloss_ratio_last_month': '3.086849686', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-07 10:00', 'high': 68.73, 'open': 68.04, 'close': 68.7, 'low': 67.63, 'volume': 1447, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018863177, 'volatility_last_month': 0.020992229, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.24489796', 'winloss_ratio_last_month': '3.090840113', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-07 11:00', 'high': 69.24, 'open': 68.75, 'close': 69.1, 'low': 68.27, 'volume': 752, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018904757, 'volatility_last_month': 0.021000852, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.20519481', 'winloss_ratio_last_month': '3.094790705', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-07 12:00', 'high': 69.7, 'open': 69.03, 'close': 69.51, 'low': 68.81, 'volume': 761, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018849658, 'volatility_last_month': 0.021010395, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.1689441', 'winloss_ratio_last_month': '3.098702058', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-07 13:00', 'high': 70.39, 'open': 69.54, 'close': 69.22, 'low': 69.17, 'volume': 1377, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018789628, 'volatility_last_month': 0.020979357, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.13571429', 'winloss_ratio_last_month': '3.102574751', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-07 14:00', 'high': 69.94, 'open': 69.22, 'close': 69.94, 'low': 69.05, 'volume': 743, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018927882, 'volatility_last_month': 0.021017787, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.10514286', 'winloss_ratio_last_month': '3.106409356', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-07 15:00', 'high': 70.1, 'open': 69.95, 'close': 69.51, 'low': 69.25, 'volume': 792, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01899993, 'volatility_last_month': 0.020965831, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.07692308', 'winloss_ratio_last_month': '3.110206431', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-07 16:00', 'high': 70.16, 'open': 69.54, 'close': 68.66, 'low': 68.61, 'volume': 1465, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018766922, 'volatility_last_month': 0.021025102, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.05079365', 'winloss_ratio_last_month': '3.113966526', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-10 07:00', 'high': 69.4, 'open': 68.98, 'close': 68.69, 'low': 68.5, 'volume': 669, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018280372, 'volatility_last_month': 0.021025123, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.02653061', 'winloss_ratio_last_month': '3.117690178', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-10 08:00', 'high': 68.73, 'open': 68.66, 'close': 66.97, 'low': 66.97, 'volume': 1372, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019309003, 'volatility_last_month': 0.021291625, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '11.00394089', 'winloss_ratio_last_month': '3.121377913', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-10 09:00', 'high': 67.14, 'open': 66.94, 'close': 67.14, 'low': 66.79, 'volume': 1601, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019280622, 'volatility_last_month': 0.021283627, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.98285714', 'winloss_ratio_last_month': '3.125030249', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-10 10:00', 'high': 68.45, 'open': 67.11, 'close': 68.07, 'low': 67.11, 'volume': 1262, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019527039, 'volatility_last_month': 0.020949883, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.96313364', 'winloss_ratio_last_month': '3.128647694', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-10 11:00', 'high': 68.17, 'open': 68.08, 'close': 67.78, 'low': 67.59, 'volume': 592, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019451874, 'volatility_last_month': 0.020954199, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.94464286', 'winloss_ratio_last_month': '3.132230745', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-10 12:00', 'high': 68.02, 'open': 67.78, 'close': 67.21, 'low': 67.0, 'volume': 1323, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019564941, 'volatility_last_month': 0.020983476, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.92727273', 'winloss_ratio_last_month': '3.135779889', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-10 13:00', 'high': 67.22, 'open': 67.21, 'close': 66.9, 'low': 66.73, 'volume': 1069, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019305039, 'volatility_last_month': 0.020831304, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.91092437', 'winloss_ratio_last_month': '3.139295607', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-10 14:00', 'high': 67.08, 'open': 66.9, 'close': 66.2, 'low': 66.18, 'volume': 1252, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019486636, 'volatility_last_month': 0.020858485, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.8955102', 'winloss_ratio_last_month': '3.142778369', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-10 15:00', 'high': 66.45, 'open': 66.2, 'close': 66.27, 'low': 65.71, 'volume': 1729, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019022468, 'volatility_last_month': 0.020686112, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.88095238', 'winloss_ratio_last_month': '3.146228634', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-10 16:00', 'high': 67.15, 'open': 66.24, 'close': 66.96, 'low': 66.01, 'volume': 2464, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.019168545, 'volatility_last_month': 0.020652987, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.86718147', 'winloss_ratio_last_month': '3.149646857', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-11 07:00', 'high': 67.4, 'open': 66.9, 'close': 66.96, 'low': 66.38, 'volume': 639, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018408513, 'volatility_last_month': 0.020566137, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.85413534', 'winloss_ratio_last_month': '3.153033481', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-11 08:00', 'high': 67.05, 'open': 66.95, 'close': 66.24, 'low': 65.8, 'volume': 1313, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018361876, 'volatility_last_month': 0.020597279, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.84175824', 'winloss_ratio_last_month': '3.156388943', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-11 09:00', 'high': 66.57, 'open': 66.21, 'close': 66.17, 'low': 65.94, 'volume': 1285, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018362953, 'volatility_last_month': 0.020552176, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.83', 'winloss_ratio_last_month': '3.15971367', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-11 10:00', 'high': 67.1, 'open': 66.18, 'close': 66.34, 'low': 66.0, 'volume': 1083, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018366947, 'volatility_last_month': 0.02054278, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.81881533', 'winloss_ratio_last_month': '3.163008083', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-11 11:00', 'high': 66.42, 'open': 66.27, 'close': 66.2, 'low': 65.65, 'volume': 1380, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018332185, 'volatility_last_month': 0.020542803, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.80816327', 'winloss_ratio_last_month': '3.166272596', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-11 12:00', 'high': 66.53, 'open': 66.2, 'close': 66.04, 'low': 65.82, 'volume': 1259, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.01833353, 'volatility_last_month': 0.020456096, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.79800664', 'winloss_ratio_last_month': '3.169507612', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-11 13:00', 'high': 66.4, 'open': 66.04, 'close': 65.83, 'low': 65.83, 'volume': 908, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.018338776, 'volatility_last_month': 0.020318369, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.78831169', 'winloss_ratio_last_month': '3.17271353', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-11 14:00', 'high': 66.4, 'open': 65.83, 'close': 66.18, 'low': 65.78, 'volume': 781, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 3.28, 'pnl_last_month': 4.89, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017618041, 'volatility_last_month': 0.020226944, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.77904762', 'winloss_ratio_last_month': '3.175890741', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-11 15:00', 'high': 66.85, 'open': 66.14, 'close': 66.35, 'low': 66.06, 'volume': 1825, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 3.11, 'pnl_last_month': 4.72, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017485595, 'volatility_last_month': 0.020177774, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.625, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.65765212', 'winloss_ratio_last_month': '3.177016657', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-11 16:00', 'high': 66.65, 'open': 66.37, 'close': 66.41, 'low': 66.06, 'volume': 2872, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 3.05, 'pnl_last_month': 4.66, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017443784, 'volatility_last_month': 0.020116251, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.571428571, 'win_ratio_last_month': 0.588235294, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.50623145', 'winloss_ratio_last_month': '3.177421156', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-12 07:00', 'high': 66.64, 'open': 66.4, 'close': 66.39, 'low': 66.19, 'volume': 727, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 2.25, 'pnl_last_month': 4.68, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017183274, 'volatility_last_month': 0.020084199, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.571428571, 'win_ratio_last_month': 0.611111111, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '10.27194492', 'winloss_ratio_last_month': '3.177895478', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-12 08:00', 'high': 66.52, 'open': 66.42, 'close': 65.43, 'low': 65.01, 'volume': 1824, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 1.52, 'pnl_last_month': 5.64, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016394704, 'volatility_last_month': 0.020172393, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.571428571, 'win_ratio_last_month': 0.631578947, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '9.918378679', 'winloss_ratio_last_month': '3.18190192', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-12 09:00', 'high': 66.34, 'open': 65.47, 'close': 66.02, 'low': 65.44, 'volume': 2392, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 0.08, 'pnl_last_month': 5.05, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016264479, 'volatility_last_month': 0.02015818, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '9.203336809', 'winloss_ratio_last_month': '3.178992027', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-12 10:00', 'high': 68.64, 'open': 66.01, 'close': 67.89, 'low': 66.0, 'volume': 3191, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 0.43, 'pnl_last_month': 5.05, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017665879, 'volatility_last_month': 0.02038467, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '8.875826972', 'winloss_ratio_last_month': '3.176124605', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-12 11:00', 'high': 68.15, 'open': 67.85, 'close': 67.74, 'low': 67.59, 'volume': 552, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 4.97, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017634308, 'volatility_last_month': 0.020385755, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.578947368, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '8.536779324', 'winloss_ratio_last_month': '3.17272203', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-12 12:00', 'high': 67.77, 'open': 67.75, 'close': 66.67, 'low': 66.6, 'volume': 1188, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 4.95, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017850153, 'volatility_last_month': 0.020490725, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.555555556, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '8.213210296', 'winloss_ratio_last_month': '3.169225264', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-12 13:00', 'high': 67.0, 'open': 66.67, 'close': 66.19, 'low': 66.0, 'volume': 1672, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 3.24, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017521749, 'volatility_last_month': 0.020255906, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '7.904083571', 'winloss_ratio_last_month': '3.153627514', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-12 14:00', 'high': 66.18, 'open': 66.15, 'close': 65.96, 'low': 65.73, 'volume': 1479, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.82, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016740997, 'volatility_last_month': 0.020243075, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '7.608453321', 'winloss_ratio_last_month': '3.135287685', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-12 15:00', 'high': 66.47, 'open': 65.92, 'close': 66.27, 'low': 65.9, 'volume': 1149, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016779997, 'volatility_last_month': 0.020254346, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '7.325454545', 'winloss_ratio_last_month': '3.117861397', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-12 16:00', 'high': 66.92, 'open': 66.21, 'close': 66.48, 'low': 66.21, 'volume': 1904, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016772176, 'volatility_last_month': 0.020259758, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '7.054294615', 'winloss_ratio_last_month': '3.100671608', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-13 07:00', 'high': 66.66, 'open': 66.37, 'close': 66.38, 'low': 66.0, 'volume': 453, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016775041, 'volatility_last_month': 0.02020767, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '6.794245859', 'winloss_ratio_last_month': '3.083713535', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-13 08:00', 'high': 66.45, 'open': 66.36, 'close': 65.98, 'low': 65.75, 'volume': 1126, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016799907, 'volatility_last_month': 0.020221486, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '6.544639043', 'winloss_ratio_last_month': '3.066982525', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-13 09:00', 'high': 67.27, 'open': 65.94, 'close': 66.81, 'low': 65.92, 'volume': 1798, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016971248, 'volatility_last_month': 0.020285567, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '6.304857621', 'winloss_ratio_last_month': '3.050474047', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-13 10:00', 'high': 68.21, 'open': 66.71, 'close': 68.04, 'low': 66.71, 'volume': 1235, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016200129, 'volatility_last_month': 0.020364451, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '6.074332649', 'winloss_ratio_last_month': '3.034183691', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-13 11:00', 'high': 68.4, 'open': 68.05, 'close': 68.21, 'low': 67.51, 'volume': 2049, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016185345, 'volatility_last_month': 0.020366914, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '5.852538276', 'winloss_ratio_last_month': '3.018107162', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-13 12:00', 'high': 68.5, 'open': 68.21, 'close': 67.75, 'low': 67.65, 'volume': 1219, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016214085, 'volatility_last_month': 0.020379286, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '5.638987742', 'winloss_ratio_last_month': '3.002240278', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-13 13:00', 'high': 69.39, 'open': 67.72, 'close': 66.97, 'low': 66.63, 'volume': 3196, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016417694, 'volatility_last_month': 0.020430955, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '5.433229814', 'winloss_ratio_last_month': '2.986578965', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-13 14:00', 'high': 68.34, 'open': 66.99, 'close': 68.27, 'low': 66.76, 'volume': 1641, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017062187, 'volatility_last_month': 0.020492458, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '5.234845597', 'winloss_ratio_last_month': '2.971119253', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-13 15:00', 'high': 69.5, 'open': 68.35, 'close': 68.71, 'low': 68.19, 'volume': 1462, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.017126789, 'volatility_last_month': 0.020508645, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '5.043445693', 'winloss_ratio_last_month': '2.955857274', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-13 16:00', 'high': 69.0, 'open': 68.54, 'close': 68.85, 'low': 68.5, 'volume': 1135, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016706402, 'volatility_last_month': 0.020497952, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '4.858667648', 'winloss_ratio_last_month': '2.940789259', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-14 07:00', 'high': 69.0, 'open': 68.72, 'close': 68.14, 'low': 67.95, 'volume': 667, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016670607, 'volatility_last_month': 0.020539407, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '4.680173661', 'winloss_ratio_last_month': '2.925911534', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-14 08:00', 'high': 68.27, 'open': 68.17, 'close': 67.85, 'low': 67.69, 'volume': 792, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016536372, 'volatility_last_month': 0.020546112, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '4.507648524', 'winloss_ratio_last_month': '2.911220517', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-14 09:00', 'high': 68.13, 'open': 67.86, 'close': 67.97, 'low': 67.7, 'volume': 1343, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.016124089, 'volatility_last_month': 0.02053836, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '4.340797761', 'winloss_ratio_last_month': '2.896712714', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-14 10:00', 'high': 68.17, 'open': 67.96, 'close': 67.9, 'low': 67.71, 'volume': 1263, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015948601, 'volatility_last_month': 0.020410886, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '4.179345955', 'winloss_ratio_last_month': '2.882384721', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-14 11:00', 'high': 68.09, 'open': 67.9, 'close': 67.7, 'low': 67.38, 'volume': 1070, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015893179, 'volatility_last_month': 0.020405187, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '4.02303523', 'winloss_ratio_last_month': '2.868233213', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-14 12:00', 'high': 67.82, 'open': 67.69, 'close': 67.59, 'low': 67.12, 'volume': 1363, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015821137, 'volatility_last_month': 0.02040153, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.871623875', 'winloss_ratio_last_month': '2.854254952', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-14 13:00', 'high': 68.15, 'open': 67.63, 'close': 67.95, 'low': 67.43, 'volume': 889, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015856727, 'volatility_last_month': 0.020413293, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.724885095', 'winloss_ratio_last_month': '2.840446774', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-14 14:00', 'high': 68.15, 'open': 67.94, 'close': 67.62, 'low': 67.37, 'volume': 2229, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 0.0, 'volatility_last_week': 0.015675372, 'volatility_last_month': 0.020421604, 'volatility_last_3months': 0.0, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.0, 'winloss_ratio_last_week': '3.582605884', 'winloss_ratio_last_month': '2.826805594', 'winloss_ratio_last_3month': '-', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-14 15:00', 'high': 68.5, 'open': 67.63, 'close': 68.12, 'low': 67.58, 'volume': 973, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.01573193, 'volatility_last_month': 0.02041778, 'volatility_last_3months': 0.017406247, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '3.444585987', 'winloss_ratio_last_month': '2.813328401', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-14 16:00', 'high': 68.24, 'open': 68.11, 'close': 68.05, 'low': 67.67, 'volume': 1418, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.015466515, 'volatility_last_month': 0.020415606, 'volatility_last_3months': 0.017391801, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '3.310636963', 'winloss_ratio_last_month': '2.800012256', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-17 07:00', 'high': 68.01, 'open': 68.01, 'close': 66.6, 'low': 66.26, 'volume': 721, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.016276183, 'volatility_last_month': 0.02058809, 'volatility_last_3months': 0.017452654, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '3.180581323', 'winloss_ratio_last_month': '2.786854288', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-17 08:00', 'high': 67.0, 'open': 66.66, 'close': 66.69, 'low': 66.37, 'volume': 1005, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.015134658, 'volatility_last_month': 0.020461881, 'volatility_last_3months': 0.017432852, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '3.054251753', 'winloss_ratio_last_month': '2.773851697', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-17 09:00', 'high': 66.99, 'open': 66.69, 'close': 66.93, 'low': 66.58, 'volume': 798, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.015147272, 'volatility_last_month': 0.020444826, 'volatility_last_3months': 0.017434458, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '2.931490385', 'winloss_ratio_last_month': '2.761001746', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-17 10:00', 'high': 67.85, 'open': 66.91, 'close': 67.72, 'low': 66.59, 'volume': 2483, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.015048296, 'volatility_last_month': 0.020423439, 'volatility_last_3months': 0.017325619, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '2.812148148', 'winloss_ratio_last_month': '2.748301763', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-17 11:00', 'high': 68.41, 'open': 67.71, 'close': 67.79, 'low': 67.67, 'volume': 1166, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.015016436, 'volatility_last_month': 0.020422769, 'volatility_last_3months': 0.017325707, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '2.696084161', 'winloss_ratio_last_month': '2.735749137', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-17 12:00', 'high': 68.63, 'open': 67.81, 'close': 68.05, 'low': 67.77, 'volume': 1779, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014901399, 'volatility_last_month': 0.020425859, 'volatility_last_3months': 0.017317407, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '2.583165177', 'winloss_ratio_last_month': '2.72334132', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-17 13:00', 'high': 68.27, 'open': 68.06, 'close': 67.58, 'low': 67.56, 'volume': 1571, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014954841, 'volatility_last_month': 0.020434935, 'volatility_last_3months': 0.01732353, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '2.473265074', 'winloss_ratio_last_month': '2.711075819', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-17 14:00', 'high': 67.71, 'open': 67.56, 'close': 67.67, 'low': 67.43, 'volume': 1053, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014728837, 'volatility_last_month': 0.020435885, 'volatility_last_3months': 0.017322988, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '2.366264384', 'winloss_ratio_last_month': '2.6989502', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-17 15:00', 'high': 68.02, 'open': 67.67, 'close': 67.37, 'low': 67.21, 'volume': 1673, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014773451, 'volatility_last_month': 0.020438728, 'volatility_last_3months': 0.017311899, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '2.262049861', 'winloss_ratio_last_month': '2.686962084', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-17 16:00', 'high': 67.6, 'open': 67.44, 'close': 67.54, 'low': 67.21, 'volume': 1318, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014583116, 'volatility_last_month': 0.020432922, 'volatility_last_3months': 0.017312818, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '2.160514083', 'winloss_ratio_last_month': '2.675109145', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-18 07:00', 'high': 67.37, 'open': 67.29, 'close': 67.07, 'low': 67.01, 'volume': 416, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014681918, 'volatility_last_month': 0.020434469, 'volatility_last_3months': 0.017319791, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '2.061555076', 'winloss_ratio_last_month': '2.663389109', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-18 08:00', 'high': 67.25, 'open': 67.05, 'close': 66.93, 'low': 66.8, 'volume': 1615, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014455364, 'volatility_last_month': 0.020401219, 'volatility_last_3months': 0.017304057, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.96507598', 'winloss_ratio_last_month': '2.651799754', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-18 09:00', 'high': 67.12, 'open': 66.92, 'close': 67.03, 'low': 66.71, 'volume': 1258, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014455212, 'volatility_last_month': 0.020377208, 'volatility_last_3months': 0.017304311, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.870984729', 'winloss_ratio_last_month': '2.640338906', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-18 10:00', 'high': 67.67, 'open': 67.04, 'close': 66.83, 'low': 66.82, 'volume': 1272, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014464369, 'volatility_last_month': 0.020224488, 'volatility_last_3months': 0.017305189, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.779193758', 'winloss_ratio_last_month': '2.629004439', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-18 11:00', 'high': 67.2, 'open': 66.83, 'close': 66.71, 'low': 66.71, 'volume': 921, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014461725, 'volatility_last_month': 0.020224304, 'volatility_last_3months': 0.017302744, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.689619733', 'winloss_ratio_last_month': '2.617794275', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-18 12:00', 'high': 66.82, 'open': 66.72, 'close': 66.76, 'low': 66.51, 'volume': 1944, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014448839, 'volatility_last_month': 0.02022422, 'volatility_last_3months': 0.017283291, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.602183295', 'winloss_ratio_last_month': '2.606706379', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-18 13:00', 'high': 67.99, 'open': 66.77, 'close': 67.9, 'low': 66.6, 'volume': 1323, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014957581, 'volatility_last_month': 0.020335312, 'volatility_last_3months': 0.017331332, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.516808831', 'winloss_ratio_last_month': '2.595738763', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-18 14:00', 'high': 68.39, 'open': 67.9, 'close': 67.97, 'low': 67.83, 'volume': 1563, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.16, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014914909, 'volatility_last_month': 0.020275918, 'volatility_last_3months': 0.017326177, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.43342425', 'winloss_ratio_last_month': '2.584889479', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-18 15:00', 'high': 68.2, 'open': 67.87, 'close': 67.82, 'low': 67.75, 'volume': 1104, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.33, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014920586, 'volatility_last_month': 0.020272063, 'volatility_last_3months': 0.017321959, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.363321799', 'winloss_ratio_last_month': '2.574156623', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-18 16:00', 'high': 67.95, 'open': 67.88, 'close': 67.46, 'low': 67.46, 'volume': 1863, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.39, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014982595, 'volatility_last_month': 0.02028284, 'volatility_last_3months': 0.017325253, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.297504324', 'winloss_ratio_last_month': '2.563538331', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-19 07:00', 'high': 68.0, 'open': 67.25, 'close': 67.76, 'low': 67.25, 'volume': 185, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.37, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.015013657, 'volatility_last_month': 0.020289582, 'volatility_last_3months': 0.017328405, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.250988142', 'winloss_ratio_last_month': '2.553032778', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-19 08:00', 'high': 68.49, 'open': 67.86, 'close': 68.47, 'low': 67.86, 'volume': 961, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': -0.59, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014749667, 'volatility_last_month': 0.020317887, 'volatility_last_3months': 0.017347208, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.198814522', 'winloss_ratio_last_month': '2.542638178', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-19 09:00', 'high': 69.69, 'open': 68.49, 'close': 69.25, 'low': 68.43, 'volume': 2123, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.014834064, 'volatility_last_month': 0.020369569, 'volatility_last_3months': 0.017363738, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.202695829', 'winloss_ratio_last_month': '2.530852897', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-19 10:00', 'high': 69.36, 'open': 69.27, 'close': 68.79, 'low': 68.47, 'volume': 1596, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.013420147, 'volatility_last_month': 0.020297736, 'volatility_last_3months': 0.017369305, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.195844156', 'winloss_ratio_last_month': '2.520997832', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-19 11:00', 'high': 68.87, 'open': 68.77, 'close': 67.85, 'low': 67.8, 'volume': 969, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.013817364, 'volatility_last_month': 0.020370449, 'volatility_last_3months': 0.01739783, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.195859873', 'winloss_ratio_last_month': '2.514210442', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-19 12:00', 'high': 67.87, 'open': 67.83, 'close': 67.63, 'low': 67.5, 'volume': 1352, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.013292589, 'volatility_last_month': 0.020374235, 'volatility_last_3months': 0.017381453, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.195876289', 'winloss_ratio_last_month': '2.50438666', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-19 13:00', 'high': 67.86, 'open': 67.6, 'close': 67.83, 'low': 67.27, 'volume': 1503, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.01318079, 'volatility_last_month': 0.020316477, 'volatility_last_3months': 0.017382869, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.195893452', 'winloss_ratio_last_month': '2.495669973', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-19 14:00', 'high': 67.85, 'open': 67.79, 'close': 67.34, 'low': 66.99, 'volume': 2569, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.013276265, 'volatility_last_month': 0.020324638, 'volatility_last_3months': 0.017390665, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.195911414', 'winloss_ratio_last_month': '2.487073234', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-19 15:00', 'high': 67.77, 'open': 67.35, 'close': 67.21, 'low': 67.08, 'volume': 854, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.66, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.013246635, 'volatility_last_month': 0.020323168, 'volatility_last_3months': 0.01737782, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.195930233', 'winloss_ratio_last_month': '2.477610395', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-19 16:00', 'high': 67.69, 'open': 67.17, 'close': 67.39, 'low': 67.12, 'volume': 1418, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.97, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.013241012, 'volatility_last_month': 0.0203175, 'volatility_last_3months': 0.01737652, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.538461538, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.19594997', 'winloss_ratio_last_month': '2.4722165', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-20 07:00', 'high': 67.66, 'open': 67.23, 'close': 67.61, 'low': 67.11, 'volume': 562, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.25, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.01325256, 'volatility_last_month': 0.020277768, 'volatility_last_3months': 0.017367408, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.195970696', 'winloss_ratio_last_month': '2.463189713', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-20 08:00', 'high': 67.83, 'open': 67.55, 'close': 67.42, 'low': 67.21, 'volume': 644, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.99, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.013184599, 'volatility_last_month': 0.020230838, 'volatility_last_3months': 0.017367549, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.195992486', 'winloss_ratio_last_month': '2.463547529', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-20 09:00', 'high': 67.47, 'open': 67.42, 'close': 66.9, 'low': 66.66, 'volume': 1495, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 3.44, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.012993078, 'volatility_last_month': 0.020236284, 'volatility_last_3months': 0.017375071, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.196015424', 'winloss_ratio_last_month': '2.469582138', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-20 10:00', 'high': 67.45, 'open': 66.9, 'close': 66.9, 'low': 66.7, 'volume': 1257, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 3.44, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.012219803, 'volatility_last_month': 0.020232717, 'volatility_last_3months': 0.017371104, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.196039604', 'winloss_ratio_last_month': '2.475627883', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-20 11:00', 'high': 67.05, 'open': 66.89, 'close': 66.87, 'low': 66.77, 'volume': 1599, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 3.44, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.012200488, 'volatility_last_month': 0.020218293, 'volatility_last_3months': 0.017345451, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.196065129', 'winloss_ratio_last_month': '2.481684794', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-20 12:00', 'high': 67.05, 'open': 66.87, 'close': 66.9, 'low': 66.56, 'volume': 1132, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 3.44, 'pnl_last_3months': 6.51, 'volatility_last_week': 0.01210355, 'volatility_last_month': 0.020212529, 'volatility_last_3months': 0.017344337, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '1.196092114', 'winloss_ratio_last_month': '2.487752901', 'winloss_ratio_last_3month': '2.920353982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-20 13:00', 'high': 67.0, 'open': 66.84, 'close': 66.8, 'low': 66.54, 'volume': 912, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 0.1, 'pnl_last_month': 3.54, 'pnl_last_3months': 6.61, 'volatility_last_week': 0.011793111, 'volatility_last_month': 0.020206954, 'volatility_last_3months': 0.017344308, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.636363636, 'win_ratio_last_3month': 0.615384615, 'winloss_ratio_last_week': '1.199712644', 'winloss_ratio_last_month': '2.494089227', 'winloss_ratio_last_3month': '2.921110355', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-20 14:00', 'high': 67.88, 'open': 66.82, 'close': 66.98, 'low': 66.77, 'volume': 1351, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': -0.08, 'pnl_last_month': 3.36, 'pnl_last_3months': 6.43, 'volatility_last_week': 0.010853216, 'volatility_last_month': 0.020210655, 'volatility_last_3months': 0.01732913, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.592592593, 'winloss_ratio_last_week': '1.195588235', 'winloss_ratio_last_month': '2.499280095', 'winloss_ratio_last_3month': '2.917955516', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-20 15:00', 'high': 67.42, 'open': 66.9, 'close': 67.11, 'low': 66.87, 'volume': 1010, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': -0.21, 'pnl_last_month': 3.23, 'pnl_last_3months': 6.3, 'volatility_last_week': 0.010742964, 'volatility_last_month': 0.020202856, 'volatility_last_3months': 0.017319508, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.538461538, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.18546272', 'winloss_ratio_last_month': '2.503638561', 'winloss_ratio_last_3month': '2.912245483', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-20 16:00', 'high': 67.15, 'open': 67.09, 'close': 66.73, 'low': 66.73, 'volume': 1594, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 0.17, 'pnl_last_month': 3.61, 'pnl_last_3months': 6.68, 'volatility_last_week': 0.010795491, 'volatility_last_month': 0.020171425, 'volatility_last_3months': 0.017322446, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.571428571, 'win_ratio_last_3month': 0.586206897, 'winloss_ratio_last_week': '1.189457601', 'winloss_ratio_last_month': '2.508975414', 'winloss_ratio_last_3month': '2.909484565', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-21 07:00', 'high': 67.16, 'open': 66.71, 'close': 66.84, 'low': 66.66, 'volume': 313, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '-', 'pnl_last_week': 0.06, 'pnl_last_month': 3.5, 'pnl_last_3months': 6.57, 'volatility_last_week': 0.010545575, 'volatility_last_month': 0.020170855, 'volatility_last_3months': 0.017320779, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.566666667, 'winloss_ratio_last_week': '1.188518231', 'winloss_ratio_last_month': '2.513602469', 'winloss_ratio_last_3month': '2.904687394', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-21 08:00', 'high': 67.6, 'open': 66.8, 'close': 67.5, 'low': 66.75, 'volume': 708, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.06, 'pnl_last_month': 3.5, 'pnl_last_3months': 6.57, 'volatility_last_week': 0.010775862, 'volatility_last_month': 0.020214078, 'volatility_last_3months': 0.01733448, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.566666667, 'winloss_ratio_last_week': '1.187549251', 'winloss_ratio_last_month': '2.518228095', 'winloss_ratio_last_3month': '2.900132626', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-21 09:00', 'high': 68.22, 'open': 67.51, 'close': 67.82, 'low': 67.14, 'volume': 1810, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.06, 'pnl_last_month': 3.5, 'pnl_last_3months': 6.57, 'volatility_last_week': 0.01082834, 'volatility_last_month': 0.020087459, 'volatility_last_3months': 0.017338568, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.566666667, 'winloss_ratio_last_week': '1.186549239', 'winloss_ratio_last_month': '2.522852296', 'winloss_ratio_last_3month': '2.895802341', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-21 10:00', 'high': 68.43, 'open': 67.83, 'close': 67.8, 'low': 67.58, 'volume': 1479, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.06, 'pnl_last_month': 3.5, 'pnl_last_3months': 6.57, 'volatility_last_week': 0.010825892, 'volatility_last_month': 0.019908047, 'volatility_last_3months': 0.017337, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.566666667, 'winloss_ratio_last_week': '1.18551668', 'winloss_ratio_last_month': '2.527475069', 'winloss_ratio_last_3month': '2.891680343', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-21 11:00', 'high': 67.97, 'open': 67.83, 'close': 67.92, 'low': 67.73, 'volume': 347, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.06, 'pnl_last_month': 3.5, 'pnl_last_3months': 6.57, 'volatility_last_week': 0.010810607, 'volatility_last_month': 0.019907855, 'volatility_last_3months': 0.017337271, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.566666667, 'winloss_ratio_last_week': '1.184449959', 'winloss_ratio_last_month': '2.532096418', 'winloss_ratio_last_3month': '2.887751957', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-21 12:00', 'high': 68.24, 'open': 67.84, 'close': 67.65, 'low': 67.61, 'volume': 798, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.06, 'pnl_last_month': 3.5, 'pnl_last_3months': 6.57, 'volatility_last_week': 0.010846289, 'volatility_last_month': 0.019882743, 'volatility_last_3months': 0.017339541, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.566666667, 'winloss_ratio_last_week': '1.183347351', 'winloss_ratio_last_month': '2.536716341', 'winloss_ratio_last_3month': '2.884003854', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-21 13:00', 'high': 67.83, 'open': 67.67, 'close': 67.44, 'low': 67.15, 'volume': 964, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.06, 'pnl_last_month': 3.5, 'pnl_last_3months': 6.57, 'volatility_last_week': 0.010793929, 'volatility_last_month': 0.019878336, 'volatility_last_3months': 0.017335339, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.566666667, 'winloss_ratio_last_week': '1.182207015', 'winloss_ratio_last_month': '2.54133484', 'winloss_ratio_last_3month': '2.880423903', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-21 14:00', 'high': 68.1, 'open': 67.43, 'close': 68.0, 'low': 67.25, 'volume': 902, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.06, 'pnl_last_month': 3.5, 'pnl_last_3months': 6.57, 'volatility_last_week': 0.010913835, 'volatility_last_month': 0.019866346, 'volatility_last_3months': 0.017347332, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.566666667, 'winloss_ratio_last_week': '1.18102698', 'winloss_ratio_last_month': '2.545951916', 'winloss_ratio_last_3month': '2.877001037', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-21 15:00', 'high': 68.65, 'open': 68.0, 'close': 68.17, 'low': 67.98, 'volume': 1631, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': 0.06, 'pnl_last_month': 3.5, 'pnl_last_3months': 6.57, 'volatility_last_week': 0.01078797, 'volatility_last_month': 0.019685115, 'volatility_last_3months': 0.01733332, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.566666667, 'winloss_ratio_last_week': '1.179805137', 'winloss_ratio_last_month': '2.550567569', 'winloss_ratio_last_3month': '2.873725137', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-21 16:00', 'high': 69.42, 'open': 68.19, 'close': 69.1, 'low': 68.19, 'volume': 2580, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 0.06, 'pnl_last_month': 3.5, 'pnl_last_3months': 6.57, 'volatility_last_week': 0.011252807, 'volatility_last_month': 0.019726016, 'volatility_last_3months': 0.01736424, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.566666667, 'winloss_ratio_last_week': '1.178539225', 'winloss_ratio_last_month': '2.5551818', 'winloss_ratio_last_3month': '2.870586937', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-24 07:00', 'high': 69.0, 'open': 69.0, 'close': 67.75, 'low': 67.64, 'volume': 1198, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': -1.29, 'pnl_last_month': 2.15, 'pnl_last_3months': 5.22, 'volatility_last_week': 0.011057155, 'volatility_last_month': 0.019896624, 'volatility_last_3months': 0.017426829, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.548387097, 'winloss_ratio_last_week': '1.108517077', 'winloss_ratio_last_month': '2.550953051', 'winloss_ratio_last_3month': '2.846813988', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-24 08:00', 'high': 68.07, 'open': 67.79, 'close': 67.79, 'low': 67.68, 'volume': 983, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': -1.25, 'pnl_last_month': 2.19, 'pnl_last_3months': 5.26, 'volatility_last_week': 0.011054638, 'volatility_last_month': 0.019856806, 'volatility_last_3months': 0.017422032, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.5625, 'winloss_ratio_last_week': '1.045681063', 'winloss_ratio_last_month': '2.546856677', 'winloss_ratio_last_3month': '2.824530271', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-24 09:00', 'high': 68.36, 'open': 67.77, 'close': 67.89, 'low': 67.77, 'volume': 1742, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -1.25, 'pnl_last_month': 2.19, 'pnl_last_3months': 5.26, 'volatility_last_week': 0.01103003, 'volatility_last_month': 0.019843722, 'volatility_last_3months': 0.017422518, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.5625, 'winloss_ratio_last_week': '0.987614862', 'winloss_ratio_last_month': '2.542789646', 'winloss_ratio_last_3month': '2.803415328', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-24 10:00', 'high': 68.75, 'open': 67.89, 'close': 68.18, 'low': 67.89, 'volume': 1332, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -1.25, 'pnl_last_month': 2.19, 'pnl_last_3months': 5.26, 'volatility_last_week': 0.010721839, 'volatility_last_month': 0.019730565, 'volatility_last_3months': 0.017423683, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.5625, 'winloss_ratio_last_week': '0.933795227', 'winloss_ratio_last_month': '2.538751645', 'winloss_ratio_last_3month': '2.783379556', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-24 11:00', 'high': 68.5, 'open': 68.19, 'close': 68.18, 'low': 68.1, 'volume': 511, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -1.25, 'pnl_last_month': 2.19, 'pnl_last_3months': 5.26, 'volatility_last_week': 0.010719666, 'volatility_last_month': 0.019676649, 'volatility_last_3months': 0.01742348, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.5625, 'winloss_ratio_last_week': '0.883772744', 'winloss_ratio_last_month': '2.534742364', 'winloss_ratio_last_3month': '2.764342283', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-24 12:00', 'high': 68.32, 'open': 68.14, 'close': 68.21, 'low': 68.01, 'volume': 606, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -1.25, 'pnl_last_month': 2.19, 'pnl_last_3months': 5.26, 'volatility_last_week': 0.010682223, 'volatility_last_month': 0.019676317, 'volatility_last_3months': 0.01742192, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.5625, 'winloss_ratio_last_week': '0.837159254', 'winloss_ratio_last_month': '2.530761498', 'winloss_ratio_last_3month': '2.746230682', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-24 13:00', 'high': 68.61, 'open': 68.22, 'close': 68.3, 'low': 68.1, 'volume': 696, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -1.25, 'pnl_last_month': 2.19, 'pnl_last_3months': 5.26, 'volatility_last_week': 0.010550841, 'volatility_last_month': 0.019674372, 'volatility_last_3months': 0.017421491, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.5625, 'winloss_ratio_last_week': '0.793617759', 'winloss_ratio_last_month': '2.526808745', 'winloss_ratio_last_3month': '2.728978841', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-24 14:00', 'high': 70.87, 'open': 68.3, 'close': 70.61, 'low': 68.3, 'volume': 3301, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 5, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': -1.25, 'pnl_last_month': 2.19, 'pnl_last_3months': 5.26, 'volatility_last_week': 0.013152581, 'volatility_last_month': 0.020136122, 'volatility_last_3months': 0.017590124, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.5625, 'winloss_ratio_last_week': '0.752854265', 'winloss_ratio_last_month': '2.522883809', 'winloss_ratio_last_3month': '2.712526959', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-24 15:00', 'high': 72.6, 'open': 70.61, 'close': 72.22, 'low': 70.6, 'volume': 4541, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 5, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.36, 'pnl_last_month': 3.8, 'pnl_last_3months': 6.87, 'volatility_last_week': 0.014058702, 'volatility_last_month': 0.017875062, 'volatility_last_3months': 0.017674795, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.555555556, 'win_ratio_last_3month': 0.575757576, 'winloss_ratio_last_week': '0.767002929', 'winloss_ratio_last_month': '2.522990078', 'winloss_ratio_last_3month': '2.703890743', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-24 16:00', 'high': 72.66, 'open': 72.17, 'close': 72.41, 'low': 71.55, 'volume': 3371, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 5, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.55, 'pnl_last_month': 3.99, 'pnl_last_3months': 7.06, 'volatility_last_week': 0.014059221, 'volatility_last_month': 0.01778782, 'volatility_last_3months': 0.017673089, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.578947368, 'win_ratio_last_3month': 0.588235294, 'winloss_ratio_last_week': '0.786300505', 'winloss_ratio_last_month': '2.523566437', 'winloss_ratio_last_3month': '2.696453109', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-25 07:00', 'high': 72.84, 'open': 72.41, 'close': 72.42, 'low': 72.0, 'volume': 938, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 5, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.56, 'pnl_last_month': 4.0, 'pnl_last_3months': 7.07, 'volatility_last_week': 0.013915932, 'volatility_last_month': 0.017751177, 'volatility_last_3months': 0.017673073, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '0.804780876', 'winloss_ratio_last_month': '2.524163477', 'winloss_ratio_last_3month': '2.689379936', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-25 08:00', 'high': 72.8, 'open': 72.47, 'close': 72.2, 'low': 71.99, 'volume': 1047, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 5, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.34, 'pnl_last_month': 3.78, 'pnl_last_3months': 6.85, 'volatility_last_week': 0.01393206, 'volatility_last_month': 0.017716235, 'volatility_last_3months': 0.017674406, 'win_ratio_last_week': 0.545454545, 'win_ratio_last_month': 0.571428571, 'win_ratio_last_3month': 0.583333333, 'winloss_ratio_last_week': '0.816863905', 'winloss_ratio_last_month': '2.523390231', 'winloss_ratio_last_3month': '2.680182401', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-25 09:00', 'high': 74.95, 'open': 72.21, 'close': 74.55, 'low': 72.14, 'volume': 3844, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 5, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.015705417, 'volatility_last_month': 0.018151593, 'volatility_last_3months': 0.017840462, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '0.895338862', 'winloss_ratio_last_month': '2.52838', 'winloss_ratio_last_3month': '2.680827974', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-25 10:00', 'high': 75.39, 'open': 74.55, 'close': 74.76, 'low': 74.12, 'volume': 2899, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.015656999, 'volatility_last_month': 0.018127979, 'volatility_last_3months': 0.017841743, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '0.968732706', 'winloss_ratio_last_month': '2.53333008', 'winloss_ratio_last_3month': '2.681446219', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-25 11:00', 'high': 75.05, 'open': 74.78, 'close': 74.59, 'low': 73.8, 'volume': 1418, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.015664535, 'volatility_last_month': 0.018117552, 'volatility_last_3months': 0.017819396, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.037523452', 'winloss_ratio_last_month': '2.538240941', 'winloss_ratio_last_3month': '2.682038835', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-25 12:00', 'high': 75.0, 'open': 74.6, 'close': 75.0, 'low': 74.35, 'volume': 1014, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.01567906, 'volatility_last_month': 0.018116571, 'volatility_last_3months': 0.017824253, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.102130977', 'winloss_ratio_last_month': '2.543113048', 'winloss_ratio_last_3month': '2.682607383', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-25 13:00', 'high': 77.17, 'open': 75.0, 'close': 76.76, 'low': 74.84, 'volume': 3128, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016080594, 'volatility_last_month': 0.018360346, 'volatility_last_3months': 0.017909849, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.162925599', 'winloss_ratio_last_month': '2.547946857', 'winloss_ratio_last_3month': '2.6831533', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-25 14:00', 'high': 77.8, 'open': 76.74, 'close': 77.72, 'low': 76.5, 'volume': 3851, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016249667, 'volatility_last_month': 0.01842452, 'volatility_last_3months': 0.017933086, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.220235179', 'winloss_ratio_last_month': '2.552742819', 'winloss_ratio_last_3month': '2.683677911', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-25 15:00', 'high': 77.73, 'open': 77.69, 'close': 76.96, 'low': 76.72, 'volume': 1885, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016480853, 'volatility_last_month': 0.018472434, 'volatility_last_3months': 0.017947981, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.269212524', 'winloss_ratio_last_month': '2.557501376', 'winloss_ratio_last_3month': '2.684182439', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-25 16:00', 'high': 77.25, 'open': 77.03, 'close': 76.84, 'low': 76.71, 'volume': 2892, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016401678, 'volatility_last_month': 0.018455113, 'volatility_last_3months': 0.017945058, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.313360882', 'winloss_ratio_last_month': '2.562222964', 'winloss_ratio_last_3month': '2.684668016', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-26 07:00', 'high': 78.0, 'open': 77.25, 'close': 77.51, 'low': 76.25, 'volume': 931, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016459903, 'volatility_last_month': 0.018428966, 'volatility_last_3months': 0.017956833, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.355204626', 'winloss_ratio_last_month': '2.566908012', 'winloss_ratio_last_3month': '2.685135691', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-26 08:00', 'high': 78.33, 'open': 77.51, 'close': 76.91, 'low': 76.83, 'volume': 1802, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016534893, 'volatility_last_month': 0.018347443, 'volatility_last_3months': 0.017932777, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.415228645', 'winloss_ratio_last_month': '2.571556943', 'winloss_ratio_last_3month': '2.685586434', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-26 09:00', 'high': 77.13, 'open': 76.94, 'close': 76.75, 'low': 76.4, 'volume': 1961, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016420902, 'volatility_last_month': 0.018257192, 'volatility_last_3months': 0.017928228, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.45377456', 'winloss_ratio_last_month': '2.576170173', 'winloss_ratio_last_3month': '2.68602115', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-26 10:00', 'high': 78.27, 'open': 76.73, 'close': 77.88, 'low': 76.69, 'volume': 1561, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016547766, 'volatility_last_month': 0.018344198, 'volatility_last_3months': 0.017962617, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.489272944', 'winloss_ratio_last_month': '2.580748111', 'winloss_ratio_last_3month': '2.686440678', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-26 11:00', 'high': 78.87, 'open': 77.91, 'close': 77.87, 'low': 77.5, 'volume': 2158, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016087683, 'volatility_last_month': 0.018343235, 'volatility_last_3months': 0.01795994, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.52207147', 'winloss_ratio_last_month': '2.585291161', 'winloss_ratio_last_3month': '2.6868458', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-26 12:00', 'high': 77.86, 'open': 77.86, 'close': 76.66, 'low': 75.5, 'volume': 2194, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016616097, 'volatility_last_month': 0.018471066, 'volatility_last_3months': 0.017996469, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.552466863', 'winloss_ratio_last_month': '2.589799721', 'winloss_ratio_last_3month': '2.687237247', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-26 13:00', 'high': 77.05, 'open': 76.72, 'close': 76.67, 'low': 76.15, 'volume': 1144, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016625259, 'volatility_last_month': 0.018443598, 'volatility_last_3months': 0.017996463, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.580713905', 'winloss_ratio_last_month': '2.59427418', 'winloss_ratio_last_3month': '2.687615698', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-26 14:00', 'high': 77.49, 'open': 76.73, 'close': 76.71, 'low': 76.34, 'volume': 1474, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016465843, 'volatility_last_month': 0.018439059, 'volatility_last_3months': 0.017995502, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.60703259', 'winloss_ratio_last_month': '2.598714926', 'winloss_ratio_last_3month': '2.687981791', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-26 15:00', 'high': 76.81, 'open': 76.71, 'close': 75.84, 'low': 75.75, 'volume': 2176, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016767321, 'volatility_last_month': 0.018507473, 'volatility_last_3months': 0.018015191, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.631613866', 'winloss_ratio_last_month': '2.603122338', 'winloss_ratio_last_3month': '2.68833612', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-26 16:00', 'high': 76.06, 'open': 75.85, 'close': 75.24, 'low': 74.9, 'volume': 4410, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016946689, 'volatility_last_month': 0.01853998, 'volatility_last_3months': 0.01802485, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.654624277', 'winloss_ratio_last_month': '2.607496788', 'winloss_ratio_last_3month': '2.688679245', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-27 07:00', 'high': 76.48, 'open': 75.26, 'close': 76.19, 'low': 74.9, 'volume': 848, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.017122768, 'volatility_last_month': 0.018592336, 'volatility_last_3months': 0.018049704, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.67620974', 'winloss_ratio_last_month': '2.611838647', 'winloss_ratio_last_3month': '2.689011688', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-27 08:00', 'high': 76.97, 'open': 76.17, 'close': 76.52, 'low': 75.75, 'volume': 1644, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.017081742, 'volatility_last_month': 0.018567399, 'volatility_last_3months': 0.018045764, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.696498642', 'winloss_ratio_last_month': '2.616148276', 'winloss_ratio_last_3month': '2.689333941', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-27 09:00', 'high': 77.05, 'open': 76.47, 'close': 76.94, 'low': 75.97, 'volume': 1481, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016911593, 'volatility_last_month': 0.018564081, 'volatility_last_3months': 0.018050804, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.715604396', 'winloss_ratio_last_month': '2.620426034', 'winloss_ratio_last_3month': '2.689646465', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-27 10:00', 'high': 78.56, 'open': 76.99, 'close': 78.01, 'low': 76.96, 'volume': 2385, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.017097365, 'volatility_last_month': 0.01856445, 'volatility_last_3months': 0.018075417, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.733627563', 'winloss_ratio_last_month': '2.624672272', 'winloss_ratio_last_3month': '2.689949693', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-27 11:00', 'high': 79.78, 'open': 78.0, 'close': 79.62, 'low': 77.87, 'volume': 2275, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.017562845, 'volatility_last_month': 0.018634267, 'volatility_last_3months': 0.018140932, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.750657621', 'winloss_ratio_last_month': '2.628887338', 'winloss_ratio_last_3month': '2.690244035', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-27 12:00', 'high': 79.7, 'open': 79.62, 'close': 79.43, 'low': 79.0, 'volume': 1433, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.69, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.017604047, 'volatility_last_month': 0.018349035, 'volatility_last_3months': 0.01813921, 'win_ratio_last_week': 0.583333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.766774454', 'winloss_ratio_last_month': '2.633071573', 'winloss_ratio_last_3month': '2.690529876', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-27 13:00', 'high': 79.85, 'open': 79.48, 'close': 78.5, 'low': 78.32, 'volume': 2294, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.59, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.017936229, 'volatility_last_month': 0.018425587, 'volatility_last_3months': 0.018159347, 'win_ratio_last_week': 0.545454545, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.779425272', 'winloss_ratio_last_month': '2.637225315', 'winloss_ratio_last_3month': '2.690807578', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-27 14:00', 'high': 79.42, 'open': 78.57, 'close': 79.29, 'low': 78.28, 'volume': 1854, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.77, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.018007831, 'volatility_last_month': 0.018391868, 'volatility_last_3months': 0.018174477, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.799717369', 'winloss_ratio_last_month': '2.641348895', 'winloss_ratio_last_3month': '2.691077485', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-27 15:00', 'high': 79.85, 'open': 79.28, 'close': 79.64, 'low': 79.0, 'volume': 2016, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.9, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.018006039, 'volatility_last_month': 0.018198313, 'volatility_last_3months': 0.018177648, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.82514834', 'winloss_ratio_last_month': '2.645442642', 'winloss_ratio_last_3month': '2.69133992', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-27 16:00', 'high': 81.0, 'open': 79.63, 'close': 81.0, 'low': 79.58, 'volume': 4231, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.52, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.018148499, 'volatility_last_month': 0.018315565, 'volatility_last_3months': 0.0182232, 'win_ratio_last_week': 0.625, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.840282949', 'winloss_ratio_last_month': '2.649506877', 'winloss_ratio_last_3month': '2.691595189', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-28 07:00', 'high': 81.62, 'open': 80.27, 'close': 81.27, 'low': 80.01, 'volume': 1375, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.63, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.018141001, 'volatility_last_month': 0.018316694, 'volatility_last_3months': 0.018223995, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.859904564', 'winloss_ratio_last_month': '2.653541919', 'winloss_ratio_last_3month': '2.691843581', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-28 08:00', 'high': 82.58, 'open': 81.26, 'close': 81.77, 'low': 81.15, 'volume': 2372, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.63, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.018092786, 'volatility_last_month': 0.01830502, 'volatility_last_3months': 0.018227601, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.87898166', 'winloss_ratio_last_month': '2.657548081', 'winloss_ratio_last_3month': '2.692085371', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-28 09:00', 'high': 82.2, 'open': 81.79, 'close': 81.6, 'low': 81.5, 'volume': 1264, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.63, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.018145687, 'volatility_last_month': 0.018180597, 'volatility_last_3months': 0.018225316, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.897536594', 'winloss_ratio_last_month': '2.661525672', 'winloss_ratio_last_3month': '2.692320819', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-28 10:00', 'high': 81.78, 'open': 81.7, 'close': 81.03, 'low': 80.5, 'volume': 1971, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.63, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.018300301, 'volatility_last_month': 0.018132103, 'volatility_last_3months': 0.018232774, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.915590514', 'winloss_ratio_last_month': '2.665474997', 'winloss_ratio_last_3month': '2.692550171', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-28 11:00', 'high': 81.35, 'open': 81.0, 'close': 81.29, 'low': 80.71, 'volume': 810, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.63, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.01829536, 'volatility_last_month': 0.018134509, 'volatility_last_3months': 0.018234034, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.933163443', 'winloss_ratio_last_month': '2.669396355', 'winloss_ratio_last_3month': '2.69277366', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-28 12:00', 'high': 81.33, 'open': 81.3, 'close': 80.63, 'low': 80.52, 'volume': 979, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.63, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.018421194, 'volatility_last_month': 0.018145622, 'volatility_last_3months': 0.018242057, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.950274348', 'winloss_ratio_last_month': '2.673290043', 'winloss_ratio_last_3month': '2.692991509', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-28 13:00', 'high': 81.22, 'open': 80.63, 'close': 80.99, 'low': 80.63, 'volume': 1141, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.63, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.018352164, 'volatility_last_month': 0.018119456, 'volatility_last_3months': 0.018242045, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.966941216', 'winloss_ratio_last_month': '2.677156353', 'winloss_ratio_last_3month': '2.693203928', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-28 14:00', 'high': 82.0, 'open': 81.05, 'close': 81.79, 'low': 81.0, 'volume': 2287, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.63, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.018378446, 'volatility_last_month': 0.018156364, 'volatility_last_3months': 0.018250718, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.983181109', 'winloss_ratio_last_month': '2.680995572', 'winloss_ratio_last_3month': '2.693411119', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-28 15:00', 'high': 82.15, 'open': 81.82, 'close': 81.86, 'low': 81.71, 'volume': 1484, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.63, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.018388734, 'volatility_last_month': 0.018156368, 'volatility_last_3months': 0.018250846, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '1.999010228', 'winloss_ratio_last_month': '2.684807985', 'winloss_ratio_last_3month': '2.693613271', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-28 16:00', 'high': 82.15, 'open': 81.87, 'close': 81.6, 'low': 81.02, 'volume': 1619, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.63, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.018299861, 'volatility_last_month': 0.0180046, 'volatility_last_3months': 0.018251267, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.014443962', 'winloss_ratio_last_month': '2.68859387', 'winloss_ratio_last_3month': '2.693810566', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-31 07:00', 'high': 82.0, 'open': 81.77, 'close': 81.6, 'low': 81.26, 'volume': 605, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 3.98, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.017448643, 'volatility_last_month': 0.017850399, 'volatility_last_3months': 0.018251243, 'win_ratio_last_week': 0.833333333, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.090025406', 'winloss_ratio_last_month': '2.692353504', 'winloss_ratio_last_3month': '2.694003177', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-31 08:00', 'high': 81.7, 'open': 81.64, 'close': 79.19, 'low': 79.08, 'volume': 2394, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 3.94, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.019189255, 'volatility_last_month': 0.018304994, 'volatility_last_3months': 0.018389637, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.167340976', 'winloss_ratio_last_month': '2.696087159', 'winloss_ratio_last_3month': '2.694191269', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-31 09:00', 'high': 79.3, 'open': 79.11, 'close': 79.01, 'low': 78.15, 'volume': 1650, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 3.94, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.019228293, 'volatility_last_month': 0.018300827, 'volatility_last_3months': 0.018388536, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.247397918', 'winloss_ratio_last_month': '2.699795104', 'winloss_ratio_last_3month': '2.694375', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-31 10:00', 'high': 79.59, 'open': 79.07, 'close': 78.99, 'low': 78.9, 'volume': 1211, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 3.94, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.019241597, 'volatility_last_month': 0.018299556, 'volatility_last_3months': 0.018388461, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.330344667', 'winloss_ratio_last_month': '2.703477602', 'winloss_ratio_last_3month': '2.694554519', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-31 11:00', 'high': 79.42, 'open': 78.99, 'close': 79.38, 'low': 78.68, 'volume': 956, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 3.94, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.019233781, 'volatility_last_month': 0.018272514, 'volatility_last_3months': 0.018390064, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.416340567', 'winloss_ratio_last_month': '2.707134915', 'winloss_ratio_last_3month': '2.694729969', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-31 12:00', 'high': 79.45, 'open': 79.38, 'close': 79.45, 'low': 78.76, 'volume': 774, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 3.94, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.019230626, 'volatility_last_month': 0.018269607, 'volatility_last_3months': 0.018384779, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.505556898', 'winloss_ratio_last_month': '2.710767301', 'winloss_ratio_last_3month': '2.694901487', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-31 13:00', 'high': 79.99, 'open': 79.46, 'close': 78.86, 'low': 78.83, 'volume': 1289, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 3.94, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.019388433, 'volatility_last_month': 0.018201454, 'volatility_last_3months': 0.018385102, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.598178013', 'winloss_ratio_last_month': '2.714375013', 'winloss_ratio_last_3month': '2.695069204', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-31 14:00', 'high': 79.86, 'open': 79.01, 'close': 79.83, 'low': 78.7, 'volume': 1131, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 3.94, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.018090105, 'volatility_last_month': 0.018260849, 'volatility_last_3months': 0.018408312, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.69440261', 'winloss_ratio_last_month': '2.717958302', 'winloss_ratio_last_3month': '2.695233245', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-31 15:00', 'high': 79.93, 'open': 79.84, 'close': 79.54, 'low': 79.05, 'volume': 1048, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.33, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.017474314, 'volatility_last_month': 0.018134368, 'volatility_last_3months': 0.018403504, 'win_ratio_last_week': 0.75, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.753231793', 'winloss_ratio_last_month': '2.721517416', 'winloss_ratio_last_3month': '2.695393729', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-10-31 16:00', 'high': 80.19, 'open': 79.49, 'close': 79.61, 'low': 79.37, 'volume': 1366, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.14, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.017475234, 'volatility_last_month': 0.018129838, 'volatility_last_3months': 0.018389888, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.809480282', 'winloss_ratio_last_month': '2.725052597', 'winloss_ratio_last_3month': '2.695550771', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-01 07:00', 'high': 80.21, 'open': 79.78, 'close': 79.79, 'low': 79.03, 'volume': 643, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.13, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.01747021, 'volatility_last_month': 0.017907727, 'volatility_last_3months': 0.018389982, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.867786219', 'winloss_ratio_last_month': '2.728564087', 'winloss_ratio_last_3month': '2.695704481', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-01 08:00', 'high': 80.69, 'open': 79.78, 'close': 79.7, 'low': 79.51, 'volume': 977, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.35, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.01744468, 'volatility_last_month': 0.017827475, 'volatility_last_3months': 0.018389576, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.946193866', 'winloss_ratio_last_month': '2.732052123', 'winloss_ratio_last_3month': '2.695854963', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-01 09:00', 'high': 80.11, 'open': 79.66, 'close': 79.35, 'low': 79.22, 'volume': 839, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.015909307, 'volatility_last_month': 0.017841063, 'volatility_last_3months': 0.018388172, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.962843068', 'winloss_ratio_last_month': '2.735516939', 'winloss_ratio_last_3month': '2.696002317', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-01 10:00', 'high': 79.43, 'open': 79.35, 'close': 78.84, 'low': 78.3, 'volume': 2790, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016008675, 'volatility_last_month': 0.01786284, 'volatility_last_3months': 0.018388307, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.980451781', 'winloss_ratio_last_month': '2.738958766', 'winloss_ratio_last_3month': '2.696146642', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-01 11:00', 'high': 78.9, 'open': 78.85, 'close': 78.5, 'low': 78.36, 'volume': 774, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016040593, 'volatility_last_month': 0.017858388, 'volatility_last_3months': 0.018388829, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '2.999105412', 'winloss_ratio_last_month': '2.742377832', 'winloss_ratio_last_3month': '2.696288028', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-01 12:00', 'high': 78.54, 'open': 78.51, 'close': 76.43, 'low': 75.69, 'volume': 3220, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.017300299, 'volatility_last_month': 0.018222227, 'volatility_last_3months': 0.018498911, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '3.018899816', 'winloss_ratio_last_month': '2.745774363', 'winloss_ratio_last_3month': '2.696426565', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-01 13:00', 'high': 77.25, 'open': 76.48, 'close': 76.2, 'low': 76.01, 'volume': 1687, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016406766, 'volatility_last_month': 0.01822745, 'volatility_last_3months': 0.018499427, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '3.039942939', 'winloss_ratio_last_month': '2.74914858', 'winloss_ratio_last_3month': '2.696562338', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-01 14:00', 'high': 76.39, 'open': 76.2, 'close': 76.09, 'low': 75.75, 'volume': 2476, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016122916, 'volatility_last_month': 0.01801808, 'volatility_last_3months': 0.018489548, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '3.062356792', 'winloss_ratio_last_month': '2.752500703', 'winloss_ratio_last_3month': '2.696695429', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-01 15:00', 'high': 76.75, 'open': 76.11, 'close': 76.75, 'low': 75.85, 'volume': 1161, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016099426, 'volatility_last_month': 0.01799668, 'volatility_last_3months': 0.018494911, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '3.086279817', 'winloss_ratio_last_month': '2.755830948', 'winloss_ratio_last_3month': '2.696825916', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-01 16:00', 'high': 77.18, 'open': 76.71, 'close': 77.18, 'low': 76.38, 'volume': 1308, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 6.13, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016150397, 'volatility_last_month': 0.017999685, 'volatility_last_3months': 0.018493813, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '3.111869748', 'winloss_ratio_last_month': '2.759139527', 'winloss_ratio_last_3month': '2.696953876', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-02 07:00', 'high': 77.8, 'open': 77.16, 'close': 77.64, 'low': 76.85, 'volume': 262, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016079402, 'volatility_last_month': 0.017950013, 'volatility_last_3months': 0.018495993, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.571428571, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '3.139307092', 'winloss_ratio_last_month': '2.759164595', 'winloss_ratio_last_3month': '2.697079381', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-02 08:00', 'high': 78.57, 'open': 77.66, 'close': 77.59, 'low': 77.39, 'volume': 1166, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 3.62, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.015968927, 'volatility_last_month': 0.017672412, 'volatility_last_3months': 0.018494549, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '3.168799398', 'winloss_ratio_last_month': '2.752488203', 'winloss_ratio_last_3month': '2.697202501', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-02 09:00', 'high': 78.3, 'open': 77.67, 'close': 78.07, 'low': 77.39, 'volume': 857, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.77, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.016021921, 'volatility_last_month': 0.017622191, 'volatility_last_3months': 0.018496414, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '3.20058651', 'winloss_ratio_last_month': '2.742495208', 'winloss_ratio_last_3month': '2.697323303', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-02 10:00', 'high': 78.06, 'open': 78.01, 'close': 77.22, 'low': 77.14, 'volume': 1205, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 3.12, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.01586039, 'volatility_last_month': 0.017671923, 'volatility_last_3months': 0.018507713, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.555555556, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '3.234947111', 'winloss_ratio_last_month': '2.736339606', 'winloss_ratio_last_3month': '2.697441852', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-02 11:00', 'high': 77.46, 'open': 77.22, 'close': 76.86, 'low': 76.38, 'volume': 1378, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.85, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.015896411, 'volatility_last_month': 0.017679131, 'volatility_last_3months': 0.018510816, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '3.272206911', 'winloss_ratio_last_month': '2.729143285', 'winloss_ratio_last_3month': '2.697558211', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-02 12:00', 'high': 76.93, 'open': 76.85, 'close': 76.78, 'low': 76.56, 'volume': 645, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.85, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.015453965, 'volatility_last_month': 0.017621019, 'volatility_last_3months': 0.018510921, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '3.312749004', 'winloss_ratio_last_month': '2.72197327', 'winloss_ratio_last_3month': '2.69767244', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-02 13:00', 'high': 76.92, 'open': 76.77, 'close': 76.32, 'low': 76.16, 'volume': 1258, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.85, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.015520396, 'volatility_last_month': 0.017500217, 'volatility_last_3months': 0.018511538, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '3.35702709', 'winloss_ratio_last_month': '2.714829419', 'winloss_ratio_last_3month': '2.697784597', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-02 14:00', 'high': 77.43, 'open': 76.28, 'close': 76.76, 'low': 76.08, 'volume': 1849, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 2.85, 'pnl_last_3months': 9.2, 'volatility_last_week': 0.015581853, 'volatility_last_month': 0.017250701, 'volatility_last_3months': 0.018494794, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.594594595, 'winloss_ratio_last_week': '3.405582524', 'winloss_ratio_last_month': '2.707711589', 'winloss_ratio_last_3month': '2.697894737', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-02 15:00', 'high': 77.07, 'open': 76.78, 'close': 76.98, 'low': 76.32, 'volume': 693, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': -0.22, 'pnl_last_month': 2.63, 'pnl_last_3months': 8.98, 'volatility_last_week': 0.015346535, 'volatility_last_month': 0.017249709, 'volatility_last_3months': 0.018495341, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.578947368, 'winloss_ratio_last_week': '3.439766675', 'winloss_ratio_last_month': '2.699458784', 'winloss_ratio_last_3month': '2.697020361', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-02 16:00', 'high': 77.3, 'open': 76.98, 'close': 76.89, 'low': 76.27, 'volume': 2230, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': -0.13, 'pnl_last_month': 2.72, 'pnl_last_3months': 9.07, 'volatility_last_week': 0.015220886, 'volatility_last_month': 0.017239513, 'volatility_last_3months': 0.018494214, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.58974359, 'winloss_ratio_last_week': '3.479553903', 'winloss_ratio_last_month': '2.691418434', 'winloss_ratio_last_3month': '2.69630966', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-03 07:00', 'high': 76.69, 'open': 76.63, 'close': 75.69, 'low': 75.6, 'volume': 1628, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 1.07, 'pnl_last_month': 3.92, 'pnl_last_3months': 10.27, 'volatility_last_week': 0.015406205, 'volatility_last_month': 0.017373621, 'volatility_last_3months': 0.018530496, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '3.556701031', 'winloss_ratio_last_month': '2.685748186', 'winloss_ratio_last_3month': '2.697562165', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-03 08:00', 'high': 76.5, 'open': 75.71, 'close': 76.44, 'low': 75.36, 'volume': 1278, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.32, 'pnl_last_month': 3.17, 'pnl_last_3months': 9.52, 'volatility_last_week': 0.015553901, 'volatility_last_month': 0.017401889, 'volatility_last_3months': 0.01854606, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.523809524, 'win_ratio_last_3month': 0.585365854, 'winloss_ratio_last_week': '3.563521652', 'winloss_ratio_last_month': '2.676207825', 'winloss_ratio_last_3month': '2.695536073', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-03 09:00', 'high': 76.67, 'open': 76.45, 'close': 76.47, 'low': 76.18, 'volume': 1229, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.015497255, 'volatility_last_month': 0.017369102, 'volatility_last_3months': 0.01852403, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.567591499', 'winloss_ratio_last_month': '2.666583002', 'winloss_ratio_last_3month': '2.693422289', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-03 10:00', 'high': 78.2, 'open': 76.39, 'close': 77.64, 'low': 76.39, 'volume': 1802, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.015571622, 'volatility_last_month': 0.017101276, 'volatility_last_3months': 0.01855812, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.571906355', 'winloss_ratio_last_month': '2.657029642', 'winloss_ratio_last_3month': '2.691351019', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-03 11:00', 'high': 77.66, 'open': 77.66, 'close': 76.81, 'low': 76.8, 'volume': 898, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.014954287, 'volatility_last_month': 0.017161831, 'volatility_last_3months': 0.018571499, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.576489028', 'winloss_ratio_last_month': '2.647546953', 'winloss_ratio_last_3month': '2.689320994', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-03 12:00', 'high': 77.51, 'open': 76.81, 'close': 76.34, 'low': 76.2, 'volume': 1090, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.015004843, 'volatility_last_month': 0.017166333, 'volatility_last_3months': 0.018572474, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.581365254', 'winloss_ratio_last_month': '2.638134153', 'winloss_ratio_last_3month': '2.687330995', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-03 13:00', 'high': 76.7, 'open': 76.35, 'close': 76.2, 'low': 76.09, 'volume': 1145, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.014769724, 'volatility_last_month': 0.017164414, 'volatility_last_3months': 0.018569815, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.589906417', 'winloss_ratio_last_month': '2.628790473', 'winloss_ratio_last_3month': '2.685379849', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-03 14:00', 'high': 76.56, 'open': 76.17, 'close': 76.08, 'low': 75.87, 'volume': 1003, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.014545227, 'volatility_last_month': 0.017166473, 'volatility_last_3months': 0.018569019, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.57677774', 'winloss_ratio_last_month': '2.619515154', 'winloss_ratio_last_3month': '2.683466428', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-03 15:00', 'high': 76.29, 'open': 76.07, 'close': 76.18, 'low': 75.63, 'volume': 903, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.014499665, 'volatility_last_month': 0.017166467, 'volatility_last_3months': 0.018569055, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.546605698', 'winloss_ratio_last_month': '2.610307448', 'winloss_ratio_last_3month': '2.68158965', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-03 16:00', 'high': 76.69, 'open': 76.21, 'close': 76.65, 'low': 76.0, 'volume': 1267, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013952318, 'volatility_last_month': 0.017063032, 'volatility_last_3months': 0.018568268, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.528648649', 'winloss_ratio_last_month': '2.60116662', 'winloss_ratio_last_3month': '2.679748473', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-04 07:00', 'high': 76.98, 'open': 76.5, 'close': 76.85, 'low': 76.26, 'volume': 473, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013940159, 'volatility_last_month': 0.016993221, 'volatility_last_3months': 0.018566575, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.495584989', 'winloss_ratio_last_month': '2.592091942', 'winloss_ratio_last_3month': '2.677941891', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-04 08:00', 'high': 78.25, 'open': 76.88, 'close': 78.01, 'low': 76.88, 'volume': 1689, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.014357545, 'volatility_last_month': 0.01705886, 'volatility_last_3months': 0.018601835, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.461104848', 'winloss_ratio_last_month': '2.5830827', 'winloss_ratio_last_3month': '2.67616894', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-04 09:00', 'high': 78.0, 'open': 78.0, 'close': 77.88, 'low': 77.32, 'volume': 1594, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.014355991, 'volatility_last_month': 0.016926555, 'volatility_last_3months': 0.018602127, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.425115207', 'winloss_ratio_last_month': '2.574138188', 'winloss_ratio_last_3month': '2.674428689', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-04 10:00', 'high': 79.0, 'open': 77.88, 'close': 78.48, 'low': 77.86, 'volume': 2391, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.014421756, 'volatility_last_month': 0.016908934, 'volatility_last_3months': 0.018611498, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.387514723', 'winloss_ratio_last_month': '2.565257711', 'winloss_ratio_last_3month': '2.672720242', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-04 11:00', 'high': 79.28, 'open': 78.5, 'close': 79.14, 'low': 78.43, 'volume': 1103, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.014552862, 'volatility_last_month': 0.016925878, 'volatility_last_3months': 0.018616659, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.348192771', 'winloss_ratio_last_month': '2.556440584', 'winloss_ratio_last_3month': '2.671042734', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-04 12:00', 'high': 79.5, 'open': 79.11, 'close': 78.6, 'low': 78.46, 'volume': 1170, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.01451677, 'volatility_last_month': 0.016940447, 'volatility_last_3months': 0.018623516, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.30702836', 'winloss_ratio_last_month': '2.547686132', 'winloss_ratio_last_3month': '2.669395333', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-04 13:00', 'high': 78.81, 'open': 78.6, 'close': 77.85, 'low': 77.75, 'volume': 1774, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.01462314, 'volatility_last_month': 0.016981931, 'volatility_last_3months': 0.018634625, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.263888889', 'winloss_ratio_last_month': '2.538993688', 'winloss_ratio_last_3month': '2.667777236', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-04 14:00', 'high': 78.2, 'open': 77.85, 'close': 77.34, 'low': 77.2, 'volume': 1061, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.014455285, 'volatility_last_month': 0.01695866, 'volatility_last_3months': 0.01864064, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.218628719', 'winloss_ratio_last_month': '2.530362596', 'winloss_ratio_last_3month': '2.666187667', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-04 15:00', 'high': 77.41, 'open': 77.3, 'close': 76.4, 'low': 76.39, 'volume': 1530, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.014684783, 'volatility_last_month': 0.017018739, 'volatility_last_3months': 0.018658714, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.171087533', 'winloss_ratio_last_month': '2.521792209', 'winloss_ratio_last_3month': '2.664625878', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-04 16:00', 'high': 76.55, 'open': 76.42, 'close': 76.46, 'low': 76.17, 'volume': 1562, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.014687004, 'volatility_last_month': 0.016934769, 'volatility_last_3months': 0.018658846, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.121088435', 'winloss_ratio_last_month': '2.513281888', 'winloss_ratio_last_3month': '2.663091149', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-07 07:00', 'high': 76.8, 'open': 76.36, 'close': 76.72, 'low': 76.04, 'volume': 561, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.014726421, 'volatility_last_month': 0.01693893, 'volatility_last_3months': 0.018660283, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '2.886990802', 'winloss_ratio_last_month': '2.504831005', 'winloss_ratio_last_3month': '2.66158278', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-07 08:00', 'high': 76.77, 'open': 76.72, 'close': 75.84, 'low': 75.54, 'volume': 1514, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013228524, 'volatility_last_month': 0.016666707, 'volatility_last_3months': 0.018674212, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '2.670055061', 'winloss_ratio_last_month': '2.496438939', 'winloss_ratio_last_3month': '2.6601001', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-07 09:00', 'high': 76.79, 'open': 75.83, 'close': 76.78, 'low': 75.67, 'volume': 1361, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013588374, 'volatility_last_month': 0.016735756, 'volatility_last_3months': 0.018695704, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '2.46699467', 'winloss_ratio_last_month': '2.488105078', 'winloss_ratio_last_3month': '2.658642457', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-07 10:00', 'high': 77.18, 'open': 76.74, 'close': 76.41, 'low': 76.41, 'volume': 1651, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013625646, 'volatility_last_month': 0.016661474, 'volatility_last_3months': 0.018696616, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '2.276519666', 'winloss_ratio_last_month': '2.479828818', 'winloss_ratio_last_3month': '2.657209223', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-07 11:00', 'high': 77.22, 'open': 76.43, 'close': 76.8, 'low': 76.41, 'volume': 583, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013629622, 'volatility_last_month': 0.016659613, 'volatility_last_3months': 0.018695774, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '2.097495183', 'winloss_ratio_last_month': '2.471609565', 'winloss_ratio_last_3month': '2.655799789', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-07 12:00', 'high': 77.43, 'open': 76.84, 'close': 76.78, 'low': 76.71, 'volume': 1036, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013624878, 'volatility_last_month': 0.016616871, 'volatility_last_3months': 0.018695774, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.928918818', 'winloss_ratio_last_month': '2.463446731', 'winloss_ratio_last_3month': '2.654413568', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-07 13:00', 'high': 77.38, 'open': 76.8, 'close': 77.09, 'low': 76.64, 'volume': 829, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.01356926, 'volatility_last_month': 0.01660796, 'volatility_last_3months': 0.018696021, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.769901854', 'winloss_ratio_last_month': '2.455339737', 'winloss_ratio_last_3month': '2.65304999', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-07 14:00', 'high': 77.4, 'open': 77.1, 'close': 76.83, 'low': 76.81, 'volume': 1352, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013231665, 'volatility_last_month': 0.016550495, 'volatility_last_3months': 0.01869527, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.619653588', 'winloss_ratio_last_month': '2.447288013', 'winloss_ratio_last_3month': '2.651708506', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-07 15:00', 'high': 77.37, 'open': 76.85, 'close': 77.37, 'low': 76.51, 'volume': 1465, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013339731, 'volatility_last_month': 0.016570916, 'volatility_last_3months': 0.018685819, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.532851737', 'winloss_ratio_last_month': '2.439290995', 'winloss_ratio_last_3month': '2.650388583', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-07 16:00', 'high': 78.2, 'open': 77.37, 'close': 77.8, 'low': 77.17, 'volume': 2970, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013414368, 'volatility_last_month': 0.01653456, 'volatility_last_3months': 0.018690997, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.456951424', 'winloss_ratio_last_month': '2.431348127', 'winloss_ratio_last_3month': '2.649089705', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-08 07:00', 'high': 77.75, 'open': 77.75, 'close': 77.74, 'low': 77.27, 'volume': 696, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013398307, 'volatility_last_month': 0.01653548, 'volatility_last_3months': 0.01868926, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.385243226', 'winloss_ratio_last_month': '2.423458862', 'winloss_ratio_last_3month': '2.647811373', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-08 08:00', 'high': 77.79, 'open': 77.75, 'close': 77.02, 'low': 76.91, 'volume': 924, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013559531, 'volatility_last_month': 0.016518357, 'volatility_last_3months': 0.01869457, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.307935504', 'winloss_ratio_last_month': '2.415622659', 'winloss_ratio_last_3month': '2.646553103', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-08 09:00', 'high': 77.03, 'open': 76.99, 'close': 76.58, 'low': 76.53, 'volume': 1456, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.01358415, 'volatility_last_month': 0.016538678, 'volatility_last_3months': 0.018698113, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.307385841', 'winloss_ratio_last_month': '2.407838983', 'winloss_ratio_last_3month': '2.645314426', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-08 10:00', 'high': 76.81, 'open': 76.52, 'close': 76.1, 'low': 75.87, 'volume': 3301, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013580303, 'volatility_last_month': 0.016562573, 'volatility_last_3months': 0.018703574, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.306868867', 'winloss_ratio_last_month': '2.400107309', 'winloss_ratio_last_3month': '2.644094889', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-08 11:00', 'high': 76.15, 'open': 76.06, 'close': 75.72, 'low': 75.64, 'volume': 1601, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.013591758, 'volatility_last_month': 0.016575439, 'volatility_last_3months': 0.018694029, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.30638175', 'winloss_ratio_last_month': '2.392427117', 'winloss_ratio_last_3month': '2.642894051', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-08 12:00', 'high': 76.11, 'open': 75.71, 'close': 76.02, 'low': 75.55, 'volume': 1177, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.012081661, 'volatility_last_month': 0.01657597, 'volatility_last_3months': 0.018696761, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.305921976', 'winloss_ratio_last_month': '2.384797896', 'winloss_ratio_last_3month': '2.641711485', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-08 13:00', 'high': 76.21, 'open': 76.0, 'close': 75.98, 'low': 75.68, 'volume': 950, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.012061634, 'volatility_last_month': 0.016568814, 'volatility_last_3months': 0.018696361, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.305487305', 'winloss_ratio_last_month': '2.377219138', 'winloss_ratio_last_3month': '2.640546778', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-08 14:00', 'high': 76.25, 'open': 75.92, 'close': 75.84, 'low': 75.74, 'volume': 1338, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.14, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.012064654, 'volatility_last_month': 0.016561087, 'volatility_last_3months': 0.018691029, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.305075737', 'winloss_ratio_last_month': '2.369690345', 'winloss_ratio_last_3month': '2.639399528', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-08 15:00', 'high': 76.62, 'open': 75.84, 'close': 76.37, 'low': 75.7, 'volume': 1263, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.31, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.012001546, 'volatility_last_month': 0.016579882, 'volatility_last_3months': 0.018696749, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.523809524, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.304685478', 'winloss_ratio_last_month': '2.363579069', 'winloss_ratio_last_3month': '2.638269345', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-08 16:00', 'high': 76.48, 'open': 76.37, 'close': 76.38, 'low': 75.88, 'volume': 2689, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.37, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.011922394, 'volatility_last_month': 0.016580031, 'volatility_last_3months': 0.018694695, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.304314913', 'winloss_ratio_last_month': '2.35798148', 'winloss_ratio_last_3month': '2.637155852', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-09 07:00', 'high': 76.33, 'open': 76.06, 'close': 75.86, 'low': 75.85, 'volume': 439, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.29, 'pnl_last_month': 3.35, 'pnl_last_3months': 9.49, 'volatility_last_week': 0.011929078, 'volatility_last_month': 0.016609101, 'volatility_last_3months': 0.018695782, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '1.303962589', 'winloss_ratio_last_month': '2.352343988', 'winloss_ratio_last_3month': '2.636058681', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-09 08:00', 'high': 76.6, 'open': 75.9, 'close': 76.54, 'low': 75.9, 'volume': 846, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': -0.39, 'pnl_last_month': 1.71, 'pnl_last_3months': 8.81, 'volatility_last_week': 0.012135564, 'volatility_last_month': 0.01652168, 'volatility_last_3months': 0.018687597, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.558139535, 'winloss_ratio_last_week': '1.28267549', 'winloss_ratio_last_month': '2.340797406', 'winloss_ratio_last_3month': '2.632935626', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-09 09:00', 'high': 76.94, 'open': 76.48, 'close': 75.8, 'low': 75.71, 'volume': 1872, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.35, 'pnl_last_month': 3.04, 'pnl_last_3months': 9.55, 'volatility_last_week': 0.012236358, 'volatility_last_month': 0.016543431, 'volatility_last_3months': 0.018680815, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.568181818, 'winloss_ratio_last_week': '1.279836326', 'winloss_ratio_last_month': '2.335215969', 'winloss_ratio_last_3month': '2.630698968', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-09 10:00', 'high': 75.87, 'open': 75.85, 'close': 74.37, 'low': 74.02, 'volume': 4243, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 1.78, 'pnl_last_month': 4.47, 'pnl_last_3months': 10.98, 'volatility_last_week': 0.012770784, 'volatility_last_month': 0.016351297, 'volatility_last_3months': 0.018732027, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.577777778, 'winloss_ratio_last_week': '1.308517626', 'winloss_ratio_last_month': '2.33205972', 'winloss_ratio_last_3month': '2.630101497', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-09 11:00', 'high': 74.65, 'open': 74.35, 'close': 74.49, 'low': 73.45, 'volume': 2149, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 1.66, 'pnl_last_month': 4.35, 'pnl_last_3months': 10.86, 'volatility_last_week': 0.012746919, 'volatility_last_month': 0.016348185, 'volatility_last_3months': 0.018727877, 'win_ratio_last_week': 0.444444444, 'win_ratio_last_month': 0.523809524, 'win_ratio_last_3month': 0.565217391, 'winloss_ratio_last_week': '1.331788498', 'winloss_ratio_last_month': '2.328578629', 'winloss_ratio_last_3month': '2.629162356', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-09 12:00', 'high': 74.5, 'open': 74.47, 'close': 73.55, 'low': 73.17, 'volume': 2920, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 2.6, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013065723, 'volatility_last_month': 0.016297413, 'volatility_last_3months': 0.018749264, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.372437589', 'winloss_ratio_last_month': '2.32672022', 'winloss_ratio_last_3month': '2.629275615', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-09 13:00', 'high': 73.69, 'open': 73.57, 'close': 73.44, 'low': 73.01, 'volume': 2600, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.6, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013007136, 'volatility_last_month': 0.016267645, 'volatility_last_3months': 0.018749426, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.410221265', 'winloss_ratio_last_month': '2.327731092', 'winloss_ratio_last_3month': '2.629387094', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-09 14:00', 'high': 73.69, 'open': 73.4, 'close': 73.18, 'low': 73.03, 'volume': 2675, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.6, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.012926069, 'volatility_last_month': 0.016267928, 'volatility_last_3months': 0.018747292, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.445432192', 'winloss_ratio_last_month': '2.329440072', 'winloss_ratio_last_3month': '2.629496833', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-09 15:00', 'high': 73.65, 'open': 73.18, 'close': 72.87, 'low': 72.85, 'volume': 3756, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.82, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.012915464, 'volatility_last_month': 0.016270556, 'volatility_last_3months': 0.018737241, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.490319012', 'winloss_ratio_last_month': '2.331026201', 'winloss_ratio_last_3month': '2.629604873', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-09 16:00', 'high': 73.03, 'open': 72.85, 'close': 72.61, 'low': 72.55, 'volume': 3300, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.73, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.012928869, 'volatility_last_month': 0.016275329, 'volatility_last_3months': 0.018735724, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.529771542', 'winloss_ratio_last_month': '2.332606416', 'winloss_ratio_last_3month': '2.629711254', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-10 07:00', 'high': 73.27, 'open': 72.5, 'close': 72.6, 'low': 72.5, 'volume': 544, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 1.53, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.01243714, 'volatility_last_month': 0.01627348, 'volatility_last_3months': 0.018735691, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.525197542', 'winloss_ratio_last_month': '2.334180749', 'winloss_ratio_last_3month': '2.629816012', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-10 08:00', 'high': 72.94, 'open': 72.6, 'close': 70.89, 'low': 70.5, 'volume': 3555, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.28, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013314019, 'volatility_last_month': 0.016562534, 'volatility_last_3months': 0.018817087, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.560992783', 'winloss_ratio_last_month': '2.335749233', 'winloss_ratio_last_3month': '2.629919186', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-10 09:00', 'high': 71.59, 'open': 70.86, 'close': 71.51, 'low': 70.71, 'volume': 2367, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013527247, 'volatility_last_month': 0.016521652, 'volatility_last_3months': 0.018797624, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.598657481', 'winloss_ratio_last_month': '2.337311901', 'winloss_ratio_last_3month': '2.63002081', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-10 10:00', 'high': 72.54, 'open': 71.52, 'close': 72.4, 'low': 71.3, 'volume': 2242, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013344448, 'volatility_last_month': 0.016428796, 'volatility_last_3months': 0.018822234, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.636589257', 'winloss_ratio_last_month': '2.338868785', 'winloss_ratio_last_3month': '2.63012092', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-10 11:00', 'high': 72.4, 'open': 72.4, 'close': 71.99, 'low': 71.85, 'volume': 935, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013193356, 'volatility_last_month': 0.016445067, 'volatility_last_3months': 0.018825497, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.674790962', 'winloss_ratio_last_month': '2.340419917', 'winloss_ratio_last_3month': '2.630219548', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-10 12:00', 'high': 72.9, 'open': 71.94, 'close': 72.6, 'low': 71.79, 'volume': 1585, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013338934, 'volatility_last_month': 0.016453549, 'volatility_last_3months': 0.018832436, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.713265488', 'winloss_ratio_last_month': '2.341965329', 'winloss_ratio_last_3month': '2.630316728', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-10 13:00', 'high': 74.29, 'open': 72.61, 'close': 72.9, 'low': 72.53, 'volume': 2292, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013392586, 'volatility_last_month': 0.016385163, 'volatility_last_3months': 0.01883378, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.752015768', 'winloss_ratio_last_month': '2.343505053', 'winloss_ratio_last_3month': '2.630412491', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-10 14:00', 'high': 73.7, 'open': 72.89, 'close': 73.35, 'low': 72.83, 'volume': 1042, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013495157, 'volatility_last_month': 0.016213697, 'volatility_last_3months': 0.018838264, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.791044776', 'winloss_ratio_last_month': '2.34503912', 'winloss_ratio_last_3month': '2.630506869', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-10 15:00', 'high': 73.51, 'open': 73.37, 'close': 73.0, 'low': 72.81, 'volume': 1059, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013519671, 'volatility_last_month': 0.01620781, 'volatility_last_3months': 0.018840495, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.830355531', 'winloss_ratio_last_month': '2.34656756', 'winloss_ratio_last_3month': '2.63059989', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-10 16:00', 'high': 73.55, 'open': 73.0, 'close': 73.17, 'low': 72.88, 'volume': 1579, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.01343583, 'volatility_last_month': 0.016208395, 'volatility_last_3months': 0.018829584, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.869951096', 'winloss_ratio_last_month': '2.348090405', 'winloss_ratio_last_3month': '2.630691584', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-11 07:00', 'high': 73.89, 'open': 73.33, 'close': 73.47, 'low': 73.05, 'volume': 541, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013462958, 'volatility_last_month': 0.01615438, 'volatility_last_3months': 0.018830657, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.909834576', 'winloss_ratio_last_month': '2.349607686', 'winloss_ratio_last_3month': '2.630781979', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-11 08:00', 'high': 74.21, 'open': 73.58, 'close': 73.5, 'low': 73.1, 'volume': 1161, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.012906502, 'volatility_last_month': 0.016142725, 'volatility_last_3months': 0.018830017, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.950009122', 'winloss_ratio_last_month': '2.351119433', 'winloss_ratio_last_3month': '2.630871103', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-11 09:00', 'high': 73.88, 'open': 73.45, 'close': 73.85, 'low': 73.43, 'volume': 779, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.012982708, 'volatility_last_month': 0.016151884, 'volatility_last_3months': 0.018832646, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.990477934', 'winloss_ratio_last_month': '2.352625677', 'winloss_ratio_last_3month': '2.630958981', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-11 10:00', 'high': 73.9, 'open': 73.84, 'close': 73.77, 'low': 73.11, 'volume': 1952, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.012808792, 'volatility_last_month': 0.016151969, 'volatility_last_3months': 0.01880753, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.031244257', 'winloss_ratio_last_month': '2.354126446', 'winloss_ratio_last_3month': '2.631045641', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-11 11:00', 'high': 75.34, 'open': 73.79, 'close': 75.04, 'low': 73.74, 'volume': 4046, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013340175, 'volatility_last_month': 0.016293453, 'volatility_last_3months': 0.018854471, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.072311382', 'winloss_ratio_last_month': '2.355621771', 'winloss_ratio_last_3month': '2.631131107', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-11 12:00', 'high': 75.5, 'open': 75.04, 'close': 74.96, 'low': 74.53, 'volume': 1657, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013266334, 'volatility_last_month': 0.016292342, 'volatility_last_3months': 0.018850149, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.113682651', 'winloss_ratio_last_month': '2.357111682', 'winloss_ratio_last_3month': '2.631215404', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-11 13:00', 'high': 75.0, 'open': 74.97, 'close': 74.82, 'low': 74.46, 'volume': 989, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.01310254, 'volatility_last_month': 0.016282976, 'volatility_last_3months': 0.018849327, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.155361457', 'winloss_ratio_last_month': '2.358596208', 'winloss_ratio_last_3month': '2.631298555', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-11 14:00', 'high': 76.3, 'open': 74.87, 'close': 76.3, 'low': 74.76, 'volume': 2269, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013888576, 'volatility_last_month': 0.016460012, 'volatility_last_3months': 0.018907238, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.19735124', 'winloss_ratio_last_month': '2.360075378', 'winloss_ratio_last_3month': '2.631380584', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-11 15:00', 'high': 76.54, 'open': 76.3, 'close': 76.07, 'low': 75.8, 'volume': 2479, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013601238, 'volatility_last_month': 0.016442494, 'volatility_last_3months': 0.018907704, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.239655495', 'winloss_ratio_last_month': '2.361549221', 'winloss_ratio_last_3month': '2.631461513', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-11 16:00', 'high': 76.54, 'open': 76.07, 'close': 75.79, 'low': 75.25, 'volume': 2288, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013626217, 'volatility_last_month': 0.016450655, 'volatility_last_3months': 0.018905491, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.282277767', 'winloss_ratio_last_month': '2.363017766', 'winloss_ratio_last_3month': '2.631541365', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-14 07:00', 'high': 76.0, 'open': 75.69, 'close': 75.15, 'low': 75.06, 'volume': 512, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013738597, 'volatility_last_month': 0.016234229, 'volatility_last_3months': 0.018903405, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.325221656', 'winloss_ratio_last_month': '2.364481041', 'winloss_ratio_last_3month': '2.63162016', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-14 08:00', 'high': 75.34, 'open': 75.11, 'close': 75.06, 'low': 74.75, 'volume': 984, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013473492, 'volatility_last_month': 0.016235652, 'volatility_last_3months': 0.018903168, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.368490816', 'winloss_ratio_last_month': '2.365939074', 'winloss_ratio_last_3month': '2.63169792', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-14 09:00', 'high': 75.45, 'open': 75.1, 'close': 75.04, 'low': 74.87, 'volume': 1152, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013126941, 'volatility_last_month': 0.016231183, 'volatility_last_3months': 0.018902304, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.412088956', 'winloss_ratio_last_month': '2.367391894', 'winloss_ratio_last_3month': '2.631774664', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-14 10:00', 'high': 75.56, 'open': 75.05, 'close': 74.95, 'low': 74.85, 'volume': 1238, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013085565, 'volatility_last_month': 0.016165556, 'volatility_last_3months': 0.018898888, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.456019844', 'winloss_ratio_last_month': '2.368839529', 'winloss_ratio_last_3month': '2.631850413', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-14 11:00', 'high': 75.25, 'open': 74.94, 'close': 75.21, 'low': 74.86, 'volume': 552, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013051988, 'volatility_last_month': 0.016170089, 'volatility_last_3months': 0.018879982, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.500287301', 'winloss_ratio_last_month': '2.370282006', 'winloss_ratio_last_3month': '2.631925186', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-14 12:00', 'high': 75.19, 'open': 75.17, 'close': 75.04, 'low': 74.95, 'volume': 983, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013059261, 'volatility_last_month': 0.016168265, 'volatility_last_3months': 0.018879198, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.544895212', 'winloss_ratio_last_month': '2.371719353', 'winloss_ratio_last_3month': '2.631999001', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-14 13:00', 'high': 75.2, 'open': 75.01, 'close': 74.81, 'low': 74.78, 'volume': 1300, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013027621, 'volatility_last_month': 0.016145422, 'volatility_last_3months': 0.018809769, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.58984752', 'winloss_ratio_last_month': '2.373151597', 'winloss_ratio_last_3month': '2.632071877', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-14 14:00', 'high': 75.07, 'open': 74.83, 'close': 74.96, 'low': 74.59, 'volume': 2045, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013024265, 'volatility_last_month': 0.016146259, 'volatility_last_3months': 0.018794823, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.635148227', 'winloss_ratio_last_month': '2.374578765', 'winloss_ratio_last_3month': '2.632143832', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-14 15:00', 'high': 75.0, 'open': 74.95, 'close': 74.86, 'low': 74.79, 'volume': 955, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.01289787, 'volatility_last_month': 0.016134848, 'volatility_last_3months': 0.018787423, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.680801401', 'winloss_ratio_last_month': '2.376000884', 'winloss_ratio_last_3month': '2.632214883', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-14 16:00', 'high': 75.38, 'open': 74.86, 'close': 75.03, 'low': 74.85, 'volume': 2997, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.012830471, 'volatility_last_month': 0.016134342, 'volatility_last_3months': 0.018770435, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.72681117', 'winloss_ratio_last_month': '2.377417982', 'winloss_ratio_last_3month': '2.632285046', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-15 07:00', 'high': 75.35, 'open': 75.03, 'close': 74.61, 'low': 74.5, 'volume': 598, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.012882946, 'volatility_last_month': 0.016124262, 'volatility_last_3months': 0.018763253, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.773181729', 'winloss_ratio_last_month': '2.378830084', 'winloss_ratio_last_3month': '2.632354339', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-15 08:00', 'high': 74.9, 'open': 74.65, 'close': 74.84, 'low': 74.55, 'volume': 795, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.012748522, 'volatility_last_month': 0.016123976, 'volatility_last_3months': 0.018757289, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.819917339', 'winloss_ratio_last_month': '2.380237216', 'winloss_ratio_last_3month': '2.632422777', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-15 09:00', 'high': 76.05, 'open': 74.88, 'close': 75.66, 'low': 74.74, 'volume': 2919, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.012971333, 'volatility_last_month': 0.016180789, 'volatility_last_3months': 0.018773763, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.867022328', 'winloss_ratio_last_month': '2.381639406', 'winloss_ratio_last_3month': '2.632490377', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-15 10:00', 'high': 75.85, 'open': 75.68, 'close': 75.11, 'low': 74.99, 'volume': 1607, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.01300058, 'volatility_last_month': 0.016207319, 'volatility_last_3months': 0.018646722, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.914501091', 'winloss_ratio_last_month': '2.383036679', 'winloss_ratio_last_3month': '2.632557153', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-15 11:00', 'high': 75.19, 'open': 75.15, 'close': 74.34, 'low': 74.25, 'volume': 1601, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013171774, 'volatility_last_month': 0.016267559, 'volatility_last_3months': 0.01865146, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.962358096', 'winloss_ratio_last_month': '2.38442906', 'winloss_ratio_last_3month': '2.632623121', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-15 12:00', 'high': 74.47, 'open': 74.29, 'close': 74.35, 'low': 74.0, 'volume': 1894, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013130781, 'volatility_last_month': 0.016267624, 'volatility_last_3months': 0.018651266, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '3.01059788', 'winloss_ratio_last_month': '2.385816576', 'winloss_ratio_last_3month': '2.632688295', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-15 13:00', 'high': 75.16, 'open': 74.35, 'close': 74.79, 'low': 74.3, 'volume': 895, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013216966, 'volatility_last_month': 0.016138547, 'volatility_last_3months': 0.018656932, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '3.059225055', 'winloss_ratio_last_month': '2.387199253', 'winloss_ratio_last_3month': '2.632752689', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-15 14:00', 'high': 75.15, 'open': 74.78, 'close': 75.1, 'low': 74.56, 'volume': 1697, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.013253456, 'volatility_last_month': 0.016145546, 'volatility_last_3months': 0.018655702, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '3.108244306', 'winloss_ratio_last_month': '2.388577114', 'winloss_ratio_last_3month': '2.632816317', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-15 15:00', 'high': 76.99, 'open': 75.07, 'close': 76.76, 'low': 75.04, 'volume': 2301, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.014158884, 'volatility_last_month': 0.016384013, 'volatility_last_3months': 0.018728065, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '3.157660393', 'winloss_ratio_last_month': '2.389950185', 'winloss_ratio_last_3month': '2.632879193', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-15 16:00', 'high': 77.25, 'open': 76.73, 'close': 77.15, 'low': 76.37, 'volume': 2799, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.014207969, 'volatility_last_month': 0.016375496, 'volatility_last_3months': 0.018724969, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '3.207478155', 'winloss_ratio_last_month': '2.391318492', 'winloss_ratio_last_3month': '2.632941331', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-16 07:00', 'high': 77.0, 'open': 77.0, 'close': 76.78, 'low': 76.15, 'volume': 412, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.31, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.01415832, 'volatility_last_month': 0.01638367, 'volatility_last_3months': 0.018723707, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '3.25770251', 'winloss_ratio_last_month': '2.392682059', 'winloss_ratio_last_3month': '2.633002742', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-16 08:00', 'high': 77.41, 'open': 76.83, 'close': 76.65, 'low': 76.62, 'volume': 1052, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.99, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.8, 'volatility_last_week': 0.014007191, 'volatility_last_month': 0.016335121, 'volatility_last_3months': 0.018710608, 'win_ratio_last_week': 0.75, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '3.403161222', 'winloss_ratio_last_month': '2.394040911', 'winloss_ratio_last_3month': '2.63306344', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-16 09:00', 'high': 76.75, 'open': 76.75, 'close': 75.21, 'low': 75.05, 'volume': 1687, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 2.25, 'pnl_last_month': 5.29, 'pnl_last_3months': 12.02, 'volatility_last_week': 0.01454011, 'volatility_last_month': 0.016473408, 'volatility_last_3months': 0.018763136, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.586956522, 'winloss_ratio_last_week': '3.526258444', 'winloss_ratio_last_month': '2.395395071', 'winloss_ratio_last_3month': '2.633589383', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-16 10:00', 'high': 75.24, 'open': 75.2, 'close': 74.11, 'low': 73.75, 'volume': 3986, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.82, 'pnl_last_month': 5.29, 'pnl_last_3months': 11.35, 'volatility_last_week': 0.01425066, 'volatility_last_month': 0.016567591, 'volatility_last_3months': 0.018787434, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.577777778, 'winloss_ratio_last_week': '3.593503271', 'winloss_ratio_last_month': '2.396744565', 'winloss_ratio_last_3month': '2.63357356', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-16 11:00', 'high': 74.42, 'open': 74.12, 'close': 74.18, 'low': 73.75, 'volume': 1843, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.94, 'pnl_last_month': 5.29, 'pnl_last_3months': 10.24, 'volatility_last_week': 0.014247001, 'volatility_last_month': 0.016462085, 'volatility_last_3months': 0.018765539, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.568181818, 'winloss_ratio_last_week': '3.686339055', 'winloss_ratio_last_month': '2.398089417', 'winloss_ratio_last_3month': '2.632674976', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-16 12:00', 'high': 74.29, 'open': 74.16, 'close': 73.65, 'low': 73.35, 'volume': 2130, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.29, 'pnl_last_3months': 10.71, 'volatility_last_week': 0.0140238, 'volatility_last_month': 0.016485454, 'volatility_last_3months': 0.018767995, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.581395349, 'winloss_ratio_last_week': '3.741222686', 'winloss_ratio_last_month': '2.399429649', 'winloss_ratio_last_3month': '2.632765143', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-16 13:00', 'high': 74.0, 'open': 73.7, 'close': 73.69, 'low': 73.31, 'volume': 2440, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.29, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.014019406, 'volatility_last_month': 0.01648208, 'volatility_last_3months': 0.018765902, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.801181608', 'winloss_ratio_last_month': '2.400765287', 'winloss_ratio_last_3month': '2.632563083', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-16 14:00', 'high': 73.89, 'open': 73.66, 'close': 73.48, 'low': 73.08, 'volume': 1592, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.29, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.014010081, 'volatility_last_month': 0.016456661, 'volatility_last_3months': 0.018766877, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.866953946', 'winloss_ratio_last_month': '2.402096353', 'winloss_ratio_last_3month': '2.632363078', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-16 15:00', 'high': 73.7, 'open': 73.45, 'close': 73.49, 'low': 73.25, 'volume': 1264, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.29, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.013970755, 'volatility_last_month': 0.016453734, 'volatility_last_3months': 0.018766351, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.915049226', 'winloss_ratio_last_month': '2.403712686', 'winloss_ratio_last_3month': '2.632165096', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-16 16:00', 'high': 73.79, 'open': 73.5, 'close': 73.41, 'low': 73.25, 'volume': 3547, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.29, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.013944849, 'volatility_last_month': 0.01645232, 'volatility_last_3months': 0.018765045, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '3.970267883', 'winloss_ratio_last_month': '2.404188609', 'winloss_ratio_last_3month': '2.631969107', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-17 07:00', 'high': 74.42, 'open': 73.55, 'close': 73.78, 'low': 73.2, 'volume': 636, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.29, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.013991157, 'volatility_last_month': 0.016459253, 'volatility_last_3months': 0.018752283, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '4.067922198', 'winloss_ratio_last_month': '2.405755942', 'winloss_ratio_last_3month': '2.631775081', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-17 08:00', 'high': 74.71, 'open': 73.78, 'close': 74.25, 'low': 73.68, 'volume': 1174, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.29, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.01277418, 'volatility_last_month': 0.016471913, 'volatility_last_3months': 0.018746453, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '4.076362484', 'winloss_ratio_last_month': '2.404619903', 'winloss_ratio_last_3month': '2.631582988', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-17 09:00', 'high': 74.51, 'open': 74.25, 'close': 74.0, 'low': 73.73, 'volume': 1001, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.29, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.012672332, 'volatility_last_month': 0.01644398, 'volatility_last_3months': 0.018742813, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '4.081274382', 'winloss_ratio_last_month': '2.401860563', 'winloss_ratio_last_3month': '2.6313928', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-17 10:00', 'high': 73.91, 'open': 73.91, 'close': 73.17, 'low': 72.65, 'volume': 1874, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.29, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.012661941, 'volatility_last_month': 0.016516751, 'volatility_last_3months': 0.018760023, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '4.086448598', 'winloss_ratio_last_month': '2.399122939', 'winloss_ratio_last_3month': '2.631204488', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-17 11:00', 'high': 73.44, 'open': 73.18, 'close': 72.86, 'low': 72.65, 'volume': 1124, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.29, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.012628065, 'volatility_last_month': 0.016527881, 'volatility_last_3months': 0.018752942, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '4.091906722', 'winloss_ratio_last_month': '2.396406774', 'winloss_ratio_last_3month': '2.631018025', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-17 12:00', 'high': 73.31, 'open': 72.83, 'close': 73.12, 'low': 72.71, 'volume': 1049, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.29, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.012498225, 'volatility_last_month': 0.016533017, 'volatility_last_3months': 0.018746133, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '4.097672779', 'winloss_ratio_last_month': '2.393711816', 'winloss_ratio_last_3month': '2.630833384', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-17 13:00', 'high': 73.28, 'open': 73.01, 'close': 73.0, 'low': 72.81, 'volume': 1228, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.19, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.01246748, 'volatility_last_month': 0.016533328, 'volatility_last_3months': 0.018704757, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.523809524, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '4.103773585', 'winloss_ratio_last_month': '2.390740961', 'winloss_ratio_last_3month': '2.630650537', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-17 14:00', 'high': 73.2, 'open': 73.0, 'close': 72.9, 'low': 72.75, 'volume': 1360, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.37, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.012382387, 'volatility_last_month': 0.01653236, 'volatility_last_3months': 0.018701612, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '4.110239163', 'winloss_ratio_last_month': '2.389064765', 'winloss_ratio_last_3month': '2.63046946', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-17 15:00', 'high': 72.92, 'open': 72.88, 'close': 72.76, 'low': 72.08, 'volume': 1698, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.5, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.012338952, 'volatility_last_month': 0.016534007, 'volatility_last_3months': 0.0186379, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.578947368, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '4.117103236', 'winloss_ratio_last_month': '2.38831529', 'winloss_ratio_last_3month': '2.630290127', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-17 16:00', 'high': 72.71, 'open': 72.71, 'close': 72.0, 'low': 71.89, 'volume': 2355, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.12, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.012569882, 'volatility_last_month': 0.016576951, 'volatility_last_3months': 0.018623443, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.555555556, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '4.124403816', 'winloss_ratio_last_month': '2.386452543', 'winloss_ratio_last_3month': '2.630112512', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-18 07:00', 'high': 73.16, 'open': 72.38, 'close': 72.81, 'low': 72.0, 'volume': 585, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': 0.0, 'pnl_last_month': 5.23, 'pnl_last_3months': 10.34, 'volatility_last_week': 0.012823882, 'volatility_last_month': 0.016637005, 'volatility_last_3months': 0.018623668, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.588235294, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '4.132183908', 'winloss_ratio_last_month': '2.385370218', 'winloss_ratio_last_3month': '2.629936591', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-18 08:00', 'high': 72.95, 'open': 72.84, 'close': 72.67, 'low': 72.19, 'volume': 793, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.14, 'pnl_last_month': 5.37, 'pnl_last_3months': 10.48, 'volatility_last_week': 0.01282969, 'volatility_last_month': 0.016593331, 'volatility_last_3months': 0.018606004, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.611111111, 'win_ratio_last_3month': 0.581395349, 'winloss_ratio_last_week': '4.146434635', 'winloss_ratio_last_month': '2.384498347', 'winloss_ratio_last_3month': '2.629864717', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-18 09:00', 'high': 73.08, 'open': 72.66, 'close': 72.6, 'low': 72.2, 'volume': 1744, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.21, 'pnl_last_month': 5.44, 'pnl_last_3months': 10.55, 'volatility_last_week': 0.012773699, 'volatility_last_month': 0.016584213, 'volatility_last_3months': 0.018575964, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.631578947, 'win_ratio_last_3month': 0.590909091, 'winloss_ratio_last_week': '4.164762742', 'winloss_ratio_last_month': '2.383733147', 'winloss_ratio_last_3month': '2.629844468', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-18 10:00', 'high': 72.68, 'open': 72.54, 'close': 72.55, 'low': 72.2, 'volume': 1474, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.26, 'pnl_last_month': 5.49, 'pnl_last_3months': 10.6, 'volatility_last_week': 0.012772721, 'volatility_last_month': 0.016584557, 'volatility_last_3months': 0.018575979, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.65, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '4.186703097', 'winloss_ratio_last_month': '2.383044681', 'winloss_ratio_last_3month': '2.629860628', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-18 11:00', 'high': 73.41, 'open': 72.53, 'close': 72.6, 'low': 72.38, 'volume': 1530, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': 0.21, 'pnl_last_month': 5.44, 'pnl_last_3months': 10.55, 'volatility_last_week': 0.012060231, 'volatility_last_month': 0.016583547, 'volatility_last_3months': 0.018575996, 'win_ratio_last_week': 0.75, 'win_ratio_last_month': 0.619047619, 'win_ratio_last_3month': 0.586956522, 'winloss_ratio_last_week': '4.200377181', 'winloss_ratio_last_month': '2.382186869', 'winloss_ratio_last_3month': '2.629781834', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-18 12:00', 'high': 73.99, 'open': 72.61, 'close': 73.54, 'low': 72.61, 'volume': 1357, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 4.5, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012480132, 'volatility_last_month': 0.016655194, 'volatility_last_3months': 0.018602754, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '4.029906542', 'winloss_ratio_last_month': '2.378097441', 'winloss_ratio_last_3month': '2.627931158', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-18 13:00', 'high': 73.9, 'open': 73.57, 'close': 73.42, 'low': 73.35, 'volume': 1162, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 4.5, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012478637, 'volatility_last_month': 0.016650892, 'volatility_last_3months': 0.018592897, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '3.862436313', 'winloss_ratio_last_month': '2.374040635', 'winloss_ratio_last_3month': '2.62610023', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-18 14:00', 'high': 73.52, 'open': 73.4, 'close': 72.86, 'low': 72.65, 'volume': 938, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 4.5, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.011616904, 'volatility_last_month': 0.016652199, 'volatility_last_3months': 0.018601241, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '3.697887971', 'winloss_ratio_last_month': '2.370016063', 'winloss_ratio_last_3month': '2.624288735', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-18 15:00', 'high': 73.0, 'open': 72.88, 'close': 72.7, 'low': 72.62, 'volume': 980, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 4.5, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.011610009, 'volatility_last_month': 0.016653101, 'volatility_last_3months': 0.018584924, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '3.536185708', 'winloss_ratio_last_month': '2.366023343', 'winloss_ratio_last_3month': '2.622496365', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-18 16:00', 'high': 72.9, 'open': 72.7, 'close': 72.16, 'low': 71.95, 'volume': 2516, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 4.5, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.011696195, 'volatility_last_month': 0.016592068, 'volatility_last_3months': 0.018583286, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '3.377256318', 'winloss_ratio_last_month': '2.362062096', 'winloss_ratio_last_3month': '2.620722821', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-21 07:00', 'high': 73.64, 'open': 72.54, 'close': 73.33, 'low': 71.68, 'volume': 1126, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 5.85, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012232826, 'volatility_last_month': 0.016511969, 'volatility_last_3months': 0.018568004, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.619047619, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '3.221029083', 'winloss_ratio_last_month': '2.367190723', 'winloss_ratio_last_3month': '2.618967805', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-21 08:00', 'high': 73.68, 'open': 73.33, 'close': 73.29, 'low': 72.72, 'volume': 1344, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 5.81, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012231633, 'volatility_last_month': 0.016512412, 'volatility_last_3months': 0.018553555, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '3.06743567', 'winloss_ratio_last_month': '2.372204518', 'winloss_ratio_last_3month': '2.61723103', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-21 09:00', 'high': 74.06, 'open': 73.29, 'close': 73.94, 'low': 73.0, 'volume': 1825, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 5.81, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012429978, 'volatility_last_month': 0.016549107, 'volatility_last_3months': 0.018563523, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.916410031', 'winloss_ratio_last_month': '2.377217315', 'winloss_ratio_last_3month': '2.615512213', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-21 10:00', 'high': 74.91, 'open': 73.96, 'close': 74.77, 'low': 73.59, 'volume': 3053, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 5.81, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012721768, 'volatility_last_month': 0.016601712, 'volatility_last_3months': 0.018580388, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.767888307', 'winloss_ratio_last_month': '2.382229113', 'winloss_ratio_last_3month': '2.613811076', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-21 11:00', 'high': 74.8, 'open': 74.75, 'close': 74.54, 'low': 74.12, 'volume': 929, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 5.81, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.01271274, 'volatility_last_month': 0.016608144, 'volatility_last_3months': 0.018573492, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.621808741', 'winloss_ratio_last_month': '2.387239913', 'winloss_ratio_last_3month': '2.612127348', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-21 12:00', 'high': 74.66, 'open': 74.55, 'close': 74.42, 'low': 74.15, 'volume': 667, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 5.81, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012707528, 'volatility_last_month': 0.016610347, 'volatility_last_3months': 0.018573634, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.478111588', 'winloss_ratio_last_month': '2.392249716', 'winloss_ratio_last_3month': '2.610460764', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-21 13:00', 'high': 74.99, 'open': 74.37, 'close': 74.88, 'low': 74.35, 'volume': 978, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 5.81, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.01277513, 'volatility_last_month': 0.01662703, 'volatility_last_3months': 0.018529307, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.336739038', 'winloss_ratio_last_month': '2.397258521', 'winloss_ratio_last_3month': '2.608811062', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-21 14:00', 'high': 75.2, 'open': 74.9, 'close': 74.94, 'low': 74.29, 'volume': 1470, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 5.81, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012767565, 'volatility_last_month': 0.016051876, 'volatility_last_3months': 0.018494931, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.197635135', 'winloss_ratio_last_month': '2.402266329', 'winloss_ratio_last_3month': '2.607177988', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-21 15:00', 'high': 75.2, 'open': 74.94, 'close': 74.52, 'low': 74.43, 'volume': 1843, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 4.2, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012833694, 'volatility_last_month': 0.015798664, 'volatility_last_3months': 0.018493212, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.578947368, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '2.060745706', 'winloss_ratio_last_month': '2.402695441', 'winloss_ratio_last_3month': '2.605561291', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-21 16:00', 'high': 74.7, 'open': 74.49, 'close': 74.28, 'low': 74.28, 'volume': 2628, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 4.01, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012841896, 'volatility_last_month': 0.015801507, 'volatility_last_3months': 0.018493089, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.555555556, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.926018288', 'winloss_ratio_last_month': '2.402584295', 'winloss_ratio_last_3month': '2.603960726', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-22 07:00', 'high': 74.7, 'open': 74.7, 'close': 74.24, 'low': 74.04, 'volume': 363, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 4.0, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012775123, 'volatility_last_month': 0.015801749, 'volatility_last_3months': 0.018491694, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.793402062', 'winloss_ratio_last_month': '2.402444745', 'winloss_ratio_last_3month': '2.602376054', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-22 08:00', 'high': 74.62, 'open': 74.26, 'close': 73.95, 'low': 73.71, 'volume': 1288, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 4.22, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012782958, 'volatility_last_month': 0.015805201, 'volatility_last_3months': 0.018489308, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5625, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.662847791', 'winloss_ratio_last_month': '2.403808413', 'winloss_ratio_last_3month': '2.600807037', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-22 09:00', 'high': 73.98, 'open': 73.94, 'close': 73.54, 'low': 73.35, 'volume': 1656, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 1.87, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012553048, 'volatility_last_month': 0.015249258, 'volatility_last_3months': 0.018493647, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.534307755', 'winloss_ratio_last_month': '2.398486077', 'winloss_ratio_last_3month': '2.599253447', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-22 10:00', 'high': 74.56, 'open': 73.54, 'close': 74.37, 'low': 73.3, 'volume': 1674, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 1.87, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012750912, 'volatility_last_month': 0.01531685, 'volatility_last_3months': 0.018091191, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.407735697', 'winloss_ratio_last_month': '2.393158135', 'winloss_ratio_last_3month': '2.597715056', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-22 11:00', 'high': 74.6, 'open': 74.37, 'close': 74.51, 'low': 74.08, 'volume': 1379, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 1.87, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012521192, 'volatility_last_month': 0.015315991, 'volatility_last_3months': 0.018090163, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.283086765', 'winloss_ratio_last_month': '2.387824576', 'winloss_ratio_last_3month': '2.596191641', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-22 12:00', 'high': 74.52, 'open': 74.51, 'close': 74.29, 'low': 74.23, 'volume': 808, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 1.87, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012541455, 'volatility_last_month': 0.015303641, 'volatility_last_3months': 0.018085712, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.16031746', 'winloss_ratio_last_month': '2.382485393', 'winloss_ratio_last_3month': '2.594682987', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-22 13:00', 'high': 74.7, 'open': 74.26, 'close': 74.14, 'low': 74.11, 'volume': 770, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 1.87, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012467196, 'volatility_last_month': 0.014994059, 'volatility_last_3months': 0.018082368, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '1.039385585', 'winloss_ratio_last_month': '2.377140576', 'winloss_ratio_last_3month': '2.593188878', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-22 14:00', 'high': 74.17, 'open': 74.16, 'close': 73.71, 'low': 73.59, 'volume': 818, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 1.87, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.012493199, 'volatility_last_month': 0.014919293, 'volatility_last_3months': 0.018072885, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '0.920250195', 'winloss_ratio_last_month': '2.371790116', 'winloss_ratio_last_3month': '2.591709107', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-22 15:00', 'high': 73.97, 'open': 73.71, 'close': 73.75, 'low': 73.53, 'volume': 1953, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 1.87, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.011274885, 'volatility_last_month': 0.014865994, 'volatility_last_3months': 0.018063559, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '0.802871556', 'winloss_ratio_last_month': '2.366434006', 'winloss_ratio_last_3month': '2.590243466', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-22 16:00', 'high': 74.09, 'open': 73.67, 'close': 73.87, 'low': 73.67, 'volume': 1772, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 1.87, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.011201117, 'volatility_last_month': 0.014866879, 'volatility_last_3months': 0.018048255, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '0.687211094', 'winloss_ratio_last_month': '2.361072234', 'winloss_ratio_last_3month': '2.588791756', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-23 07:00', 'high': 74.5, 'open': 74.09, 'close': 74.4, 'low': 73.8, 'volume': 461, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 1.87, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.011318564, 'volatility_last_month': 0.014852328, 'volatility_last_3months': 0.018055236, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '0.573231358', 'winloss_ratio_last_month': '2.355704794', 'winloss_ratio_last_3month': '2.587353778', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-23 08:00', 'high': 74.85, 'open': 74.42, 'close': 74.69, 'low': 74.34, 'volume': 1115, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 1.87, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.011365979, 'volatility_last_month': 0.014828219, 'volatility_last_3months': 0.018057373, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '0.449296817', 'winloss_ratio_last_month': '2.350331675', 'winloss_ratio_last_3month': '2.585929338', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-23 09:00', 'high': 74.73, 'open': 74.65, 'close': 73.86, 'low': 73.6, 'volume': 1611, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 1.87, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.010772903, 'volatility_last_month': 0.014896918, 'volatility_last_3months': 0.018032559, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '0.359627106', 'winloss_ratio_last_month': '2.344952869', 'winloss_ratio_last_3month': '2.584518247', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-23 10:00', 'high': 75.88, 'open': 73.81, 'close': 75.79, 'low': 73.76, 'volume': 2019, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': -0.73, 'pnl_last_month': 1.87, 'pnl_last_3months': 9.61, 'volatility_last_week': 0.01190279, 'volatility_last_month': 0.015160332, 'volatility_last_3months': 0.018131694, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '0.325104312', 'winloss_ratio_last_month': '2.339568366', 'winloss_ratio_last_3month': '2.583120316', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-23 11:00', 'high': 76.5, 'open': 75.8, 'close': 76.04, 'low': 75.75, 'volume': 1994, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': -0.48, 'pnl_last_month': 2.12, 'pnl_last_3months': 9.86, 'volatility_last_week': 0.011921496, 'volatility_last_month': 0.01516704, 'volatility_last_3months': 0.018132603, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.5625, 'win_ratio_last_3month': 0.583333333, 'winloss_ratio_last_week': '0.299831933', 'winloss_ratio_last_month': '2.334536134', 'winloss_ratio_last_3month': '2.581890507', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-23 12:00', 'high': 76.39, 'open': 76.1, 'close': 76.16, 'low': 75.7, 'volume': 950, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': -0.36, 'pnl_last_month': 2.24, 'pnl_last_3months': 9.98, 'volatility_last_week': 0.011777798, 'volatility_last_month': 0.015028771, 'volatility_last_3months': 0.018133043, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.588235294, 'win_ratio_last_3month': 0.591836735, 'winloss_ratio_last_week': '0.310670137', 'winloss_ratio_last_month': '2.329670487', 'winloss_ratio_last_3month': '2.580746186', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-23 13:00', 'high': 76.28, 'open': 76.19, 'close': 76.2, 'low': 75.85, 'volume': 1014, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': -0.32, 'pnl_last_month': 2.28, 'pnl_last_3months': 10.02, 'volatility_last_week': 0.01177781, 'volatility_last_month': 0.015028935, 'volatility_last_3months': 0.018129243, 'win_ratio_last_week': 0.75, 'win_ratio_last_month': 0.611111111, 'win_ratio_last_3month': 0.6, 'winloss_ratio_last_week': '0.322092657', 'winloss_ratio_last_month': '2.324857016', 'winloss_ratio_last_3month': '2.579636985', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-23 14:00', 'high': 76.88, 'open': 76.22, 'close': 76.74, 'low': 76.14, 'volume': 1247, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': 0.22, 'pnl_last_month': 2.82, 'pnl_last_3months': 10.56, 'volatility_last_week': 0.011842363, 'volatility_last_month': 0.015057701, 'volatility_last_3months': 0.018135841, 'win_ratio_last_week': 0.777777778, 'win_ratio_last_month': 0.631578947, 'win_ratio_last_3month': 0.607843137, 'winloss_ratio_last_week': '0.349327628', 'winloss_ratio_last_month': '2.320812896', 'winloss_ratio_last_3month': '2.578868447', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-23 15:00', 'high': 76.83, 'open': 76.73, 'close': 75.82, 'low': 75.7, 'volume': 1773, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '-', 'pnl_last_week': -0.7, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012233884, 'volatility_last_month': 0.015066569, 'volatility_last_3months': 0.018157816, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.365001444', 'winloss_ratio_last_month': '2.313710036', 'winloss_ratio_last_3month': '2.576662648', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-23 16:00', 'high': 76.17, 'open': 75.82, 'close': 75.87, 'low': 75.76, 'volume': 2270, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.7, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012226829, 'volatility_last_month': 0.015030177, 'volatility_last_3months': 0.018152973, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.379036672', 'winloss_ratio_last_month': '2.306618363', 'winloss_ratio_last_3month': '2.574479299', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-24 07:00', 'high': 76.6, 'open': 76.05, 'close': 76.33, 'low': 75.8, 'volume': 486, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.7, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012250128, 'volatility_last_month': 0.014960289, 'volatility_last_3months': 0.018121107, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.391677503', 'winloss_ratio_last_month': '2.299537852', 'winloss_ratio_last_3month': '2.57231806', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-24 08:00', 'high': 76.86, 'open': 76.32, 'close': 75.86, 'low': 75.85, 'volume': 1460, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.7, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012277633, 'volatility_last_month': 0.014971389, 'volatility_last_3months': 0.018100122, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.403121903', 'winloss_ratio_last_month': '2.292468476', 'winloss_ratio_last_3month': '2.570178595', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-24 09:00', 'high': 76.83, 'open': 75.85, 'close': 76.44, 'low': 75.63, 'volume': 1665, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.7, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012358978, 'volatility_last_month': 0.014987761, 'volatility_last_3months': 0.018101068, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.413532056', 'winloss_ratio_last_month': '2.285410208', 'winloss_ratio_last_3month': '2.568060578', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-24 10:00', 'high': 77.75, 'open': 76.41, 'close': 77.2, 'low': 76.31, 'volume': 1339, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.7, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012204701, 'volatility_last_month': 0.014933609, 'volatility_last_3months': 0.018107281, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.4230421', 'winloss_ratio_last_month': '2.278363024', 'winloss_ratio_last_3month': '2.565963687', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-24 11:00', 'high': 77.55, 'open': 77.17, 'close': 77.14, 'low': 76.87, 'volume': 1795, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.7, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012145327, 'volatility_last_month': 0.01468641, 'volatility_last_3months': 0.018066561, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.43176394', 'winloss_ratio_last_month': '2.271326896', 'winloss_ratio_last_3month': '2.563887609', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-24 12:00', 'high': 77.2, 'open': 77.11, 'close': 76.91, 'low': 76.72, 'volume': 597, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.7, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012169778, 'volatility_last_month': 0.014688195, 'volatility_last_3months': 0.018061183, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.439791667', 'winloss_ratio_last_month': '2.264301799', 'winloss_ratio_last_3month': '2.561832034', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-24 13:00', 'high': 77.1, 'open': 76.95, 'close': 76.73, 'low': 76.43, 'volume': 1018, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.7, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012179711, 'volatility_last_month': 0.014610689, 'volatility_last_3months': 0.018060596, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.447204969', 'winloss_ratio_last_month': '2.257287706', 'winloss_ratio_last_3month': '2.559796661', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-24 14:00', 'high': 76.95, 'open': 76.67, 'close': 76.71, 'low': 76.55, 'volume': 637, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.7, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012170133, 'volatility_last_month': 0.014549238, 'volatility_last_3months': 0.018055674, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.454071787', 'winloss_ratio_last_month': '2.250284592', 'winloss_ratio_last_3month': '2.557781193', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-24 15:00', 'high': 77.34, 'open': 76.73, 'close': 77.32, 'low': 76.57, 'volume': 1180, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.7, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012257486, 'volatility_last_month': 0.014575764, 'volatility_last_3months': 0.01798481, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.4604504', 'winloss_ratio_last_month': '2.243292432', 'winloss_ratio_last_3month': '2.555785339', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-24 16:00', 'high': 78.75, 'open': 77.36, 'close': 78.69, 'low': 77.29, 'volume': 3908, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.7, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012523009, 'volatility_last_month': 0.014588844, 'volatility_last_3months': 0.018021634, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.466391086', 'winloss_ratio_last_month': '2.236311198', 'winloss_ratio_last_3month': '2.553808816', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-25 07:00', 'high': 79.4, 'open': 78.71, 'close': 78.9, 'low': 78.39, 'volume': 809, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.7, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012316125, 'volatility_last_month': 0.014586362, 'volatility_last_3months': 0.017995077, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.471937446', 'winloss_ratio_last_month': '2.229340867', 'winloss_ratio_last_3month': '2.551851342', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-25 08:00', 'high': 79.5, 'open': 78.85, 'close': 79.24, 'low': 78.73, 'volume': 1264, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.84, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012302111, 'volatility_last_month': 0.014574624, 'volatility_last_3months': 0.017997699, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.472418433', 'winloss_ratio_last_month': '2.222381412', 'winloss_ratio_last_3month': '2.549912644', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-25 09:00', 'high': 79.78, 'open': 79.24, 'close': 79.66, 'low': 79.18, 'volume': 2041, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.91, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012312868, 'volatility_last_month': 0.014589919, 'volatility_last_3months': 0.017994845, 'win_ratio_last_week': 0.625, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.470588235', 'winloss_ratio_last_month': '2.215432807', 'winloss_ratio_last_3month': '2.547992454', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-25 10:00', 'high': 79.7, 'open': 79.7, 'close': 79.34, 'low': 79.26, 'volume': 1245, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.96, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012377929, 'volatility_last_month': 0.014570685, 'volatility_last_3months': 0.017989977, 'win_ratio_last_week': 0.571428571, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.467288243', 'winloss_ratio_last_month': '2.208495029', 'winloss_ratio_last_3month': '2.546090507', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-25 11:00', 'high': 79.34, 'open': 79.34, 'close': 78.27, 'low': 78.24, 'volume': 1248, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': -0.91, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012903932, 'volatility_last_month': 0.014671251, 'volatility_last_3months': 0.018015817, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.464894761', 'winloss_ratio_last_month': '2.20156805', 'winloss_ratio_last_3month': '2.544206545', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-25 12:00', 'high': 78.6, 'open': 78.26, 'close': 78.39, 'low': 78.1, 'volume': 582, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012606727, 'volatility_last_month': 0.014635116, 'volatility_last_3months': 0.018004416, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.476007382', 'winloss_ratio_last_month': '2.194651847', 'winloss_ratio_last_3month': '2.542340313', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-25 13:00', 'high': 78.58, 'open': 78.38, 'close': 78.26, 'low': 78.22, 'volume': 956, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012607083, 'volatility_last_month': 0.014623826, 'volatility_last_3months': 0.018004013, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.487143957', 'winloss_ratio_last_month': '2.187746393', 'winloss_ratio_last_3month': '2.540491564', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-25 14:00', 'high': 78.61, 'open': 78.21, 'close': 78.4, 'low': 78.12, 'volume': 576, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012419904, 'volatility_last_month': 0.014566352, 'volatility_last_3months': 0.018001449, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.498304562', 'winloss_ratio_last_month': '2.180851665', 'winloss_ratio_last_3month': '2.538660051', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-25 15:00', 'high': 78.94, 'open': 78.41, 'close': 78.74, 'low': 78.18, 'volume': 1087, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012405779, 'volatility_last_month': 0.014577926, 'volatility_last_3months': 0.018004424, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.509489276', 'winloss_ratio_last_month': '2.173967636', 'winloss_ratio_last_3month': '2.536845536', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-25 16:00', 'high': 79.12, 'open': 78.73, 'close': 78.97, 'low': 78.65, 'volume': 1672, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012213128, 'volatility_last_month': 0.014578267, 'volatility_last_3months': 0.018004618, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.520698177', 'winloss_ratio_last_month': '2.167094282', 'winloss_ratio_last_3month': '2.535047782', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-28 07:00', 'high': 78.92, 'open': 78.77, 'close': 77.7, 'low': 77.35, 'volume': 1127, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012446251, 'volatility_last_month': 0.014730254, 'volatility_last_3months': 0.01792906, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.531931344', 'winloss_ratio_last_month': '2.160231579', 'winloss_ratio_last_3month': '2.533266559', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-28 08:00', 'high': 78.3, 'open': 77.73, 'close': 77.67, 'low': 77.49, 'volume': 2793, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012445052, 'volatility_last_month': 0.014196268, 'volatility_last_3months': 0.017906943, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.543188854', 'winloss_ratio_last_month': '2.153379501', 'winloss_ratio_last_3month': '2.53150164', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-28 09:00', 'high': 77.91, 'open': 77.7, 'close': 77.75, 'low': 77.28, 'volume': 1537, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012305391, 'volatility_last_month': 0.014194105, 'volatility_last_3months': 0.017901116, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.554470789', 'winloss_ratio_last_month': '2.146538023', 'winloss_ratio_last_3month': '2.529752801', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-28 10:00', 'high': 78.0, 'open': 77.71, 'close': 77.77, 'low': 77.55, 'volume': 1126, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012057136, 'volatility_last_month': 0.014194155, 'volatility_last_3months': 0.017901121, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.565777226', 'winloss_ratio_last_month': '2.139707122', 'winloss_ratio_last_3month': '2.528019824', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-28 11:00', 'high': 77.79, 'open': 77.72, 'close': 77.05, 'low': 76.87, 'volume': 1828, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012260919, 'volatility_last_month': 0.014230258, 'volatility_last_3months': 0.017907276, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.577108247', 'winloss_ratio_last_month': '2.132886773', 'winloss_ratio_last_3month': '2.526302495', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-28 12:00', 'high': 77.68, 'open': 77.06, 'close': 77.31, 'low': 77.0, 'volume': 1031, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012264816, 'volatility_last_month': 0.014237148, 'volatility_last_3months': 0.01790905, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.58846393', 'winloss_ratio_last_month': '2.126076951', 'winloss_ratio_last_3month': '2.524600601', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-28 13:00', 'high': 77.39, 'open': 77.32, 'close': 77.11, 'low': 77.07, 'volume': 2007, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012219045, 'volatility_last_month': 0.014208032, 'volatility_last_3months': 0.017905242, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.599844358', 'winloss_ratio_last_month': '2.119277632', 'winloss_ratio_last_3month': '2.522913937', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-28 14:00', 'high': 77.52, 'open': 77.11, 'close': 77.41, 'low': 77.1, 'volume': 1128, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.01224407, 'volatility_last_month': 0.014124214, 'volatility_last_3months': 0.017907284, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.61124961', 'winloss_ratio_last_month': '2.112488792', 'winloss_ratio_last_3month': '2.521242298', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-28 15:00', 'high': 78.84, 'open': 77.38, 'close': 78.65, 'low': 77.37, 'volume': 2627, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.01266902, 'volatility_last_month': 0.014273227, 'volatility_last_3months': 0.017947418, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.622679769', 'winloss_ratio_last_month': '2.105710406', 'winloss_ratio_last_3month': '2.519585485', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-28 16:00', 'high': 79.04, 'open': 78.7, 'close': 78.59, 'low': 78.53, 'volume': 2041, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012634287, 'volatility_last_month': 0.014272989, 'volatility_last_3months': 0.017934552, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.634134916', 'winloss_ratio_last_month': '2.09894245', 'winloss_ratio_last_3month': '2.5179433', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-29 07:00', 'high': 78.87, 'open': 78.86, 'close': 78.17, 'low': 78.05, 'volume': 578, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012722499, 'volatility_last_month': 0.014286589, 'volatility_last_3months': 0.017929793, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.645615132', 'winloss_ratio_last_month': '2.092184901', 'winloss_ratio_last_3month': '2.516315553', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-29 08:00', 'high': 79.08, 'open': 78.13, 'close': 78.38, 'low': 78.13, 'volume': 979, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012671231, 'volatility_last_month': 0.014290624, 'volatility_last_3months': 0.017899354, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.657120501', 'winloss_ratio_last_month': '2.085437734', 'winloss_ratio_last_3month': '2.514702052', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-29 09:00', 'high': 78.89, 'open': 78.37, 'close': 78.66, 'low': 78.2, 'volume': 966, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012577476, 'volatility_last_month': 0.014287192, 'volatility_last_3months': 0.017886847, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.668651104', 'winloss_ratio_last_month': '2.078700925', 'winloss_ratio_last_3month': '2.513102611', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-29 10:00', 'high': 79.48, 'open': 78.66, 'close': 79.29, 'low': 78.66, 'volume': 1442, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012453687, 'volatility_last_month': 0.014300741, 'volatility_last_3months': 0.017887339, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.680207026', 'winloss_ratio_last_month': '2.071974451', 'winloss_ratio_last_3month': '2.511517048', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-29 11:00', 'high': 79.7, 'open': 79.26, 'close': 79.62, 'low': 79.25, 'volume': 1179, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.01247168, 'volatility_last_month': 0.014299361, 'volatility_last_3months': 0.017888776, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.69178835', 'winloss_ratio_last_month': '2.065258288', 'winloss_ratio_last_3month': '2.509945183', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-29 12:00', 'high': 79.85, 'open': 79.62, 'close': 79.62, 'low': 79.33, 'volume': 908, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '-', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012432907, 'volatility_last_month': 0.013853831, 'volatility_last_3months': 0.017881417, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.703395159', 'winloss_ratio_last_month': '2.058552412', 'winloss_ratio_last_3month': '2.508386839', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-29 13:00', 'high': 80.43, 'open': 79.62, 'close': 80.39, 'low': 79.42, 'volume': 1740, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '24', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012556354, 'volatility_last_month': 0.013902423, 'volatility_last_3months': 0.01783862, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.715027537', 'winloss_ratio_last_month': '2.051856799', 'winloss_ratio_last_3month': '2.506841842', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-29 14:00', 'high': 81.32, 'open': 80.39, 'close': 81.1, 'low': 80.3, 'volume': 2518, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '24', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012537805, 'volatility_last_month': 0.013945641, 'volatility_last_3months': 0.017786129, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.72668557', 'winloss_ratio_last_month': '2.045171426', 'winloss_ratio_last_3month': '2.505310022', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-29 15:00', 'high': 81.49, 'open': 81.1, 'close': 81.47, 'low': 80.92, 'volume': 1122, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '24', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012548782, 'volatility_last_month': 0.013913598, 'volatility_last_3months': 0.01778881, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.738369342', 'winloss_ratio_last_month': '2.03849627', 'winloss_ratio_last_3month': '2.50379121', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-29 16:00', 'high': 81.5, 'open': 81.47, 'close': 81.39, 'low': 80.97, 'volume': 2096, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '24', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012568441, 'volatility_last_month': 0.013897005, 'volatility_last_3months': 0.017786471, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.750078939', 'winloss_ratio_last_month': '2.031831307', 'winloss_ratio_last_3month': '2.502285243', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-30 07:00', 'high': 81.41, 'open': 81.12, 'close': 81.09, 'low': 80.81, 'volume': 915, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '24', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012573523, 'volatility_last_month': 0.013886353, 'volatility_last_3months': 0.01777691, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.761814446', 'winloss_ratio_last_month': '2.026313964', 'winloss_ratio_last_3month': '2.500791956', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-30 08:00', 'high': 81.65, 'open': 81.1, 'close': 81.32, 'low': 80.99, 'volume': 895, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '24', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012565525, 'volatility_last_month': 0.013890131, 'volatility_last_3months': 0.017774133, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.773575949', 'winloss_ratio_last_month': '2.023147506', 'winloss_ratio_last_3month': '2.499311192', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-30 09:00', 'high': 81.84, 'open': 81.39, 'close': 81.79, 'low': 81.32, 'volume': 1453, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '24', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012205047, 'volatility_last_month': 0.01388723, 'volatility_last_3months': 0.01777548, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.785363536', 'winloss_ratio_last_month': '2.021163142', 'winloss_ratio_last_3month': '2.497842792', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-30 10:00', 'high': 83.86, 'open': 81.8, 'close': 83.79, 'low': 81.76, 'volume': 3162, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '24', 'pnl_last_week': 0.03, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012026491, 'volatility_last_month': 0.014160756, 'volatility_last_3months': 0.017867643, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.797177291', 'winloss_ratio_last_month': '2.018204202', 'winloss_ratio_last_3month': '2.496386604', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-30 11:00', 'high': 84.49, 'open': 83.8, 'close': 84.2, 'low': 83.64, 'volume': 2598, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '24', 'pnl_last_week': -0.22, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012042183, 'volatility_last_month': 0.014156908, 'volatility_last_3months': 0.017870001, 'win_ratio_last_week': 0.75, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.801079536', 'winloss_ratio_last_month': '2.015625648', 'winloss_ratio_last_3month': '2.494942474', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-30 12:00', 'high': 84.6, 'open': 84.18, 'close': 84.37, 'low': 83.91, 'volume': 1479, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '24', 'pnl_last_week': -0.34, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012041664, 'volatility_last_month': 0.014156999, 'volatility_last_3months': 0.017859428, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.801176097', 'winloss_ratio_last_month': '2.013053497', 'winloss_ratio_last_3month': '2.493510255', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-30 13:00', 'high': 84.42, 'open': 84.4, 'close': 84.0, 'low': 83.91, 'volume': 2986, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '24', 'pnl_last_week': -0.38, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012133882, 'volatility_last_month': 0.014145741, 'volatility_last_3months': 0.017859869, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.8', 'winloss_ratio_last_month': '2.010487728', 'winloss_ratio_last_3month': '2.492089799', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-30 14:00', 'high': 84.25, 'open': 84.0, 'close': 84.02, 'low': 83.63, 'volume': 3175, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '24', 'pnl_last_week': -0.92, 'pnl_last_month': 1.9, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012076446, 'volatility_last_month': 0.014128612, 'volatility_last_3months': 0.017743209, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.78161835', 'winloss_ratio_last_month': '2.007928315', 'winloss_ratio_last_3month': '2.490680962', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-30 15:00', 'high': 84.54, 'open': 84.02, 'close': 82.85, 'low': 82.8, 'volume': 4092, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 2.12, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.012214494, 'volatility_last_month': 0.014252525, 'volatility_last_3months': 0.017775472, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.631578947, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.786265812', 'winloss_ratio_last_month': '2.006588993', 'winloss_ratio_last_3month': '2.489283601', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-11-30 16:00', 'high': 85.25, 'open': 82.85, 'close': 84.9, 'low': 82.85, 'volume': 5792, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '7', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 2.03, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.013344299, 'volatility_last_month': 0.01459838, 'volatility_last_3months': 0.017859168, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.611111111, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.791214383', 'winloss_ratio_last_month': '2.005003918', 'winloss_ratio_last_3month': '2.487897579', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-01 07:00', 'high': 85.75, 'open': 84.86, 'close': 85.21, 'low': 84.43, 'volume': 995, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '7', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 0.83, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.013317134, 'volatility_last_month': 0.014445614, 'volatility_last_3months': 0.017850888, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.588235294, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.796494303', 'winloss_ratio_last_month': '2.000123645', 'winloss_ratio_last_3month': '2.486522755', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-01 08:00', 'high': 86.84, 'open': 85.26, 'close': 86.73, 'low': 85.19, 'volume': 2198, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '7', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.58, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.013654687, 'volatility_last_month': 0.014568941, 'volatility_last_3months': 0.017703715, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.625, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.802140007', 'winloss_ratio_last_month': '1.999367132', 'winloss_ratio_last_3month': '2.485158997', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-01 09:00', 'high': 86.87, 'open': 86.66, 'close': 86.66, 'low': 86.0, 'volume': 2258, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '7', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.013625851, 'volatility_last_month': 0.014570138, 'volatility_last_3months': 0.017673053, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.80819087', 'winloss_ratio_last_month': '1.998773682', 'winloss_ratio_last_3month': '2.48380617', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-01 10:00', 'high': 86.66, 'open': 86.66, 'close': 86.23, 'low': 85.85, 'volume': 1461, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.01361966, 'volatility_last_month': 0.014460769, 'volatility_last_3months': 0.017648401, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.814692128', 'winloss_ratio_last_month': '1.998178431', 'winloss_ratio_last_3month': '2.482464144', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-01 11:00', 'high': 87.0, 'open': 86.26, 'close': 86.99, 'low': 85.82, 'volume': 1512, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.013687799, 'volatility_last_month': 0.01442411, 'volatility_last_3months': 0.017657274, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.821696013', 'winloss_ratio_last_month': '1.99758137', 'winloss_ratio_last_3month': '2.48113279', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-01 12:00', 'high': 88.75, 'open': 87.0, 'close': 88.68, 'low': 86.99, 'volume': 2338, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.014193093, 'volatility_last_month': 0.014602439, 'volatility_last_3months': 0.017714888, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.829263158', 'winloss_ratio_last_month': '1.99698249', 'winloss_ratio_last_3month': '2.479811981', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-01 13:00', 'high': 88.68, 'open': 88.66, 'close': 86.49, 'low': 86.42, 'volume': 2880, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015620093, 'volatility_last_month': 0.01498666, 'volatility_last_3months': 0.017818874, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.837464356', 'winloss_ratio_last_month': '1.996381784', 'winloss_ratio_last_3month': '2.478501592', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-01 14:00', 'high': 86.75, 'open': 86.55, 'close': 85.12, 'low': 84.7, 'volume': 4391, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.016208532, 'volatility_last_month': 0.015142251, 'volatility_last_3months': 0.017838199, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.846382784', 'winloss_ratio_last_month': '1.995779243', 'winloss_ratio_last_3month': '2.477201501', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-01 15:00', 'high': 85.37, 'open': 85.09, 'close': 85.15, 'low': 83.87, 'volume': 1710, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.016151102, 'volatility_last_month': 0.015141949, 'volatility_last_3months': 0.017815721, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.85611683', 'winloss_ratio_last_month': '1.995174859', 'winloss_ratio_last_3month': '2.475911586', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-01 16:00', 'high': 85.44, 'open': 85.11, 'close': 84.88, 'low': 84.65, 'volume': 2343, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.01574118, 'volatility_last_month': 0.015131729, 'volatility_last_3months': 0.017817774, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.866783743', 'winloss_ratio_last_month': '1.994568623', 'winloss_ratio_last_3month': '2.474631728', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-02 07:00', 'high': 86.27, 'open': 85.0, 'close': 85.2, 'low': 84.83, 'volume': 481, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015747961, 'volatility_last_month': 0.01513528, 'volatility_last_3months': 0.017819513, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.878524374', 'winloss_ratio_last_month': '1.993960527', 'winloss_ratio_last_3month': '2.473361812', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-02 08:00', 'high': 86.2, 'open': 85.21, 'close': 85.57, 'low': 84.45, 'volume': 1732, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.0157483, 'volatility_last_month': 0.015022724, 'volatility_last_3months': 0.01778511, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.895394007', 'winloss_ratio_last_month': '1.993350562', 'winloss_ratio_last_3month': '2.47210172', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-02 09:00', 'high': 85.94, 'open': 85.42, 'close': 85.67, 'low': 85.07, 'volume': 2719, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.0157222, 'volatility_last_month': 0.015020363, 'volatility_last_3months': 0.017782527, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.916202754', 'winloss_ratio_last_month': '1.99273872', 'winloss_ratio_last_3month': '2.470851339', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-02 10:00', 'high': 85.69, 'open': 85.69, 'close': 85.35, 'low': 84.66, 'volume': 1042, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015716663, 'volatility_last_month': 0.015000289, 'volatility_last_3months': 0.017784126, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.941030416', 'winloss_ratio_last_month': '1.992124991', 'winloss_ratio_last_3month': '2.469610558', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-02 11:00', 'high': 85.75, 'open': 85.31, 'close': 85.74, 'low': 85.17, 'volume': 1274, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015302326, 'volatility_last_month': 0.014973481, 'volatility_last_3months': 0.017785401, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.967391304', 'winloss_ratio_last_month': '1.991509368', 'winloss_ratio_last_3month': '2.468379267', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-02 12:00', 'high': 86.59, 'open': 85.73, 'close': 86.45, 'low': 85.6, 'volume': 1341, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015377943, 'volatility_last_month': 0.014978068, 'volatility_last_3months': 0.017793552, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.965353261', 'winloss_ratio_last_month': '1.990891841', 'winloss_ratio_last_3month': '2.467157356', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-02 13:00', 'high': 87.0, 'open': 86.42, 'close': 86.85, 'low': 86.4, 'volume': 1718, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015365199, 'volatility_last_month': 0.014928572, 'volatility_last_3months': 0.017781838, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.963183731', 'winloss_ratio_last_month': '1.990272401', 'winloss_ratio_last_3month': '2.46594472', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-02 14:00', 'high': 87.43, 'open': 86.87, 'close': 87.42, 'low': 86.82, 'volume': 2706, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015401407, 'volatility_last_month': 0.014919478, 'volatility_last_3months': 0.017785968, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.960869565', 'winloss_ratio_last_month': '1.98965104', 'winloss_ratio_last_3month': '2.464741253', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-02 15:00', 'high': 87.97, 'open': 87.38, 'close': 87.6, 'low': 87.1, 'volume': 1782, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015392601, 'volatility_last_month': 0.014823719, 'volatility_last_3months': 0.017758405, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.958395802', 'winloss_ratio_last_month': '1.989027748', 'winloss_ratio_last_3month': '2.463546851', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-02 16:00', 'high': 87.98, 'open': 87.56, 'close': 87.98, 'low': 87.29, 'volume': 2159, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015400396, 'volatility_last_month': 0.014831463, 'volatility_last_3months': 0.017758872, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.955745342', 'winloss_ratio_last_month': '1.988402518', 'winloss_ratio_last_3month': '2.462361413', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-05 07:00', 'high': 88.0, 'open': 87.92, 'close': 86.78, 'low': 86.5, 'volume': 1043, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015240444, 'volatility_last_month': 0.014948017, 'volatility_last_3months': 0.017791608, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.952898551', 'winloss_ratio_last_month': '1.987775338', 'winloss_ratio_last_3month': '2.461184837', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-05 08:00', 'high': 87.33, 'open': 86.82, 'close': 85.45, 'low': 85.39, 'volume': 1767, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015797968, 'volatility_last_month': 0.015011956, 'volatility_last_3months': 0.017832487, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.949832776', 'winloss_ratio_last_month': '1.987146201', 'winloss_ratio_last_3month': '2.460017025', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-05 09:00', 'high': 86.57, 'open': 85.45, 'close': 85.96, 'low': 85.4, 'volume': 1940, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015825426, 'volatility_last_month': 0.014948745, 'volatility_last_3months': 0.01783265, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.946521739', 'winloss_ratio_last_month': '1.986515098', 'winloss_ratio_last_3month': '2.45885788', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-05 10:00', 'high': 86.51, 'open': 85.87, 'close': 86.13, 'low': 85.56, 'volume': 1271, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015819743, 'volatility_last_month': 0.014932833, 'volatility_last_3months': 0.017674381, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.942934783', 'winloss_ratio_last_month': '1.985882018', 'winloss_ratio_last_3month': '2.457707305', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-05 11:00', 'high': 86.89, 'open': 86.12, 'close': 86.61, 'low': 85.85, 'volume': 3219, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015598411, 'volatility_last_month': 0.014935398, 'volatility_last_3months': 0.017677059, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.939035917', 'winloss_ratio_last_month': '1.985246953', 'winloss_ratio_last_3month': '2.456565205', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-05 12:00', 'high': 87.44, 'open': 86.5, 'close': 87.36, 'low': 86.41, 'volume': 1338, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015668166, 'volatility_last_month': 0.014972137, 'volatility_last_3months': 0.017688064, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.934782609', 'winloss_ratio_last_month': '1.984609894', 'winloss_ratio_last_3month': '2.455431487', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-05 13:00', 'high': 87.95, 'open': 87.27, 'close': 87.65, 'low': 87.2, 'volume': 1834, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015621614, 'volatility_last_month': 0.014969622, 'volatility_last_3months': 0.017629545, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.930124224', 'winloss_ratio_last_month': '1.98397083', 'winloss_ratio_last_3month': '2.45430606', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-05 14:00', 'high': 87.92, 'open': 87.65, 'close': 87.58, 'low': 87.25, 'volume': 1611, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015638411, 'volatility_last_month': 0.014961421, 'volatility_last_3months': 0.017624255, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.925', 'winloss_ratio_last_month': '1.983329753', 'winloss_ratio_last_3month': '2.453188832', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-05 15:00', 'high': 87.77, 'open': 87.59, 'close': 87.33, 'low': 86.97, 'volume': 1763, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015343127, 'volatility_last_month': 0.01494488, 'volatility_last_3months': 0.017562408, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.919336384', 'winloss_ratio_last_month': '1.982686653', 'winloss_ratio_last_3month': '2.452079714', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-05 16:00', 'high': 88.34, 'open': 87.33, 'close': 87.17, 'low': 87.05, 'volume': 2324, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015356713, 'volatility_last_month': 0.014934003, 'volatility_last_3months': 0.017534394, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.913043478', 'winloss_ratio_last_month': '1.98204152', 'winloss_ratio_last_3month': '2.450978619', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-06 07:00', 'high': 87.77, 'open': 87.13, 'close': 87.43, 'low': 86.9, 'volume': 423, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015251731, 'volatility_last_month': 0.014936296, 'volatility_last_3months': 0.017498379, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.90601023', 'winloss_ratio_last_month': '1.981394345', 'winloss_ratio_last_3month': '2.44988546', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-06 08:00', 'high': 87.5, 'open': 87.41, 'close': 86.52, 'low': 86.0, 'volume': 1554, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.01554664, 'volatility_last_month': 0.0149504, 'volatility_last_3months': 0.017510234, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.898097826', 'winloss_ratio_last_month': '1.980745118', 'winloss_ratio_last_3month': '2.44880015', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-06 09:00', 'high': 86.91, 'open': 86.58, 'close': 86.54, 'low': 86.35, 'volume': 1452, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.015547186, 'volatility_last_month': 0.014927195, 'volatility_last_3months': 0.017494766, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.889130435', 'winloss_ratio_last_month': '1.980093829', 'winloss_ratio_last_3month': '2.447722607', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-06 10:00', 'high': 88.6, 'open': 86.55, 'close': 88.35, 'low': 86.48, 'volume': 2704, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.016116586, 'volatility_last_month': 0.015130953, 'volatility_last_3months': 0.017559219, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.878881988', 'winloss_ratio_last_month': '1.979440468', 'winloss_ratio_last_3month': '2.446652746', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-06 11:00', 'high': 88.46, 'open': 88.31, 'close': 87.99, 'low': 87.4, 'volume': 2939, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.016176712, 'volatility_last_month': 0.015125339, 'volatility_last_3months': 0.017561465, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.867056856', 'winloss_ratio_last_month': '1.978785026', 'winloss_ratio_last_3month': '2.445590487', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-06 12:00', 'high': 88.03, 'open': 87.95, 'close': 87.82, 'low': 87.14, 'volume': 2531, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.016196991, 'volatility_last_month': 0.015123485, 'volatility_last_3months': 0.017524508, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.85326087', 'winloss_ratio_last_month': '1.978127493', 'winloss_ratio_last_3month': '2.444535747', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-06 13:00', 'high': 88.97, 'open': 87.84, 'close': 88.58, 'low': 87.84, 'volume': 2510, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.016171277, 'volatility_last_month': 0.01515814, 'volatility_last_3months': 0.017476861, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.836956522', 'winloss_ratio_last_month': '1.977467858', 'winloss_ratio_last_3month': '2.443488448', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-06 14:00', 'high': 89.49, 'open': 88.59, 'close': 89.0, 'low': 88.55, 'volume': 1629, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.016100762, 'volatility_last_month': 0.015163111, 'volatility_last_3months': 0.017442964, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.817391304', 'winloss_ratio_last_month': '1.976806111', 'winloss_ratio_last_3month': '2.442448512', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-06 15:00', 'high': 89.39, 'open': 88.99, 'close': 88.8, 'low': 88.57, 'volume': 1792, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.016116326, 'volatility_last_month': 0.015146409, 'volatility_last_3months': 0.017421373, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.793478261', 'winloss_ratio_last_month': '1.975652757', 'winloss_ratio_last_3month': '2.44141586', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-06 16:00', 'high': 88.71, 'open': 88.61, 'close': 88.61, 'low': 87.5, 'volume': 2231, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.016129887, 'volatility_last_month': 0.015150983, 'volatility_last_3months': 0.017401094, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.763586957', 'winloss_ratio_last_month': '1.974323437', 'winloss_ratio_last_3month': '2.440390417', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-07 07:00', 'high': 88.67, 'open': 88.25, 'close': 87.87, 'low': 87.46, 'volume': 635, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.61, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.016258305, 'volatility_last_month': 0.01516587, 'volatility_last_3months': 0.017399655, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.72515528', 'winloss_ratio_last_month': '1.973019954', 'winloss_ratio_last_3month': '2.439372107', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-07 08:00', 'high': 88.49, 'open': 87.91, 'close': 87.98, 'low': 87.85, 'volume': 1447, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 2.29, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.016255775, 'volatility_last_month': 0.015127368, 'volatility_last_3months': 0.017399623, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.714285714, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.673913043', 'winloss_ratio_last_month': '1.977049901', 'winloss_ratio_last_3month': '2.438360856', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-07 09:00', 'high': 88.44, 'open': 87.98, 'close': 88.38, 'low': 87.6, 'volume': 1933, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 1.55, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.016240173, 'volatility_last_month': 0.015072868, 'volatility_last_3months': 0.017380367, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.692307692, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.602173913', 'winloss_ratio_last_month': '1.977214967', 'winloss_ratio_last_3month': '2.437356591', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-07 10:00', 'high': 88.44, 'open': 88.39, 'close': 86.17, 'low': 86.1, 'volume': 5544, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 0.12, 'pnl_last_3months': 9.64, 'volatility_last_week': 0.01652505, 'volatility_last_month': 0.015238046, 'volatility_last_3months': 0.017449479, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.596153846, 'winloss_ratio_last_week': '0.494565217', 'winloss_ratio_last_month': '1.973169187', 'winloss_ratio_last_3month': '2.43635924', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-07 11:00', 'high': 86.77, 'open': 86.19, 'close': 86.66, 'low': 86.16, 'volume': 1559, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': 0.24, 'pnl_last_3months': 9.56, 'volatility_last_week': 0.016537994, 'volatility_last_month': 0.015251442, 'volatility_last_3months': 0.017454055, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.727272727, 'win_ratio_last_3month': 0.588235294, 'winloss_ratio_last_week': '0.405797101', 'winloss_ratio_last_month': '1.96980168', 'winloss_ratio_last_3month': '2.435336061', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-07 12:00', 'high': 87.45, 'open': 86.66, 'close': 87.2, 'low': 86.66, 'volume': 1118, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 9.54, 'volatility_last_week': 0.016589001, 'volatility_last_month': 0.015163936, 'volatility_last_3months': 0.017459717, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.58, 'winloss_ratio_last_week': '0.293478261', 'winloss_ratio_last_month': '1.963631517', 'winloss_ratio_last_3month': '2.434311738', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-07 13:00', 'high': 88.0, 'open': 87.2, 'close': 87.7, 'low': 86.9, 'volume': 2064, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.83, 'volatility_last_week': 0.016585499, 'volatility_last_month': 0.015174199, 'volatility_last_3months': 0.017370716, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.571428571, 'winloss_ratio_last_week': '0', 'winloss_ratio_last_month': '1.957426904', 'winloss_ratio_last_3month': '2.432600813', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-07 14:00', 'high': 88.0, 'open': 87.71, 'close': 87.88, 'low': 87.17, 'volume': 1937, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.41, 'volatility_last_week': 0.01658716, 'volatility_last_month': 0.015163619, 'volatility_last_3months': 0.017366165, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.5625, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.951187551', 'winloss_ratio_last_3month': '2.430731731', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-07 15:00', 'high': 88.55, 'open': 87.82, 'close': 88.35, 'low': 87.71, 'volume': 2603, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.016217609, 'volatility_last_month': 0.015159282, 'volatility_last_3months': 0.017370228, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.944913166', 'winloss_ratio_last_3month': '2.428904654', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-07 16:00', 'high': 88.43, 'open': 88.35, 'close': 88.19, 'low': 87.88, 'volume': 1837, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.015227677, 'volatility_last_month': 0.015151884, 'volatility_last_3months': 0.017370769, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.938603453', 'winloss_ratio_last_3month': '2.427089858', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-08 07:00', 'high': 88.84, 'open': 88.15, 'close': 88.34, 'low': 87.92, 'volume': 442, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.015213461, 'volatility_last_month': 0.015151471, 'volatility_last_3months': 0.017347933, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.932258113', 'winloss_ratio_last_3month': '2.425287218', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-08 08:00', 'high': 89.0, 'open': 88.42, 'close': 88.63, 'low': 87.86, 'volume': 2275, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.014663539, 'volatility_last_month': 0.014794865, 'volatility_last_3months': 0.017349159, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.925876843', 'winloss_ratio_last_3month': '2.423496614', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-08 09:00', 'high': 88.97, 'open': 88.65, 'close': 87.77, 'low': 87.6, 'volume': 1578, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.014859763, 'volatility_last_month': 0.014829354, 'volatility_last_3months': 0.017364345, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.919459337', 'winloss_ratio_last_3month': '2.421717925', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-08 10:00', 'high': 89.45, 'open': 87.8, 'close': 89.36, 'low': 87.77, 'volume': 2166, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.015384255, 'volatility_last_month': 0.014921526, 'volatility_last_3months': 0.017386829, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.913005286', 'winloss_ratio_last_3month': '2.419951032', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-08 11:00', 'high': 89.54, 'open': 89.38, 'close': 89.1, 'low': 89.02, 'volume': 982, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.015281751, 'volatility_last_month': 0.014904261, 'volatility_last_3months': 0.017388583, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.906514376', 'winloss_ratio_last_3month': '2.418195818', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-08 12:00', 'high': 89.41, 'open': 89.16, 'close': 89.4, 'low': 89.02, 'volume': 1488, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.014609728, 'volatility_last_month': 0.014875539, 'volatility_last_3months': 0.017386877, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.899986291', 'winloss_ratio_last_3month': '2.416452169', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-08 13:00', 'high': 89.54, 'open': 89.39, 'close': 88.6, 'low': 88.07, 'volume': 1668, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.013471754, 'volatility_last_month': 0.01492817, 'volatility_last_3months': 0.017400704, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.893420711', 'winloss_ratio_last_3month': '2.41471997', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-08 14:00', 'high': 89.1, 'open': 88.63, 'close': 89.01, 'low': 88.55, 'volume': 1764, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012899625, 'volatility_last_month': 0.014920268, 'volatility_last_3months': 0.017367844, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.886817311', 'winloss_ratio_last_3month': '2.412999109', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-08 15:00', 'high': 89.06, 'open': 89.0, 'close': 88.59, 'low': 88.22, 'volume': 2431, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012968239, 'volatility_last_month': 0.014919912, 'volatility_last_3months': 0.017371562, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.880175765', 'winloss_ratio_last_3month': '2.411289474', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-08 16:00', 'high': 89.1, 'open': 88.59, 'close': 88.93, 'low': 88.35, 'volume': 2204, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012951584, 'volatility_last_month': 0.014923605, 'volatility_last_3months': 0.01736953, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.87349574', 'winloss_ratio_last_3month': '2.409590957', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-09 07:00', 'high': 88.79, 'open': 88.65, 'close': 88.6, 'low': 87.78, 'volume': 433, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012979327, 'volatility_last_month': 0.014930597, 'volatility_last_3months': 0.017371059, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.8667769', 'winloss_ratio_last_3month': '2.407903449', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-09 08:00', 'high': 88.93, 'open': 88.55, 'close': 88.44, 'low': 88.25, 'volume': 1169, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012964556, 'volatility_last_month': 0.014934803, 'volatility_last_3months': 0.01737179, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.860018908', 'winloss_ratio_last_3month': '2.406226843', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-09 09:00', 'high': 89.19, 'open': 88.42, 'close': 88.63, 'low': 88.35, 'volume': 1785, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012968846, 'volatility_last_month': 0.014927131, 'volatility_last_3months': 0.017367536, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.853221419', 'winloss_ratio_last_3month': '2.404561035', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-09 10:00', 'high': 88.78, 'open': 88.63, 'close': 88.66, 'low': 88.35, 'volume': 1994, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012924874, 'volatility_last_month': 0.014924997, 'volatility_last_3months': 0.017321115, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.846384087', 'winloss_ratio_last_3month': '2.40290592', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-09 11:00', 'high': 88.9, 'open': 88.65, 'close': 88.52, 'low': 88.4, 'volume': 806, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012903247, 'volatility_last_month': 0.014774966, 'volatility_last_3months': 0.017319326, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.839506559', 'winloss_ratio_last_3month': '2.401261396', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-09 12:00', 'high': 88.51, 'open': 88.51, 'close': 86.89, 'low': 86.7, 'volume': 3036, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.013550502, 'volatility_last_month': 0.014992047, 'volatility_last_3months': 0.017376247, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.832588481', 'winloss_ratio_last_3month': '2.39962736', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-09 13:00', 'high': 87.9, 'open': 86.96, 'close': 87.69, 'low': 86.87, 'volume': 1609, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.013680269, 'volatility_last_month': 0.015028875, 'volatility_last_3months': 0.017388937, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.825629492', 'winloss_ratio_last_3month': '2.398003715', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-09 14:00', 'high': 88.5, 'open': 87.77, 'close': 88.16, 'low': 87.42, 'volume': 1554, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.013651215, 'volatility_last_month': 0.014834722, 'volatility_last_3months': 0.017392748, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.818629229', 'winloss_ratio_last_3month': '2.396390359', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-09 15:00', 'high': 88.44, 'open': 88.17, 'close': 87.65, 'low': 87.3, 'volume': 976, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.013716132, 'volatility_last_month': 0.014851429, 'volatility_last_3months': 0.017389297, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.811587324', 'winloss_ratio_last_3month': '2.394787197', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-09 16:00', 'high': 88.2, 'open': 87.69, 'close': 87.73, 'low': 87.35, 'volume': 1803, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.01367816, 'volatility_last_month': 0.014840076, 'volatility_last_3months': 0.017387842, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.804503404', 'winloss_ratio_last_3month': '2.393194131', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-12 07:00', 'high': 88.33, 'open': 87.5, 'close': 87.7, 'low': 87.36, 'volume': 499, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.013270885, 'volatility_last_month': 0.014790771, 'volatility_last_3months': 0.017379733, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.797377091', 'winloss_ratio_last_3month': '2.391611067', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-12 08:00', 'high': 88.59, 'open': 87.76, 'close': 88.5, 'low': 87.61, 'volume': 1933, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012877779, 'volatility_last_month': 0.014828699, 'volatility_last_3months': 0.017338084, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.790208004', 'winloss_ratio_last_3month': '2.39003791', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-12 09:00', 'high': 88.78, 'open': 88.43, 'close': 88.26, 'low': 87.96, 'volume': 2683, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.01283885, 'volatility_last_month': 0.014835326, 'volatility_last_3months': 0.017332032, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.782995757', 'winloss_ratio_last_3month': '2.388474569', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-12 10:00', 'high': 89.3, 'open': 88.25, 'close': 89.24, 'low': 88.18, 'volume': 1896, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.01307625, 'volatility_last_month': 0.014893733, 'volatility_last_3months': 0.017315381, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.775739959', 'winloss_ratio_last_3month': '2.386920951', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-12 11:00', 'high': 89.5, 'open': 89.26, 'close': 89.08, 'low': 88.86, 'volume': 1084, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.01303585, 'volatility_last_month': 0.014893887, 'volatility_last_3months': 0.01731594, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.768440213', 'winloss_ratio_last_3month': '2.385376965', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-12 12:00', 'high': 89.1, 'open': 89.03, 'close': 88.96, 'low': 88.9, 'volume': 964, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012895245, 'volatility_last_month': 0.014891053, 'volatility_last_3months': 0.017314253, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.761096121', 'winloss_ratio_last_3month': '2.383842524', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-12 13:00', 'high': 89.75, 'open': 88.96, 'close': 89.53, 'low': 88.96, 'volume': 1783, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012956273, 'volatility_last_month': 0.014899708, 'volatility_last_3months': 0.017315242, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.753707275', 'winloss_ratio_last_3month': '2.382317538', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-12 14:00', 'high': 90.42, 'open': 89.5, 'close': 90.3, 'low': 89.39, 'volume': 2266, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.013095559, 'volatility_last_month': 0.014933113, 'volatility_last_3months': 0.017326179, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.746273267', 'winloss_ratio_last_3month': '2.38080192', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-12 15:00', 'high': 90.42, 'open': 90.31, 'close': 90.0, 'low': 89.75, 'volume': 1778, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.013103192, 'volatility_last_month': 0.014940669, 'volatility_last_3months': 0.017327282, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.73879368', 'winloss_ratio_last_3month': '2.379295585', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-12 16:00', 'high': 90.28, 'open': 90.01, 'close': 89.81, 'low': 89.72, 'volume': 2609, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.013106354, 'volatility_last_month': 0.014944913, 'volatility_last_3months': 0.017323785, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.731268094', 'winloss_ratio_last_3month': '2.377798446', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-13 07:00', 'high': 90.15, 'open': 90.15, 'close': 89.38, 'low': 89.18, 'volume': 1111, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.013155126, 'volatility_last_month': 0.014939127, 'volatility_last_3months': 0.017322607, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.723696084', 'winloss_ratio_last_3month': '2.376310421', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-13 08:00', 'high': 89.47, 'open': 89.43, 'close': 88.9, 'low': 88.78, 'volume': 1938, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012966655, 'volatility_last_month': 0.014959159, 'volatility_last_3months': 0.017316533, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.716077218', 'winloss_ratio_last_3month': '2.374831426', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-13 09:00', 'high': 89.53, 'open': 88.9, 'close': 89.45, 'low': 88.78, 'volume': 1708, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.013034969, 'volatility_last_month': 0.014916868, 'volatility_last_3months': 0.017310378, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.708411061', 'winloss_ratio_last_3month': '2.37336138', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-13 10:00', 'high': 89.6, 'open': 89.46, 'close': 88.63, 'low': 87.85, 'volume': 3151, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012303027, 'volatility_last_month': 0.014937115, 'volatility_last_3months': 0.017269291, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.70069717', 'winloss_ratio_last_3month': '2.371900201', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-13 11:00', 'high': 89.25, 'open': 88.63, 'close': 88.94, 'low': 88.63, 'volume': 2124, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.0122876, 'volatility_last_month': 0.014868417, 'volatility_last_3months': 0.017270068, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.692935099', 'winloss_ratio_last_3month': '2.370447809', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-13 12:00', 'high': 89.09, 'open': 88.86, 'close': 88.35, 'low': 88.25, 'volume': 980, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012385979, 'volatility_last_month': 0.014901252, 'volatility_last_3months': 0.017278223, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.685124394', 'winloss_ratio_last_3month': '2.369004126', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-13 13:00', 'high': 88.45, 'open': 88.39, 'close': 88.22, 'low': 88.06, 'volume': 1934, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.01221815, 'volatility_last_month': 0.014889465, 'volatility_last_3months': 0.017270851, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.677264597', 'winloss_ratio_last_3month': '2.367569073', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-13 14:00', 'high': 88.38, 'open': 88.26, 'close': 87.72, 'low': 87.56, 'volume': 2411, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012232436, 'volatility_last_month': 0.014907511, 'volatility_last_3months': 0.017251085, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.669355244', 'winloss_ratio_last_3month': '2.366142573', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-13 15:00', 'high': 88.6, 'open': 87.7, 'close': 88.45, 'low': 87.16, 'volume': 3652, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012390354, 'volatility_last_month': 0.014678849, 'volatility_last_3months': 0.017260066, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.661395865', 'winloss_ratio_last_3month': '2.36472455', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-13 16:00', 'high': 89.13, 'open': 88.5, 'close': 88.99, 'low': 88.32, 'volume': 2212, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012465446, 'volatility_last_month': 0.014684718, 'volatility_last_3months': 0.017265525, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.653385984', 'winloss_ratio_last_3month': '2.363314929', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-14 07:00', 'high': 89.27, 'open': 88.44, 'close': 89.16, 'low': 88.0, 'volume': 348, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012301324, 'volatility_last_month': 0.014667374, 'volatility_last_3months': 0.017265331, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.645325117', 'winloss_ratio_last_3month': '2.361913634', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-14 08:00', 'high': 89.43, 'open': 89.22, 'close': 88.89, 'low': 88.82, 'volume': 2076, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012324161, 'volatility_last_month': 0.014672303, 'volatility_last_3months': 0.017258053, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.637212778', 'winloss_ratio_last_3month': '2.360520594', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-14 09:00', 'high': 88.92, 'open': 88.88, 'close': 88.32, 'low': 88.12, 'volume': 1871, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012377597, 'volatility_last_month': 0.014470881, 'volatility_last_3months': 0.017264554, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.629048471', 'winloss_ratio_last_3month': '2.359135734', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-14 10:00', 'high': 88.35, 'open': 88.28, 'close': 86.07, 'low': 86.01, 'volume': 3417, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012434772, 'volatility_last_month': 0.01474816, 'volatility_last_3months': 0.017341304, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.620831695', 'winloss_ratio_last_3month': '2.357758983', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-14 11:00', 'high': 86.6, 'open': 86.04, 'close': 85.03, 'low': 84.99, 'volume': 2958, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012683466, 'volatility_last_month': 0.014845465, 'volatility_last_3months': 0.017365203, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.612561944', 'winloss_ratio_last_3month': '2.356390269', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-14 12:00', 'high': 85.57, 'open': 85.02, 'close': 85.29, 'low': 84.76, 'volume': 3776, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012610903, 'volatility_last_month': 0.014812304, 'volatility_last_3months': 0.017366432, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.604238702', 'winloss_ratio_last_3month': '2.355029524', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-14 13:00', 'high': 86.36, 'open': 85.3, 'close': 85.7, 'low': 85.25, 'volume': 3475, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.01258684, 'volatility_last_month': 0.014821909, 'volatility_last_3months': 0.017343652, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.59586145', 'winloss_ratio_last_3month': '2.353676677', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-14 14:00', 'high': 86.2, 'open': 85.66, 'close': 86.07, 'low': 85.07, 'volume': 1835, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.44, 'volatility_last_week': 0.012624166, 'volatility_last_month': 0.014821525, 'volatility_last_3months': 0.017341905, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.574468085, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.58742966', 'winloss_ratio_last_3month': '2.35233166', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-14 15:00', 'high': 86.84, 'open': 86.01, 'close': 86.64, 'low': 85.92, 'volume': 1619, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.25, 'volatility_last_week': 0.012660934, 'volatility_last_month': 0.014840935, 'volatility_last_3months': 0.017346806, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.565217391, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.578942797', 'winloss_ratio_last_3month': '2.350928904', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-14 16:00', 'high': 86.9, 'open': 86.67, 'close': 86.19, 'low': 86.17, 'volume': 2813, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '24', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 7.56, 'volatility_last_week': 0.012708494, 'volatility_last_month': 0.014860031, 'volatility_last_3months': 0.017349179, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.577777778, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.570400321', 'winloss_ratio_last_3month': '2.349784618', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-15 07:00', 'high': 86.85, 'open': 86.17, 'close': 86.33, 'low': 85.87, 'volume': 973, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '25', 'pnl_last_week': 0.0, 'pnl_last_month': -0.7, 'pnl_last_3months': 6.84, 'volatility_last_week': 0.012707759, 'volatility_last_month': 0.014849946, 'volatility_last_3months': 0.017330299, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.568181818, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.561801682', 'winloss_ratio_last_3month': '2.348399815', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-15 08:00', 'high': 86.75, 'open': 86.25, 'close': 86.68, 'low': 85.59, 'volume': 1900, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '25', 'pnl_last_week': -0.35, 'pnl_last_month': -1.05, 'pnl_last_3months': 7.23, 'volatility_last_week': 0.012722085, 'volatility_last_month': 0.014838023, 'volatility_last_3months': 0.017314932, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.636363636, 'win_ratio_last_3month': 0.568181818, 'winloss_ratio_last_week': '0', 'winloss_ratio_last_month': '1.552193275', 'winloss_ratio_last_3month': '2.347335631', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-15 09:00', 'high': 87.15, 'open': 86.67, 'close': 86.37, 'low': 86.03, 'volume': 1838, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '25', 'pnl_last_week': -0.04, 'pnl_last_month': -0.74, 'pnl_last_3months': 7.99, 'volatility_last_week': 0.012545391, 'volatility_last_month': 0.014839069, 'volatility_last_3months': 0.017311369, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.590909091, 'winloss_ratio_last_week': '0.442857143', 'winloss_ratio_last_month': '1.54307814', 'winloss_ratio_last_3month': '2.34674289', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-15 10:00', 'high': 86.69, 'open': 86.37, 'close': 86.52, 'low': 86.0, 'volume': 1807, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '25', 'pnl_last_week': -0.19, 'pnl_last_month': -0.89, 'pnl_last_3months': 7.84, 'volatility_last_week': 0.011751192, 'volatility_last_month': 0.014753779, 'volatility_last_3months': 0.01730967, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.615384615, 'win_ratio_last_3month': 0.577777778, 'winloss_ratio_last_week': '0.516666667', 'winloss_ratio_last_month': '1.533508379', 'winloss_ratio_last_3month': '2.346033323', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-15 11:00', 'high': 87.75, 'open': 86.52, 'close': 87.06, 'low': 86.48, 'volume': 1834, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '25', 'pnl_last_week': -0.73, 'pnl_last_month': -1.43, 'pnl_last_3months': 7.3, 'volatility_last_week': 0.011849683, 'volatility_last_month': 0.014755308, 'volatility_last_3months': 0.017310915, 'win_ratio_last_week': 0.25, 'win_ratio_last_month': 0.571428571, 'win_ratio_last_3month': 0.565217391, 'winloss_ratio_last_week': '0.415178571', 'winloss_ratio_last_month': '1.522439687', 'winloss_ratio_last_3month': '2.344897558', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-15 12:00', 'high': 87.37, 'open': 87.06, 'close': 87.19, 'low': 86.45, 'volume': 3353, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '25', 'pnl_last_week': -0.86, 'pnl_last_month': -1.56, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.011823225, 'volatility_last_month': 0.014751303, 'volatility_last_3months': 0.017307992, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.363636364', 'winloss_ratio_last_month': '1.510990953', 'winloss_ratio_last_3month': '2.343664765', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-15 13:00', 'high': 87.44, 'open': 87.14, 'close': 86.87, 'low': 86.8, 'volume': 1577, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -1.56, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.011669927, 'volatility_last_month': 0.014759775, 'volatility_last_3months': 0.017308997, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.338427948', 'winloss_ratio_last_month': '1.499690381', 'winloss_ratio_last_3month': '2.342438662', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-15 14:00', 'high': 86.95, 'open': 86.89, 'close': 85.72, 'low': 85.6, 'volume': 1796, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -1.56, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.012000122, 'volatility_last_month': 0.014874641, 'volatility_last_3months': 0.017339986, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.323478261', 'winloss_ratio_last_month': '1.487886945', 'winloss_ratio_last_3month': '2.341219195', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-15 15:00', 'high': 86.43, 'open': 85.74, 'close': 85.84, 'low': 85.61, 'volume': 1593, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -1.56, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.011971452, 'volatility_last_month': 0.014870448, 'volatility_last_3months': 0.017337227, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.313583815', 'winloss_ratio_last_month': '1.475721273', 'winloss_ratio_last_3month': '2.34000631', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-15 16:00', 'high': 85.97, 'open': 85.79, 'close': 85.2, 'low': 85.0, 'volume': 2982, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -1.56, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.012030249, 'volatility_last_month': 0.014835755, 'volatility_last_3months': 0.017328318, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.306551298', 'winloss_ratio_last_month': '1.464212244', 'winloss_ratio_last_3month': '2.338799954', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-16 07:00', 'high': 86.22, 'open': 85.24, 'close': 85.88, 'low': 84.97, 'volume': 236, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '5', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -1.56, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.012188758, 'volatility_last_month': 0.014802578, 'volatility_last_3months': 0.017336701, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.301295896', 'winloss_ratio_last_month': '1.452403343', 'winloss_ratio_last_3month': '2.337600075', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-16 08:00', 'high': 85.84, 'open': 85.84, 'close': 85.2, 'low': 85.1, 'volume': 2256, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '5', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -1.7, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.012309873, 'volatility_last_month': 0.014843192, 'volatility_last_3months': 0.017348099, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.297219559', 'winloss_ratio_last_month': '1.440085212', 'winloss_ratio_last_3month': '2.336406619', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-16 09:00', 'high': 85.23, 'open': 85.19, 'close': 84.16, 'low': 83.62, 'volume': 3951, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '5', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -1.77, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.012588709, 'volatility_last_month': 0.01494083, 'volatility_last_3months': 0.017319116, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.461538462, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.293965517', 'winloss_ratio_last_month': '1.427505728', 'winloss_ratio_last_3month': '2.335219537', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-16 10:00', 'high': 84.4, 'open': 84.17, 'close': 83.75, 'low': 83.33, 'volume': 3012, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '5', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -1.82, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.012617, 'volatility_last_month': 0.014957974, 'volatility_last_3months': 0.017252262, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.416666667, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.291307753', 'winloss_ratio_last_month': '1.41473557', 'winloss_ratio_last_3month': '2.334038776', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-16 11:00', 'high': 84.75, 'open': 83.81, 'close': 84.29, 'low': 83.63, 'volume': 705, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '5', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -1.77, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.012743679, 'volatility_last_month': 0.014976808, 'volatility_last_3months': 0.017257613, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.454545455, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.289096126', 'winloss_ratio_last_month': '1.402201554', 'winloss_ratio_last_3month': '2.332864287', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-16 12:00', 'high': 85.55, 'open': 84.42, 'close': 85.51, 'low': 84.42, 'volume': 1038, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '5', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.01252462, 'volatility_last_month': 0.014999278, 'volatility_last_3months': 0.017279013, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.287227002', 'winloss_ratio_last_month': '1.394314895', 'winloss_ratio_last_3month': '2.33169602', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-16 13:00', 'high': 86.33, 'open': 85.5, 'close': 86.01, 'low': 85.49, 'volume': 1317, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.012403631, 'volatility_last_month': 0.015010743, 'volatility_last_3months': 0.017280026, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.285626536', 'winloss_ratio_last_month': '1.386364044', 'winloss_ratio_last_3month': '2.330533925', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-16 14:00', 'high': 86.33, 'open': 86.04, 'close': 85.95, 'low': 85.95, 'volume': 478, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.012325617, 'volatility_last_month': 0.014970505, 'volatility_last_3months': 0.017263144, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.284240688', 'winloss_ratio_last_month': '1.378348214', 'winloss_ratio_last_3month': '2.329377953', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-16 15:00', 'high': 85.97, 'open': 85.95, 'close': 84.35, 'low': 84.28, 'volume': 630, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.013014083, 'volatility_last_month': 0.015186286, 'volatility_last_3months': 0.01725314, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.283029001', 'winloss_ratio_last_month': '1.370266606', 'winloss_ratio_last_3month': '2.328228057', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-16 16:00', 'high': 84.29, 'open': 84.29, 'close': 83.68, 'low': 83.07, 'volume': 1073, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.013118874, 'volatility_last_month': 0.015191313, 'volatility_last_3months': 0.017251265, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.281960586', 'winloss_ratio_last_month': '1.362118407', 'winloss_ratio_last_3month': '2.327084189', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-19 07:00', 'high': 85.77, 'open': 84.62, 'close': 85.66, 'low': 84.34, 'volume': 44, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.014336494, 'volatility_last_month': 0.015349067, 'volatility_last_3months': 0.017338378, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.28101145', 'winloss_ratio_last_month': '1.350579937', 'winloss_ratio_last_3month': '2.325946301', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-19 08:00', 'high': 85.64, 'open': 85.5, 'close': 85.54, 'low': 85.33, 'volume': 691, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.014149333, 'volatility_last_month': 0.015350762, 'volatility_last_3months': 0.017322782, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.280162675', 'winloss_ratio_last_month': '1.339075646', 'winloss_ratio_last_3month': '2.324814346', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-19 09:00', 'high': 85.83, 'open': 85.42, 'close': 85.25, 'low': 85.0, 'volume': 369, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.014155893, 'volatility_last_month': 0.015323397, 'volatility_last_3months': 0.01732047, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.279399142', 'winloss_ratio_last_month': '1.327532742', 'winloss_ratio_last_3month': '2.323688279', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-19 10:00', 'high': 85.35, 'open': 85.35, 'close': 84.84, 'low': 84.16, 'volume': 365, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.013897717, 'volatility_last_month': 0.015278009, 'volatility_last_3months': 0.017280792, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.278708623', 'winloss_ratio_last_month': '1.315951032', 'winloss_ratio_last_3month': '2.322568053', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-19 11:00', 'high': 85.03, 'open': 84.8, 'close': 84.7, 'low': 84.65, 'volume': 217, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.013897294, 'volatility_last_month': 0.015273127, 'volatility_last_3months': 0.017259973, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.278081123', 'winloss_ratio_last_month': '1.30433032', 'winloss_ratio_last_3month': '2.321453623', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-19 12:00', 'high': 84.77, 'open': 84.58, 'close': 84.75, 'low': 84.58, 'volume': 64, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.013902194, 'volatility_last_month': 0.015270233, 'volatility_last_3months': 0.017259908, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.277508392', 'winloss_ratio_last_month': '1.292670407', 'winloss_ratio_last_3month': '2.320344945', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-19 13:00', 'high': 85.1, 'open': 84.76, 'close': 85.01, 'low': 84.63, 'volume': 600, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.013823089, 'volatility_last_month': 0.015256218, 'volatility_last_3months': 0.01725989, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.27698356', 'winloss_ratio_last_month': '1.280971095', 'winloss_ratio_last_3month': '2.319241973', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-19 14:00', 'high': 85.06, 'open': 85.06, 'close': 84.15, 'low': 84.15, 'volume': 400, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.013790168, 'volatility_last_month': 0.015322204, 'volatility_last_3months': 0.017272866, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.276500858', 'winloss_ratio_last_month': '1.269232184', 'winloss_ratio_last_3month': '2.318144664', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-19 15:00', 'high': 84.15, 'open': 84.15, 'close': 83.8, 'low': 83.04, 'volume': 519, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.013798337, 'volatility_last_month': 0.015313175, 'volatility_last_3months': 0.016344535, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.276055409', 'winloss_ratio_last_month': '1.260420122', 'winloss_ratio_last_3month': '2.317052975', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-19 16:00', 'high': 84.5, 'open': 83.78, 'close': 83.98, 'low': 83.75, 'volume': 812, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.013823225, 'volatility_last_month': 0.01530622, 'volatility_last_3months': 0.016311605, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.275643061', 'winloss_ratio_last_month': '1.251928827', 'winloss_ratio_last_3month': '2.315966862', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-20 07:00', 'high': 87.6, 'open': 87.49, 'close': 86.33, 'low': 85.86, 'volume': 407, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015408704, 'volatility_last_month': 0.015711391, 'volatility_last_3months': 0.016429087, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.275260257', 'winloss_ratio_last_month': '1.243427135', 'winloss_ratio_last_3month': '2.314886282', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-20 08:00', 'high': 88.51, 'open': 86.4, 'close': 88.44, 'low': 86.4, 'volume': 1014, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016453008, 'volatility_last_month': 0.01599731, 'volatility_last_3months': 0.016513134, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.274903931', 'winloss_ratio_last_month': '1.234393513', 'winloss_ratio_last_3month': '2.313811194', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-20 09:00', 'high': 89.49, 'open': 88.42, 'close': 89.49, 'low': 88.16, 'volume': 1207, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016630238, 'volatility_last_month': 0.016038453, 'volatility_last_3months': 0.016516921, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.274571429', 'winloss_ratio_last_month': '1.229692656', 'winloss_ratio_last_3month': '2.312741556', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-20 10:00', 'high': 89.65, 'open': 89.49, 'close': 89.27, 'low': 89.19, 'volume': 808, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016491486, 'volatility_last_month': 0.01598744, 'volatility_last_3months': 0.016508882, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.274260437', 'winloss_ratio_last_month': '1.224979583', 'winloss_ratio_last_3month': '2.311677326', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-20 11:00', 'high': 89.3, 'open': 89.27, 'close': 88.75, 'low': 88.35, 'volume': 677, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016531399, 'volatility_last_month': 0.016011561, 'volatility_last_3months': 0.01651063, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.273968934', 'winloss_ratio_last_month': '1.220254247', 'winloss_ratio_last_3month': '2.310618464', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-20 12:00', 'high': 89.29, 'open': 88.78, 'close': 89.23, 'low': 88.6, 'volume': 642, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016501619, 'volatility_last_month': 0.01601449, 'volatility_last_3months': 0.016510238, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.273695144', 'winloss_ratio_last_month': '1.2155166', 'winloss_ratio_last_3month': '2.309564929', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-20 13:00', 'high': 89.49, 'open': 89.24, 'close': 89.3, 'low': 88.92, 'volume': 856, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016497168, 'volatility_last_month': 0.016009794, 'volatility_last_3months': 0.016510192, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.2734375', 'winloss_ratio_last_month': '1.210766593', 'winloss_ratio_last_3month': '2.308516681', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-20 14:00', 'high': 89.79, 'open': 89.31, 'close': 89.69, 'low': 89.1, 'volume': 855, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016461833, 'volatility_last_month': 0.015991165, 'volatility_last_3months': 0.016512216, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.273194614', 'winloss_ratio_last_month': '1.206004179', 'winloss_ratio_last_3month': '2.30747368', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-20 15:00', 'high': 90.68, 'open': 89.7, 'close': 90.23, 'low': 89.66, 'volume': 1539, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016407991, 'volatility_last_month': 0.016004681, 'volatility_last_3months': 0.016516307, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.272965255', 'winloss_ratio_last_month': '1.201229309', 'winloss_ratio_last_3month': '2.306435886', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-20 16:00', 'high': 90.49, 'open': 90.29, 'close': 89.3, 'low': 89.3, 'volume': 1714, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016544318, 'volatility_last_month': 0.016074132, 'volatility_last_3months': 0.016529174, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.272748321', 'winloss_ratio_last_month': '1.196441934', 'winloss_ratio_last_3month': '2.305403262', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-21 07:00', 'high': 90.02, 'open': 89.3, 'close': 88.05, 'low': 87.91, 'volume': 737, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016875539, 'volatility_last_month': 0.016174216, 'volatility_last_3months': 0.016545882, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.272542831', 'winloss_ratio_last_month': '1.191642004', 'winloss_ratio_last_3month': '2.304375769', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-21 08:00', 'high': 88.33, 'open': 88.13, 'close': 88.09, 'low': 87.22, 'volume': 1031, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016862755, 'volatility_last_month': 0.016169271, 'volatility_last_3months': 0.016504551, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.272347902', 'winloss_ratio_last_month': '1.18682947', 'winloss_ratio_last_3month': '2.303353367', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-21 09:00', 'high': 88.16, 'open': 88.16, 'close': 87.16, 'low': 86.63, 'volume': 693, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016980872, 'volatility_last_month': 0.016162196, 'volatility_last_3months': 0.01649277, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.272162741', 'winloss_ratio_last_month': '1.182004282', 'winloss_ratio_last_3month': '2.30233602', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-21 10:00', 'high': 87.51, 'open': 87.11, 'close': 87.35, 'low': 86.99, 'volume': 561, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '3', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.83, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015818209, 'volatility_last_month': 0.015822857, 'volatility_last_3months': 0.016492345, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.27198663', 'winloss_ratio_last_month': '1.177166391', 'winloss_ratio_last_3month': '2.301323691', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-21 11:00', 'high': 87.94, 'open': 87.38, 'close': 87.73, 'low': 87.3, 'volume': 696, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '3', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -1.08, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015553582, 'volatility_last_month': 0.01582643, 'volatility_last_3months': 0.016494174, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.444444444, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.271818923', 'winloss_ratio_last_month': '1.171374233', 'winloss_ratio_last_3month': '2.300316341', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-21 12:00', 'high': 87.84, 'open': 87.73, 'close': 87.64, 'low': 87.52, 'volume': 332, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '3', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -1.2, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.0155471, 'volatility_last_month': 0.015827669, 'volatility_last_3months': 0.016494532, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.375, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.271659032', 'winloss_ratio_last_month': '1.165114262', 'winloss_ratio_last_3month': '2.299313936', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-21 13:00', 'high': 88.44, 'open': 87.63, 'close': 88.22, 'low': 87.49, 'volume': 705, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '3', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -1.24, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015581312, 'volatility_last_month': 0.015846619, 'volatility_last_3months': 0.016491553, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.285714286, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.271506423', 'winloss_ratio_last_month': '1.158686705', 'winloss_ratio_last_3month': '2.298316437', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-21 14:00', 'high': 88.7, 'open': 88.23, 'close': 88.09, 'low': 87.77, 'volume': 1294, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '3', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -1.78, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.01556217, 'volatility_last_month': 0.015827105, 'volatility_last_3months': 0.01649086, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.166666667, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.271360609', 'winloss_ratio_last_month': '1.150200393', 'winloss_ratio_last_3month': '2.297323809', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-21 15:00', 'high': 88.31, 'open': 88.12, 'close': 87.97, 'low': 87.69, 'volume': 995, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '3', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015496166, 'volatility_last_month': 0.015739572, 'volatility_last_3months': 0.016491033, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.271221147', 'winloss_ratio_last_month': '1.145682155', 'winloss_ratio_last_3month': '2.296336018', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-21 16:00', 'high': 88.25, 'open': 87.94, 'close': 87.7, 'low': 87.59, 'volume': 957, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015460704, 'volatility_last_month': 0.015747575, 'volatility_last_3months': 0.016491967, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.27108763', 'winloss_ratio_last_month': '1.141120104', 'winloss_ratio_last_3month': '2.295353026', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-22 07:00', 'high': 88.14, 'open': 87.59, 'close': 87.95, 'low': 87.59, 'volume': 173, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': -0.86, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015469249, 'volatility_last_month': 0.015734403, 'volatility_last_3months': 0.01648863, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.270959686', 'winloss_ratio_last_month': '1.1365136', 'winloss_ratio_last_3month': '2.2943748', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-22 08:00', 'high': 88.05, 'open': 88.02, 'close': 87.96, 'low': 87.22, 'volume': 444, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': -0.51, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015443643, 'volatility_last_month': 0.015708279, 'volatility_last_3months': 0.016478062, 'win_ratio_last_week': 0.25, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.274190695', 'winloss_ratio_last_month': '1.131861989', 'winloss_ratio_last_3month': '2.293401305', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-22 09:00', 'high': 89.88, 'open': 87.89, 'close': 89.67, 'low': 87.84, 'volume': 1080, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': -0.82, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016054536, 'volatility_last_month': 0.015870578, 'volatility_last_3months': 0.016533808, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.266491228', 'winloss_ratio_last_month': '1.127164607', 'winloss_ratio_last_3month': '2.292432507', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-22 10:00', 'high': 89.72, 'open': 89.65, 'close': 89.3, 'low': 88.98, 'volume': 585, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': -0.67, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016094312, 'volatility_last_month': 0.015838026, 'volatility_last_3months': 0.016506888, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.260276369', 'winloss_ratio_last_month': '1.122420774', 'winloss_ratio_last_3month': '2.291468372', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-22 11:00', 'high': 90.45, 'open': 89.3, 'close': 90.42, 'low': 89.1, 'volume': 823, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': -0.13, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016286361, 'volatility_last_month': 0.015911722, 'volatility_last_3months': 0.016489133, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.258976182', 'winloss_ratio_last_month': '1.117629798', 'winloss_ratio_last_3month': '2.290508865', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-22 12:00', 'high': 90.55, 'open': 90.49, 'close': 89.66, 'low': 89.47, 'volume': 591, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016430887, 'volatility_last_month': 0.015950378, 'volatility_last_3months': 0.016398301, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.258849156', 'winloss_ratio_last_month': '1.112790972', 'winloss_ratio_last_3month': '2.289553955', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-22 13:00', 'high': 90.0, 'open': 89.65, 'close': 89.44, 'low': 89.15, 'volume': 547, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.01641532, 'volatility_last_month': 0.01595077, 'volatility_last_3months': 0.016399868, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.258716617', 'winloss_ratio_last_month': '1.107903576', 'winloss_ratio_last_3month': '2.288603608', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-22 14:00', 'high': 89.71, 'open': 89.43, 'close': 89.3, 'low': 89.06, 'volume': 997, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016075607, 'volatility_last_month': 0.015953156, 'volatility_last_3months': 0.016375558, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.258578199', 'winloss_ratio_last_month': '1.102966876', 'winloss_ratio_last_3month': '2.287657791', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-22 15:00', 'high': 89.3, 'open': 89.3, 'close': 89.3, 'low': 88.26, 'volume': 1219, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.016076133, 'volatility_last_month': 0.015925336, 'volatility_last_3months': 0.016298143, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.258433501', 'winloss_ratio_last_month': '1.097980121', 'winloss_ratio_last_3month': '2.286716472', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-22 16:00', 'high': 89.5, 'open': 89.31, 'close': 89.16, 'low': 88.86, 'volume': 1094, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015961802, 'volatility_last_month': 0.015771609, 'volatility_last_3months': 0.016297946, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.258282087', 'winloss_ratio_last_month': '1.092942545', 'winloss_ratio_last_3month': '2.285779619', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-23 07:00', 'high': 89.9, 'open': 89.26, 'close': 89.73, 'low': 89.04, 'volume': 157, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015926094, 'volatility_last_month': 0.015787478, 'volatility_last_3months': 0.016303164, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.258123477', 'winloss_ratio_last_month': '1.087853369', 'winloss_ratio_last_3month': '2.2848472', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-23 08:00', 'high': 89.98, 'open': 89.66, 'close': 89.33, 'low': 89.28, 'volume': 273, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015836174, 'volatility_last_month': 0.015794284, 'volatility_last_3months': 0.01629718, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.257957146', 'winloss_ratio_last_month': '1.082711796', 'winloss_ratio_last_3month': '2.283919185', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-23 09:00', 'high': 89.6, 'open': 89.38, 'close': 89.23, 'low': 89.1, 'volume': 788, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015517973, 'volatility_last_month': 0.015783704, 'volatility_last_3months': 0.016246627, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.257782516', 'winloss_ratio_last_month': '1.077517011', 'winloss_ratio_last_3month': '2.282995541', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-23 10:00', 'high': 90.44, 'open': 89.34, 'close': 90.32, 'low': 89.2, 'volume': 813, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015661946, 'volatility_last_month': 0.015845029, 'volatility_last_3months': 0.01624363, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.25759895', 'winloss_ratio_last_month': '1.072268185', 'winloss_ratio_last_3month': '2.282076238', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-23 11:00', 'high': 90.65, 'open': 90.34, 'close': 90.19, 'low': 90.05, 'volume': 410, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.0156312, 'volatility_last_month': 0.015735614, 'volatility_last_3months': 0.01624427, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.257405745', 'winloss_ratio_last_month': '1.06696447', 'winloss_ratio_last_3month': '2.281161246', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-23 12:00', 'high': 90.25, 'open': 90.17, 'close': 89.47, 'low': 89.17, 'volume': 841, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015461324, 'volatility_last_month': 0.015776976, 'volatility_last_3months': 0.016245326, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.25720212', 'winloss_ratio_last_month': '1.061605', 'winloss_ratio_last_3month': '2.280250534', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-23 13:00', 'high': 89.55, 'open': 89.47, 'close': 89.04, 'low': 88.81, 'volume': 572, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.015472928, 'volatility_last_month': 0.015790513, 'volatility_last_3months': 0.016239621, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.25698721', 'winloss_ratio_last_month': '1.056188891', 'winloss_ratio_last_3month': '2.279344072', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-23 14:00', 'high': 89.11, 'open': 88.95, 'close': 88.8, 'low': 88.54, 'volume': 351, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.01549058, 'volatility_last_month': 0.015795896, 'volatility_last_3months': 0.01624085, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.256760049', 'winloss_ratio_last_month': '1.050715242', 'winloss_ratio_last_3month': '2.278441831', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-23 15:00', 'high': 89.13, 'open': 88.81, 'close': 88.71, 'low': 88.62, 'volume': 173, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.014764101, 'volatility_last_month': 0.015789738, 'volatility_last_3months': 0.016241205, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.256519559', 'winloss_ratio_last_month': '1.045183129', 'winloss_ratio_last_3month': '2.277543781', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-23 16:00', 'high': 89.59, 'open': 88.72, 'close': 89.37, 'low': 88.52, 'volume': 290, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.01467722, 'volatility_last_month': 0.01581228, 'volatility_last_3months': 0.016193834, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.256264531', 'winloss_ratio_last_month': '1.039591612', 'winloss_ratio_last_3month': '2.276649892', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-27 07:00', 'high': 89.92, 'open': 89.92, 'close': 88.83, 'low': 88.32, 'volume': 105, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.013765868, 'volatility_last_month': 0.015680234, 'volatility_last_3months': 0.016148193, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.255993607', 'winloss_ratio_last_month': '1.033939729', 'winloss_ratio_last_3month': '2.275760138', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-27 08:00', 'high': 88.85, 'open': 88.8, 'close': 88.61, 'low': 88.5, 'volume': 119, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.013777845, 'volatility_last_month': 0.015685101, 'volatility_last_3months': 0.016147282, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.255705252', 'winloss_ratio_last_month': '1.028226496', 'winloss_ratio_last_3month': '2.274874487', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-27 09:00', 'high': 90.0, 'open': 88.7, 'close': 89.78, 'low': 88.69, 'volume': 586, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.01405063, 'volatility_last_month': 0.015770408, 'volatility_last_3months': 0.016171392, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.255397727', 'winloss_ratio_last_month': '1.022450909', 'winloss_ratio_last_3month': '2.273992913', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-27 10:00', 'high': 91.18, 'open': 89.82, 'close': 91.0, 'low': 89.65, 'volume': 628, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.014282966, 'volatility_last_month': 0.015859551, 'volatility_last_3months': 0.016200849, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.255069057', 'winloss_ratio_last_month': '1.016611942', 'winloss_ratio_last_3month': '2.273115387', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-27 11:00', 'high': 91.0, 'open': 90.99, 'close': 90.8, 'low': 90.58, 'volume': 338, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.014290315, 'volatility_last_month': 0.015808453, 'volatility_last_3months': 0.016187975, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.254716981', 'winloss_ratio_last_month': '1.010708545', 'winloss_ratio_last_3month': '2.272241881', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-27 12:00', 'high': 91.0, 'open': 90.8, 'close': 90.5, 'low': 90.33, 'volume': 155, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.014332872, 'volatility_last_month': 0.015814141, 'volatility_last_3months': 0.016189858, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.254338908', 'winloss_ratio_last_month': '1.004739647', 'winloss_ratio_last_3month': '2.271372368', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-27 13:00', 'high': 90.5, 'open': 90.47, 'close': 90.02, 'low': 90.01, 'volume': 223, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.014412023, 'volatility_last_month': 0.015828333, 'volatility_last_3months': 0.016159998, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.253931848', 'winloss_ratio_last_month': '0.998704151', 'winloss_ratio_last_3month': '2.270506821', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-27 14:00', 'high': 89.98, 'open': 89.96, 'close': 88.33, 'low': 88.33, 'volume': 735, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.014950255, 'volatility_last_month': 0.016033949, 'volatility_last_3months': 0.016227658, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.253492334', 'winloss_ratio_last_month': '0.992600936', 'winloss_ratio_last_3month': '2.269645212', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-27 15:00', 'high': 88.83, 'open': 88.37, 'close': 88.36, 'low': 88.16, 'volume': 324, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.014899306, 'volatility_last_month': 0.015907069, 'volatility_last_3months': 0.016173632, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.253016324', 'winloss_ratio_last_month': '0.986428857', 'winloss_ratio_last_3month': '2.268787514', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-27 16:00', 'high': 88.7, 'open': 88.37, 'close': 88.4, 'low': 87.89, 'volume': 517, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.014897628, 'volatility_last_month': 0.015906085, 'volatility_last_3months': 0.016172409, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.252499074', 'winloss_ratio_last_month': '0.980186741', 'winloss_ratio_last_3month': '2.267933702', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-28 07:00', 'high': 88.84, 'open': 88.2, 'close': 88.56, 'low': 87.82, 'volume': 233, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.013436396, 'volatility_last_month': 0.015887422, 'volatility_last_3months': 0.016086627, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.251934985', 'winloss_ratio_last_month': '0.973873388', 'winloss_ratio_last_3month': '2.267083748', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-28 08:00', 'high': 88.57, 'open': 88.52, 'close': 87.66, 'low': 87.65, 'volume': 728, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.01239434, 'volatility_last_month': 0.015948803, 'volatility_last_3months': 0.016078681, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.25131739', 'winloss_ratio_last_month': '0.967487573', 'winloss_ratio_last_3month': '2.266237627', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-28 09:00', 'high': 87.77, 'open': 87.72, 'close': 87.04, 'low': 86.46, 'volume': 1172, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.012153214, 'volatility_last_month': 0.015975502, 'volatility_last_3months': 0.016088925, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.250638298', 'winloss_ratio_last_month': '0.961028042', 'winloss_ratio_last_3month': '2.265395312', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-28 10:00', 'high': 87.37, 'open': 87.15, 'close': 86.87, 'low': 86.85, 'volume': 494, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.012149234, 'volatility_last_month': 0.015948135, 'volatility_last_3months': 0.016088789, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.249888043', 'winloss_ratio_last_month': '0.954493509', 'winloss_ratio_last_3month': '2.264556778', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-28 11:00', 'high': 86.9, 'open': 86.87, 'close': 86.65, 'low': 86.43, 'volume': 579, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.01209146, 'volatility_last_month': 0.015945444, 'volatility_last_3months': 0.01608478, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.24905482', 'winloss_ratio_last_month': '0.947882661', 'winloss_ratio_last_3month': '2.263722', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-28 12:00', 'high': 86.85, 'open': 86.65, 'close': 86.39, 'low': 86.33, 'volume': 553, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.012020944, 'volatility_last_month': 0.015951727, 'volatility_last_3months': 0.016086327, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.248124062', 'winloss_ratio_last_month': '0.941194154', 'winloss_ratio_last_3month': '2.262890953', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-28 13:00', 'high': 86.85, 'open': 86.39, 'close': 86.72, 'low': 86.32, 'volume': 556, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.01206317, 'volatility_last_month': 0.015911614, 'volatility_last_3months': 0.016087665, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.247077577', 'winloss_ratio_last_month': '0.934426611', 'winloss_ratio_last_3month': '2.262063611', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-28 14:00', 'high': 87.43, 'open': 86.72, 'close': 86.7, 'low': 86.51, 'volume': 834, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.012003962, 'volatility_last_month': 0.01587287, 'volatility_last_3months': 0.016006823, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.245892351', 'winloss_ratio_last_month': '0.927578622', 'winloss_ratio_last_3month': '2.26123995', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-28 15:00', 'high': 87.5, 'open': 86.63, 'close': 86.32, 'low': 86.25, 'volume': 718, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.011924533, 'volatility_last_month': 0.015875141, 'volatility_last_3months': 0.015992199, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.244538835', 'winloss_ratio_last_month': '0.920648745', 'winloss_ratio_last_3month': '2.260419945', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-28 16:00', 'high': 86.74, 'open': 86.32, 'close': 86.19, 'low': 86.0, 'volume': 1050, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '27', 'pnl_last_week': 0.0, 'pnl_last_month': -0.86, 'pnl_last_3months': 7.17, 'volatility_last_week': 0.011702816, 'volatility_last_month': 0.015876022, 'volatility_last_3months': 0.015989222, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.553191489, 'winloss_ratio_last_week': '0.242978445', 'winloss_ratio_last_month': '0.913635503', 'winloss_ratio_last_3month': '2.259603572', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-29 07:00', 'high': 86.63, 'open': 86.03, 'close': 86.46, 'low': 86.0, 'volume': 146, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '27', 'pnl_last_week': -0.27, 'pnl_last_month': -1.13, 'pnl_last_3months': 6.08, 'volatility_last_week': 0.011275563, 'volatility_last_month': 0.015871644, 'volatility_last_3months': 0.015965876, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.166666667, 'win_ratio_last_3month': 0.531914894, 'winloss_ratio_last_week': '0.236641221', 'winloss_ratio_last_month': '0.90595462', 'winloss_ratio_last_3month': '2.258397848', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-29 08:00', 'high': 86.61, 'open': 86.41, 'close': 85.55, 'low': 85.46, 'volume': 584, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '27', 'pnl_last_week': 0.64, 'pnl_last_month': -0.22, 'pnl_last_3months': 5.3, 'volatility_last_week': 0.011531325, 'volatility_last_month': 0.015932666, 'volatility_last_3months': 0.015882288, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.285714286, 'win_ratio_last_3month': 0.531914894, 'winloss_ratio_last_week': '0.296817172', 'winloss_ratio_last_month': '0.90036865', 'winloss_ratio_last_3month': '2.256983746', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-29 09:00', 'high': 85.99, 'open': 85.53, 'close': 85.56, 'low': 85.31, 'volume': 503, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '27', 'pnl_last_week': 0.63, 'pnl_last_month': -0.23, 'pnl_last_3months': 4.44, 'volatility_last_week': 0.011272732, 'volatility_last_month': 0.01591602, 'volatility_last_3months': 0.015857148, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.25, 'win_ratio_last_3month': 0.510638298, 'winloss_ratio_last_week': '0.365293185', 'winloss_ratio_last_month': '0.894700106', 'winloss_ratio_last_3month': '2.255337368', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-29 10:00', 'high': 86.06, 'open': 85.51, 'close': 85.71, 'low': 85.44, 'volume': 523, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '27', 'pnl_last_week': 0.48, 'pnl_last_month': -0.38, 'pnl_last_3months': 4.64, 'volatility_last_week': 0.01126764, 'volatility_last_month': 0.015599947, 'volatility_last_3months': 0.015852445, 'win_ratio_last_week': 0.25, 'win_ratio_last_month': 0.222222222, 'win_ratio_last_3month': 0.510638298, 'winloss_ratio_last_week': '0.438552189', 'winloss_ratio_last_month': '0.888646975', 'winloss_ratio_last_3month': '2.253821503', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-29 11:00', 'high': 86.66, 'open': 85.74, 'close': 86.08, 'low': 85.74, 'volume': 377, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '27', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.01126684, 'volatility_last_month': 0.015597081, 'volatility_last_3months': 0.015853101, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '0.504778454', 'winloss_ratio_last_month': '0.881738454', 'winloss_ratio_last_3month': '2.252011863', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-29 12:00', 'high': 86.08, 'open': 86.03, 'close': 85.41, 'low': 85.35, 'volume': 564, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.011403854, 'volatility_last_month': 0.015629748, 'volatility_last_3months': 0.015843727, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '0.57540395', 'winloss_ratio_last_month': '0.87477128', 'winloss_ratio_last_3month': '2.250210572', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-29 13:00', 'high': 85.6, 'open': 85.44, 'close': 85.56, 'low': 85.3, 'volume': 503, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.011287363, 'volatility_last_month': 0.01562019, 'volatility_last_3months': 0.015792703, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '0.65088208', 'winloss_ratio_last_month': '0.867744702', 'winloss_ratio_last_3month': '2.248417571', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-29 14:00', 'high': 85.58, 'open': 85.56, 'close': 85.41, 'low': 85.35, 'volume': 1144, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.011288792, 'volatility_last_month': 0.015622065, 'volatility_last_3months': 0.015698916, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '0.731730769', 'winloss_ratio_last_month': '0.860657956', 'winloss_ratio_last_3month': '2.246632805', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-29 15:00', 'high': 85.44, 'open': 85.39, 'close': 84.74, 'low': 84.3, 'volume': 1023, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.011418838, 'volatility_last_month': 0.015546306, 'volatility_last_3months': 0.01571049, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '0.818544367', 'winloss_ratio_last_month': '0.853046506', 'winloss_ratio_last_3month': '2.244856217', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-29 16:00', 'high': 84.87, 'open': 84.82, 'close': 84.21, 'low': 84.19, 'volume': 1177, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.011481522, 'volatility_last_month': 0.01523234, 'volatility_last_3months': 0.015714815, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '0.912008282', 'winloss_ratio_last_month': '0.845600813', 'winloss_ratio_last_3month': '2.24308775', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-30 07:00', 'high': 85.97, 'open': 84.03, 'close': 84.19, 'low': 83.75, 'volume': 471, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.011448247, 'volatility_last_month': 0.015224566, 'volatility_last_3months': 0.015714709, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.012917115', 'winloss_ratio_last_month': '0.841084982', 'winloss_ratio_last_3month': '2.241327349', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-30 08:00', 'high': 84.65, 'open': 84.15, 'close': 84.65, 'low': 83.95, 'volume': 810, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.011544527, 'volatility_last_month': 0.01506088, 'volatility_last_3months': 0.015714737, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.0798274', 'winloss_ratio_last_month': '0.834970579', 'winloss_ratio_last_3month': '2.239574958', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-30 09:00', 'high': 84.8, 'open': 84.6, 'close': 84.63, 'low': 84.5, 'volume': 558, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.010476916, 'volatility_last_month': 0.015060615, 'volatility_last_3months': 0.015703634, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.180540541', 'winloss_ratio_last_month': '0.828770545', 'winloss_ratio_last_3month': '2.237830524', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-30 10:00', 'high': 85.2, 'open': 84.63, 'close': 85.15, 'low': 84.15, 'volume': 548, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.01059191, 'volatility_last_month': 0.015069151, 'volatility_last_3months': 0.015569794, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.26119403', 'winloss_ratio_last_month': '0.822547548', 'winloss_ratio_last_3month': '2.236093993', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-30 11:00', 'high': 85.35, 'open': 85.14, 'close': 85.23, 'low': 84.89, 'volume': 345, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.010093291, 'volatility_last_month': 0.015024429, 'volatility_last_3months': 0.01556765, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.267661692', 'winloss_ratio_last_month': '0.816301459', 'winloss_ratio_last_3month': '2.234365309', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-30 12:00', 'high': 85.25, 'open': 85.22, 'close': 83.88, 'low': 83.8, 'volume': 750, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.010552918, 'volatility_last_month': 0.014949786, 'volatility_last_3months': 0.015610804, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.258064516', 'winloss_ratio_last_month': '0.810032151', 'winloss_ratio_last_3month': '2.232644422', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-30 13:00', 'high': 83.94, 'open': 83.83, 'close': 83.25, 'low': 82.95, 'volume': 990, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.010652709, 'volatility_last_month': 0.014620405, 'volatility_last_3months': 0.015620243, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.249785408', 'winloss_ratio_last_month': '0.803739494', 'winloss_ratio_last_3month': '2.230931277', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-30 14:00', 'high': 83.2, 'open': 83.2, 'close': 82.22, 'low': 82.19, 'volume': 673, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.010969575, 'volatility_last_month': 0.014561626, 'volatility_last_3months': 0.015650345, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.242570281', 'winloss_ratio_last_month': '0.797423356', 'winloss_ratio_last_3month': '2.229225823', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-30 15:00', 'high': 82.29, 'open': 82.26, 'close': 80.96, 'low': 80.58, 'volume': 1704, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.011439194, 'volatility_last_month': 0.014699258, 'volatility_last_3months': 0.015695828, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.236226415', 'winloss_ratio_last_month': '0.791083607', 'winloss_ratio_last_3month': '2.227528008', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2022-12-30 16:00', 'high': 82.4, 'open': 80.89, 'close': 82.26, 'low': 80.64, 'volume': 976, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '27', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012203687, 'volatility_last_month': 0.01484723, 'volatility_last_3months': 0.015696172, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.230604982', 'winloss_ratio_last_month': '0.784720113', 'winloss_ratio_last_3month': '2.225837781', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-02 07:00', 'high': 82.97, 'open': 82.45, 'close': 82.91, 'low': 82.4, 'volume': 44, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '27', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012265172, 'volatility_last_month': 0.014875886, 'volatility_last_3months': 0.015681798, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.225589226', 'winloss_ratio_last_month': '0.778332742', 'winloss_ratio_last_3month': '2.224155092', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-02 08:00', 'high': 83.48, 'open': 82.9, 'close': 83.42, 'low': 82.45, 'volume': 70, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '27', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012379499, 'volatility_last_month': 0.014887161, 'volatility_last_3months': 0.015672659, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.221086262', 'winloss_ratio_last_month': '0.771921357', 'winloss_ratio_last_3month': '2.222479889', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-02 09:00', 'high': 83.9, 'open': 83.4, 'close': 83.76, 'low': 83.29, 'volume': 235, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '27', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012446678, 'volatility_last_month': 0.014896428, 'volatility_last_3months': 0.015628375, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.217021277', 'winloss_ratio_last_month': '0.765485825', 'winloss_ratio_last_3month': '2.220812123', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-02 10:00', 'high': 84.1, 'open': 83.75, 'close': 83.9, 'low': 83.65, 'volume': 206, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '27', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012038958, 'volatility_last_month': 0.014890509, 'volatility_last_3months': 0.015612155, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.213333333', 'winloss_ratio_last_month': '0.759026007', 'winloss_ratio_last_3month': '2.219151745', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-02 11:00', 'high': 84.97, 'open': 83.91, 'close': 84.95, 'low': 83.9, 'volume': 321, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '27', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012484492, 'volatility_last_month': 0.014969074, 'volatility_last_3months': 0.015633688, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.209972299', 'winloss_ratio_last_month': '0.752541767', 'winloss_ratio_last_3month': '2.217498705', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-02 12:00', 'high': 84.99, 'open': 84.9, 'close': 84.37, 'low': 84.37, 'volume': 87, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012450889, 'volatility_last_month': 0.014955357, 'volatility_last_3months': 0.015637466, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.206896552', 'winloss_ratio_last_month': '0.746032965', 'winloss_ratio_last_3month': '2.215852955', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-02 13:00', 'high': 85.96, 'open': 84.36, 'close': 85.0, 'low': 84.36, 'volume': 393, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012584115, 'volatility_last_month': 0.014975501, 'volatility_last_3months': 0.01564303, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.204071247', 'winloss_ratio_last_month': '0.739499462', 'winloss_ratio_last_3month': '2.214214448', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-02 14:00', 'high': 85.84, 'open': 85.0, 'close': 85.79, 'low': 84.98, 'volume': 103, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012805623, 'volatility_last_month': 0.015000636, 'volatility_last_3months': 0.0156392, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.201466993', 'winloss_ratio_last_month': '0.732941116', 'winloss_ratio_last_3month': '2.212583134', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-02 15:00', 'high': 85.8, 'open': 85.78, 'close': 85.61, 'low': 85.2, 'volume': 83, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012809809, 'volatility_last_month': 0.015000237, 'volatility_last_3months': 0.015632473, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.199058824', 'winloss_ratio_last_month': '0.726357786', 'winloss_ratio_last_3month': '2.210958968', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-02 16:00', 'high': 86.87, 'open': 85.78, 'close': 85.32, 'low': 85.3, 'volume': 649, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012671603, 'volatility_last_month': 0.01499485, 'volatility_last_3months': 0.015605444, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.196825397', 'winloss_ratio_last_month': '0.719749329', 'winloss_ratio_last_3month': '2.209341901', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-03 07:00', 'high': 86.21, 'open': 85.12, 'close': 84.62, 'low': 84.37, 'volume': 734, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012732828, 'volatility_last_month': 0.01492559, 'volatility_last_3months': 0.015619101, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.194748359', 'winloss_ratio_last_month': '0.713115601', 'winloss_ratio_last_3month': '2.207731889', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-03 08:00', 'high': 84.75, 'open': 84.64, 'close': 83.35, 'low': 83.1, 'volume': 1098, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.01316602, 'volatility_last_month': 0.014919891, 'volatility_last_3months': 0.01554094, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.192811839', 'winloss_ratio_last_month': '0.706456456', 'winloss_ratio_last_3month': '2.206128883', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-03 09:00', 'high': 83.67, 'open': 83.31, 'close': 83.17, 'low': 82.85, 'volume': 1093, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.01269956, 'volatility_last_month': 0.014900603, 'volatility_last_3months': 0.015541244, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.191002045', 'winloss_ratio_last_month': '0.699771749', 'winloss_ratio_last_3month': '2.20453284', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-03 10:00', 'high': 83.36, 'open': 83.18, 'close': 83.14, 'low': 82.75, 'volume': 1247, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012170864, 'volatility_last_month': 0.014897936, 'volatility_last_3months': 0.015507878, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.189306931', 'winloss_ratio_last_month': '0.693061331', 'winloss_ratio_last_3month': '2.202943714', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-03 11:00', 'high': 83.2, 'open': 83.18, 'close': 82.58, 'low': 82.55, 'volume': 734, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012227404, 'volatility_last_month': 0.014903584, 'volatility_last_3months': 0.015513295, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.187715931', 'winloss_ratio_last_month': '0.686325053', 'winloss_ratio_last_3month': '2.201361459', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-03 12:00', 'high': 83.3, 'open': 82.58, 'close': 82.86, 'low': 81.87, 'volume': 1399, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012286191, 'volatility_last_month': 0.014865321, 'volatility_last_3months': 0.015500654, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.186219739', 'winloss_ratio_last_month': '0.679562767', 'winloss_ratio_last_3month': '2.199786032', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-03 13:00', 'high': 83.3, 'open': 82.82, 'close': 82.8, 'low': 82.58, 'volume': 1486, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012258094, 'volatility_last_month': 0.014857907, 'volatility_last_3months': 0.015496257, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.184810127', 'winloss_ratio_last_month': '0.672774322', 'winloss_ratio_last_3month': '2.198217387', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-03 14:00', 'high': 83.5, 'open': 82.82, 'close': 82.59, 'low': 82.5, 'volume': 1416, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.011523656, 'volatility_last_month': 0.014860718, 'volatility_last_3months': 0.015475751, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.183479789', 'winloss_ratio_last_month': '0.665959564', 'winloss_ratio_last_3month': '2.196655483', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-03 15:00', 'high': 83.98, 'open': 82.6, 'close': 83.67, 'low': 82.49, 'volume': 1314, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012011849, 'volatility_last_month': 0.014959047, 'volatility_last_3months': 0.015505324, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.182222222', 'winloss_ratio_last_month': '0.659118342', 'winloss_ratio_last_3month': '2.195100274', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-03 16:00', 'high': 83.73, 'open': 83.66, 'close': 82.94, 'low': 82.87, 'volume': 1217, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012142488, 'volatility_last_month': 0.014999796, 'volatility_last_3months': 0.015502297, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.181031614', 'winloss_ratio_last_month': '0.6522505', 'winloss_ratio_last_3month': '2.193551719', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-04 07:00', 'high': 84.12, 'open': 82.72, 'close': 82.2, 'low': 82.05, 'volume': 682, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012254077, 'volatility_last_month': 0.015037299, 'volatility_last_3months': 0.015518417, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.179902755', 'winloss_ratio_last_month': '0.645355882', 'winloss_ratio_last_3month': '2.192009775', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-04 08:00', 'high': 82.67, 'open': 82.2, 'close': 80.8, 'low': 80.52, 'volume': 2150, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012645095, 'volatility_last_month': 0.015141714, 'volatility_last_3months': 0.015552342, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.178830964', 'winloss_ratio_last_month': '0.638434333', 'winloss_ratio_last_3month': '2.190474399', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-04 09:00', 'high': 81.25, 'open': 80.82, 'close': 80.79, 'low': 79.8, 'volume': 2279, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012579776, 'volatility_last_month': 0.015141552, 'volatility_last_3months': 0.015552023, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.177812018', 'winloss_ratio_last_month': '0.631485693', 'winloss_ratio_last_3month': '2.188945549', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-04 10:00', 'high': 81.8, 'open': 80.79, 'close': 81.71, 'low': 80.79, 'volume': 1267, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012941882, 'volatility_last_month': 0.014964191, 'volatility_last_3months': 0.015573487, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.176842105', 'winloss_ratio_last_month': '0.624509804', 'winloss_ratio_last_3month': '2.187423184', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-04 11:00', 'high': 81.99, 'open': 81.72, 'close': 81.1, 'low': 80.88, 'volume': 745, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.01302415, 'volatility_last_month': 0.014985528, 'volatility_last_3months': 0.015583753, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.175917768', 'winloss_ratio_last_month': '0.617506505', 'winloss_ratio_last_3month': '2.185907263', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-04 12:00', 'high': 81.61, 'open': 81.12, 'close': 80.74, 'low': 80.61, 'volume': 924, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.013039393, 'volatility_last_month': 0.014993629, 'volatility_last_3months': 0.015586588, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.175035868', 'winloss_ratio_last_month': '0.610475633', 'winloss_ratio_last_3month': '2.184397745', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-04 13:00', 'high': 80.8, 'open': 80.75, 'close': 80.18, 'low': 80.02, 'volume': 1251, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.013044779, 'volatility_last_month': 0.014970355, 'volatility_last_3months': 0.015594146, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.174193548', 'winloss_ratio_last_month': '0.603417026', 'winloss_ratio_last_3month': '2.18289459', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-04 14:00', 'high': 80.22, 'open': 80.22, 'close': 79.33, 'low': 78.9, 'volume': 2754, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.013217294, 'volatility_last_month': 0.015013664, 'volatility_last_3months': 0.015611828, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '1.173388203', 'winloss_ratio_last_month': '0.59633052', 'winloss_ratio_last_3month': '2.181397757', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-04 15:00', 'high': 79.53, 'open': 79.33, 'close': 78.64, 'low': 78.5, 'volume': 1795, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.17, 'volatility_last_week': 0.013306786, 'volatility_last_month': 0.015050432, 'volatility_last_3months': 0.015625944, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.17261745', 'winloss_ratio_last_month': '0.589215949', 'winloss_ratio_last_3month': '2.179998813', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-04 16:00', 'high': 78.62, 'open': 78.58, 'close': 77.5, 'low': 77.15, 'volume': 4259, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.11, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.23, 'volatility_last_week': 0.013645989, 'volatility_last_month': 0.015161336, 'volatility_last_3months': 0.015666697, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.511111111, 'winloss_ratio_last_week': '1.171879106', 'winloss_ratio_last_month': '0.582073145', 'winloss_ratio_last_3month': '2.178637859', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-05 07:00', 'high': 78.31, 'open': 77.56, 'close': 77.15, 'low': 77.0, 'volume': 1397, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.38, 'pnl_last_month': -0.75, 'pnl_last_3months': 4.21, 'volatility_last_week': 0.013597351, 'volatility_last_month': 0.01513566, 'volatility_last_3months': 0.015670852, 'win_ratio_last_week': 0.25, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.187679457', 'winloss_ratio_last_month': '0.57490194', 'winloss_ratio_last_3month': '2.177277517', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-05 08:00', 'high': 78.94, 'open': 77.17, 'close': 78.81, 'low': 77.17, 'volume': 1818, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.53, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.25, 'volatility_last_week': 0.014551245, 'volatility_last_month': 0.015406428, 'volatility_last_3months': 0.015711514, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.488372093, 'winloss_ratio_last_week': '1.156079855', 'winloss_ratio_last_month': '0.566664875', 'winloss_ratio_last_3month': '2.175686831', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-05 09:00', 'high': 79.75, 'open': 78.8, 'close': 78.82, 'low': 78.45, 'volume': 1898, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.52, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014551314, 'volatility_last_month': 0.015392028, 'volatility_last_3months': 0.015697684, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.125483123', 'winloss_ratio_last_month': '0.560413867', 'winloss_ratio_last_3month': '2.17441715', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-05 10:00', 'high': 79.41, 'open': 78.87, 'close': 79.23, 'low': 78.56, 'volume': 1202, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': -0.37, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014620211, 'volatility_last_month': 0.015058556, 'volatility_last_3months': 0.015560834, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.103741935', 'winloss_ratio_last_month': '0.558004516', 'winloss_ratio_last_3month': '2.173152237', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-05 11:00', 'high': 79.3, 'open': 79.23, 'close': 79.09, 'low': 78.79, 'volume': 1097, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014550562, 'volatility_last_month': 0.015038023, 'volatility_last_3months': 0.015560464, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.103030303', 'winloss_ratio_last_month': '0.555415211', 'winloss_ratio_last_3month': '2.171892064', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-05 12:00', 'high': 79.66, 'open': 79.1, 'close': 78.93, 'low': 78.85, 'volume': 856, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014475121, 'volatility_last_month': 0.01501352, 'volatility_last_3months': 0.015512714, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.102288022', 'winloss_ratio_last_month': '0.555352537', 'winloss_ratio_last_3month': '2.170636604', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-05 13:00', 'high': 79.37, 'open': 78.96, 'close': 78.54, 'low': 78.5, 'volume': 1247, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014473898, 'volatility_last_month': 0.015002308, 'volatility_last_3months': 0.015507321, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.101513067', 'winloss_ratio_last_month': '0.555289882', 'winloss_ratio_last_3month': '2.169385833', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-05 14:00', 'high': 78.8, 'open': 78.57, 'close': 77.82, 'low': 77.72, 'volume': 2879, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014583119, 'volatility_last_month': 0.015041401, 'volatility_last_3months': 0.015521452, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.100703235', 'winloss_ratio_last_month': '0.555227248', 'winloss_ratio_last_3month': '2.168139722', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-05 15:00', 'high': 79.14, 'open': 77.82, 'close': 78.74, 'low': 77.75, 'volume': 1561, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014859877, 'volatility_last_month': 0.015109022, 'volatility_last_3months': 0.015542307, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.099856115', 'winloss_ratio_last_month': '0.555164634', 'winloss_ratio_last_3month': '2.166898248', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-05 16:00', 'high': 79.15, 'open': 78.74, 'close': 78.53, 'low': 78.26, 'volume': 1465, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014817359, 'volatility_last_month': 0.015110665, 'volatility_last_3months': 0.015542384, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.098969072', 'winloss_ratio_last_month': '0.555102041', 'winloss_ratio_last_3month': '2.165661382', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-06 07:00', 'high': 78.99, 'open': 78.62, 'close': 78.71, 'low': 78.12, 'volume': 429, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014840891, 'volatility_last_month': 0.015112411, 'volatility_last_3months': 0.015542541, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.098039216', 'winloss_ratio_last_month': '0.555039467', 'winloss_ratio_last_3month': '2.164429101', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-06 08:00', 'high': 78.8, 'open': 78.61, 'close': 78.62, 'low': 78.02, 'volume': 782, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014749398, 'volatility_last_month': 0.015103992, 'volatility_last_3months': 0.015535471, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.097063369', 'winloss_ratio_last_month': '0.554976914', 'winloss_ratio_last_3month': '2.163201377', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-06 09:00', 'high': 79.66, 'open': 78.61, 'close': 79.2, 'low': 78.49, 'volume': 895, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014895596, 'volatility_last_month': 0.015091315, 'volatility_last_3months': 0.015517004, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.096038035', 'winloss_ratio_last_month': '0.554914381', 'winloss_ratio_last_3month': '2.161978188', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-06 10:00', 'high': 79.22, 'open': 79.2, 'close': 78.48, 'low': 78.45, 'volume': 863, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014897048, 'volatility_last_month': 0.014934744, 'volatility_last_3months': 0.015473183, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.09495935', 'winloss_ratio_last_month': '0.554851868', 'winloss_ratio_last_3month': '2.160759506', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-06 11:00', 'high': 79.08, 'open': 78.49, 'close': 79.01, 'low': 78.49, 'volume': 357, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.015017669, 'volatility_last_month': 0.014963159, 'volatility_last_3months': 0.015480092, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.093823038', 'winloss_ratio_last_month': '0.554789375', 'winloss_ratio_last_3month': '2.159545307', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-06 12:00', 'high': 79.09, 'open': 79.06, 'close': 78.1, 'low': 78.1, 'volume': 780, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014809443, 'volatility_last_month': 0.015023349, 'volatility_last_3months': 0.015497009, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.092624357', 'winloss_ratio_last_month': '0.554726902', 'winloss_ratio_last_3month': '2.158335568', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-06 13:00', 'high': 78.72, 'open': 78.11, 'close': 78.64, 'low': 77.9, 'volume': 1044, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014863185, 'volatility_last_month': 0.015015754, 'volatility_last_3months': 0.015479122, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.091358025', 'winloss_ratio_last_month': '0.554664449', 'winloss_ratio_last_3month': '2.157130262', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-06 14:00', 'high': 78.7, 'open': 78.62, 'close': 77.7, 'low': 77.5, 'volume': 1179, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014844917, 'volatility_last_month': 0.015074719, 'volatility_last_3months': 0.01543991, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.090018149', 'winloss_ratio_last_month': '0.554602017', 'winloss_ratio_last_3month': '2.155929366', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-06 15:00', 'high': 78.2, 'open': 77.73, 'close': 77.61, 'low': 77.55, 'volume': 908, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014433894, 'volatility_last_month': 0.015065353, 'volatility_last_3months': 0.015433034, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.088598131', 'winloss_ratio_last_month': '0.554539604', 'winloss_ratio_last_3month': '2.154732855', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-06 16:00', 'high': 77.83, 'open': 77.57, 'close': 77.62, 'low': 77.07, 'volume': 2160, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.013852645, 'volatility_last_month': 0.015054026, 'volatility_last_3months': 0.015432406, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.087090559', 'winloss_ratio_last_month': '0.554477212', 'winloss_ratio_last_3month': '2.153540706', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-09 07:00', 'high': 77.97, 'open': 77.64, 'close': 77.69, 'low': 77.04, 'volume': 890, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.013688905, 'volatility_last_month': 0.015050081, 'volatility_last_3months': 0.015411554, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.085487078', 'winloss_ratio_last_month': '0.554414839', 'winloss_ratio_last_3month': '2.152352895', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-09 08:00', 'high': 77.94, 'open': 77.69, 'close': 77.49, 'low': 77.01, 'volume': 1277, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.01357241, 'volatility_last_month': 0.015051445, 'volatility_last_3months': 0.015409252, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.083778234', 'winloss_ratio_last_month': '0.554352487', 'winloss_ratio_last_3month': '2.151169398', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-09 09:00', 'high': 78.2, 'open': 77.49, 'close': 77.87, 'low': 77.4, 'volume': 1864, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.013593198, 'volatility_last_month': 0.015064674, 'volatility_last_3months': 0.015412888, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.081953291', 'winloss_ratio_last_month': '0.554290155', 'winloss_ratio_last_3month': '2.149990192', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-09 10:00', 'high': 79.79, 'open': 77.95, 'close': 79.36, 'low': 77.8, 'volume': 2320, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014417565, 'volatility_last_month': 0.015283212, 'volatility_last_3months': 0.015478039, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.08', 'winloss_ratio_last_month': '0.554227843', 'winloss_ratio_last_3month': '2.148815253', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-09 11:00', 'high': 81.0, 'open': 79.35, 'close': 80.52, 'low': 79.29, 'volume': 2453, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014537752, 'volatility_last_month': 0.015410432, 'volatility_last_3months': 0.015513873, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.077904328', 'winloss_ratio_last_month': '0.55416555', 'winloss_ratio_last_3month': '2.147644559', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-09 12:00', 'high': 80.82, 'open': 80.59, 'close': 80.46, 'low': 80.11, 'volume': 1202, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014470148, 'volatility_last_month': 0.015223405, 'volatility_last_3months': 0.015513389, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.075650118', 'winloss_ratio_last_month': '0.554103278', 'winloss_ratio_last_3month': '2.146478085', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-09 13:00', 'high': 80.82, 'open': 80.47, 'close': 80.07, 'low': 79.86, 'volume': 1224, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014354039, 'volatility_last_month': 0.015182151, 'volatility_last_3months': 0.015513599, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.073218673', 'winloss_ratio_last_month': '0.554041026', 'winloss_ratio_last_3month': '2.145315811', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-09 14:00', 'high': 81.0, 'open': 80.06, 'close': 80.94, 'low': 80.06, 'volume': 1581, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014423841, 'volatility_last_month': 0.015235321, 'volatility_last_3months': 0.015529247, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.070588235', 'winloss_ratio_last_month': '0.553978794', 'winloss_ratio_last_3month': '2.144157712', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-09 15:00', 'high': 81.88, 'open': 80.84, 'close': 81.44, 'low': 80.84, 'volume': 1799, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014526059, 'volatility_last_month': 0.015243176, 'volatility_last_3months': 0.015526341, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.067733333', 'winloss_ratio_last_month': '0.553916582', 'winloss_ratio_last_3month': '2.142387174', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-09 16:00', 'high': 81.75, 'open': 81.48, 'close': 81.0, 'low': 80.88, 'volume': 1484, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014553214, 'volatility_last_month': 0.015256664, 'volatility_last_3months': 0.015532082, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.064623955', 'winloss_ratio_last_month': '0.55385439', 'winloss_ratio_last_3month': '2.140620179', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-10 07:00', 'high': 82.58, 'open': 81.16, 'close': 82.08, 'low': 80.73, 'volume': 1026, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.01483348, 'volatility_last_month': 0.015361843, 'volatility_last_3months': 0.015474212, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.06122449', 'winloss_ratio_last_month': '0.553792218', 'winloss_ratio_last_3month': '2.138856716', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-10 08:00', 'high': 82.3, 'open': 82.11, 'close': 81.21, 'low': 80.84, 'volume': 1083, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.01461819, 'volatility_last_month': 0.015370935, 'volatility_last_3months': 0.015496563, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.057492355', 'winloss_ratio_last_month': '0.553730065', 'winloss_ratio_last_3month': '2.137096774', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-10 09:00', 'high': 82.1, 'open': 81.19, 'close': 81.87, 'low': 80.88, 'volume': 1828, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014754304, 'volatility_last_month': 0.015408693, 'volatility_last_3months': 0.015505807, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.053376206', 'winloss_ratio_last_month': '0.553667933', 'winloss_ratio_last_3month': '2.135340343', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-10 10:00', 'high': 82.07, 'open': 81.97, 'close': 81.34, 'low': 81.25, 'volume': 1976, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014827193, 'volatility_last_month': 0.015355308, 'volatility_last_3months': 0.015490188, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.048813559', 'winloss_ratio_last_month': '0.553605821', 'winloss_ratio_last_3month': '2.133587413', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-10 11:00', 'high': 81.5, 'open': 81.33, 'close': 80.74, 'low': 80.33, 'volume': 1147, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014843823, 'volatility_last_month': 0.015381461, 'volatility_last_3months': 0.015501143, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.043727599', 'winloss_ratio_last_month': '0.553543728', 'winloss_ratio_last_3month': '2.131837973', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-10 12:00', 'high': 80.73, 'open': 80.71, 'close': 80.33, 'low': 80.25, 'volume': 773, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014854265, 'volatility_last_month': 0.015392958, 'volatility_last_3months': 0.015504185, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.038022814', 'winloss_ratio_last_month': '0.553481656', 'winloss_ratio_last_3month': '2.130092012', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-10 13:00', 'high': 80.61, 'open': 80.31, 'close': 80.33, 'low': 80.05, 'volume': 1133, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014854969, 'volatility_last_month': 0.015366103, 'volatility_last_3months': 0.015494506, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.031578947', 'winloss_ratio_last_month': '0.553419603', 'winloss_ratio_last_3month': '2.12834952', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-10 14:00', 'high': 80.75, 'open': 80.33, 'close': 80.7, 'low': 80.1, 'volume': 763, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014898129, 'volatility_last_month': 0.015334051, 'volatility_last_3months': 0.015497758, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.024242424', 'winloss_ratio_last_month': '0.553357571', 'winloss_ratio_last_3month': '2.126610487', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-10 15:00', 'high': 80.75, 'open': 80.7, 'close': 80.39, 'low': 80.24, 'volume': 579, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014555421, 'volatility_last_month': 0.015335838, 'volatility_last_3months': 0.015496775, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.015813953', 'winloss_ratio_last_month': '0.553295558', 'winloss_ratio_last_3month': '2.124874903', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-10 16:00', 'high': 80.85, 'open': 80.36, 'close': 80.8, 'low': 80.32, 'volume': 1412, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014490788, 'volatility_last_month': 0.015352427, 'volatility_last_3months': 0.015500129, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.006030151', 'winloss_ratio_last_month': '0.553233565', 'winloss_ratio_last_3month': '2.123142757', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-11 07:00', 'high': 81.19, 'open': 80.74, 'close': 80.67, 'low': 80.5, 'volume': 457, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014348741, 'volatility_last_month': 0.015342731, 'volatility_last_3months': 0.015490917, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.994535519', 'winloss_ratio_last_month': '0.553171592', 'winloss_ratio_last_3month': '2.121414039', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-11 08:00', 'high': 80.81, 'open': 80.66, 'close': 80.39, 'low': 80.15, 'volume': 716, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.013782673, 'volatility_last_month': 0.015334248, 'volatility_last_3months': 0.015492516, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.980838323', 'winloss_ratio_last_month': '0.553109639', 'winloss_ratio_last_3month': '2.119688739', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-11 09:00', 'high': 80.6, 'open': 80.41, 'close': 80.4, 'low': 80.0, 'volume': 1683, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.013782776, 'volatility_last_month': 0.015309122, 'volatility_last_3months': 0.015492258, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.964238411', 'winloss_ratio_last_month': '0.553047706', 'winloss_ratio_last_3month': '2.117966846', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-11 10:00', 'high': 81.06, 'open': 80.34, 'close': 81.06, 'low': 80.25, 'volume': 925, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.013652219, 'volatility_last_month': 0.015308586, 'volatility_last_3months': 0.015501749, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.943703704', 'winloss_ratio_last_month': '0.552985792', 'winloss_ratio_last_3month': '2.116248352', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-11 11:00', 'high': 81.64, 'open': 81.1, 'close': 81.47, 'low': 81.04, 'volume': 1154, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.013589134, 'volatility_last_month': 0.015316896, 'volatility_last_3months': 0.015505055, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.917647059', 'winloss_ratio_last_month': '0.552923899', 'winloss_ratio_last_3month': '2.114533245', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-11 12:00', 'high': 81.46, 'open': 81.46, 'close': 79.55, 'low': 79.55, 'volume': 1754, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014698847, 'volatility_last_month': 0.015602482, 'volatility_last_3months': 0.015613371, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.883495146', 'winloss_ratio_last_month': '0.552862025', 'winloss_ratio_last_3month': '2.112821516', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-11 13:00', 'high': 79.9, 'open': 79.55, 'close': 79.47, 'low': 79.01, 'volume': 2871, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014611195, 'volatility_last_month': 0.01560211, 'volatility_last_3months': 0.015562289, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.836781609', 'winloss_ratio_last_month': '0.552800171', 'winloss_ratio_last_3month': '2.111113154', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-11 14:00', 'high': 79.85, 'open': 79.46, 'close': 78.82, 'low': 78.82, 'volume': 2449, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014521458, 'volatility_last_month': 0.01562019, 'volatility_last_3months': 0.015575476, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.769014085', 'winloss_ratio_last_month': '0.552738337', 'winloss_ratio_last_3month': '2.10940815', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-11 15:00', 'high': 78.93, 'open': 78.86, 'close': 78.79, 'low': 78.51, 'volume': 1537, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.014371119, 'volatility_last_month': 0.015576619, 'volatility_last_3months': 0.015574429, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.661818182', 'winloss_ratio_last_month': '0.552676522', 'winloss_ratio_last_3month': '2.107706494', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-11 16:00', 'high': 79.3, 'open': 78.83, 'close': 79.03, 'low': 78.75, 'volume': 1887, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.013940713, 'volatility_last_month': 0.015559107, 'volatility_last_3months': 0.015570095, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.466666667', 'winloss_ratio_last_month': '0.552614728', 'winloss_ratio_last_3month': '2.106008177', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-12 07:00', 'high': 79.46, 'open': 78.77, 'close': 78.95, 'low': 78.77, 'volume': 490, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.013894243, 'volatility_last_month': 0.015555683, 'volatility_last_3months': 0.015567157, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0', 'winloss_ratio_last_month': '0.552552953', 'winloss_ratio_last_3month': '2.104313187', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-12 08:00', 'high': 79.55, 'open': 78.89, 'close': 79.11, 'low': 78.5, 'volume': 1492, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.012952927, 'volatility_last_month': 0.015556199, 'volatility_last_3months': 0.015548523, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0', 'winloss_ratio_last_month': '0.552491198', 'winloss_ratio_last_3month': '2.102621516', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-12 09:00', 'high': 79.99, 'open': 79.12, 'close': 79.69, 'low': 79.1, 'volume': 1677, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.013066322, 'volatility_last_month': 0.015571304, 'volatility_last_3months': 0.015534951, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0', 'winloss_ratio_last_month': '0.552429463', 'winloss_ratio_last_3month': '2.100933153', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-12 10:00', 'high': 80.25, 'open': 79.84, 'close': 80.06, 'low': 79.7, 'volume': 1658, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.013054806, 'volatility_last_month': 0.015222514, 'volatility_last_3months': 0.015529659, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.552367747', 'winloss_ratio_last_3month': '2.099248089', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-12 11:00', 'high': 80.86, 'open': 80.01, 'close': 80.47, 'low': 80.01, 'volume': 1026, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.01309699, 'volatility_last_month': 0.015159177, 'volatility_last_3months': 0.01549739, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.552306052', 'winloss_ratio_last_3month': '2.097566315', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-12 12:00', 'high': 80.6, 'open': 80.52, 'close': 80.15, 'low': 80.12, 'volume': 720, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.013125666, 'volatility_last_month': 0.015160569, 'volatility_last_3months': 0.015498461, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.552244376', 'winloss_ratio_last_3month': '2.095887821', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-12 13:00', 'high': 80.38, 'open': 80.19, 'close': 80.12, 'low': 79.78, 'volume': 1232, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.013064914, 'volatility_last_month': 0.015145537, 'volatility_last_3months': 0.01549721, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.552182719', 'winloss_ratio_last_3month': '2.094212596', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-12 14:00', 'high': 80.3, 'open': 80.11, 'close': 79.88, 'low': 79.65, 'volume': 1061, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.012883927, 'volatility_last_month': 0.015137071, 'volatility_last_3months': 0.015488644, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.552121083', 'winloss_ratio_last_3month': '2.092540632', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-12 15:00', 'high': 80.19, 'open': 79.92, 'close': 80.07, 'low': 79.65, 'volume': 1346, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.012602683, 'volatility_last_month': 0.015113412, 'volatility_last_3months': 0.015488537, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.552059466', 'winloss_ratio_last_3month': '2.09087192', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-12 16:00', 'high': 80.36, 'open': 80.02, 'close': 80.17, 'low': 80.0, 'volume': 1106, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.012583228, 'volatility_last_month': 0.015101515, 'volatility_last_3months': 0.015487649, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.551997869', 'winloss_ratio_last_3month': '2.089206449', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-13 07:00', 'high': 80.5, 'open': 80.5, 'close': 80.04, 'low': 79.9, 'volume': 327, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '3', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.75, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.012583962, 'volatility_last_month': 0.015100131, 'volatility_last_3months': 0.015486678, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.551936291', 'winloss_ratio_last_3month': '2.08754421', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-13 08:00', 'high': 80.37, 'open': 80.04, 'close': 80.11, 'low': 79.65, 'volume': 916, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '3', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.4, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.012579432, 'volatility_last_month': 0.015089734, 'volatility_last_3months': 0.015484953, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.222222222, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.552905395', 'winloss_ratio_last_3month': '2.085885193', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-13 09:00', 'high': 80.56, 'open': 80.15, 'close': 79.58, 'low': 79.1, 'volume': 1888, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '3', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.71, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.012571709, 'volatility_last_month': 0.015106298, 'volatility_last_3months': 0.015481871, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.125, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.552220856', 'winloss_ratio_last_3month': '2.084229391', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-13 10:00', 'high': 79.61, 'open': 79.6, 'close': 78.84, 'low': 78.22, 'volume': 3064, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '3', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.56, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.012580677, 'volatility_last_month': 0.015149155, 'volatility_last_3months': 0.015499122, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.142857143, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.55197728', 'winloss_ratio_last_3month': '2.082576792', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-13 11:00', 'high': 78.95, 'open': 78.85, 'close': 78.76, 'low': 78.3, 'volume': 1094, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '3', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': -0.02, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.012479424, 'volatility_last_month': 0.015123501, 'volatility_last_3months': 0.015499334, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.166666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.553337643', 'winloss_ratio_last_3month': '2.080927388', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-13 12:00', 'high': 78.99, 'open': 78.81, 'close': 78.58, 'low': 78.42, 'volume': 401, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '3', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': 0.11, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.012176867, 'volatility_last_month': 0.015123012, 'volatility_last_3months': 0.015500548, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.555103811', 'winloss_ratio_last_3month': '2.07928117', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-13 13:00', 'high': 78.98, 'open': 78.62, 'close': 78.42, 'low': 78.21, 'volume': 1417, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': 0.11, 'pnl_last_3months': 3.74, 'volatility_last_week': 0.012075383, 'volatility_last_month': 0.0151186, 'volatility_last_3months': 0.015500959, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.487804878, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.556891943', 'winloss_ratio_last_3month': '2.077593047', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-13 14:00', 'high': 78.69, 'open': 78.38, 'close': 78.44, 'low': 78.2, 'volume': 1291, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': 0.11, 'pnl_last_3months': 3.92, 'volatility_last_week': 0.011721555, 'volatility_last_month': 0.015023691, 'volatility_last_3months': 0.015499863, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.558702453', 'winloss_ratio_last_3month': '2.07607648', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-13 15:00', 'high': 80.12, 'open': 78.48, 'close': 79.84, 'low': 78.46, 'volume': 1931, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '2', 'operations_last_3_months': '26', 'pnl_last_week': 0.0, 'pnl_last_month': 0.11, 'pnl_last_3months': 4.05, 'volatility_last_week': 0.012430725, 'volatility_last_month': 0.015210073, 'volatility_last_3months': 0.015555748, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.512820513, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.560535764', 'winloss_ratio_last_3month': '2.07468396', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-13 16:00', 'high': 80.0, 'open': 79.81, 'close': 79.63, 'low': 79.54, 'volume': 1530, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '27', 'pnl_last_week': 0.0, 'pnl_last_month': 0.11, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.012453635, 'volatility_last_month': 0.015183949, 'volatility_last_3months': 0.015550718, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.2, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.562392307', 'winloss_ratio_last_3month': '2.073122858', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-16 07:00', 'high': 79.75, 'open': 79.72, 'close': 79.41, 'low': 79.05, 'volume': 523, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '27', 'pnl_last_week': 0.22, 'pnl_last_month': 0.33, 'pnl_last_3months': 4.0, 'volatility_last_week': 0.012477486, 'volatility_last_month': 0.015147633, 'volatility_last_3months': 0.015552113, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.333333333, 'win_ratio_last_3month': 0.526315789, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.564887845', 'winloss_ratio_last_3month': '2.071716137', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-16 08:00', 'high': 79.49, 'open': 79.43, 'close': 78.53, 'low': 78.33, 'volume': 1504, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '27', 'pnl_last_week': 1.1, 'pnl_last_month': 1.21, 'pnl_last_3months': 4.88, 'volatility_last_week': 0.012759519, 'volatility_last_month': 0.015181127, 'volatility_last_3months': 0.015559342, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.428571429, 'win_ratio_last_3month': 0.538461538, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.570286551', 'winloss_ratio_last_3month': '2.070509137', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-16 09:00', 'high': 78.87, 'open': 78.53, 'close': 78.04, 'low': 78.0, 'volume': 2536, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '27', 'pnl_last_week': 1.59, 'pnl_last_month': 1.7, 'pnl_last_3months': 5.37, 'volatility_last_week': 0.012801673, 'volatility_last_month': 0.015119775, 'volatility_last_3months': 0.015563453, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.577341492', 'winloss_ratio_last_3month': '2.069413913', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-16 10:00', 'high': 78.39, 'open': 78.06, 'close': 77.91, 'low': 77.89, 'volume': 1930, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '27', 'pnl_last_week': 1.72, 'pnl_last_month': 1.83, 'pnl_last_3months': 5.5, 'volatility_last_week': 0.011956769, 'volatility_last_month': 0.015109024, 'volatility_last_3months': 0.015564071, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.555555556, 'win_ratio_last_3month': 0.56097561, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.585001426', 'winloss_ratio_last_3month': '2.068349516', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-16 11:00', 'high': 78.82, 'open': 77.9, 'close': 78.58, 'low': 77.89, 'volume': 1587, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '3', 'operations_last_3_months': '27', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 4.83, 'volatility_last_week': 0.011608844, 'volatility_last_month': 0.015128306, 'volatility_last_3months': 0.015576493, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.547619048, 'winloss_ratio_last_week': '9.47761194', 'winloss_ratio_last_month': '0.591539211', 'winloss_ratio_last_3month': '2.066976592', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-16 12:00', 'high': 79.12, 'open': 78.56, 'close': 78.9, 'low': 78.5, 'volume': 1997, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 4.83, 'volatility_last_week': 0.011658824, 'volatility_last_month': 0.015014256, 'volatility_last_3months': 0.015575868, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.547619048, 'winloss_ratio_last_week': '6.02238806', 'winloss_ratio_last_month': '0.596522438', 'winloss_ratio_last_3month': '2.065606193', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-16 13:00', 'high': 79.11, 'open': 79.0, 'close': 78.09, 'low': 78.05, 'volume': 1335, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 4.83, 'volatility_last_week': 0.011849947, 'volatility_last_month': 0.015047912, 'volatility_last_3months': 0.015594477, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.547619048, 'winloss_ratio_last_week': '4.870646766', 'winloss_ratio_last_month': '0.601523426', 'winloss_ratio_last_3month': '2.064238311', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-16 14:00', 'high': 78.19, 'open': 78.12, 'close': 77.45, 'low': 77.39, 'volume': 2507, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 4.83, 'volatility_last_week': 0.011665309, 'volatility_last_month': 0.015082345, 'volatility_last_3months': 0.015595764, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.547619048, 'winloss_ratio_last_week': '4.294776119', 'winloss_ratio_last_month': '0.606542271', 'winloss_ratio_last_3month': '2.062872941', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-16 15:00', 'high': 77.75, 'open': 77.43, 'close': 77.55, 'low': 77.37, 'volume': 937, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 4.83, 'volatility_last_week': 0.011552884, 'volatility_last_month': 0.014889458, 'volatility_last_3months': 0.015595015, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.547619048, 'winloss_ratio_last_week': '3.949253731', 'winloss_ratio_last_month': '0.611579069', 'winloss_ratio_last_3month': '2.061510075', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-16 16:00', 'high': 77.75, 'open': 77.53, 'close': 77.52, 'low': 77.45, 'volume': 1565, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 4.83, 'volatility_last_week': 0.011503278, 'volatility_last_month': 0.014855935, 'volatility_last_3months': 0.015562057, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.547619048, 'winloss_ratio_last_week': '3.718905473', 'winloss_ratio_last_month': '0.616633915', 'winloss_ratio_last_3month': '2.060149706', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-17 07:00', 'high': 78.17, 'open': 77.55, 'close': 77.6, 'low': 77.55, 'volume': 772, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 6.18, 'volatility_last_week': 0.01099427, 'volatility_last_month': 0.014521323, 'volatility_last_3months': 0.015488231, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.56097561, 'winloss_ratio_last_week': '3.554371002', 'winloss_ratio_last_month': '0.621706908', 'winloss_ratio_last_3month': '2.060030529', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-17 08:00', 'high': 77.75, 'open': 77.66, 'close': 77.31, 'low': 77.07, 'volume': 1489, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 6.14, 'volatility_last_week': 0.010769389, 'volatility_last_month': 0.014527135, 'volatility_last_3months': 0.015491139, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '3.430970149', 'winloss_ratio_last_month': '0.626798144', 'winloss_ratio_last_3month': '2.059893606', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-17 09:00', 'high': 77.9, 'open': 77.31, 'close': 77.9, 'low': 77.15, 'volume': 1891, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 6.14, 'volatility_last_week': 0.010746127, 'volatility_last_month': 0.014561076, 'volatility_last_3months': 0.015500994, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '3.334991708', 'winloss_ratio_last_month': '0.631907723', 'winloss_ratio_last_3month': '2.059756768', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-17 10:00', 'high': 79.37, 'open': 77.9, 'close': 79.08, 'low': 77.85, 'volume': 3392, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 6.14, 'volatility_last_week': 0.011313714, 'volatility_last_month': 0.014691059, 'volatility_last_3months': 0.015538715, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '3.258208955', 'winloss_ratio_last_month': '0.637035743', 'winloss_ratio_last_3month': '2.059620017', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-17 11:00', 'high': 79.25, 'open': 79.1, 'close': 79.25, 'low': 79.0, 'volume': 1348, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 6.14, 'volatility_last_week': 0.011208181, 'volatility_last_month': 0.014693711, 'volatility_last_3months': 0.015539373, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '3.195386703', 'winloss_ratio_last_month': '0.642182306', 'winloss_ratio_last_3month': '2.059483351', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-17 12:00', 'high': 80.09, 'open': 79.23, 'close': 79.41, 'low': 79.03, 'volume': 1677, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 6.14, 'volatility_last_week': 0.011162957, 'volatility_last_month': 0.014696466, 'volatility_last_3months': 0.015539946, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '3.143034826', 'winloss_ratio_last_month': '0.647347511', 'winloss_ratio_last_3month': '2.059346772', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-17 13:00', 'high': 79.48, 'open': 79.41, 'close': 78.79, 'low': 78.72, 'volume': 1160, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 6.14, 'volatility_last_week': 0.011308033, 'volatility_last_month': 0.014722684, 'volatility_last_3months': 0.015551871, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '3.098737084', 'winloss_ratio_last_month': '0.65253146', 'winloss_ratio_last_3month': '2.059210278', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-17 14:00', 'high': 80.2, 'open': 78.81, 'close': 79.87, 'low': 78.45, 'volume': 2218, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 6.14, 'volatility_last_week': 0.011731997, 'volatility_last_month': 0.014779961, 'volatility_last_3months': 0.015381534, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '3.060767591', 'winloss_ratio_last_month': '0.657734256', 'winloss_ratio_last_3month': '2.059073869', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-17 15:00', 'high': 80.07, 'open': 79.86, 'close': 79.76, 'low': 79.37, 'volume': 1775, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 4.53, 'volatility_last_week': 0.011702467, 'volatility_last_month': 0.014771693, 'volatility_last_3months': 0.015287843, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.538461538, 'winloss_ratio_last_week': '3.027860697', 'winloss_ratio_last_month': '0.662956001', 'winloss_ratio_last_3month': '2.058221804', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-17 16:00', 'high': 79.95, 'open': 79.81, 'close': 79.42, 'low': 79.35, 'volume': 1427, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 4.34, 'volatility_last_week': 0.011672089, 'volatility_last_month': 0.014777734, 'volatility_last_3months': 0.015290402, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.526315789, 'winloss_ratio_last_week': '2.999067164', 'winloss_ratio_last_month': '0.668196798', 'winloss_ratio_last_3month': '2.057285834', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-18 07:00', 'high': 79.66, 'open': 79.29, 'close': 78.91, 'low': 78.8, 'volume': 680, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 4.33, 'volatility_last_week': 0.01175841, 'volatility_last_month': 0.014332835, 'volatility_last_3months': 0.015298604, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.513513514, 'winloss_ratio_last_week': '2.973661106', 'winloss_ratio_last_month': '0.673456754', 'winloss_ratio_last_3month': '2.056346006', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-18 08:00', 'high': 79.47, 'open': 78.92, 'close': 78.73, 'low': 78.52, 'volume': 1462, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 4.55, 'volatility_last_week': 0.011744073, 'volatility_last_month': 0.013961025, 'volatility_last_3months': 0.0152978, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.527777778, 'winloss_ratio_last_week': '2.951077944', 'winloss_ratio_last_month': '0.678735972', 'winloss_ratio_last_3month': '2.055607624', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-18 09:00', 'high': 79.15, 'open': 78.73, 'close': 79.12, 'low': 78.31, 'volume': 1817, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 2.2, 'volatility_last_week': 0.011812437, 'volatility_last_month': 0.01388417, 'volatility_last_3months': 0.015108821, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.514285714, 'winloss_ratio_last_week': '2.930871956', 'winloss_ratio_last_month': '0.684034558', 'winloss_ratio_last_3month': '2.053825948', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-18 10:00', 'high': 81.49, 'open': 79.15, 'close': 81.26, 'low': 79.12, 'volume': 4474, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 2.2, 'volatility_last_week': 0.013304123, 'volatility_last_month': 0.014338679, 'volatility_last_3months': 0.015241774, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.514285714, 'winloss_ratio_last_week': '2.912686567', 'winloss_ratio_last_month': '0.68935262', 'winloss_ratio_last_3month': '2.052045039', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-18 11:00', 'high': 81.92, 'open': 81.26, 'close': 81.44, 'low': 81.24, 'volume': 1046, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 2.2, 'volatility_last_week': 0.013259803, 'volatility_last_month': 0.014325345, 'volatility_last_3months': 0.015241477, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.514285714, 'winloss_ratio_last_week': '2.89623312', 'winloss_ratio_last_month': '0.694690265', 'winloss_ratio_last_3month': '2.050264897', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-18 12:00', 'high': 81.59, 'open': 81.44, 'close': 81.47, 'low': 81.0, 'volume': 824, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 2.2, 'volatility_last_week': 0.011933708, 'volatility_last_month': 0.014305061, 'volatility_last_3months': 0.015236084, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.514285714, 'winloss_ratio_last_week': '2.881275441', 'winloss_ratio_last_month': '0.700047602', 'winloss_ratio_last_3month': '2.048485521', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-18 13:00', 'high': 81.69, 'open': 81.49, 'close': 81.59, 'low': 81.25, 'volume': 1182, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 2.2, 'volatility_last_week': 0.011930492, 'volatility_last_month': 0.014306382, 'volatility_last_3months': 0.015135169, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.514285714, 'winloss_ratio_last_week': '2.86761843', 'winloss_ratio_last_month': '0.705424739', 'winloss_ratio_last_3month': '2.046706911', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-18 14:00', 'high': 83.45, 'open': 81.57, 'close': 83.08, 'low': 81.57, 'volume': 2523, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 2.2, 'volatility_last_week': 0.0124468, 'volatility_last_month': 0.014499499, 'volatility_last_3months': 0.015167918, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.514285714, 'winloss_ratio_last_week': '2.855099502', 'winloss_ratio_last_month': '0.710821787', 'winloss_ratio_last_3month': '2.044929066', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-18 15:00', 'high': 83.27, 'open': 83.07, 'close': 83.06, 'low': 82.53, 'volume': 1749, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 2.2, 'volatility_last_week': 0.012445916, 'volatility_last_month': 0.014474929, 'volatility_last_3months': 0.015149098, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.514285714, 'winloss_ratio_last_week': '2.84358209', 'winloss_ratio_last_month': '0.716238856', 'winloss_ratio_last_3month': '2.043151987', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-18 16:00', 'high': 83.86, 'open': 83.1, 'close': 83.82, 'low': 82.84, 'volume': 2969, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 2.2, 'volatility_last_week': 0.012584377, 'volatility_last_month': 0.014468832, 'volatility_last_3months': 0.015163888, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.514285714, 'winloss_ratio_last_week': '2.832950631', 'winloss_ratio_last_month': '0.721676058', 'winloss_ratio_last_3month': '2.041375671', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-19 07:00', 'high': 84.0, 'open': 83.32, 'close': 83.74, 'low': 83.13, 'volume': 912, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 2.2, 'volatility_last_week': 0.012583804, 'volatility_last_month': 0.014354023, 'volatility_last_3months': 0.015150204, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.514285714, 'winloss_ratio_last_week': '2.823106689', 'winloss_ratio_last_month': '0.727133505', 'winloss_ratio_last_3month': '2.03960012', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-19 08:00', 'high': 85.41, 'open': 83.74, 'close': 84.86, 'low': 83.73, 'volume': 2479, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 1.05, 'pnl_last_month': 1.16, 'pnl_last_3months': 2.2, 'volatility_last_week': 0.012907718, 'volatility_last_month': 0.014463776, 'volatility_last_3months': 0.01517115, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.514285714, 'winloss_ratio_last_week': '2.813965885', 'winloss_ratio_last_month': '0.732611312', 'winloss_ratio_last_3month': '2.037825332', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-19 09:00', 'high': 84.96, 'open': 84.84, 'close': 84.47, 'low': 84.42, 'volume': 1676, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 0.66, 'pnl_last_month': 0.77, 'pnl_last_3months': 1.81, 'volatility_last_week': 0.012904259, 'volatility_last_month': 0.01440997, 'volatility_last_3months': 0.015174519, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.454545455, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '2.75025227', 'winloss_ratio_last_month': '0.737242978', 'winloss_ratio_last_3month': '2.035875383', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-19 10:00', 'high': 85.3, 'open': 84.48, 'close': 83.81, 'low': 83.7, 'volume': 2880, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 0.0, 'pnl_last_month': 0.11, 'pnl_last_3months': 1.15, 'volatility_last_week': 0.013050795, 'volatility_last_month': 0.014441992, 'volatility_last_3months': 0.015146755, 'win_ratio_last_week': 0.571428571, 'win_ratio_last_month': 0.416666667, 'win_ratio_last_3month': 0.486486486, 'winloss_ratio_last_week': '2.610492108', 'winloss_ratio_last_month': '0.7404089', 'winloss_ratio_last_3month': '2.033629338', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-19 11:00', 'high': 84.6, 'open': 83.83, 'close': 84.25, 'low': 83.83, 'volume': 1632, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 0.44, 'pnl_last_month': 0.55, 'pnl_last_3months': 1.59, 'volatility_last_week': 0.013053202, 'volatility_last_month': 0.014447373, 'volatility_last_3months': 0.015151719, 'win_ratio_last_week': 0.625, 'win_ratio_last_month': 0.461538462, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '2.510318143', 'winloss_ratio_last_month': '0.745638531', 'winloss_ratio_last_3month': '2.031482695', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-19 12:00', 'high': 84.38, 'open': 84.25, 'close': 84.23, 'low': 83.5, 'volume': 1445, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 0.42, 'pnl_last_month': 0.53, 'pnl_last_3months': 1.57, 'volatility_last_week': 0.013002397, 'volatility_last_month': 0.014446963, 'volatility_last_3months': 0.015104064, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.428571429, 'win_ratio_last_3month': 0.487179487, 'winloss_ratio_last_week': '2.422', 'winloss_ratio_last_month': '0.751169485', 'winloss_ratio_last_3month': '2.029328991', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-19 13:00', 'high': 84.65, 'open': 84.19, 'close': 84.49, 'low': 83.85, 'volume': 1264, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.013007392, 'volatility_last_month': 0.014425608, 'volatility_last_3months': 0.015105696, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '2.354899028', 'winloss_ratio_last_month': '0.757583925', 'winloss_ratio_last_3month': '2.02723473', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-19 14:00', 'high': 84.66, 'open': 84.47, 'close': 83.8, 'low': 83.74, 'volume': 1644, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.01315873, 'volatility_last_month': 0.014462881, 'volatility_last_3months': 0.015119009, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '2.295997191', 'winloss_ratio_last_month': '0.765596028', 'winloss_ratio_last_3month': '2.025142357', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-19 15:00', 'high': 84.27, 'open': 83.76, 'close': 83.9, 'low': 83.5, 'volume': 1453, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.01315439, 'volatility_last_month': 0.014463366, 'volatility_last_3months': 0.01509365, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '2.243878226', 'winloss_ratio_last_month': '0.771466865', 'winloss_ratio_last_3month': '2.023051871', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-19 16:00', 'high': 84.8, 'open': 83.89, 'close': 84.26, 'low': 83.77, 'volume': 1507, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.013178278, 'volatility_last_month': 0.014470635, 'volatility_last_3months': 0.015084249, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '2.197434293', 'winloss_ratio_last_month': '0.777289986', 'winloss_ratio_last_3month': '2.020963268', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-20 07:00', 'high': 84.63, 'open': 84.05, 'close': 84.06, 'low': 83.97, 'volume': 320, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.013188114, 'volatility_last_month': 0.014467822, 'volatility_last_3months': 0.015056171, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '2.15578635', 'winloss_ratio_last_month': '0.783065969', 'winloss_ratio_last_3month': '2.018876546', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-20 08:00', 'high': 84.53, 'open': 84.07, 'close': 83.95, 'low': 83.43, 'volume': 1181, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.013199324, 'volatility_last_month': 0.014468451, 'volatility_last_3months': 0.015053261, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '2.118227991', 'winloss_ratio_last_month': '0.788795386', 'winloss_ratio_last_3month': '2.016791703', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-20 09:00', 'high': 84.4, 'open': 83.92, 'close': 84.03, 'low': 83.73, 'volume': 1539, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.013071009, 'volatility_last_month': 0.014238305, 'volatility_last_3months': 0.015047947, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '2.084185046', 'winloss_ratio_last_month': '0.794478797', 'winloss_ratio_last_3month': '2.014708737', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-20 10:00', 'high': 84.83, 'open': 84.1, 'close': 84.44, 'low': 83.51, 'volume': 1964, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012852028, 'volatility_last_month': 0.014245652, 'volatility_last_3months': 0.015016334, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '2.053186023', 'winloss_ratio_last_month': '0.800116754', 'winloss_ratio_last_3month': '2.012627643', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-20 11:00', 'high': 84.96, 'open': 84.47, 'close': 84.9, 'low': 84.29, 'volume': 1305, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012874317, 'volatility_last_month': 0.014166096, 'volatility_last_3months': 0.014942262, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '2.024840138', 'winloss_ratio_last_month': '0.8057098', 'winloss_ratio_last_3month': '2.010548421', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-20 12:00', 'high': 85.3, 'open': 84.9, 'close': 85.3, 'low': 84.43, 'volume': 1312, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012862897, 'volatility_last_month': 0.014140462, 'volatility_last_3months': 0.014945119, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.998820755', 'winloss_ratio_last_month': '0.81125847', 'winloss_ratio_last_3month': '2.008471067', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-20 13:00', 'high': 85.3, 'open': 85.3, 'close': 85.08, 'low': 84.61, 'volume': 1077, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.01287251, 'volatility_last_month': 0.014140812, 'volatility_last_3months': 0.014919133, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.974852741', 'winloss_ratio_last_month': '0.816763289', 'winloss_ratio_last_3month': '2.006395579', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-20 14:00', 'high': 85.25, 'open': 85.04, 'close': 84.77, 'low': 84.61, 'volume': 1373, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012929854, 'volatility_last_month': 0.01414684, 'volatility_last_3months': 0.014902944, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.952702703', 'winloss_ratio_last_month': '0.822224776', 'winloss_ratio_last_3month': '2.004321955', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-20 15:00', 'high': 85.29, 'open': 84.82, 'close': 85.18, 'low': 84.78, 'volume': 1124, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012353359, 'volatility_last_month': 0.014162607, 'volatility_last_3months': 0.014903678, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.932171357', 'winloss_ratio_last_month': '0.82764344', 'winloss_ratio_last_3month': '2.002250191', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-20 16:00', 'high': 85.29, 'open': 85.17, 'close': 85.09, 'low': 84.87, 'volume': 1679, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.68, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012330136, 'volatility_last_month': 0.01416193, 'volatility_last_3months': 0.014848916, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.91308752', 'winloss_ratio_last_month': '0.833019782', 'winloss_ratio_last_3month': '2.000180286', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-23 07:00', 'high': 85.75, 'open': 84.95, 'close': 85.68, 'low': 84.7, 'volume': 620, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.46, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012359417, 'volatility_last_month': 0.014166463, 'volatility_last_3months': 0.014855928, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.886692759', 'winloss_ratio_last_month': '0.838354297', 'winloss_ratio_last_3month': '1.998112237', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-23 08:00', 'high': 85.92, 'open': 85.68, 'close': 85.06, 'low': 84.95, 'volume': 1441, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': -0.42, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012163208, 'volatility_last_month': 0.014185749, 'volatility_last_3months': 0.014859268, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.828728236', 'winloss_ratio_last_month': '0.843647471', 'winloss_ratio_last_3month': '1.996046042', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-23 09:00', 'high': 85.15, 'open': 85.07, 'close': 84.75, 'low': 84.63, 'volume': 1422, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': -0.91, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012081682, 'volatility_last_month': 0.014192368, 'volatility_last_3months': 0.014861056, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.756504214', 'winloss_ratio_last_month': '0.848899783', 'winloss_ratio_last_3month': '1.993981697', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-23 10:00', 'high': 84.85, 'open': 84.76, 'close': 84.47, 'low': 84.2, 'volume': 1787, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': -1.04, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012113906, 'volatility_last_month': 0.014102898, 'volatility_last_3months': 0.014853539, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.68412642', 'winloss_ratio_last_month': '0.854111704', 'winloss_ratio_last_3month': '1.991919201', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-23 11:00', 'high': 84.53, 'open': 84.48, 'close': 84.38, 'low': 83.8, 'volume': 1194, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012011471, 'volatility_last_month': 0.014102473, 'volatility_last_3months': 0.014851873, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.654266573', 'winloss_ratio_last_month': '0.859283697', 'winloss_ratio_last_3month': '1.989858551', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-23 12:00', 'high': 84.45, 'open': 84.38, 'close': 84.35, 'low': 83.75, 'volume': 1027, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012001418, 'volatility_last_month': 0.014065869, 'volatility_last_3months': 0.014838762, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.62482493', 'winloss_ratio_last_month': '0.864416221', 'winloss_ratio_last_3month': '1.987799744', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-23 13:00', 'high': 84.88, 'open': 84.33, 'close': 84.77, 'low': 84.0, 'volume': 921, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011691869, 'volatility_last_month': 0.014070038, 'volatility_last_3months': 0.014839685, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.595792768', 'winloss_ratio_last_month': '0.869509725', 'winloss_ratio_last_3month': '1.985742778', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-23 14:00', 'high': 85.29, 'open': 84.78, 'close': 85.02, 'low': 84.64, 'volume': 1684, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011447332, 'volatility_last_month': 0.01407251, 'volatility_last_3months': 0.01482275, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.567161602', 'winloss_ratio_last_month': '0.874564653', 'winloss_ratio_last_3month': '1.983687651', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-23 15:00', 'high': 85.4, 'open': 84.97, 'close': 85.12, 'low': 84.5, 'volume': 1367, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011447696, 'volatility_last_month': 0.014073302, 'volatility_last_3months': 0.014822869, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.538923182', 'winloss_ratio_last_month': '0.87958144', 'winloss_ratio_last_3month': '1.98163436', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-23 16:00', 'high': 85.4, 'open': 85.16, 'close': 84.46, 'low': 84.2, 'volume': 1299, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011664281, 'volatility_last_month': 0.014072198, 'volatility_last_3months': 0.014832808, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.511069482', 'winloss_ratio_last_month': '0.884560518', 'winloss_ratio_last_3month': '1.979582903', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-24 07:00', 'high': 84.8, 'open': 84.8, 'close': 84.33, 'low': 83.86, 'volume': 860, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011688939, 'volatility_last_month': 0.014052476, 'volatility_last_3months': 0.014833303, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.483592693', 'winloss_ratio_last_month': '0.889502309', 'winloss_ratio_last_3month': '1.977533277', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-24 08:00', 'high': 84.77, 'open': 84.33, 'close': 84.15, 'low': 83.9, 'volume': 1599, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011652288, 'volatility_last_month': 0.014051606, 'volatility_last_3months': 0.014657329, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.456485215', 'winloss_ratio_last_month': '0.894407232', 'winloss_ratio_last_3month': '1.975485479', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-24 09:00', 'high': 84.86, 'open': 84.13, 'close': 83.95, 'low': 83.95, 'volume': 1679, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011602055, 'volatility_last_month': 0.013942548, 'volatility_last_3months': 0.014657428, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.429739653', 'winloss_ratio_last_month': '0.899275696', 'winloss_ratio_last_3month': '1.973439508', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-24 10:00', 'high': 83.95, 'open': 83.95, 'close': 82.27, 'low': 82.08, 'volume': 4245, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012238037, 'volatility_last_month': 0.01406554, 'volatility_last_3months': 0.014738367, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.403348806', 'winloss_ratio_last_month': '0.904108108', 'winloss_ratio_last_3month': '1.971395361', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-24 11:00', 'high': 83.48, 'open': 82.25, 'close': 83.19, 'low': 82.06, 'volume': 2041, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012478657, 'volatility_last_month': 0.014146497, 'volatility_last_3months': 0.014757656, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.377305665', 'winloss_ratio_last_month': '0.908904866', 'winloss_ratio_last_3month': '1.969353035', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-24 12:00', 'high': 83.4, 'open': 83.17, 'close': 82.74, 'low': 82.62, 'volume': 944, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012567624, 'volatility_last_month': 0.014156689, 'volatility_last_3months': 0.014763439, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.351603403', 'winloss_ratio_last_month': '0.913666363', 'winloss_ratio_last_3month': '1.967312528', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-24 13:00', 'high': 82.99, 'open': 82.69, 'close': 82.88, 'low': 82.37, 'volume': 1251, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012392115, 'volatility_last_month': 0.014144781, 'volatility_last_3months': 0.014752858, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.326235371', 'winloss_ratio_last_month': '0.918392986', 'winloss_ratio_last_3month': '1.965273838', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-24 14:00', 'high': 82.9, 'open': 82.87, 'close': 81.95, 'low': 81.92, 'volume': 2256, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012345537, 'volatility_last_month': 0.014005332, 'volatility_last_3months': 0.01474911, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.30119509', 'winloss_ratio_last_month': '0.923085118', 'winloss_ratio_last_3month': '1.963236962', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-24 15:00', 'high': 82.25, 'open': 81.99, 'close': 81.82, 'low': 81.53, 'volume': 1821, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012347486, 'volatility_last_month': 0.014005917, 'volatility_last_3months': 0.014746974, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.276476252', 'winloss_ratio_last_month': '0.927743134', 'winloss_ratio_last_3month': '1.961201898', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-24 16:00', 'high': 82.5, 'open': 81.82, 'close': 81.97, 'low': 81.51, 'volume': 2465, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012296381, 'volatility_last_month': 0.014008524, 'volatility_last_3months': 0.014747461, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.252072704', 'winloss_ratio_last_month': '0.932367405', 'winloss_ratio_last_3month': '1.959168642', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-25 07:00', 'high': 82.41, 'open': 81.76, 'close': 82.15, 'low': 81.61, 'volume': 778, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.01218084, 'volatility_last_month': 0.01400966, 'volatility_last_3months': 0.014747406, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.227978454', 'winloss_ratio_last_month': '0.936958297', 'winloss_ratio_last_3month': '1.957137194', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-25 08:00', 'high': 82.4, 'open': 82.07, 'close': 82.2, 'low': 81.67, 'volume': 1009, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.012157961, 'volatility_last_month': 0.013949706, 'volatility_last_3months': 0.014747195, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.204187657', 'winloss_ratio_last_month': '0.94151617', 'winloss_ratio_last_3month': '1.95510755', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-25 09:00', 'high': 82.67, 'open': 82.2, 'close': 81.89, 'low': 81.78, 'volume': 1758, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.01216597, 'volatility_last_month': 0.013928489, 'volatility_last_3months': 0.014746185, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.180694618', 'winloss_ratio_last_month': '0.946041378', 'winloss_ratio_last_3month': '1.953079709', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-25 10:00', 'high': 82.11, 'open': 81.96, 'close': 81.8, 'low': 81.4, 'volume': 2022, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.010409989, 'volatility_last_month': 0.013927181, 'volatility_last_3months': 0.014738158, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.157493781', 'winloss_ratio_last_month': '0.950534272', 'winloss_ratio_last_3month': '1.951053667', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-25 11:00', 'high': 81.85, 'open': 81.75, 'close': 81.68, 'low': 81.0, 'volume': 2031, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.010404354, 'volatility_last_month': 0.01392491, 'volatility_last_3months': 0.014734849, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.134579728', 'winloss_ratio_last_month': '0.954995196', 'winloss_ratio_last_3month': '1.949029422', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-25 12:00', 'high': 82.24, 'open': 81.69, 'close': 82.07, 'low': 81.57, 'volume': 931, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.010464112, 'volatility_last_month': 0.013936108, 'volatility_last_3months': 0.014597555, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.111947174', 'winloss_ratio_last_month': '0.95942449', 'winloss_ratio_last_3month': '1.947006972', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-25 13:00', 'high': 82.15, 'open': 82.1, 'close': 81.7, 'low': 81.57, 'volume': 1239, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.010517073, 'volatility_last_month': 0.013936894, 'volatility_last_3months': 0.014599867, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.089590965', 'winloss_ratio_last_month': '0.96382249', 'winloss_ratio_last_3month': '1.944986314', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-25 14:00', 'high': 83.29, 'open': 81.66, 'close': 82.91, 'low': 81.57, 'volume': 1904, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.0102074, 'volatility_last_month': 0.014075833, 'volatility_last_3months': 0.014641319, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.067506068', 'winloss_ratio_last_month': '0.968189527', 'winloss_ratio_last_3month': '1.942967447', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-25 15:00', 'high': 83.5, 'open': 82.95, 'close': 83.17, 'low': 82.82, 'volume': 2137, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.010234997, 'volatility_last_month': 0.014071873, 'volatility_last_3months': 0.014628836, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.045687575', 'winloss_ratio_last_month': '0.972525925', 'winloss_ratio_last_3month': '1.940950368', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-25 16:00', 'high': 85.0, 'open': 83.16, 'close': 84.7, 'low': 83.11, 'volume': 4121, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '5', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.79, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.010908387, 'volatility_last_month': 0.014277559, 'volatility_last_3months': 0.014687433, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.466666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.024130695', 'winloss_ratio_last_month': '0.976832007', 'winloss_ratio_last_3month': '1.938935074', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-26 07:00', 'high': 84.95, 'open': 84.68, 'close': 84.24, 'low': 84.01, 'volume': 890, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '5', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 1.06, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.010987875, 'volatility_last_month': 0.014288521, 'volatility_last_3months': 0.014686972, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.002830751', 'winloss_ratio_last_month': '0.982457034', 'winloss_ratio_last_3month': '1.936921562', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-26 08:00', 'high': 85.88, 'open': 84.25, 'close': 85.6, 'low': 84.05, 'volume': 2358, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '28', 'pnl_last_week': -0.37, 'pnl_last_month': 0.15, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011193474, 'volatility_last_month': 0.014378226, 'volatility_last_3months': 0.014736259, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.461538462, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.981783175', 'winloss_ratio_last_month': '0.983434118', 'winloss_ratio_last_3month': '1.934909832', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-26 09:00', 'high': 87.46, 'open': 85.59, 'close': 86.95, 'low': 85.29, 'volume': 3395, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '28', 'pnl_last_week': 0.02, 'pnl_last_month': 0.16, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011717412, 'volatility_last_month': 0.014524719, 'volatility_last_3months': 0.014776098, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.972147751', 'winloss_ratio_last_month': '0.98445701', 'winloss_ratio_last_3month': '1.93289988', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-26 10:00', 'high': 88.35, 'open': 87.0, 'close': 88.06, 'low': 86.5, 'volume': 4627, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '28', 'pnl_last_week': 0.68, 'pnl_last_month': 0.31, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011881414, 'volatility_last_month': 0.014617475, 'volatility_last_3months': 0.014782282, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.981815403', 'winloss_ratio_last_month': '0.986224361', 'winloss_ratio_last_3month': '1.930891703', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-26 11:00', 'high': 88.65, 'open': 88.0, 'close': 88.45, 'low': 87.77, 'volume': 1703, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.68, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011866166, 'volatility_last_month': 0.014618035, 'volatility_last_3months': 0.014781027, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.978192658', 'winloss_ratio_last_month': '0.989839613', 'winloss_ratio_last_3month': '1.9288853', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-26 12:00', 'high': 88.65, 'open': 88.42, 'close': 88.31, 'low': 87.81, 'volume': 1464, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.26, 'pnl_last_month': 0.68, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011878385, 'volatility_last_month': 0.014582054, 'volatility_last_3months': 0.014781354, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.975', 'winloss_ratio_last_month': '0.993459081', 'winloss_ratio_last_3month': '1.926880669', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-26 13:00', 'high': 89.15, 'open': 88.33, 'close': 87.96, 'low': 87.77, 'volume': 2133, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.68, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011923449, 'volatility_last_month': 0.014590659, 'volatility_last_3months': 0.014777193, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.962993694', 'winloss_ratio_last_month': '0.997082773', 'winloss_ratio_last_3month': '1.924877806', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-26 14:00', 'high': 89.3, 'open': 87.86, 'close': 88.52, 'low': 87.79, 'volume': 1849, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.68, 'pnl_last_3months': 1.83, 'volatility_last_week': 0.011790786, 'volatility_last_month': 0.014611274, 'volatility_last_3months': 0.01477855, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.950273411', 'winloss_ratio_last_month': '1.000710696', 'winloss_ratio_last_3month': '1.92287671', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-26 15:00', 'high': 89.6, 'open': 88.53, 'close': 89.49, 'low': 88.2, 'volume': 1604, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.68, 'pnl_last_3months': 2.05, 'volatility_last_week': 0.012018663, 'volatility_last_month': 0.014639966, 'volatility_last_3months': 0.014799377, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.512820513, 'winloss_ratio_last_week': '0.936773512', 'winloss_ratio_last_month': '1.004342857', 'winloss_ratio_last_3month': '1.921060025', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-26 16:00', 'high': 89.6, 'open': 89.49, 'close': 89.39, 'low': 89.0, 'volume': 2403, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.68, 'pnl_last_3months': 1.96, 'volatility_last_week': 0.012009712, 'volatility_last_month': 0.014615567, 'volatility_last_3months': 0.014799349, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.922420058', 'winloss_ratio_last_month': '1.007979264', 'winloss_ratio_last_3month': '1.91920571', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-27 07:00', 'high': 89.77, 'open': 89.31, 'close': 89.57, 'low': 88.67, 'volume': 1587, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '4', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.68, 'pnl_last_3months': 0.76, 'volatility_last_week': 0.011980037, 'volatility_last_month': 0.014617126, 'volatility_last_3months': 0.014749935, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.486486486, 'winloss_ratio_last_week': '0.907129456', 'winloss_ratio_last_month': '1.011619924', 'winloss_ratio_last_3month': '1.91683439', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-27 08:00', 'high': 89.89, 'open': 89.54, 'close': 89.5, 'low': 88.88, 'volume': 1819, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 0.0, 'pnl_last_month': 0.68, 'pnl_last_3months': 1.51, 'volatility_last_week': 0.011974148, 'volatility_last_month': 0.014602005, 'volatility_last_3months': 0.014732142, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.890806827', 'winloss_ratio_last_month': '1.015264845', 'winloss_ratio_last_3month': '1.915084846', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-27 09:00', 'high': 89.89, 'open': 89.49, 'close': 89.35, 'low': 89.21, 'volume': 1662, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': -0.15, 'pnl_last_month': 0.53, 'pnl_last_3months': 1.39, 'volatility_last_week': 0.01199458, 'volatility_last_month': 0.014604118, 'volatility_last_3months': 0.014732875, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.870722433', 'winloss_ratio_last_month': '1.018525116', 'winloss_ratio_last_3month': '1.913298193', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-27 10:00', 'high': 90.73, 'open': 89.3, 'close': 90.31, 'low': 89.3, 'volume': 4079, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 0.81, 'pnl_last_month': 1.49, 'pnl_last_3months': 2.35, 'volatility_last_week': 0.012174637, 'volatility_last_month': 0.014648162, 'volatility_last_3months': 0.014710503, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.513513514, 'winloss_ratio_last_week': '0.86916081', 'winloss_ratio_last_month': '1.024230084', 'winloss_ratio_last_3month': '1.911718979', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-27 11:00', 'high': 90.45, 'open': 90.3, 'close': 90.1, 'low': 90.1, 'volume': 1490, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 0.6, 'pnl_last_month': 1.28, 'pnl_last_3months': 2.14, 'volatility_last_week': 0.012164135, 'volatility_last_month': 0.014651963, 'volatility_last_3months': 0.014688016, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.538461538, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.863617021', 'winloss_ratio_last_month': '1.02938707', 'winloss_ratio_last_3month': '1.910053287', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-27 12:00', 'high': 90.12, 'open': 90.11, 'close': 89.28, 'low': 88.63, 'volume': 1955, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': -0.22, 'pnl_last_month': 0.46, 'pnl_last_3months': 1.32, 'volatility_last_week': 0.012376733, 'volatility_last_month': 0.014547558, 'volatility_last_3months': 0.014697352, 'win_ratio_last_week': 0.25, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.487179487, 'winloss_ratio_last_week': '0.842592593', 'winloss_ratio_last_month': '1.032387431', 'winloss_ratio_last_3month': '1.908050096', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-27 13:00', 'high': 90.0, 'open': 89.28, 'close': 89.74, 'low': 89.09, 'volume': 1318, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012387116, 'volatility_last_month': 0.014524151, 'volatility_last_3months': 0.014701148, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.831081081', 'winloss_ratio_last_month': '1.036538121', 'winloss_ratio_last_3month': '1.906147226', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-27 14:00', 'high': 90.1, 'open': 89.75, 'close': 89.9, 'low': 88.95, 'volume': 1680, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012335096, 'volatility_last_month': 0.014425959, 'volatility_last_3months': 0.01470092, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.819285084', 'winloss_ratio_last_month': '1.04066889', 'winloss_ratio_last_3month': '1.904245358', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-27 15:00', 'high': 90.45, 'open': 89.88, 'close': 89.21, 'low': 88.99, 'volume': 1783, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012479919, 'volatility_last_month': 0.014313236, 'volatility_last_3months': 0.014713196, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.807193923', 'winloss_ratio_last_month': '1.044779881', 'winloss_ratio_last_3month': '1.902344491', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-27 16:00', 'high': 90.2, 'open': 89.17, 'close': 89.55, 'low': 89.08, 'volume': 1640, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012488896, 'volatility_last_month': 0.014173938, 'volatility_last_3months': 0.014708851, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.79479638', 'winloss_ratio_last_month': '1.048871235', 'winloss_ratio_last_3month': '1.900444625', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-30 07:00', 'high': 89.95, 'open': 89.53, 'close': 89.75, 'low': 88.88, 'volume': 650, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012411064, 'volatility_last_month': 0.014141802, 'volatility_last_3months': 0.014708533, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.787121907', 'winloss_ratio_last_month': '1.052943094', 'winloss_ratio_last_3month': '1.898545757', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-30 08:00', 'high': 90.32, 'open': 89.74, 'close': 89.66, 'low': 89.46, 'volume': 1333, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012261322, 'volatility_last_month': 0.014122613, 'volatility_last_3months': 0.014666143, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.799675023', 'winloss_ratio_last_month': '1.056995596', 'winloss_ratio_last_3month': '1.896647888', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-30 09:00', 'high': 89.67, 'open': 89.62, 'close': 88.15, 'low': 87.52, 'volume': 3528, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012946121, 'volatility_last_month': 0.0142969, 'volatility_last_3months': 0.014723608, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.824082785', 'winloss_ratio_last_month': '1.06102888', 'winloss_ratio_last_3month': '1.894751017', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-30 10:00', 'high': 89.33, 'open': 88.17, 'close': 88.71, 'low': 88.15, 'volume': 1518, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012973059, 'volatility_last_month': 0.014318057, 'volatility_last_3months': 0.014720029, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.852240229', 'winloss_ratio_last_month': '1.06504308', 'winloss_ratio_last_3month': '1.892855143', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-30 11:00', 'high': 90.1, 'open': 88.67, 'close': 89.84, 'low': 88.54, 'volume': 1965, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.013255103, 'volatility_last_month': 0.01432134, 'volatility_last_3months': 0.014737342, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.867126218', 'winloss_ratio_last_month': '1.069038333', 'winloss_ratio_last_3month': '1.890960265', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-30 12:00', 'high': 90.04, 'open': 89.84, 'close': 89.53, 'low': 89.0, 'volume': 1407, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.0132972, 'volatility_last_month': 0.014298899, 'volatility_last_3months': 0.014730211, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.881934566', 'winloss_ratio_last_month': '1.073014771', 'winloss_ratio_last_3month': '1.889066382', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-30 13:00', 'high': 89.91, 'open': 89.52, 'close': 89.69, 'low': 88.91, 'volume': 1670, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.013266626, 'volatility_last_month': 0.014269104, 'volatility_last_3months': 0.014711793, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.896665878', 'winloss_ratio_last_month': '1.076972529', 'winloss_ratio_last_3month': '1.887173494', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-30 14:00', 'high': 90.1, 'open': 89.7, 'close': 89.6, 'low': 89.4, 'volume': 1259, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.013268656, 'volatility_last_month': 0.014220534, 'volatility_last_3months': 0.014702983, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.911320755', 'winloss_ratio_last_month': '1.080911737', 'winloss_ratio_last_3month': '1.8852816', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-30 15:00', 'high': 90.1, 'open': 89.6, 'close': 89.84, 'low': 89.6, 'volume': 1276, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.013274259, 'volatility_last_month': 0.014220873, 'volatility_last_3months': 0.014673479, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.925899788', 'winloss_ratio_last_month': '1.084832525', 'winloss_ratio_last_3month': '1.883390698', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-30 16:00', 'high': 90.09, 'open': 89.86, 'close': 89.81, 'low': 89.31, 'volume': 2033, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.013104659, 'volatility_last_month': 0.014212971, 'volatility_last_3months': 0.014673498, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.940403566', 'winloss_ratio_last_month': '1.088735023', 'winloss_ratio_last_3month': '1.881500789', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-31 07:00', 'high': 90.5, 'open': 89.9, 'close': 89.58, 'low': 89.5, 'volume': 1860, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.013119369, 'volatility_last_month': 0.014173552, 'volatility_last_3months': 0.014673142, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.95483267', 'winloss_ratio_last_month': '1.092619357', 'winloss_ratio_last_3month': '1.879611872', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-31 08:00', 'high': 89.7, 'open': 89.59, 'close': 89.68, 'low': 88.91, 'volume': 1974, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.013094342, 'volatility_last_month': 0.014026831, 'volatility_last_3months': 0.014645829, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.969187675', 'winloss_ratio_last_month': '1.096485654', 'winloss_ratio_last_3month': '1.877723945', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-31 09:00', 'high': 89.94, 'open': 89.66, 'close': 89.46, 'low': 89.16, 'volume': 1889, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.013095586, 'volatility_last_month': 0.014027806, 'volatility_last_3months': 0.014618629, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.98346915', 'winloss_ratio_last_month': '1.10033404', 'winloss_ratio_last_3month': '1.875837008', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-31 10:00', 'high': 90.1, 'open': 89.4, 'close': 89.74, 'low': 89.32, 'volume': 1955, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012019697, 'volatility_last_month': 0.014032164, 'volatility_last_3months': 0.014615127, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.997677659', 'winloss_ratio_last_month': '1.104164639', 'winloss_ratio_last_3month': '1.87395106', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-31 11:00', 'high': 89.99, 'open': 89.79, 'close': 89.88, 'low': 89.6, 'volume': 762, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.011802505, 'volatility_last_month': 0.01400119, 'volatility_last_3months': 0.014610855, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.01181376', 'winloss_ratio_last_month': '1.107977574', 'winloss_ratio_last_3month': '1.872066101', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-31 12:00', 'high': 92.5, 'open': 89.89, 'close': 92.02, 'low': 89.87, 'volume': 4691, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012781666, 'volatility_last_month': 0.014322288, 'volatility_last_3months': 0.014717238, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.025878004', 'winloss_ratio_last_month': '1.111772967', 'winloss_ratio_last_3month': '1.870182129', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-31 13:00', 'high': 93.02, 'open': 92.05, 'close': 92.52, 'low': 92.04, 'volume': 2867, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012805022, 'volatility_last_month': 0.014335761, 'volatility_last_3months': 0.014719648, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.039870938', 'winloss_ratio_last_month': '1.115550939', 'winloss_ratio_last_3month': '1.868299144', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-31 14:00', 'high': 92.95, 'open': 92.52, 'close': 92.79, 'low': 92.01, 'volume': 1842, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012381084, 'volatility_last_month': 0.014333239, 'volatility_last_3months': 0.014718311, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.053793103', 'winloss_ratio_last_month': '1.119311608', 'winloss_ratio_last_3month': '1.866417145', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-31 15:00', 'high': 92.9, 'open': 92.79, 'close': 92.36, 'low': 92.31, 'volume': 1367, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.01246006, 'volatility_last_month': 0.014255261, 'volatility_last_3months': 0.014714313, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.067645036', 'winloss_ratio_last_month': '1.123055095', 'winloss_ratio_last_3month': '1.864536131', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-01-31 16:00', 'high': 93.7, 'open': 92.39, 'close': 93.0, 'low': 92.38, 'volume': 3871, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012504414, 'volatility_last_month': 0.014227297, 'volatility_last_3months': 0.014717484, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.081427264', 'winloss_ratio_last_month': '1.126781515', 'winloss_ratio_last_3month': '1.862656102', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-01 07:00', 'high': 93.66, 'open': 92.71, 'close': 93.28, 'low': 92.25, 'volume': 606, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012504665, 'volatility_last_month': 0.014174839, 'volatility_last_3months': 0.014718695, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.095140315', 'winloss_ratio_last_month': '1.130490987', 'winloss_ratio_last_3month': '1.860777056', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-01 08:00', 'high': 93.42, 'open': 93.37, 'close': 93.06, 'low': 92.4, 'volume': 1349, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012550923, 'volatility_last_month': 0.013983847, 'volatility_last_3months': 0.014701949, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.108784706', 'winloss_ratio_last_month': '1.134183625', 'winloss_ratio_last_3month': '1.858898994', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-01 09:00', 'high': 95.81, 'open': 93.09, 'close': 95.5, 'low': 93.04, 'volume': 4679, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.013630641, 'volatility_last_month': 0.014369964, 'volatility_last_3months': 0.014822344, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.122360953', 'winloss_ratio_last_month': '1.137859543', 'winloss_ratio_last_3month': '1.857021913', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-01 10:00', 'high': 97.55, 'open': 95.37, 'close': 96.85, 'low': 95.09, 'volume': 4093, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.013836486, 'volatility_last_month': 0.014408776, 'volatility_last_3months': 0.014849957, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.135869565', 'winloss_ratio_last_month': '1.141518855', 'winloss_ratio_last_3month': '1.855145814', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-01 11:00', 'high': 97.25, 'open': 96.82, 'close': 95.3, 'low': 95.25, 'volume': 2509, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.014552519, 'volatility_last_month': 0.014539967, 'volatility_last_3months': 0.014897325, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.149311046', 'winloss_ratio_last_month': '1.145161673', 'winloss_ratio_last_3month': '1.853270696', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-01 12:00', 'high': 95.51, 'open': 95.3, 'close': 94.85, 'low': 93.2, 'volume': 2594, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.014663891, 'volatility_last_month': 0.014541802, 'volatility_last_3months': 0.014899903, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.162685895', 'winloss_ratio_last_month': '1.148788108', 'winloss_ratio_last_3month': '1.851396557', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-01 13:00', 'high': 95.19, 'open': 94.85, 'close': 94.74, 'low': 93.8, 'volume': 1378, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.014587614, 'volatility_last_month': 0.014507927, 'volatility_last_3months': 0.014900201, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.175994606', 'winloss_ratio_last_month': '1.152398271', 'winloss_ratio_last_3month': '1.849523398', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-01 14:00', 'high': 95.8, 'open': 94.72, 'close': 95.77, 'low': 94.16, 'volume': 2442, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.014435824, 'volatility_last_month': 0.014487065, 'volatility_last_3months': 0.014920333, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.189237668', 'winloss_ratio_last_month': '1.155992271', 'winloss_ratio_last_3month': '1.847651216', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-01 15:00', 'high': 95.84, 'open': 95.77, 'close': 94.82, 'low': 94.0, 'volume': 2536, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.014754907, 'volatility_last_month': 0.014502287, 'volatility_last_3months': 0.014932633, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.202415567', 'winloss_ratio_last_month': '1.159570216', 'winloss_ratio_last_3month': '1.845780013', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-01 16:00', 'high': 95.75, 'open': 94.88, 'close': 95.6, 'low': 94.82, 'volume': 2320, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.014329986, 'volatility_last_month': 0.014387128, 'volatility_last_3months': 0.01494446, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.215528782', 'winloss_ratio_last_month': '1.163132214', 'winloss_ratio_last_3month': '1.843909786', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-02 07:00', 'high': 96.4, 'open': 95.45, 'close': 96.16, 'low': 95.2, 'volume': 996, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.014223591, 'volatility_last_month': 0.014381887, 'volatility_last_3months': 0.014940149, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.228577788', 'winloss_ratio_last_month': '1.16667837', 'winloss_ratio_last_3month': '1.842040535', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-02 08:00', 'high': 96.96, 'open': 96.12, 'close': 96.61, 'low': 95.58, 'volume': 1893, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 2.46, 'volatility_last_week': 0.013860142, 'volatility_last_month': 0.014140414, 'volatility_last_3months': 0.01492961, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.512820513, 'winloss_ratio_last_week': '1.241563055', 'winloss_ratio_last_month': '1.170208791', 'winloss_ratio_last_3month': '1.840707548', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-02 09:00', 'high': 97.07, 'open': 96.59, 'close': 96.6, 'low': 96.03, 'volume': 2029, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 1.72, 'volatility_last_week': 0.013494652, 'volatility_last_month': 0.014140699, 'volatility_last_3months': 0.014909869, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.243741765', 'winloss_ratio_last_month': '1.17372358', 'winloss_ratio_last_3month': '1.839057915', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-02 10:00', 'high': 97.14, 'open': 96.73, 'close': 95.95, 'low': 95.56, 'volume': 2069, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 0.29, 'volatility_last_week': 0.013406734, 'volatility_last_month': 0.01416664, 'volatility_last_3months': 0.014846333, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.486486486, 'winloss_ratio_last_week': '1.228265525', 'winloss_ratio_last_month': '1.177222842', 'winloss_ratio_last_3month': '1.83679642', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-02 11:00', 'high': 96.93, 'open': 95.93, 'close': 96.25, 'low': 95.86, 'volume': 869, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': 0.41, 'volatility_last_week': 0.013395208, 'volatility_last_month': 0.014164882, 'volatility_last_3months': 0.014847473, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.222732971', 'winloss_ratio_last_month': '1.180706679', 'winloss_ratio_last_3month': '1.834628977', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-02 12:00', 'high': 96.43, 'open': 96.21, 'close': 95.48, 'low': 95.04, 'volume': 1618, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.013572428, 'volatility_last_month': 0.014209015, 'volatility_last_3months': 0.014827857, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.216965742', 'winloss_ratio_last_month': '1.184175193', 'winloss_ratio_last_3month': '1.832058978', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-02 13:00', 'high': 96.08, 'open': 95.46, 'close': 93.02, 'low': 92.6, 'volume': 3616, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015032073, 'volatility_last_month': 0.014627899, 'volatility_last_3months': 0.014963466, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.216646754', 'winloss_ratio_last_month': '1.187628485', 'winloss_ratio_last_3month': '1.829488562', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-02 14:00', 'high': 93.62, 'open': 93.05, 'close': 92.6, 'low': 92.3, 'volume': 2880, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015036215, 'volatility_last_month': 0.014584829, 'volatility_last_3months': 0.014965139, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.216342412', 'winloss_ratio_last_month': '1.191066654', 'winloss_ratio_last_3month': '1.826917728', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-02 15:00', 'high': 93.61, 'open': 92.61, 'close': 92.77, 'low': 91.75, 'volume': 2970, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.014842052, 'volatility_last_month': 0.014514326, 'volatility_last_3months': 0.014961378, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.216051731', 'winloss_ratio_last_month': '1.194489801', 'winloss_ratio_last_3month': '1.824346475', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-02 16:00', 'high': 93.94, 'open': 92.7, 'close': 93.59, 'low': 92.5, 'volume': 1766, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.01495792, 'volatility_last_month': 0.014544745, 'volatility_last_3months': 0.014971885, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.21577381', 'winloss_ratio_last_month': '1.197898022', 'winloss_ratio_last_3month': '1.821774805', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-03 07:00', 'high': 94.5, 'open': 94.24, 'close': 93.93, 'low': 93.05, 'volume': 1032, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.014969633, 'volatility_last_month': 0.014548063, 'volatility_last_3months': 0.014973798, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.215507827', 'winloss_ratio_last_month': '1.201291416', 'winloss_ratio_last_3month': '1.819202716', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-03 08:00', 'high': 95.25, 'open': 93.93, 'close': 94.25, 'low': 93.93, 'volume': 1425, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.24, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.014974737, 'volatility_last_month': 0.01454933, 'volatility_last_3months': 0.014861289, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.215253029', 'winloss_ratio_last_month': '1.204670079', 'winloss_ratio_last_3month': '1.816630208', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-03 09:00', 'high': 95.05, 'open': 94.12, 'close': 94.05, 'low': 93.77, 'volume': 1797, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.39, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.014979774, 'volatility_last_month': 0.014529731, 'volatility_last_3months': 0.014849387, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.221403509', 'winloss_ratio_last_month': '1.208034106', 'winloss_ratio_last_3month': '1.814057282', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-03 10:00', 'high': 95.3, 'open': 94.13, 'close': 94.99, 'low': 94.1, 'volume': 1769, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.57, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.014953226, 'volatility_last_month': 0.014518299, 'volatility_last_3months': 0.014839293, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.194194886', 'winloss_ratio_last_month': '1.211383593', 'winloss_ratio_last_3month': '1.811483938', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-03 11:00', 'high': 96.2, 'open': 94.95, 'close': 94.64, 'low': 94.64, 'volume': 1420, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': -0.36, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.014974252, 'volatility_last_month': 0.014510921, 'volatility_last_3months': 0.014835317, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.176208433', 'winloss_ratio_last_month': '1.214718634', 'winloss_ratio_last_3month': '1.808910175', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-03 12:00', 'high': 95.41, 'open': 94.66, 'close': 95.26, 'low': 94.35, 'volume': 840, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.46, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.014826037, 'volatility_last_month': 0.014435308, 'volatility_last_3months': 0.014830094, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.191742477', 'winloss_ratio_last_month': '1.218039322', 'winloss_ratio_last_3month': '1.806335993', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-03 13:00', 'high': 95.41, 'open': 95.26, 'close': 94.02, 'low': 94.0, 'volume': 1279, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015182888, 'volatility_last_month': 0.014532476, 'volatility_last_3months': 0.014863117, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.191496963', 'winloss_ratio_last_month': '1.22134575', 'winloss_ratio_last_3month': '1.803761392', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-03 14:00', 'high': 94.52, 'open': 94.05, 'close': 93.81, 'low': 93.2, 'volume': 1787, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015199934, 'volatility_last_month': 0.014437919, 'volatility_last_3months': 0.014858089, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.191240876', 'winloss_ratio_last_month': '1.224638008', 'winloss_ratio_last_3month': '1.801186372', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-03 15:00', 'high': 94.47, 'open': 93.87, 'close': 93.83, 'low': 93.37, 'volume': 1280, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015058962, 'volatility_last_month': 0.014435575, 'volatility_last_3months': 0.014852846, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.190973517', 'winloss_ratio_last_month': '1.227916188', 'winloss_ratio_last_3month': '1.798610933', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-03 16:00', 'high': 94.2, 'open': 93.78, 'close': 93.67, 'low': 93.06, 'volume': 1380, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015056982, 'volatility_last_month': 0.014439406, 'volatility_last_3months': 0.014853011, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.190694127', 'winloss_ratio_last_month': '1.231180381', 'winloss_ratio_last_3month': '1.796035074', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-06 07:00', 'high': 94.75, 'open': 93.55, 'close': 94.06, 'low': 93.1, 'volume': 676, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.01507371, 'volatility_last_month': 0.014445595, 'volatility_last_3months': 0.014853103, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.190401873', 'winloss_ratio_last_month': '1.234430675', 'winloss_ratio_last_3month': '1.793458797', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-06 08:00', 'high': 95.54, 'open': 94.07, 'close': 94.95, 'low': 94.06, 'volume': 1739, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015199213, 'volatility_last_month': 0.014480689, 'volatility_last_3months': 0.014868837, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.190095847', 'winloss_ratio_last_month': '1.237667159', 'winloss_ratio_last_3month': '1.7908821', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-06 09:00', 'high': 96.0, 'open': 94.95, 'close': 95.68, 'low': 94.88, 'volume': 1866, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.014622706, 'volatility_last_month': 0.014498126, 'volatility_last_3months': 0.01487536, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.189775051', 'winloss_ratio_last_month': '1.24088992', 'winloss_ratio_last_3month': '1.788304983', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-06 10:00', 'high': 95.78, 'open': 95.7, 'close': 94.65, 'low': 94.26, 'volume': 1642, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.014871523, 'volatility_last_month': 0.014386661, 'volatility_last_3months': 0.014899456, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.189438391', 'winloss_ratio_last_month': '1.244099047', 'winloss_ratio_last_3month': '1.785727447', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-06 11:00', 'high': 95.18, 'open': 94.65, 'close': 94.88, 'low': 94.46, 'volume': 651, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.014617803, 'volatility_last_month': 0.014274981, 'volatility_last_3months': 0.014846321, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.189084658', 'winloss_ratio_last_month': '1.247294625', 'winloss_ratio_last_3month': '1.783149491', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-06 12:00', 'high': 94.9, 'open': 94.83, 'close': 93.69, 'low': 93.45, 'volume': 1201, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.014940668, 'volatility_last_month': 0.014382678, 'volatility_last_3months': 0.014878764, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.188712522', 'winloss_ratio_last_month': '1.25047674', 'winloss_ratio_last_3month': '1.780571115', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-06 13:00', 'high': 93.79, 'open': 93.69, 'close': 92.11, 'low': 91.82, 'volume': 2766, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015532598, 'volatility_last_month': 0.014552959, 'volatility_last_3months': 0.01493623, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.188320507', 'winloss_ratio_last_month': '1.253645476', 'winloss_ratio_last_3month': '1.777992319', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-06 14:00', 'high': 92.48, 'open': 92.11, 'close': 91.24, 'low': 91.08, 'volume': 1906, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015710784, 'volatility_last_month': 0.014552748, 'volatility_last_3months': 0.01488299, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.187906977', 'winloss_ratio_last_month': '1.256800919', 'winloss_ratio_last_3month': '1.775413104', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-06 15:00', 'high': 91.57, 'open': 91.29, 'close': 90.71, 'low': 90.6, 'volume': 2241, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015768434, 'volatility_last_month': 0.014558511, 'volatility_last_3months': 0.014888128, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.18747011', 'winloss_ratio_last_month': '1.259943151', 'winloss_ratio_last_3month': '1.772833468', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-06 16:00', 'high': 91.26, 'open': 90.7, 'close': 91.18, 'low': 90.54, 'volume': 1986, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015811971, 'volatility_last_month': 0.014549784, 'volatility_last_3months': 0.014889638, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.187007874', 'winloss_ratio_last_month': '1.263072255', 'winloss_ratio_last_3month': '1.770253411', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-07 07:00', 'high': 91.83, 'open': 91.18, 'close': 91.7, 'low': 90.78, 'volume': 450, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015847278, 'volatility_last_month': 0.014469247, 'volatility_last_3months': 0.014880201, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.186517993', 'winloss_ratio_last_month': '1.266188313', 'winloss_ratio_last_3month': '1.767672935', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-07 08:00', 'high': 92.35, 'open': 91.7, 'close': 91.05, 'low': 90.84, 'volume': 1922, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015948188, 'volatility_last_month': 0.014428979, 'volatility_last_3months': 0.01489049, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.18599791', 'winloss_ratio_last_month': '1.269291408', 'winloss_ratio_last_3month': '1.765092038', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-07 09:00', 'high': 91.25, 'open': 91.0, 'close': 91.06, 'low': 90.09, 'volume': 3044, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015934262, 'volatility_last_month': 0.014394786, 'volatility_last_3months': 0.014890431, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.185444744', 'winloss_ratio_last_month': '1.272381618', 'winloss_ratio_last_3month': '1.76251072', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-07 10:00', 'high': 91.34, 'open': 91.05, 'close': 91.0, 'low': 90.41, 'volume': 1723, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015921724, 'volatility_last_month': 0.014365695, 'volatility_last_3months': 0.014890169, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.184855234', 'winloss_ratio_last_month': '1.275459026', 'winloss_ratio_last_3month': '1.759928982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-07 11:00', 'high': 91.5, 'open': 91.0, 'close': 90.91, 'low': 90.7, 'volume': 586, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015921435, 'volatility_last_month': 0.014328591, 'volatility_last_3months': 0.014888585, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.184225676', 'winloss_ratio_last_month': '1.278523709', 'winloss_ratio_last_3month': '1.757346823', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-07 12:00', 'high': 91.3, 'open': 90.87, 'close': 90.5, 'low': 90.38, 'volume': 874, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.014920011, 'volatility_last_month': 0.014324851, 'volatility_last_3months': 0.014891829, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.533333333, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.183551847', 'winloss_ratio_last_month': '1.281575747', 'winloss_ratio_last_3month': '1.754764243', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-07 13:00', 'high': 90.51, 'open': 90.48, 'close': 89.21, 'low': 89.18, 'volume': 2559, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 1.29, 'pnl_last_month': 2.21, 'pnl_last_3months': 0.76, 'volatility_last_week': 0.015217692, 'volatility_last_month': 0.014459055, 'volatility_last_3months': 0.014931245, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.5625, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.222668314', 'winloss_ratio_last_month': '1.287410875', 'winloss_ratio_last_3month': '1.752457919', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-07 14:00', 'high': 90.31, 'open': 89.25, 'close': 90.0, 'low': 89.25, 'volume': 1468, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.5, 'pnl_last_month': 1.42, 'pnl_last_3months': -0.03, 'volatility_last_week': 0.015362996, 'volatility_last_month': 0.014490341, 'volatility_last_3months': 0.014944708, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.529411765, 'win_ratio_last_3month': 0.486486486, 'winloss_ratio_last_week': '1.233510472', 'winloss_ratio_last_month': '1.291016258', 'winloss_ratio_last_3month': '1.749854704', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-07 15:00', 'high': 90.9, 'open': 90.03, 'close': 90.24, 'low': 89.7, 'volume': 1193, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.26, 'pnl_last_month': 1.18, 'pnl_last_3months': -0.27, 'volatility_last_week': 0.015350569, 'volatility_last_month': 0.014481319, 'volatility_last_3months': 0.014945258, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.473684211, 'winloss_ratio_last_week': '1.235238693', 'winloss_ratio_last_month': '1.29392669', 'winloss_ratio_last_3month': '1.747162027', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-07 16:00', 'high': 91.0, 'open': 90.2, 'close': 90.5, 'low': 90.1, 'volume': 2128, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.0, 'pnl_last_month': 0.92, 'pnl_last_3months': -0.53, 'volatility_last_week': 0.015268785, 'volatility_last_month': 0.014472269, 'volatility_last_3months': 0.01494579, 'win_ratio_last_week': 0.25, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.461538462, 'winloss_ratio_last_week': '1.226917058', 'winloss_ratio_last_month': '1.296091059', 'winloss_ratio_last_3month': '1.744372863', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-08 07:00', 'high': 91.12, 'open': 90.6, 'close': 90.1, 'low': 89.85, 'volume': 558, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.015271377, 'volatility_last_month': 0.014484388, 'volatility_last_3months': 0.014943349, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.231129133', 'winloss_ratio_last_month': '1.299086515', 'winloss_ratio_last_3month': '1.741670525', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-08 08:00', 'high': 91.4, 'open': 90.16, 'close': 90.88, 'low': 90.16, 'volume': 714, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.015424401, 'volatility_last_month': 0.014513229, 'volatility_last_3months': 0.014955189, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.235312403', 'winloss_ratio_last_month': '1.302053532', 'winloss_ratio_last_3month': '1.738969241', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-08 09:00', 'high': 91.7, 'open': 90.89, 'close': 91.64, 'low': 90.15, 'volume': 1500, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.014212983, 'volatility_last_month': 0.014548488, 'volatility_last_3months': 0.014945968, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.239467162', 'winloss_ratio_last_month': '1.304992512', 'winloss_ratio_last_3month': '1.73626901', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-08 10:00', 'high': 92.09, 'open': 91.7, 'close': 91.72, 'low': 91.35, 'volume': 1302, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013756893, 'volatility_last_month': 0.014514519, 'volatility_last_3months': 0.014934796, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.243593702', 'winloss_ratio_last_month': '1.307903852', 'winloss_ratio_last_3month': '1.733569833', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-08 11:00', 'high': 91.74, 'open': 91.74, 'close': 90.71, 'low': 90.66, 'volume': 905, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013488015, 'volatility_last_month': 0.014583686, 'volatility_last_3months': 0.01493807, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.247692308', 'winloss_ratio_last_month': '1.31078794', 'winloss_ratio_last_3month': '1.730871707', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-08 12:00', 'high': 90.6, 'open': 90.56, 'close': 89.69, 'low': 89.43, 'volume': 2179, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013682264, 'volatility_last_month': 0.014309821, 'volatility_last_3months': 0.014964191, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.251763263', 'winloss_ratio_last_month': '1.313645155', 'winloss_ratio_last_3month': '1.728174633', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-08 13:00', 'high': 89.85, 'open': 89.59, 'close': 89.59, 'low': 89.18, 'volume': 1916, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.01368226, 'volatility_last_month': 0.014310041, 'volatility_last_3months': 0.014958542, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.255806846', 'winloss_ratio_last_month': '1.316475873', 'winloss_ratio_last_3month': '1.72547861', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-08 14:00', 'high': 90.52, 'open': 89.57, 'close': 90.3, 'low': 89.44, 'volume': 1533, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013552591, 'volatility_last_month': 0.014294396, 'volatility_last_3months': 0.014966825, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.259823332', 'winloss_ratio_last_month': '1.31928046', 'winloss_ratio_last_3month': '1.722783637', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-08 15:00', 'high': 90.38, 'open': 90.3, 'close': 90.0, 'low': 89.78, 'volume': 971, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013395343, 'volatility_last_month': 0.01430345, 'volatility_last_3months': 0.014879259, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.263812993', 'winloss_ratio_last_month': '1.322059276', 'winloss_ratio_last_3month': '1.720089714', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-08 16:00', 'high': 90.62, 'open': 89.99, 'close': 90.39, 'low': 89.99, 'volume': 1330, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013272679, 'volatility_last_month': 0.014308148, 'volatility_last_3months': 0.01487798, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.267776097', 'winloss_ratio_last_month': '1.324812675', 'winloss_ratio_last_3month': '1.71739684', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-09 07:00', 'high': 90.82, 'open': 90.4, 'close': 90.82, 'low': 90.1, 'volume': 373, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013242119, 'volatility_last_month': 0.014316406, 'volatility_last_3months': 0.014876857, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.271712907', 'winloss_ratio_last_month': '1.327541004', 'winloss_ratio_last_3month': '1.714705014', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-09 08:00', 'high': 91.44, 'open': 90.75, 'close': 91.2, 'low': 90.44, 'volume': 1383, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013230159, 'volatility_last_month': 0.014322658, 'volatility_last_3months': 0.014879035, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.275623685', 'winloss_ratio_last_month': '1.330244603', 'winloss_ratio_last_3month': '1.712014236', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-09 09:00', 'high': 91.08, 'open': 91.08, 'close': 90.3, 'low': 89.9, 'volume': 1831, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013389839, 'volatility_last_month': 0.014363871, 'volatility_last_3months': 0.01482718, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.279508688', 'winloss_ratio_last_month': '1.332923808', 'winloss_ratio_last_3month': '1.709244093', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-09 10:00', 'high': 92.9, 'open': 90.4, 'close': 92.6, 'low': 90.4, 'volume': 3792, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.01472702, 'volatility_last_month': 0.014712685, 'volatility_last_3months': 0.014902666, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.28336817', 'winloss_ratio_last_month': '1.335578947', 'winloss_ratio_last_3month': '1.706618523', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-09 11:00', 'high': 92.99, 'open': 92.6, 'close': 91.92, 'low': 91.78, 'volume': 1458, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.014780628, 'volatility_last_month': 0.014739608, 'volatility_last_3months': 0.014914189, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.287202381', 'winloss_ratio_last_month': '1.338210344', 'winloss_ratio_last_3month': '1.704231463', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-09 12:00', 'high': 92.0, 'open': 91.88, 'close': 91.2, 'low': 91.12, 'volume': 1417, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.014776013, 'volatility_last_month': 0.014769681, 'volatility_last_3months': 0.014916303, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.291011569', 'winloss_ratio_last_month': '1.340818314', 'winloss_ratio_last_3month': '1.701674658', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-09 13:00', 'high': 91.6, 'open': 91.2, 'close': 90.96, 'low': 90.7, 'volume': 1016, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013465929, 'volatility_last_month': 0.014775388, 'volatility_last_3months': 0.014918025, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.294795979', 'winloss_ratio_last_month': '1.343403169', 'winloss_ratio_last_3month': '1.699198626', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-09 14:00', 'high': 91.6, 'open': 90.96, 'close': 90.87, 'low': 90.63, 'volume': 1555, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013430417, 'volatility_last_month': 0.014769156, 'volatility_last_3months': 0.014916384, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.29855585', 'winloss_ratio_last_month': '1.345965216', 'winloss_ratio_last_3month': '1.696724287', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-09 15:00', 'high': 91.22, 'open': 90.86, 'close': 91.01, 'low': 90.83, 'volume': 666, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013427805, 'volatility_last_month': 0.014767861, 'volatility_last_3months': 0.014916646, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.302291422', 'winloss_ratio_last_month': '1.348504755', 'winloss_ratio_last_3month': '1.694251637', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-09 16:00', 'high': 91.48, 'open': 90.99, 'close': 91.32, 'low': 90.87, 'volume': 1103, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013275515, 'volatility_last_month': 0.014772113, 'volatility_last_3months': 0.014918029, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.306002928', 'winloss_ratio_last_month': '1.351022082', 'winloss_ratio_last_3month': '1.691780676', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-10 07:00', 'high': 91.41, 'open': 91.32, 'close': 91.21, 'low': 90.63, 'volume': 672, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013238781, 'volatility_last_month': 0.014771098, 'volatility_last_3months': 0.014914282, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.309690601', 'winloss_ratio_last_month': '1.353517485', 'winloss_ratio_last_3month': '1.689311401', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-10 08:00', 'high': 93.42, 'open': 91.13, 'close': 92.85, 'low': 91.11, 'volume': 2692, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013915041, 'volatility_last_month': 0.014942667, 'volatility_last_3months': 0.014966264, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.31335467', 'winloss_ratio_last_month': '1.355073305', 'winloss_ratio_last_3month': '1.686843811', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-10 09:00', 'high': 93.0, 'open': 92.87, 'close': 92.99, 'low': 92.5, 'volume': 873, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013914352, 'volatility_last_month': 0.014911137, 'volatility_last_3months': 0.014963797, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.311290788', 'winloss_ratio_last_month': '1.357210215', 'winloss_ratio_last_3month': '1.684377904', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-10 10:00', 'high': 93.09, 'open': 92.99, 'close': 92.58, 'low': 92.52, 'volume': 1599, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.01372677, 'volatility_last_month': 0.014867035, 'volatility_last_3months': 0.014941973, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.336772714', 'winloss_ratio_last_month': '1.35893558', 'winloss_ratio_last_3month': '1.681913679', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-10 11:00', 'high': 92.89, 'open': 92.52, 'close': 92.64, 'low': 92.3, 'volume': 800, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013707616, 'volatility_last_month': 0.014865107, 'volatility_last_3months': 0.01493782, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.353789593', 'winloss_ratio_last_month': '1.359245096', 'winloss_ratio_last_3month': '1.679451133', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-10 12:00', 'high': 92.73, 'open': 92.7, 'close': 92.62, 'low': 92.3, 'volume': 555, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013603716, 'volatility_last_month': 0.014860093, 'volatility_last_3months': 0.014935956, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.339699864', 'winloss_ratio_last_month': '1.359216694', 'winloss_ratio_last_3month': '1.676990265', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-10 13:00', 'high': 92.69, 'open': 92.6, 'close': 92.25, 'low': 92.0, 'volume': 1034, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013288694, 'volatility_last_month': 0.014868911, 'volatility_last_3months': 0.01493889, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.338692673', 'winloss_ratio_last_month': '1.359188656', 'winloss_ratio_last_3month': '1.674531073', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-10 14:00', 'high': 92.63, 'open': 92.28, 'close': 92.16, 'low': 92.05, 'volume': 798, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '29', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013281927, 'volatility_last_month': 0.014870594, 'volatility_last_3months': 0.014938651, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.337751721', 'winloss_ratio_last_month': '1.359160975', 'winloss_ratio_last_3month': '1.672073556', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-10 15:00', 'high': 93.05, 'open': 92.17, 'close': 92.9, 'low': 92.11, 'volume': 842, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '29', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013429054, 'volatility_last_month': 0.014733269, 'volatility_last_3months': 0.014948767, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.336870681', 'winloss_ratio_last_month': '1.359133644', 'winloss_ratio_last_3month': '1.669617711', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-10 16:00', 'high': 93.32, 'open': 92.9, 'close': 93.32, 'low': 92.72, 'volume': 2405, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.4, 'pnl_last_month': 1.32, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013470036, 'volatility_last_month': 0.014734644, 'volatility_last_3months': 0.014928951, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.336044009', 'winloss_ratio_last_month': '1.359106656', 'winloss_ratio_last_3month': '1.667163538', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-13 07:00', 'high': 93.99, 'open': 93.15, 'close': 93.6, 'low': 92.57, 'volume': 687, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.4, 'pnl_last_month': 1.1, 'pnl_last_3months': -0.13, 'volatility_last_week': 0.013451876, 'volatility_last_month': 0.014729932, 'volatility_last_3months': 0.014907811, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.335266821', 'winloss_ratio_last_month': '1.358274277', 'winloss_ratio_last_3month': '1.664711034', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-13 08:00', 'high': 93.68, 'open': 93.68, 'close': 91.94, 'low': 91.68, 'volume': 1597, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.4, 'pnl_last_month': 0.22, 'pnl_last_3months': -0.27, 'volatility_last_week': 0.01389677, 'volatility_last_month': 0.014851523, 'volatility_last_3months': 0.014971492, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.444444444, 'win_ratio_last_3month': 0.461538462, 'winloss_ratio_last_week': '1.334534805', 'winloss_ratio_last_month': '1.354249318', 'winloss_ratio_last_3month': '1.662200699', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-13 09:00', 'high': 92.65, 'open': 91.95, 'close': 92.35, 'low': 91.85, 'volume': 1712, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.4, 'pnl_last_month': -0.27, 'pnl_last_3months': -0.34, 'volatility_last_week': 0.013806949, 'volatility_last_month': 0.014829947, 'volatility_last_3months': 0.014974304, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.411764706, 'win_ratio_last_3month': 0.447368421, 'winloss_ratio_last_week': '1.333844133', 'winloss_ratio_last_month': '1.348501564', 'winloss_ratio_last_3month': '1.65966233', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-13 10:00', 'high': 92.44, 'open': 92.33, 'close': 92.4, 'low': 91.8, 'volume': 1433, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '28', 'pnl_last_week': 0.4, 'pnl_last_month': -0.4, 'pnl_last_3months': -0.39, 'volatility_last_week': 0.013589696, 'volatility_last_month': 0.014826296, 'volatility_last_3months': 0.014974078, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.375, 'win_ratio_last_3month': 0.432432432, 'winloss_ratio_last_week': '1.333191399', 'winloss_ratio_last_month': '1.342356917', 'winloss_ratio_last_3month': '1.657104452', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-13 11:00', 'high': 92.49, 'open': 92.44, 'close': 92.21, 'low': 92.21, 'volume': 382, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '28', 'pnl_last_week': 0.4, 'pnl_last_month': 0.27, 'pnl_last_3months': -0.34, 'volatility_last_week': 0.013576184, 'volatility_last_month': 0.014796052, 'volatility_last_3months': 0.014975228, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.444444444, 'winloss_ratio_last_week': '1.33257356', 'winloss_ratio_last_month': '1.339493902', 'winloss_ratio_last_3month': '1.654583434', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-13 12:00', 'high': 92.63, 'open': 92.23, 'close': 92.63, 'low': 92.08, 'volume': 666, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.4, 'pnl_last_month': 0.27, 'pnl_last_3months': 0.6, 'volatility_last_week': 0.013308496, 'volatility_last_month': 0.014798029, 'volatility_last_3months': 0.014948529, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.457142857, 'winloss_ratio_last_week': '1.331987891', 'winloss_ratio_last_month': '1.336652036', 'winloss_ratio_last_3month': '1.652723405', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-13 13:00', 'high': 92.63, 'open': 92.63, 'close': 91.9, 'low': 91.75, 'volume': 1028, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.4, 'pnl_last_month': 0.27, 'pnl_last_3months': 0.6, 'volatility_last_week': 0.012814172, 'volatility_last_month': 0.014769938, 'volatility_last_3months': 0.014961023, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.457142857, 'winloss_ratio_last_week': '1.331431943', 'winloss_ratio_last_month': '1.333831085', 'winloss_ratio_last_3month': '1.650863076', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-13 14:00', 'high': 92.44, 'open': 91.86, 'close': 92.05, 'low': 91.85, 'volume': 1006, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.55, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.75, 'volatility_last_week': 0.012612876, 'volatility_last_month': 0.014721832, 'volatility_last_3months': 0.014948846, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.4375, 'win_ratio_last_3month': 0.472222222, 'winloss_ratio_last_week': '1.333780932', 'winloss_ratio_last_month': '1.331296493', 'winloss_ratio_last_3month': '1.649034296', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-13 15:00', 'high': 92.25, 'open': 92.08, 'close': 92.25, 'low': 91.68, 'volume': 1212, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.75, 'pnl_last_month': 0.62, 'pnl_last_3months': 0.95, 'volatility_last_week': 0.012536528, 'volatility_last_month': 0.014722731, 'volatility_last_3months': 0.014948159, 'win_ratio_last_week': 0.571428571, 'win_ratio_last_month': 0.470588235, 'win_ratio_last_3month': 0.486486486, 'winloss_ratio_last_week': '1.339760389', 'winloss_ratio_last_month': '1.329133358', 'winloss_ratio_last_3month': '1.647247693', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-13 16:00', 'high': 92.44, 'open': 92.25, 'close': 92.44, 'low': 92.06, 'volume': 1588, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.94, 'pnl_last_month': 0.81, 'pnl_last_3months': 1.14, 'volatility_last_week': 0.012489175, 'volatility_last_month': 0.014722619, 'volatility_last_3months': 0.014936746, 'win_ratio_last_week': 0.625, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.348930726', 'winloss_ratio_last_month': '1.327320041', 'winloss_ratio_last_3month': '1.645501151', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-14 07:00', 'high': 92.5, 'open': 92.28, 'close': 92.46, 'low': 92.02, 'volume': 391, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 0.96, 'pnl_last_month': 0.83, 'pnl_last_3months': 1.16, 'volatility_last_week': 0.012420061, 'volatility_last_month': 0.014722864, 'volatility_last_3months': 0.014889116, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.512820513, 'winloss_ratio_last_week': '1.358035714', 'winloss_ratio_last_month': '1.325554913', 'winloss_ratio_last_3month': '1.643758575', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-14 08:00', 'high': 93.5, 'open': 92.5, 'close': 93.08, 'low': 92.5, 'volume': 2100, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': 1.58, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012386859, 'volatility_last_month': 0.014729849, 'volatility_last_3months': 0.014896619, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.377552802', 'winloss_ratio_last_month': '1.324884793', 'winloss_ratio_last_3month': '1.642147409', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-14 09:00', 'high': 93.29, 'open': 93.12, 'close': 92.76, 'low': 92.54, 'volume': 1197, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.58, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012421059, 'volatility_last_month': 0.014714472, 'volatility_last_3months': 0.014885647, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.396210311', 'winloss_ratio_last_month': '1.324219497', 'winloss_ratio_last_3month': '1.640535982', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-14 10:00', 'high': 93.34, 'open': 92.88, 'close': 92.04, 'low': 92.0, 'volume': 1552, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.58, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012569824, 'volatility_last_month': 0.014638628, 'volatility_last_3months': 0.014875913, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.414063805', 'winloss_ratio_last_month': '1.323558975', 'winloss_ratio_last_3month': '1.638924295', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-14 11:00', 'high': 92.35, 'open': 92.06, 'close': 90.55, 'low': 90.25, 'volume': 3019, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.58, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.013168829, 'volatility_last_month': 0.014808708, 'volatility_last_3months': 0.014927416, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.43116416', 'winloss_ratio_last_month': '1.322903176', 'winloss_ratio_last_3month': '1.637312348', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-14 12:00', 'high': 90.9, 'open': 90.52, 'close': 90.19, 'low': 89.91, 'volume': 2527, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.58, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.013159021, 'volatility_last_month': 0.01482022, 'volatility_last_3months': 0.014930279, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.447558046', 'winloss_ratio_last_month': '1.322252048', 'winloss_ratio_last_3month': '1.635700141', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-14 13:00', 'high': 91.13, 'open': 90.19, 'close': 90.68, 'low': 90.01, 'volume': 1301, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.29, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012754781, 'volatility_last_month': 0.014790962, 'volatility_last_3months': 0.014928705, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.422811421', 'winloss_ratio_last_month': '1.321605542', 'winloss_ratio_last_3month': '1.634087673', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-14 14:00', 'high': 91.53, 'open': 90.63, 'close': 90.99, 'low': 90.61, 'volume': 1133, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.08, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012611417, 'volatility_last_month': 0.014696701, 'volatility_last_3months': 0.014930509, 'win_ratio_last_week': 0.75, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.433884949', 'winloss_ratio_last_month': '1.320963608', 'winloss_ratio_last_3month': '1.632474945', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-14 15:00', 'high': 92.17, 'open': 90.99, 'close': 91.76, 'low': 90.96, 'volume': 1427, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.32, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012749356, 'volatility_last_month': 0.014729698, 'volatility_last_3months': 0.014936312, 'win_ratio_last_week': 0.857142857, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.456126356', 'winloss_ratio_last_month': '1.3203262', 'winloss_ratio_last_3month': '1.630861957', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-14 16:00', 'high': 92.19, 'open': 91.72, 'close': 91.39, 'low': 91.35, 'volume': 2010, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.58, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012775786, 'volatility_last_month': 0.014728371, 'volatility_last_3months': 0.014937566, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.491448119', 'winloss_ratio_last_month': '1.319693268', 'winloss_ratio_last_3month': '1.629248708', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-15 07:00', 'high': 91.97, 'open': 91.74, 'close': 91.34, 'low': 91.09, 'volume': 676, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012728033, 'volatility_last_month': 0.014699037, 'volatility_last_3months': 0.014937569, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.514975042', 'winloss_ratio_last_month': '1.319064766', 'winloss_ratio_last_3month': '1.627635198', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-15 08:00', 'high': 91.47, 'open': 91.44, 'close': 90.77, 'low': 90.45, 'volume': 1372, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.01265677, 'volatility_last_month': 0.014722501, 'volatility_last_3months': 0.014942494, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.539534093', 'winloss_ratio_last_month': '1.318440648', 'winloss_ratio_last_3month': '1.626021428', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-15 09:00', 'high': 91.22, 'open': 90.78, 'close': 90.91, 'low': 90.75, 'volume': 1175, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012500554, 'volatility_last_month': 0.014712306, 'volatility_last_3months': 0.014936024, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.565194715', 'winloss_ratio_last_month': '1.317820868', 'winloss_ratio_last_3month': '1.624407397', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-15 10:00', 'high': 91.65, 'open': 90.89, 'close': 91.44, 'low': 90.84, 'volume': 1020, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012578922, 'volatility_last_month': 0.014322117, 'volatility_last_3months': 0.014918924, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.592032723', 'winloss_ratio_last_month': '1.31720538', 'winloss_ratio_last_3month': '1.622793105', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-15 11:00', 'high': 92.21, 'open': 91.41, 'close': 92.01, 'low': 91.4, 'volume': 883, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012376142, 'volatility_last_month': 0.014339657, 'volatility_last_3months': 0.014925129, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.620131052', 'winloss_ratio_last_month': '1.316594141', 'winloss_ratio_last_3month': '1.621178552', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-15 12:00', 'high': 93.21, 'open': 91.97, 'close': 92.91, 'low': 91.94, 'volume': 1993, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012249931, 'volatility_last_month': 0.01438973, 'volatility_last_3months': 0.014939984, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.649580615', 'winloss_ratio_last_month': '1.315987107', 'winloss_ratio_last_3month': '1.619563738', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-15 13:00', 'high': 93.1, 'open': 92.92, 'close': 92.51, 'low': 92.47, 'volume': 985, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012300794, 'volatility_last_month': 0.014404151, 'volatility_last_3months': 0.014943121, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.680481283', 'winloss_ratio_last_month': '1.315384233', 'winloss_ratio_last_3month': '1.617948663', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-15 14:00', 'high': 93.95, 'open': 92.5, 'close': 93.69, 'low': 92.5, 'volume': 2483, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012513758, 'volatility_last_month': 0.014307149, 'volatility_last_3months': 0.014964866, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.712943019', 'winloss_ratio_last_month': '1.314785479', 'winloss_ratio_last_3month': '1.616333328', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-15 15:00', 'high': 95.0, 'open': 93.69, 'close': 94.8, 'low': 93.41, 'volume': 2378, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012743666, 'volatility_last_month': 0.014381984, 'volatility_last_3months': 0.014989783, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.747087184', 'winloss_ratio_last_month': '1.3141908', 'winloss_ratio_last_3month': '1.614717731', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-15 16:00', 'high': 94.94, 'open': 94.82, 'close': 94.59, 'low': 94.2, 'volume': 2433, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012741318, 'volatility_last_month': 0.014343424, 'volatility_last_3months': 0.014990837, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.783048051', 'winloss_ratio_last_month': '1.313600157', 'winloss_ratio_last_3month': '1.613101873', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-16 07:00', 'high': 95.39, 'open': 94.3, 'close': 95.06, 'low': 94.19, 'volume': 938, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.01274507, 'volatility_last_month': 0.01435332, 'volatility_last_3months': 0.014986069, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.820974576', 'winloss_ratio_last_month': '1.313013508', 'winloss_ratio_last_3month': '1.611485753', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-16 08:00', 'high': 96.75, 'open': 95.09, 'close': 96.55, 'low': 95.0, 'volume': 2502, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.45, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.01319167, 'volatility_last_month': 0.014391102, 'volatility_last_3months': 0.015027827, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.861032455', 'winloss_ratio_last_month': '1.312430813', 'winloss_ratio_last_3month': '1.609869373', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-16 09:00', 'high': 97.7, 'open': 96.55, 'close': 96.71, 'low': 96.51, 'volume': 3572, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.84, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.012916595, 'volatility_last_month': 0.014374932, 'volatility_last_3months': 0.015002172, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.578947368, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.903406544', 'winloss_ratio_last_month': '1.313514304', 'winloss_ratio_last_3month': '1.608252731', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-16 10:00', 'high': 96.96, 'open': 96.71, 'close': 96.64, 'low': 95.8, 'volume': 2006, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 2.5, 'pnl_last_3months': 1.78, 'volatility_last_week': 0.011564933, 'volatility_last_month': 0.014331998, 'volatility_last_3months': 0.01487804, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.611111111, 'win_ratio_last_3month': 0.525, 'winloss_ratio_last_week': '1.948303716', 'winloss_ratio_last_month': '1.317412353', 'winloss_ratio_last_3month': '1.606635827', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-16 11:00', 'high': 96.79, 'open': 96.62, 'close': 96.62, 'low': 96.42, 'volume': 512, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 2.06, 'pnl_last_3months': 1.53, 'volatility_last_week': 0.011395488, 'volatility_last_month': 0.014320003, 'volatility_last_3months': 0.01487649, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.588235294, 'win_ratio_last_3month': 0.512820513, 'winloss_ratio_last_week': '1.995956232', 'winloss_ratio_last_month': '1.319881074', 'winloss_ratio_last_3month': '1.604912263', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-16 12:00', 'high': 96.59, 'open': 96.59, 'close': 96.0, 'low': 95.98, 'volume': 939, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 2.08, 'pnl_last_3months': 1.41, 'volatility_last_week': 0.011336495, 'volatility_last_month': 0.014350132, 'volatility_last_3months': 0.01488527, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.625, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '2.046625767', 'winloss_ratio_last_month': '1.322435981', 'winloss_ratio_last_3month': '1.603137343', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-16 13:00', 'high': 97.05, 'open': 96.0, 'close': 96.61, 'low': 96.0, 'volume': 1554, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '7', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.37, 'volatility_last_week': 0.011369893, 'volatility_last_month': 0.014366063, 'volatility_last_3months': 0.014892118, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.486486486, 'winloss_ratio_last_week': '2.100608211', 'winloss_ratio_last_month': '1.324146448', 'winloss_ratio_last_3month': '1.60134511', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-16 14:00', 'high': 97.1, 'open': 96.57, 'close': 96.2, 'low': 96.1, 'volume': 1558, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 0.83, 'volatility_last_week': 0.011432437, 'volatility_last_month': 0.014333034, 'volatility_last_3months': 0.014887649, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.472222222, 'winloss_ratio_last_week': '2.158239455', 'winloss_ratio_last_month': '1.325857305', 'winloss_ratio_last_3month': '1.599322708', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-16 15:00', 'high': 97.0, 'open': 96.24, 'close': 96.71, 'low': 96.15, 'volume': 1216, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011474583, 'volatility_last_month': 0.014345643, 'volatility_last_3months': 0.014862201, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '2.219902386', 'winloss_ratio_last_month': '1.327568552', 'winloss_ratio_last_3month': '1.59792585', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-16 16:00', 'high': 98.31, 'open': 96.71, 'close': 97.85, 'low': 96.71, 'volume': 3938, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011733132, 'volatility_last_month': 0.014411029, 'volatility_last_3months': 0.014886998, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '2.286035403', 'winloss_ratio_last_month': '1.329280187', 'winloss_ratio_last_3month': '1.596527671', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-17 07:00', 'high': 98.15, 'open': 98.0, 'close': 97.54, 'low': 97.09, 'volume': 511, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011767385, 'volatility_last_month': 0.014414412, 'volatility_last_3months': 0.014883352, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '2.357142857', 'winloss_ratio_last_month': '1.330992213', 'winloss_ratio_last_3month': '1.595128169', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-17 08:00', 'high': 98.45, 'open': 97.6, 'close': 98.28, 'low': 97.55, 'volume': 2068, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011177403, 'volatility_last_month': 0.014439613, 'volatility_last_3months': 0.014884794, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '2.433807937', 'winloss_ratio_last_month': '1.332704627', 'winloss_ratio_last_3month': '1.593727342', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-17 09:00', 'high': 98.35, 'open': 98.29, 'close': 97.36, 'low': 97.11, 'volume': 2037, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011453835, 'volatility_last_month': 0.014501687, 'volatility_last_3months': 0.014893455, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '2.516708701', 'winloss_ratio_last_month': '1.334417432', 'winloss_ratio_last_3month': '1.592325187', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-17 10:00', 'high': 97.5, 'open': 97.39, 'close': 97.15, 'low': 96.4, 'volume': 2235, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011405667, 'volatility_last_month': 0.014496333, 'volatility_last_3months': 0.014877209, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '2.606638186', 'winloss_ratio_last_month': '1.336130627', 'winloss_ratio_last_3month': '1.590921704', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-17 11:00', 'high': 97.7, 'open': 97.15, 'close': 97.37, 'low': 97.15, 'volume': 575, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.01140954, 'volatility_last_month': 0.014484333, 'volatility_last_3months': 0.01487763, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '2.704529856', 'winloss_ratio_last_month': '1.337844212', 'winloss_ratio_last_3month': '1.58951689', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-17 12:00', 'high': 97.39, 'open': 97.39, 'close': 96.16, 'low': 95.99, 'volume': 1818, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011849794, 'volatility_last_month': 0.014577875, 'volatility_last_3months': 0.014907625, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '2.811490126', 'winloss_ratio_last_month': '1.339558187', 'winloss_ratio_last_3month': '1.588110744', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-17 13:00', 'high': 96.3, 'open': 96.16, 'close': 96.06, 'low': 95.53, 'volume': 1692, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011802159, 'volatility_last_month': 0.014573434, 'volatility_last_3months': 0.014906583, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '2.928840361', 'winloss_ratio_last_month': '1.341272553', 'winloss_ratio_last_3month': '1.586703262', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-17 14:00', 'high': 96.57, 'open': 96.11, 'close': 96.13, 'low': 96.0, 'volume': 944, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011794251, 'volatility_last_month': 0.014562572, 'volatility_last_3months': 0.014906529, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '3.058171745', 'winloss_ratio_last_month': '1.342987309', 'winloss_ratio_last_3month': '1.585294445', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-17 15:00', 'high': 96.75, 'open': 96.18, 'close': 96.28, 'low': 96.01, 'volume': 865, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011667828, 'volatility_last_month': 0.014552543, 'volatility_last_3months': 0.014895756, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '3.201417848', 'winloss_ratio_last_month': '1.344702456', 'winloss_ratio_last_3month': '1.583884289', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-17 16:00', 'high': 96.43, 'open': 96.33, 'close': 96.03, 'low': 96.0, 'volume': 1186, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011656878, 'volatility_last_month': 0.014557034, 'volatility_last_3months': 0.014839885, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '3.360951961', 'winloss_ratio_last_month': '1.346417993', 'winloss_ratio_last_3month': '1.582472792', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-20 07:00', 'high': 96.82, 'open': 96.3, 'close': 96.32, 'low': 95.9, 'volume': 493, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011657111, 'volatility_last_month': 0.014536706, 'volatility_last_3months': 0.014840227, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '3.539719626', 'winloss_ratio_last_month': '1.348133921', 'winloss_ratio_last_3month': '1.581059954', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-20 08:00', 'high': 98.25, 'open': 96.32, 'close': 97.35, 'low': 96.1, 'volume': 1890, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011006394, 'volatility_last_month': 0.014559316, 'volatility_last_3months': 0.014857802, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '3.741422178', 'winloss_ratio_last_month': '1.349850241', 'winloss_ratio_last_3month': '1.579645771', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-20 09:00', 'high': 97.7, 'open': 97.3, 'close': 96.5, 'low': 96.5, 'volume': 1898, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011223652, 'volatility_last_month': 0.01460126, 'volatility_last_3months': 0.014869124, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '3.97077577', 'winloss_ratio_last_month': '1.351566952', 'winloss_ratio_last_3month': '1.578230243', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-20 10:00', 'high': 98.32, 'open': 96.64, 'close': 98.21, 'low': 96.64, 'volume': 1524, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011902389, 'volatility_last_month': 0.014760119, 'volatility_last_3months': 0.01492297, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '4.233884769', 'winloss_ratio_last_month': '1.353284054', 'winloss_ratio_last_3month': '1.576813366', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-20 11:00', 'high': 99.61, 'open': 98.29, 'close': 99.22, 'low': 97.69, 'volume': 1938, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.01206449, 'volatility_last_month': 0.014810611, 'volatility_last_3months': 0.014904079, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '4.538793103', 'winloss_ratio_last_month': '1.355001547', 'winloss_ratio_last_3month': '1.575395139', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-20 12:00', 'high': 99.45, 'open': 99.27, 'close': 99.11, 'low': 98.71, 'volume': 1648, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.012056153, 'volatility_last_month': 0.014811974, 'volatility_last_3months': 0.014904265, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '4.89632107', 'winloss_ratio_last_month': '1.356719432', 'winloss_ratio_last_3month': '1.573975561', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-20 13:00', 'high': 99.6, 'open': 99.06, 'close': 99.01, 'low': 98.79, 'volume': 1415, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 1.18, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011860587, 'volatility_last_month': 0.014803643, 'volatility_last_3months': 0.014903831, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '5.321376281', 'winloss_ratio_last_month': '1.358437709', 'winloss_ratio_last_3month': '1.572554629', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-20 14:00', 'high': 99.99, 'open': 99.05, 'close': 99.73, 'low': 98.76, 'volume': 1323, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 1.03, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.011939178, 'volatility_last_month': 0.014825386, 'volatility_last_3months': 0.014912543, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '5.81083266', 'winloss_ratio_last_month': '1.360156377', 'winloss_ratio_last_3month': '1.571132341', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-20 15:00', 'high': 99.78, 'open': 99.68, 'close': 98.73, 'low': 98.51, 'volume': 1612, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.83, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.012256061, 'volatility_last_month': 0.014894121, 'volatility_last_3months': 0.014930743, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '6.378158845', 'winloss_ratio_last_month': '1.361875438', 'winloss_ratio_last_3month': '1.569708696', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-20 16:00', 'high': 99.01, 'open': 98.73, 'close': 98.3, 'low': 97.69, 'volume': 2439, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.64, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.012329703, 'volatility_last_month': 0.01486686, 'volatility_last_3months': 0.014933827, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '7.056179775', 'winloss_ratio_last_month': '1.363594891', 'winloss_ratio_last_3month': '1.568283691', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-21 07:00', 'high': 99.32, 'open': 98.05, 'close': 99.17, 'low': 98.05, 'volume': 954, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.62, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.01245748, 'volatility_last_month': 0.014901331, 'volatility_last_3months': 0.014894226, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '7.935294118', 'winloss_ratio_last_month': '1.365314735', 'winloss_ratio_last_3month': '1.566857325', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-21 08:00', 'high': 99.27, 'open': 99.23, 'close': 97.19, 'low': 96.64, 'volume': 2678, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013399543, 'volatility_last_month': 0.015149959, 'volatility_last_3months': 0.014975828, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '8.957004161', 'winloss_ratio_last_month': '1.367034973', 'winloss_ratio_last_3month': '1.565429595', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-21 09:00', 'high': 98.7, 'open': 97.2, 'close': 98.4, 'low': 96.61, 'volume': 2489, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013631575, 'volatility_last_month': 0.01522156, 'volatility_last_3months': 0.015003443, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '10.42398649', 'winloss_ratio_last_month': '1.368755603', 'winloss_ratio_last_3month': '1.5640005', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-21 10:00', 'high': 100.7, 'open': 98.32, 'close': 99.69, 'low': 98.32, 'volume': 3878, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013739122, 'volatility_last_month': 0.015050816, 'volatility_last_3months': 0.015034069, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '12.70842333', 'winloss_ratio_last_month': '1.370476625', 'winloss_ratio_last_3month': '1.562570037', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-21 11:00', 'high': 100.5, 'open': 99.71, 'close': 100.29, 'low': 99.41, 'volume': 1431, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013069581, 'volatility_last_month': 0.015005724, 'volatility_last_3months': 0.015021855, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '16.75748503', 'winloss_ratio_last_month': '1.37219804', 'winloss_ratio_last_3month': '1.561138205', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-21 12:00', 'high': 101.05, 'open': 100.3, 'close': 100.13, 'low': 99.81, 'volume': 2131, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013018383, 'volatility_last_month': 0.014985959, 'volatility_last_3months': 0.015020994, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '25.90243902', 'winloss_ratio_last_month': '1.373919849', 'winloss_ratio_last_3month': '1.559705002', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-21 13:00', 'high': 101.25, 'open': 100.09, 'close': 101.16, 'low': 99.6, 'volume': 1711, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013139, 'volatility_last_month': 0.015035306, 'volatility_last_3months': 0.015037652, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '67.78947368', 'winloss_ratio_last_month': '1.37564205', 'winloss_ratio_last_3month': '1.558270425', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-21 14:00', 'high': 101.15, 'open': 101.1, 'close': 100.16, 'low': 99.99, 'volume': 1580, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013446386, 'volatility_last_month': 0.015017173, 'volatility_last_3months': 0.015056039, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '192.0769231', 'winloss_ratio_last_month': '1.377364644', 'winloss_ratio_last_3month': '1.556834473', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-21 15:00', 'high': 100.5, 'open': 100.16, 'close': 100.26, 'low': 99.9, 'volume': 1239, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.01335486, 'volatility_last_month': 0.015013268, 'volatility_last_3months': 0.015010103, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.379087632', 'winloss_ratio_last_3month': '1.555397144', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-21 16:00', 'high': 100.55, 'open': 100.25, 'close': 100.12, 'low': 100.02, 'volume': 1470, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013303267, 'volatility_last_month': 0.015016225, 'volatility_last_3months': 0.015010465, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.380811014', 'winloss_ratio_last_3month': '1.553958436', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-22 07:00', 'high': 100.6, 'open': 100.03, 'close': 99.37, 'low': 99.15, 'volume': 848, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013476861, 'volatility_last_month': 0.015057086, 'volatility_last_3months': 0.015016151, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.382534788', 'winloss_ratio_last_3month': '1.552518347', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-22 08:00', 'high': 100.1, 'open': 99.47, 'close': 99.44, 'low': 99.44, 'volume': 890, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013341263, 'volatility_last_month': 0.015057053, 'volatility_last_3months': 0.015015165, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.384258957', 'winloss_ratio_last_3month': '1.551076874', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-22 09:00', 'high': 99.88, 'open': 99.53, 'close': 98.6, 'low': 98.55, 'volume': 1654, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013564248, 'volatility_last_month': 0.015095415, 'volatility_last_3months': 0.015028346, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.38598352', 'winloss_ratio_last_3month': '1.549634016', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-22 10:00', 'high': 99.35, 'open': 98.71, 'close': 98.63, 'low': 98.25, 'volume': 1711, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013529355, 'volatility_last_month': 0.015093265, 'volatility_last_3months': 0.015017223, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.387708476', 'winloss_ratio_last_3month': '1.548189771', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-22 11:00', 'high': 98.95, 'open': 98.69, 'close': 98.65, 'low': 98.34, 'volume': 692, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.01348437, 'volatility_last_month': 0.015090239, 'volatility_last_3months': 0.015014462, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.389433827', 'winloss_ratio_last_3month': '1.546744137', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-22 12:00', 'high': 98.83, 'open': 98.57, 'close': 98.64, 'low': 98.4, 'volume': 732, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.01333562, 'volatility_last_month': 0.015082394, 'volatility_last_3months': 0.015014477, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.391159572', 'winloss_ratio_last_3month': '1.545297111', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-22 13:00', 'high': 98.63, 'open': 98.61, 'close': 98.0, 'low': 97.74, 'volume': 2350, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013397919, 'volatility_last_month': 0.015097139, 'volatility_last_3months': 0.015006941, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.392885711', 'winloss_ratio_last_3month': '1.543848693', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-22 14:00', 'high': 98.33, 'open': 98.02, 'close': 97.45, 'low': 97.34, 'volume': 2474, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013195475, 'volatility_last_month': 0.015010787, 'volatility_last_3months': 0.014999922, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.394612245', 'winloss_ratio_last_3month': '1.542398879', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-22 15:00', 'high': 97.47, 'open': 97.45, 'close': 97.14, 'low': 96.7, 'volume': 2158, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.012954292, 'volatility_last_month': 0.015016783, 'volatility_last_3months': 0.01499878, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.396339173', 'winloss_ratio_last_3month': '1.540947668', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-22 16:00', 'high': 97.22, 'open': 97.2, 'close': 96.3, 'low': 95.95, 'volume': 3008, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013122126, 'volatility_last_month': 0.014889651, 'volatility_last_3months': 0.01501396, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.398066497', 'winloss_ratio_last_3month': '1.539495057', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-23 07:00', 'high': 96.72, 'open': 96.07, 'close': 96.5, 'low': 95.91, 'volume': 1021, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013081761, 'volatility_last_month': 0.014869037, 'volatility_last_3months': 0.015011532, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.399177223', 'winloss_ratio_last_3month': '1.538041046', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-23 08:00', 'high': 97.85, 'open': 96.5, 'close': 97.82, 'low': 96.33, 'volume': 1970, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.01295744, 'volatility_last_month': 0.014828854, 'volatility_last_3months': 0.015044225, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.401772309', 'winloss_ratio_last_3month': '1.536585631', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-23 09:00', 'high': 97.8, 'open': 97.8, 'close': 97.0, 'low': 96.75, 'volume': 1283, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013115087, 'volatility_last_month': 0.014743745, 'volatility_last_3months': 0.015053061, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.40434279', 'winloss_ratio_last_3month': '1.535128811', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-23 10:00', 'high': 97.23, 'open': 97.04, 'close': 96.52, 'low': 96.15, 'volume': 1419, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013168194, 'volatility_last_month': 0.014673678, 'volatility_last_3months': 0.014948356, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.406567427', 'winloss_ratio_last_3month': '1.533670583', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-23 11:00', 'high': 96.8, 'open': 96.52, 'close': 96.66, 'low': 96.3, 'volume': 672, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013172754, 'volatility_last_month': 0.01466494, 'volatility_last_3months': 0.014944467, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.407940592', 'winloss_ratio_last_3month': '1.532210946', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-23 12:00', 'high': 97.25, 'open': 96.62, 'close': 97.0, 'low': 96.32, 'volume': 661, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013104237, 'volatility_last_month': 0.014668003, 'volatility_last_3months': 0.01494593, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.409310496', 'winloss_ratio_last_3month': '1.530749898', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-23 13:00', 'high': 98.17, 'open': 97.0, 'close': 97.82, 'low': 96.6, 'volume': 1334, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013169552, 'volatility_last_month': 0.014693284, 'volatility_last_3months': 0.014954683, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.410677151', 'winloss_ratio_last_3month': '1.529287436', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-23 14:00', 'high': 98.17, 'open': 97.83, 'close': 97.42, 'low': 97.25, 'volume': 1125, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013166544, 'volatility_last_month': 0.01468563, 'volatility_last_3months': 0.014958333, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.412040568', 'winloss_ratio_last_3month': '1.527823558', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-23 15:00', 'high': 97.57, 'open': 97.45, 'close': 97.5, 'low': 96.7, 'volume': 1211, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.0131108, 'volatility_last_month': 0.014620997, 'volatility_last_3months': 0.014918962, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.413400759', 'winloss_ratio_last_3month': '1.526358263', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-23 16:00', 'high': 97.79, 'open': 97.53, 'close': 96.69, 'low': 96.61, 'volume': 2018, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.012957771, 'volatility_last_month': 0.01466509, 'volatility_last_3months': 0.014819558, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.414757734', 'winloss_ratio_last_3month': '1.524891548', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-24 07:00', 'high': 98.24, 'open': 97.09, 'close': 97.96, 'low': 96.83, 'volume': 1340, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013314456, 'volatility_last_month': 0.014757834, 'volatility_last_3months': 0.014849298, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.416111506', 'winloss_ratio_last_3month': '1.523423412', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-24 08:00', 'high': 98.21, 'open': 97.97, 'close': 97.92, 'low': 97.05, 'volume': 1792, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.82, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013190898, 'volatility_last_month': 0.014757374, 'volatility_last_3months': 0.014789945, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.417462086', 'winloss_ratio_last_3month': '1.521953851', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-24 09:00', 'high': 98.87, 'open': 97.91, 'close': 98.58, 'low': 97.85, 'volume': 2759, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.97, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013089723, 'volatility_last_month': 0.01477763, 'volatility_last_3months': 0.014798014, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.642857143, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.419496449', 'winloss_ratio_last_3month': '1.520482865', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-24 10:00', 'high': 100.2, 'open': 98.58, 'close': 99.99, 'low': 98.58, 'volume': 2860, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.01, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013487471, 'volatility_last_month': 0.014826936, 'volatility_last_3months': 0.014830781, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.615384615, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.418431366', 'winloss_ratio_last_3month': '1.519010451', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-24 11:00', 'high': 99.95, 'open': 99.95, 'close': 99.33, 'low': 98.72, 'volume': 997, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.22, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013588314, 'volatility_last_month': 0.014851959, 'volatility_last_3months': 0.014825729, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.41832785', 'winloss_ratio_last_3month': '1.517536607', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-24 12:00', 'high': 99.74, 'open': 99.4, 'close': 98.13, 'low': 98.0, 'volume': 876, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 2.04, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013569402, 'volatility_last_month': 0.014891142, 'volatility_last_3months': 0.014784719, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.727272727, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.421985414', 'winloss_ratio_last_3month': '1.51606133', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-24 13:00', 'high': 98.67, 'open': 98.15, 'close': 98.5, 'low': 98.0, 'volume': 697, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '5', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.58, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013587902, 'volatility_last_month': 0.014884745, 'volatility_last_3months': 0.014662683, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.424170693', 'winloss_ratio_last_3month': '1.51458462', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-24 14:00', 'high': 98.8, 'open': 98.55, 'close': 98.17, 'low': 97.65, 'volume': 946, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 0.0, 'pnl_last_month': 1.58, 'pnl_last_3months': 1.75, 'volatility_last_week': 0.013618592, 'volatility_last_month': 0.014892184, 'volatility_last_3months': 0.01461348, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.7, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.426367553', 'winloss_ratio_last_3month': '1.513106473', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-24 15:00', 'high': 98.75, 'open': 98.19, 'close': 97.72, 'low': 97.65, 'volume': 836, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 0.45, 'pnl_last_month': 2.03, 'pnl_last_3months': 2.2, 'volatility_last_week': 0.013667332, 'volatility_last_month': 0.014868209, 'volatility_last_3months': 0.014618083, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.727272727, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '1.429309558', 'winloss_ratio_last_3month': '1.511725513', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-24 16:00', 'high': 97.98, 'open': 97.74, 'close': 97.85, 'low': 97.1, 'volume': 1809, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 0.32, 'pnl_last_month': 1.9, 'pnl_last_3months': 2.07, 'volatility_last_week': 0.013651235, 'volatility_last_month': 0.014862082, 'volatility_last_3months': 0.014616024, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.486486486, 'winloss_ratio_last_week': '94.46153846', 'winloss_ratio_last_month': '1.431963008', 'winloss_ratio_last_3month': '1.510300157', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-27 07:00', 'high': 98.22, 'open': 97.55, 'close': 97.84, 'low': 97.36, 'volume': 596, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': 0.33, 'pnl_last_month': 1.91, 'pnl_last_3months': 2.08, 'volatility_last_week': 0.013636597, 'volatility_last_month': 0.014860371, 'volatility_last_3months': 0.014613585, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.692307692, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '44.46153846', 'winloss_ratio_last_month': '1.434645876', 'winloss_ratio_last_3month': '1.508875688', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-27 08:00', 'high': 98.91, 'open': 97.86, 'close': 98.33, 'low': 97.73, 'volume': 1072, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': -0.16, 'pnl_last_month': 1.42, 'pnl_last_3months': 1.59, 'volatility_last_week': 0.013455268, 'volatility_last_month': 0.014871242, 'volatility_last_3months': 0.014614746, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.642857143, 'win_ratio_last_3month': 0.487179487, 'winloss_ratio_last_week': '12.31818182', 'winloss_ratio_last_month': '1.436186445', 'winloss_ratio_last_3month': '1.507287817', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-27 09:00', 'high': 99.28, 'open': 98.32, 'close': 98.94, 'low': 98.31, 'volume': 2536, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '5', 'operations_last_3_months': '29', 'pnl_last_week': -0.77, 'pnl_last_month': 0.81, 'pnl_last_3months': 0.98, 'volatility_last_week': 0.013349706, 'volatility_last_month': 0.014710032, 'volatility_last_3months': 0.014621565, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '4.796208531', 'winloss_ratio_last_month': '1.436292295', 'winloss_ratio_last_3month': '1.505497231', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-27 10:00', 'high': 99.0, 'open': 98.92, 'close': 98.79, 'low': 98.34, 'volume': 1298, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': -0.77, 'pnl_last_month': 0.81, 'pnl_last_3months': 0.98, 'volatility_last_week': 0.012698592, 'volatility_last_month': 0.014692858, 'volatility_last_3months': 0.014619041, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '2.814371257', 'winloss_ratio_last_month': '1.436398285', 'winloss_ratio_last_3month': '1.503705867', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-27 11:00', 'high': 99.39, 'open': 98.79, 'close': 99.31, 'low': 98.71, 'volume': 1151, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': -0.77, 'pnl_last_month': 0.81, 'pnl_last_3months': 0.98, 'volatility_last_week': 0.012524014, 'volatility_last_month': 0.014618745, 'volatility_last_3months': 0.014620313, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.899343545', 'winloss_ratio_last_month': '1.436504414', 'winloss_ratio_last_3month': '1.501913723', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-27 12:00', 'high': 99.49, 'open': 99.21, 'close': 99.06, 'low': 98.5, 'volume': 887, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': -0.77, 'pnl_last_month': 0.81, 'pnl_last_3months': 0.98, 'volatility_last_week': 0.012535814, 'volatility_last_month': 0.014614858, 'volatility_last_3months': 0.014609134, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.372413793', 'winloss_ratio_last_month': '1.436610683', 'winloss_ratio_last_3month': '1.500120801', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-27 13:00', 'high': 99.19, 'open': 99.06, 'close': 99.0, 'low': 98.4, 'volume': 1061, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': -0.77, 'pnl_last_month': 0.81, 'pnl_last_3months': 0.98, 'volatility_last_week': 0.012534328, 'volatility_last_month': 0.014614602, 'volatility_last_3months': 0.014605453, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '1.029871977', 'winloss_ratio_last_month': '1.436717091', 'winloss_ratio_last_3month': '1.498327098', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-27 14:00', 'high': 99.41, 'open': 98.95, 'close': 99.02, 'low': 98.89, 'volume': 1489, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': -0.77, 'pnl_last_month': 0.81, 'pnl_last_3months': 0.98, 'volatility_last_week': 0.012410839, 'volatility_last_month': 0.014613314, 'volatility_last_3months': 0.014597532, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '0.807506053', 'winloss_ratio_last_month': '1.43682364', 'winloss_ratio_last_3month': '1.496532615', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-27 15:00', 'high': 100.64, 'open': 99.03, 'close': 99.97, 'low': 98.82, 'volume': 3285, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': -0.77, 'pnl_last_month': 0.81, 'pnl_last_3months': 0.98, 'volatility_last_week': 0.012381834, 'volatility_last_month': 0.014658989, 'volatility_last_3months': 0.014614087, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '0.663856691', 'winloss_ratio_last_month': '1.436930329', 'winloss_ratio_last_3month': '1.494737351', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-27 16:00', 'high': 100.49, 'open': 100.0, 'close': 100.29, 'low': 99.96, 'volume': 2186, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': -0.77, 'pnl_last_month': 0.81, 'pnl_last_3months': 0.98, 'volatility_last_week': 0.012349796, 'volatility_last_month': 0.014662706, 'volatility_last_3months': 0.014612501, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '0.570895522', 'winloss_ratio_last_month': '1.437037159', 'winloss_ratio_last_3month': '1.492941306', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-28 07:00', 'high': 100.5, 'open': 100.06, 'close': 99.74, 'low': 99.68, 'volume': 912, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': -0.77, 'pnl_last_month': 0.81, 'pnl_last_3months': 0.98, 'volatility_last_week': 0.012256101, 'volatility_last_month': 0.014678621, 'volatility_last_3months': 0.014580436, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '0.49874477', 'winloss_ratio_last_month': '1.43714413', 'winloss_ratio_last_3month': '1.491144479', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-28 08:00', 'high': 100.09, 'open': 99.76, 'close': 99.56, 'low': 99.0, 'volume': 1504, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': -0.77, 'pnl_last_month': 0.81, 'pnl_last_3months': 0.98, 'volatility_last_week': 0.011240154, 'volatility_last_month': 0.01468165, 'volatility_last_3months': 0.014532443, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '0.487101669', 'winloss_ratio_last_month': '1.437251243', 'winloss_ratio_last_3month': '1.489346869', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-28 09:00', 'high': 99.71, 'open': 99.46, 'close': 99.31, 'low': 99.15, 'volume': 1735, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '30', 'pnl_last_week': -0.77, 'pnl_last_month': 0.81, 'pnl_last_3months': 0.98, 'volatility_last_week': 0.010883851, 'volatility_last_month': 0.014681857, 'volatility_last_3months': 0.014527495, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '0.477446218', 'winloss_ratio_last_month': '1.437358497', 'winloss_ratio_last_3month': '1.487548476', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-28 10:00', 'high': 100.79, 'open': 99.33, 'close': 99.99, 'low': 99.33, 'volume': 2524, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '6', 'operations_last_3_months': '29', 'pnl_last_week': -0.77, 'pnl_last_month': 0.81, 'pnl_last_3months': 0.98, 'volatility_last_week': 0.01055729, 'volatility_last_month': 0.014701303, 'volatility_last_3months': 0.014535502, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '0.469309463', 'winloss_ratio_last_month': '1.437465893', 'winloss_ratio_last_3month': '1.4857493', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-28 11:00', 'high': 100.15, 'open': 99.91, 'close': 99.93, 'low': 99.7, 'volume': 622, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '30', 'pnl_last_week': -0.77, 'pnl_last_month': 0.81, 'pnl_last_3months': 0.98, 'volatility_last_week': 0.010459379, 'volatility_last_month': 0.014701446, 'volatility_last_3months': 0.014530032, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.475, 'winloss_ratio_last_week': '0.462359218', 'winloss_ratio_last_month': '1.437573431', 'winloss_ratio_last_3month': '1.48394934', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-28 12:00', 'high': 99.93, 'open': 99.93, 'close': 99.36, 'low': 99.29, 'volume': 1212, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '30', 'pnl_last_week': -1.34, 'pnl_last_month': 0.24, 'pnl_last_3months': 0.41, 'volatility_last_week': 0.010539583, 'volatility_last_month': 0.014406258, 'volatility_last_3months': 0.01452305, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.5625, 'win_ratio_last_3month': 0.463414634, 'winloss_ratio_last_week': '0.442420996', 'winloss_ratio_last_month': '1.436325437', 'winloss_ratio_last_3month': '1.48196266', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-28 13:00', 'high': 100.1, 'open': 99.32, 'close': 99.99, 'low': 99.32, 'volume': 952, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '30', 'pnl_last_week': -0.71, 'pnl_last_month': 0.87, 'pnl_last_3months': 1.04, 'volatility_last_week': 0.010356826, 'volatility_last_month': 0.014412224, 'volatility_last_3months': 0.014528549, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.588235294, 'win_ratio_last_3month': 0.476190476, 'winloss_ratio_last_week': '0.456765999', 'winloss_ratio_last_month': '1.436120069', 'winloss_ratio_last_3month': '1.48011428', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-28 14:00', 'high': 100.2, 'open': 100.02, 'close': 100.09, 'low': 99.75, 'volume': 1048, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '30', 'pnl_last_week': -0.61, 'pnl_last_month': 0.97, 'pnl_last_3months': 1.14, 'volatility_last_week': 0.010089198, 'volatility_last_month': 0.01440859, 'volatility_last_3months': 0.014528463, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.611111111, 'win_ratio_last_3month': 0.488372093, 'winloss_ratio_last_week': '0.473282443', 'winloss_ratio_last_month': '1.436080156', 'winloss_ratio_last_3month': '1.478287572', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-28 15:00', 'high': 100.42, 'open': 100.12, 'close': 99.89, 'low': 99.83, 'volume': 1093, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '30', 'pnl_last_week': -0.81, 'pnl_last_month': 0.77, 'pnl_last_3months': 0.94, 'volatility_last_week': 0.010097021, 'volatility_last_month': 0.014396753, 'volatility_last_3months': 0.014527557, 'win_ratio_last_week': 0.444444444, 'win_ratio_last_month': 0.578947368, 'win_ratio_last_3month': 0.477272727, 'winloss_ratio_last_week': '0.483312732', 'winloss_ratio_last_month': '1.43556569', 'winloss_ratio_last_3month': '1.476395521', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-02-28 16:00', 'high': 100.05, 'open': 99.89, 'close': 99.54, 'low': 99.03, 'volume': 3464, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '7', 'operations_last_3_months': '29', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.010125407, 'volatility_last_month': 0.014379992, 'volatility_last_3months': 0.014529486, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.485349361', 'winloss_ratio_last_month': '1.434222904', 'winloss_ratio_last_3month': '1.47438968', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-01 07:00', 'high': 99.79, 'open': 99.73, 'close': 98.65, 'low': 98.44, 'volume': 1166, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.010192717, 'volatility_last_month': 0.0144277, 'volatility_last_3months': 0.014544752, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.487055575', 'winloss_ratio_last_month': '1.432883306', 'winloss_ratio_last_3month': '1.472383954', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-01 08:00', 'high': 99.26, 'open': 98.66, 'close': 98.96, 'low': 98.0, 'volume': 2020, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.010220757, 'volatility_last_month': 0.014428326, 'volatility_last_3months': 0.014523808, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.488505747', 'winloss_ratio_last_month': '1.431546884', 'winloss_ratio_last_3month': '1.470378344', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-01 09:00', 'high': 99.65, 'open': 98.91, 'close': 99.6, 'low': 98.58, 'volume': 1748, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.010130254, 'volatility_last_month': 0.014050919, 'volatility_last_3months': 0.014531492, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.48975349', 'winloss_ratio_last_month': '1.430213627', 'winloss_ratio_last_3month': '1.468372848', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-01 10:00', 'high': 99.67, 'open': 99.66, 'close': 97.5, 'low': 97.38, 'volume': 3148, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.01134626, 'volatility_last_month': 0.014214327, 'volatility_last_3months': 0.014539912, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.490838423', 'winloss_ratio_last_month': '1.428883523', 'winloss_ratio_last_3month': '1.466367466', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-01 11:00', 'high': 97.97, 'open': 97.49, 'close': 97.7, 'low': 97.22, 'volume': 1640, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.011358795, 'volatility_last_month': 0.014055072, 'volatility_last_3months': 0.014537022, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.491790461', 'winloss_ratio_last_month': '1.427556562', 'winloss_ratio_last_3month': '1.4643622', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-01 12:00', 'high': 98.2, 'open': 97.61, 'close': 98.16, 'low': 97.25, 'volume': 741, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.011418098, 'volatility_last_month': 0.014053137, 'volatility_last_3months': 0.014540187, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.492632613', 'winloss_ratio_last_month': '1.426232732', 'winloss_ratio_last_3month': '1.462357048', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-01 13:00', 'high': 98.26, 'open': 98.19, 'close': 97.25, 'low': 97.0, 'volume': 1307, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.011527723, 'volatility_last_month': 0.014107381, 'volatility_last_3months': 0.014543884, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.493382865', 'winloss_ratio_last_month': '1.424912022', 'winloss_ratio_last_3month': '1.460352011', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-01 14:00', 'high': 97.43, 'open': 97.25, 'close': 96.93, 'low': 96.42, 'volume': 2054, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '29', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.011477136, 'volatility_last_month': 0.014043545, 'volatility_last_3months': 0.014542072, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.494055482', 'winloss_ratio_last_month': '1.423594422', 'winloss_ratio_last_3month': '1.458347089', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-01 15:00', 'high': 97.48, 'open': 97.01, 'close': 96.9, 'low': 96.61, 'volume': 1398, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '29', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.011453006, 'volatility_last_month': 0.013980941, 'volatility_last_3months': 0.014540976, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.494661922', 'winloss_ratio_last_month': '1.42227992', 'winloss_ratio_last_3month': '1.456342281', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-01 16:00', 'high': 97.28, 'open': 96.9, 'close': 97.11, 'low': 96.68, 'volume': 1731, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '29', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.011270443, 'volatility_last_month': 0.013942774, 'volatility_last_3months': 0.01454074, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.495211492', 'winloss_ratio_last_month': '1.420968505', 'winloss_ratio_last_3month': '1.454337588', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-02 07:00', 'high': 97.96, 'open': 96.95, 'close': 97.62, 'low': 96.93, 'volume': 779, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.011326198, 'volatility_last_month': 0.013938669, 'volatility_last_3months': 0.014531331, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.495711835', 'winloss_ratio_last_month': '1.419660166', 'winloss_ratio_last_3month': '1.45233301', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-02 08:00', 'high': 98.41, 'open': 97.63, 'close': 97.72, 'low': 97.61, 'volume': 2143, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.010856495, 'volatility_last_month': 0.013926025, 'volatility_last_3months': 0.014531244, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.496169281', 'winloss_ratio_last_month': '1.418354894', 'winloss_ratio_last_3month': '1.450328546', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-02 09:00', 'high': 97.82, 'open': 97.72, 'close': 96.76, 'low': 96.71, 'volume': 2070, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.010926572, 'volatility_last_month': 0.013987121, 'volatility_last_3months': 0.014547449, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.49658912', 'winloss_ratio_last_month': '1.417052676', 'winloss_ratio_last_3month': '1.448324198', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-02 10:00', 'high': 97.11, 'open': 96.89, 'close': 96.09, 'low': 95.44, 'volume': 3339, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.010988065, 'volatility_last_month': 0.013988799, 'volatility_last_3months': 0.014428028, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.496975806', 'winloss_ratio_last_month': '1.415753502', 'winloss_ratio_last_3month': '1.446319963', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-02 11:00', 'high': 96.39, 'open': 96.12, 'close': 96.1, 'low': 95.6, 'volume': 935, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.010981861, 'volatility_last_month': 0.013982748, 'volatility_last_3months': 0.014421988, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.497333118', 'winloss_ratio_last_month': '1.414457362', 'winloss_ratio_last_3month': '1.444333447', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-02 12:00', 'high': 96.0, 'open': 96.0, 'close': 95.2, 'low': 94.93, 'volume': 1995, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '27', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.011163579, 'volatility_last_month': 0.013997654, 'volatility_last_3months': 0.014432982, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.497664279', 'winloss_ratio_last_month': '1.413164245', 'winloss_ratio_last_3month': '1.442351445', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-02 13:00', 'high': 95.4, 'open': 95.2, 'close': 93.74, 'low': 93.52, 'volume': 3154, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '27', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.011515858, 'volatility_last_month': 0.013719968, 'volatility_last_3months': 0.01447526, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.497972059', 'winloss_ratio_last_month': '1.411874141', 'winloss_ratio_last_3month': '1.440745801', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-02 14:00', 'high': 94.27, 'open': 93.75, 'close': 93.97, 'low': 93.75, 'volume': 1683, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '8', 'operations_last_3_months': '27', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.01151465, 'volatility_last_month': 0.0137103, 'volatility_last_3months': 0.014475602, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.498258851', 'winloss_ratio_last_month': '1.410587038', 'winloss_ratio_last_3month': '1.439232658', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-02 15:00', 'high': 94.02, 'open': 94.0, 'close': 93.3, 'low': 93.25, 'volume': 2310, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 5, 'operations_last_month': '9', 'operations_last_3_months': '28', 'pnl_last_week': -1.16, 'pnl_last_month': 0.42, 'pnl_last_3months': 0.59, 'volatility_last_week': 0.011608687, 'volatility_last_month': 0.013741175, 'volatility_last_3months': 0.014480644, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.466666667, 'winloss_ratio_last_week': '0.498526729', 'winloss_ratio_last_month': '1.409302926', 'winloss_ratio_last_3month': '1.437710112', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-02 16:00', 'high': 93.76, 'open': 93.29, 'close': 93.0, 'low': 92.9, 'volume': 2742, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 5, 'operations_last_month': '9', 'operations_last_3_months': '28', 'pnl_last_week': -0.86, 'pnl_last_month': 0.72, 'pnl_last_3months': 0.89, 'volatility_last_week': 0.011482149, 'volatility_last_month': 0.013698656, 'volatility_last_3months': 0.014482108, 'win_ratio_last_week': 0.454545455, 'win_ratio_last_month': 0.571428571, 'win_ratio_last_3month': 0.47826087, 'winloss_ratio_last_week': '0.502852486', 'winloss_ratio_last_month': '1.408505425', 'winloss_ratio_last_3month': '1.436253674', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-03 07:00', 'high': 93.51, 'open': 93.0, 'close': 92.82, 'low': 92.25, 'volume': 1283, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 5, 'operations_last_month': '9', 'operations_last_3_months': '28', 'pnl_last_week': -0.68, 'pnl_last_month': 0.9, 'pnl_last_3months': 1.07, 'volatility_last_week': 0.010983868, 'volatility_last_month': 0.013692371, 'volatility_last_3months': 0.014482404, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.590909091, 'win_ratio_last_3month': 0.489361702, 'winloss_ratio_last_week': '0.509279979', 'winloss_ratio_last_month': '1.407999614', 'winloss_ratio_last_3month': '1.434836938', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-03 08:00', 'high': 93.49, 'open': 92.79, 'close': 93.27, 'low': 92.66, 'volume': 2006, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 5, 'operations_last_month': '9', 'operations_last_3_months': '27', 'pnl_last_week': -1.13, 'pnl_last_month': 0.45, 'pnl_last_3months': 0.62, 'volatility_last_week': 0.011072608, 'volatility_last_month': 0.013699977, 'volatility_last_3months': 0.014484874, 'win_ratio_last_week': 0.461538462, 'win_ratio_last_month': 0.565217391, 'win_ratio_last_3month': 0.479166667, 'winloss_ratio_last_week': '0.512377809', 'winloss_ratio_last_month': '1.406477017', 'winloss_ratio_last_3month': '1.433278415', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-03 09:00', 'high': 93.48, 'open': 93.26, 'close': 92.39, 'low': 92.13, 'volume': 2496, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 5, 'operations_last_month': '9', 'operations_last_3_months': '27', 'pnl_last_week': -0.25, 'pnl_last_month': 1.33, 'pnl_last_3months': 1.5, 'volatility_last_week': 0.011093323, 'volatility_last_month': 0.013753369, 'volatility_last_3months': 0.014483827, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.489795918, 'winloss_ratio_last_week': '0.526051244', 'winloss_ratio_last_month': '1.406371818', 'winloss_ratio_last_3month': '1.431913855', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-03 10:00', 'high': 92.77, 'open': 92.39, 'close': 92.22, 'low': 91.54, 'volume': 2880, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 5, 'operations_last_month': '9', 'operations_last_3_months': '27', 'pnl_last_week': -0.08, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010437729, 'volatility_last_month': 0.013691096, 'volatility_last_3months': 0.014420644, 'win_ratio_last_week': 0.533333333, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.540832049', 'winloss_ratio_last_month': '1.406539213', 'winloss_ratio_last_3month': '1.430587045', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-03 11:00', 'high': 92.56, 'open': 92.26, 'close': 92.54, 'low': 92.17, 'volume': 586, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': -0.08, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010434261, 'volatility_last_month': 0.013691245, 'volatility_last_3months': 0.014421199, 'win_ratio_last_week': 0.533333333, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.554663302', 'winloss_ratio_last_month': '1.406705982', 'winloss_ratio_last_3month': '1.429260592', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-03 12:00', 'high': 92.83, 'open': 92.51, 'close': 92.1, 'low': 92.1, 'volume': 1414, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': -0.08, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010139787, 'volatility_last_month': 0.013676574, 'volatility_last_3months': 0.014423668, 'win_ratio_last_week': 0.533333333, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.567633656', 'winloss_ratio_last_month': '1.406872129', 'winloss_ratio_last_3month': '1.427934494', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-03 13:00', 'high': 92.66, 'open': 92.1, 'close': 92.52, 'low': 92.1, 'volume': 1606, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 6, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': -0.08, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.01016396, 'volatility_last_month': 0.013583094, 'volatility_last_3months': 0.014411301, 'win_ratio_last_week': 0.533333333, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.579821063', 'winloss_ratio_last_month': '1.407037656', 'winloss_ratio_last_3month': '1.426608751', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-03 14:00', 'high': 92.99, 'open': 92.51, 'close': 92.78, 'low': 92.21, 'volume': 1342, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': -0.08, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010195905, 'volatility_last_month': 0.013585372, 'volatility_last_3months': 0.014408659, 'win_ratio_last_week': 0.533333333, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.591294339', 'winloss_ratio_last_month': '1.407202568', 'winloss_ratio_last_3month': '1.425283365', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-03 15:00', 'high': 92.74, 'open': 92.74, 'close': 92.21, 'low': 92.16, 'volume': 1336, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': -0.53, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010233338, 'volatility_last_month': 0.013609041, 'volatility_last_3months': 0.014411825, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.592965335', 'winloss_ratio_last_month': '1.407366868', 'winloss_ratio_last_3month': '1.423958333', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-03 16:00', 'high': 92.43, 'open': 92.22, 'close': 92.27, 'low': 92.04, 'volume': 1805, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': -0.4, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.01022509, 'volatility_last_month': 0.013607695, 'volatility_last_3months': 0.014409051, 'win_ratio_last_week': 0.538461538, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.596075711', 'winloss_ratio_last_month': '1.407530559', 'winloss_ratio_last_3month': '1.422633657', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-06 07:00', 'high': 92.95, 'open': 92.36, 'close': 92.7, 'low': 92.2, 'volume': 526, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': -0.41, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010315706, 'volatility_last_month': 0.013610522, 'volatility_last_3months': 0.014410384, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.598840019', 'winloss_ratio_last_month': '1.407693645', 'winloss_ratio_last_3month': '1.421309336', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-06 08:00', 'high': 93.9, 'open': 92.7, 'close': 93.53, 'low': 92.7, 'volume': 1857, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.08, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010489155, 'volatility_last_month': 0.013604575, 'volatility_last_3months': 0.014425282, 'win_ratio_last_week': 0.545454545, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.60708504', 'winloss_ratio_last_month': '1.407856129', 'winloss_ratio_last_3month': '1.41998537', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-06 09:00', 'high': 94.1, 'open': 93.52, 'close': 93.94, 'low': 93.25, 'volume': 1439, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.69, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010426353, 'volatility_last_month': 0.013578946, 'volatility_last_3months': 0.014428105, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.622211675', 'winloss_ratio_last_month': '1.408018014', 'winloss_ratio_last_3month': '1.418661758', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-06 10:00', 'high': 94.63, 'open': 94.0, 'close': 93.71, 'low': 93.71, 'volume': 1387, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.69, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010431191, 'volatility_last_month': 0.013508378, 'volatility_last_3months': 0.01442939, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.637240988', 'winloss_ratio_last_month': '1.408179303', 'winloss_ratio_last_3month': '1.417338502', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-06 11:00', 'high': 94.08, 'open': 93.72, 'close': 93.64, 'low': 93.51, 'volume': 1062, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.69, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010319085, 'volatility_last_month': 0.01350471, 'volatility_last_3months': 0.014428971, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.652173913', 'winloss_ratio_last_month': '1.408340001', 'winloss_ratio_last_3month': '1.416015599', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-06 12:00', 'high': 93.57, 'open': 93.57, 'close': 92.71, 'low': 92.71, 'volume': 1754, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.69, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010525273, 'volatility_last_month': 0.013466447, 'volatility_last_3months': 0.014379345, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.667011375', 'winloss_ratio_last_month': '1.40850011', 'winloss_ratio_last_3month': '1.414693051', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-06 13:00', 'high': 93.01, 'open': 92.71, 'close': 92.76, 'low': 92.4, 'volume': 1363, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.69, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010533292, 'volatility_last_month': 0.013278803, 'volatility_last_3months': 0.014362874, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.681754287', 'winloss_ratio_last_month': '1.408659633', 'winloss_ratio_last_3month': '1.413370858', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-06 14:00', 'high': 92.96, 'open': 92.64, 'close': 92.23, 'low': 92.09, 'volume': 1501, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.69, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010578693, 'volatility_last_month': 0.013241067, 'volatility_last_3months': 0.014364107, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.69640355', 'winloss_ratio_last_month': '1.408818573', 'winloss_ratio_last_3month': '1.412049018', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-06 15:00', 'high': 93.18, 'open': 92.17, 'close': 92.88, 'low': 92.15, 'volume': 1175, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.69, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010444021, 'volatility_last_month': 0.01324971, 'volatility_last_3months': 0.014366812, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.710960052', 'winloss_ratio_last_month': '1.408976935', 'winloss_ratio_last_3month': '1.410727532', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-06 16:00', 'high': 93.27, 'open': 92.89, 'close': 92.95, 'low': 92.7, 'volume': 1132, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.69, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010397169, 'volatility_last_month': 0.013233171, 'volatility_last_3months': 0.014366765, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.725424673', 'winloss_ratio_last_month': '1.40913472', 'winloss_ratio_last_3month': '1.4094064', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-07 07:00', 'high': 93.86, 'open': 93.23, 'close': 93.59, 'low': 92.8, 'volume': 574, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.69, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010538495, 'volatility_last_month': 0.0132427, 'volatility_last_3months': 0.014375906, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.739798279', 'winloss_ratio_last_month': '1.409291932', 'winloss_ratio_last_3month': '1.408085622', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-07 08:00', 'high': 94.25, 'open': 93.62, 'close': 94.2, 'low': 93.55, 'volume': 1749, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.69, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010697715, 'volatility_last_month': 0.013234755, 'volatility_last_3months': 0.014367929, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.754081727', 'winloss_ratio_last_month': '1.409448575', 'winloss_ratio_last_3month': '1.406765197', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-07 09:00', 'high': 95.32, 'open': 94.2, 'close': 95.08, 'low': 94.18, 'volume': 2608, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.69, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010972812, 'volatility_last_month': 0.013289093, 'volatility_last_3months': 0.014383265, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.768275862', 'winloss_ratio_last_month': '1.409604651', 'winloss_ratio_last_3month': '1.405445125', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-07 10:00', 'high': 95.11, 'open': 95.08, 'close': 94.89, 'low': 94.74, 'volume': 1680, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.69, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010815555, 'volatility_last_month': 0.013291799, 'volatility_last_3months': 0.014360162, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.78238152', 'winloss_ratio_last_month': '1.409760163', 'winloss_ratio_last_3month': '1.404125407', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-07 11:00', 'high': 95.03, 'open': 94.9, 'close': 95.0, 'low': 94.74, 'volume': 520, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.69, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010827805, 'volatility_last_month': 0.013291433, 'volatility_last_3months': 0.01435966, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.796399525', 'winloss_ratio_last_month': '1.409915115', 'winloss_ratio_last_3month': '1.402806042', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-07 12:00', 'high': 95.88, 'open': 94.98, 'close': 95.73, 'low': 94.77, 'volume': 2131, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '28', 'pnl_last_week': 1.26, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.010958318, 'volatility_last_month': 0.013312388, 'volatility_last_3months': 0.014370659, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.818834576', 'winloss_ratio_last_month': '1.410069509', 'winloss_ratio_last_3month': '1.40148703', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-07 13:00', 'high': 96.86, 'open': 95.78, 'close': 96.62, 'low': 95.76, 'volume': 2467, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '28', 'pnl_last_week': 0.63, 'pnl_last_month': 0.21, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.011088642, 'volatility_last_month': 0.013222938, 'volatility_last_3months': 0.014379524, 'win_ratio_last_week': 0.625, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.829917463', 'winloss_ratio_last_month': '1.406262952', 'winloss_ratio_last_3month': '1.40016837', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-07 14:00', 'high': 96.85, 'open': 96.62, 'close': 96.4, 'low': 95.75, 'volume': 2034, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '28', 'pnl_last_week': 0.53, 'pnl_last_month': 1.0, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.011087305, 'volatility_last_month': 0.013180603, 'volatility_last_3months': 0.014366347, 'win_ratio_last_week': 0.571428571, 'win_ratio_last_month': 0.608695652, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.839297393', 'winloss_ratio_last_month': '1.405873679', 'winloss_ratio_last_3month': '1.398850063', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-07 15:00', 'high': 96.43, 'open': 96.38, 'close': 95.77, 'low': 95.64, 'volume': 1228, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '28', 'pnl_last_week': 0.73, 'pnl_last_month': 1.24, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.011169416, 'volatility_last_month': 0.013208146, 'volatility_last_3months': 0.014372899, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.636363636, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.852026835', 'winloss_ratio_last_month': '1.406521572', 'winloss_ratio_last_3month': '1.397532109', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-07 16:00', 'high': 96.41, 'open': 95.79, 'close': 95.94, 'low': 95.6, 'volume': 1957, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '28', 'pnl_last_week': 1.08, 'pnl_last_month': 1.5, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.011167272, 'volatility_last_month': 0.013205196, 'volatility_last_3months': 0.014372467, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.666666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.870874627', 'winloss_ratio_last_month': '1.408299165', 'winloss_ratio_last_3month': '1.396214507', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-08 07:00', 'high': 96.82, 'open': 95.98, 'close': 96.24, 'low': 95.9, 'volume': 995, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '28', 'pnl_last_week': 1.08, 'pnl_last_month': 1.1, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.01102187, 'volatility_last_month': 0.013195603, 'volatility_last_3months': 0.014369425, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.65, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.890424385', 'winloss_ratio_last_month': '1.408848655', 'winloss_ratio_last_3month': '1.394897257', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-08 08:00', 'high': 98.75, 'open': 96.23, 'close': 98.32, 'low': 96.2, 'volume': 3039, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': 1.08, 'pnl_last_month': 1.1, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.012161659, 'volatility_last_month': 0.013439505, 'volatility_last_3months': 0.01445367, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.65, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.910716069', 'winloss_ratio_last_month': '1.409400545', 'winloss_ratio_last_3month': '1.393580359', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-08 09:00', 'high': 99.0, 'open': 98.32, 'close': 97.84, 'low': 97.38, 'volume': 2754, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': 1.08, 'pnl_last_month': 1.1, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.012106782, 'volatility_last_month': 0.013416283, 'volatility_last_3months': 0.014451556, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.65, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.931792731', 'winloss_ratio_last_month': '1.409954849', 'winloss_ratio_last_3month': '1.392263814', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-08 10:00', 'high': 98.71, 'open': 97.84, 'close': 98.56, 'low': 97.7, 'volume': 1456, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': 1.08, 'pnl_last_month': 1.1, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.011124099, 'volatility_last_month': 0.013447708, 'volatility_last_3months': 0.014444269, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.65, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.95370082', 'winloss_ratio_last_month': '1.410511585', 'winloss_ratio_last_3month': '1.39094762', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-08 11:00', 'high': 98.85, 'open': 98.64, 'close': 98.42, 'low': 98.31, 'volume': 686, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': 1.08, 'pnl_last_month': 1.1, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.011121721, 'volatility_last_month': 0.013364717, 'volatility_last_3months': 0.014442576, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.65, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.976490522', 'winloss_ratio_last_month': '1.411070766', 'winloss_ratio_last_3month': '1.389631777', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-08 12:00', 'high': 98.4, 'open': 98.35, 'close': 97.02, 'low': 97.02, 'volume': 1799, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': 1.08, 'pnl_last_month': 1.1, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.011582299, 'volatility_last_month': 0.013416518, 'volatility_last_3months': 0.014475221, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.65, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.000216146', 'winloss_ratio_last_month': '1.411632411', 'winloss_ratio_last_3month': '1.388316287', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-08 13:00', 'high': 97.58, 'open': 97.02, 'close': 97.07, 'low': 96.51, 'volume': 1561, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': 1.08, 'pnl_last_month': 1.1, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.011371964, 'volatility_last_month': 0.013415045, 'volatility_last_3months': 0.014474717, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.65, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.024936555', 'winloss_ratio_last_month': '1.412196535', 'winloss_ratio_last_3month': '1.387001147', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-08 14:00', 'high': 97.85, 'open': 97.07, 'close': 97.25, 'low': 96.72, 'volume': 1275, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': 1.08, 'pnl_last_month': 1.1, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.011352752, 'volatility_last_month': 0.013379868, 'volatility_last_3months': 0.014468457, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.65, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.050715654', 'winloss_ratio_last_month': '1.412763154', 'winloss_ratio_last_3month': '1.385686359', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-08 15:00', 'high': 98.17, 'open': 97.22, 'close': 98.09, 'low': 97.05, 'volume': 1311, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': 1.08, 'pnl_last_month': 1.1, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.011531734, 'volatility_last_month': 0.013414475, 'volatility_last_3months': 0.01446949, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.65, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.077622941', 'winloss_ratio_last_month': '1.413332285', 'winloss_ratio_last_3month': '1.384371922', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-08 16:00', 'high': 98.17, 'open': 98.14, 'close': 97.83, 'low': 97.55, 'volume': 2052, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': 1.08, 'pnl_last_month': 1.1, 'pnl_last_3months': 1.67, 'volatility_last_week': 0.011542334, 'volatility_last_month': 0.013410658, 'volatility_last_3months': 0.014464077, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.65, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.105734134', 'winloss_ratio_last_month': '1.413903946', 'winloss_ratio_last_3month': '1.383057836', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-09 07:00', 'high': 98.32, 'open': 97.85, 'close': 97.61, 'low': 97.55, 'volume': 768, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': 0.86, 'pnl_last_month': 0.88, 'pnl_last_3months': 1.45, 'volatility_last_week': 0.011489121, 'volatility_last_month': 0.013402771, 'volatility_last_3months': 0.014464619, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.619047619, 'win_ratio_last_3month': 0.490196078, 'winloss_ratio_last_week': '1.132132132', 'winloss_ratio_last_month': '1.413986897', 'winloss_ratio_last_3month': '1.381677585', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-09 08:00', 'high': 99.5, 'open': 97.7, 'close': 99.28, 'low': 97.66, 'volume': 3492, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': 2.53, 'pnl_last_month': 2.55, 'pnl_last_3months': 3.12, 'volatility_last_week': 0.012174847, 'volatility_last_month': 0.013570547, 'volatility_last_3months': 0.014518798, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.636363636, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.18009072', 'winloss_ratio_last_month': '1.416711479', 'winloss_ratio_last_3month': '1.380663185', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-09 09:00', 'high': 99.84, 'open': 99.3, 'close': 99.05, 'low': 98.65, 'volume': 3105, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': 2.3, 'pnl_last_month': 2.32, 'pnl_last_3months': 2.89, 'volatility_last_week': 0.011940741, 'volatility_last_month': 0.013506525, 'volatility_last_3months': 0.014511327, 'win_ratio_last_week': 0.625, 'win_ratio_last_month': 0.608695652, 'win_ratio_last_3month': 0.490566038, 'winloss_ratio_last_week': '1.226535197', 'winloss_ratio_last_month': '1.418929194', 'winloss_ratio_last_3month': '1.379579749', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-09 10:00', 'high': 99.1, 'open': 99.05, 'close': 97.23, 'low': 96.53, 'volume': 3502, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': 0.48, 'pnl_last_month': 0.5, 'pnl_last_3months': 1.07, 'volatility_last_week': 0.012652312, 'volatility_last_month': 0.013339576, 'volatility_last_3months': 0.014446534, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.481481481, 'winloss_ratio_last_week': '1.245869052', 'winloss_ratio_last_month': '1.417062136', 'winloss_ratio_last_3month': '1.377948419', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-09 11:00', 'high': 97.42, 'open': 97.24, 'close': 97.2, 'low': 96.92, 'volume': 968, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': 0.45, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.04, 'volatility_last_week': 0.01265294, 'volatility_last_month': 0.013301778, 'volatility_last_3months': 0.014415969, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.472727273, 'winloss_ratio_last_week': '1.264556806', 'winloss_ratio_last_month': '1.415133086', 'winloss_ratio_last_3month': '1.376310121', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-09 12:00', 'high': 97.5, 'open': 97.13, 'close': 97.0, 'low': 96.93, 'volume': 727, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.45, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.04, 'volatility_last_week': 0.012449362, 'volatility_last_month': 0.013261852, 'volatility_last_3months': 0.0144154, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.472727273, 'winloss_ratio_last_week': '1.283060647', 'winloss_ratio_last_month': '1.413209452', 'winloss_ratio_last_3month': '1.374673906', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-09 13:00', 'high': 97.56, 'open': 97.0, 'close': 97.3, 'low': 96.71, 'volume': 1247, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.45, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.04, 'volatility_last_week': 0.011856705, 'volatility_last_month': 0.013261131, 'volatility_last_3months': 0.014412849, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.472727273, 'winloss_ratio_last_week': '1.301383278', 'winloss_ratio_last_month': '1.411291212', 'winloss_ratio_last_3month': '1.373039768', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-09 14:00', 'high': 98.11, 'open': 97.29, 'close': 98.0, 'low': 97.1, 'volume': 1432, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.45, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.04, 'volatility_last_week': 0.011948415, 'volatility_last_month': 0.013290318, 'volatility_last_3months': 0.014419179, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.472727273, 'winloss_ratio_last_week': '1.319527348', 'winloss_ratio_last_month': '1.409378343', 'winloss_ratio_last_3month': '1.371407703', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-09 15:00', 'high': 98.65, 'open': 98.12, 'close': 98.02, 'low': 97.75, 'volume': 1702, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.45, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.04, 'volatility_last_week': 0.011791668, 'volatility_last_month': 0.013289449, 'volatility_last_3months': 0.014411, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.472727273, 'winloss_ratio_last_week': '1.337495454', 'winloss_ratio_last_month': '1.407470822', 'winloss_ratio_last_3month': '1.369819107', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-09 16:00', 'high': 99.3, 'open': 98.1, 'close': 99.3, 'low': 98.01, 'volume': 2485, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.15, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.04, 'volatility_last_week': 0.012082962, 'volatility_last_month': 0.013386684, 'volatility_last_3months': 0.014437663, 'win_ratio_last_week': 0.444444444, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.472727273, 'winloss_ratio_last_week': '1.348051635', 'winloss_ratio_last_month': '1.405568627', 'winloss_ratio_last_3month': '1.368140171', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-10 07:00', 'high': 99.26, 'open': 99.0, 'close': 98.58, 'low': 98.27, 'volume': 1217, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.03, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.04, 'volatility_last_week': 0.01223134, 'volatility_last_month': 0.013423261, 'volatility_last_3months': 0.014448538, 'win_ratio_last_week': 0.375, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.472727273, 'winloss_ratio_last_week': '1.354184176', 'winloss_ratio_last_month': '1.403671737', 'winloss_ratio_last_3month': '1.366620248', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-10 08:00', 'high': 100.2, 'open': 98.57, 'close': 99.22, 'low': 98.52, 'volume': 2563, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.42, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.39, 'volatility_last_week': 0.012265424, 'volatility_last_month': 0.013248886, 'volatility_last_3months': 0.014453419, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.481481481, 'winloss_ratio_last_week': '1.375045295', 'winloss_ratio_last_month': '1.401780128', 'winloss_ratio_last_3month': '1.365090582', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-10 09:00', 'high': 99.52, 'open': 99.25, 'close': 98.62, 'low': 98.45, 'volume': 2080, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.46, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.08, 'volatility_last_week': 0.012119554, 'volatility_last_month': 0.013274618, 'volatility_last_3months': 0.014458426, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.471698113, 'winloss_ratio_last_week': '1.374772147', 'winloss_ratio_last_month': '1.399893778', 'winloss_ratio_last_3month': '1.363295075', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-10 10:00', 'high': 99.32, 'open': 98.72, 'close': 99.12, 'low': 98.36, 'volume': 1815, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.23, 'volatility_last_week': 0.012127211, 'volatility_last_month': 0.013274638, 'volatility_last_3months': 0.014462635, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.480769231, 'winloss_ratio_last_week': '1.370338672', 'winloss_ratio_last_month': '1.398012667', 'winloss_ratio_last_3month': '1.361591132', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-10 11:00', 'high': 99.55, 'open': 99.13, 'close': 99.2, 'low': 98.88, 'volume': 978, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.77, 'volatility_last_week': 0.012118136, 'volatility_last_month': 0.013274718, 'volatility_last_3months': 0.014455513, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.490196078, 'winloss_ratio_last_week': '1.365850658', 'winloss_ratio_last_month': '1.396136771', 'winloss_ratio_last_3month': '1.360208393', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-10 12:00', 'high': 99.28, 'open': 99.23, 'close': 98.48, 'low': 98.39, 'volume': 1186, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.012206281, 'volatility_last_month': 0.013312384, 'volatility_last_3months': 0.014466403, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.361307092', 'winloss_ratio_last_month': '1.394266069', 'winloss_ratio_last_3month': '1.358903676', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-10 13:00', 'high': 98.87, 'open': 98.48, 'close': 98.69, 'low': 98.4, 'volume': 544, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.01218314, 'volatility_last_month': 0.013302302, 'volatility_last_3months': 0.014464128, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.356706937', 'winloss_ratio_last_month': '1.392400541', 'winloss_ratio_last_3month': '1.35760013', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-10 14:00', 'high': 99.5, 'open': 98.74, 'close': 99.25, 'low': 98.35, 'volume': 1933, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.01222235, 'volatility_last_month': 0.01331952, 'volatility_last_3months': 0.014433297, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.352049129', 'winloss_ratio_last_month': '1.390540163', 'winloss_ratio_last_3month': '1.356297754', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-10 15:00', 'high': 99.5, 'open': 99.26, 'close': 99.22, 'low': 98.9, 'volume': 878, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.012093549, 'volatility_last_month': 0.013281575, 'volatility_last_3months': 0.014433089, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.353007946', 'winloss_ratio_last_month': '1.388684916', 'winloss_ratio_last_3month': '1.354996545', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-10 16:00', 'high': 100.34, 'open': 99.29, 'close': 100.17, 'low': 99.22, 'volume': 3031, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.012242149, 'volatility_last_month': 0.013325371, 'volatility_last_3months': 0.014438311, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.351748606', 'winloss_ratio_last_month': '1.386834777', 'winloss_ratio_last_3month': '1.353696503', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-13 07:00', 'high': 100.16, 'open': 100.16, 'close': 99.45, 'low': 99.19, 'volume': 1405, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.01239781, 'volatility_last_month': 0.0133578, 'volatility_last_3months': 0.014437639, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.35060471', 'winloss_ratio_last_month': '1.385329605', 'winloss_ratio_last_3month': '1.352397626', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-13 08:00', 'high': 99.65, 'open': 99.52, 'close': 97.58, 'low': 97.35, 'volume': 3059, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.013172142, 'volatility_last_month': 0.013384314, 'volatility_last_3months': 0.014497985, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.341044871', 'winloss_ratio_last_month': '1.385186214', 'winloss_ratio_last_3month': '1.351099911', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-13 09:00', 'high': 98.2, 'open': 97.58, 'close': 98.2, 'low': 96.82, 'volume': 3719, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.013210531, 'volatility_last_month': 0.013396763, 'volatility_last_3months': 0.014474119, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.321284635', 'winloss_ratio_last_month': '1.385798142', 'winloss_ratio_last_3month': '1.349803359', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-13 10:00', 'high': 98.46, 'open': 98.01, 'close': 98.0, 'low': 97.38, 'volume': 2467, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.013204887, 'volatility_last_month': 0.013400276, 'volatility_last_3months': 0.014469894, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.301884438', 'winloss_ratio_last_month': '1.386608407', 'winloss_ratio_last_3month': '1.348507966', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-13 11:00', 'high': 98.0, 'open': 97.99, 'close': 97.86, 'low': 97.52, 'volume': 1030, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.01321077, 'volatility_last_month': 0.013398612, 'volatility_last_3months': 0.014462857, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.282834529', 'winloss_ratio_last_month': '1.385989686', 'winloss_ratio_last_3month': '1.347213732', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-13 12:00', 'high': 98.0, 'open': 97.89, 'close': 95.61, 'low': 95.15, 'volume': 5075, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '29', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.014180829, 'volatility_last_month': 0.013740092, 'volatility_last_3months': 0.014532756, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.264125506', 'winloss_ratio_last_month': '1.38537392', 'winloss_ratio_last_3month': '1.345920654', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-13 13:00', 'high': 96.65, 'open': 95.62, 'close': 96.23, 'low': 95.39, 'volume': 2037, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': -0.63, 'pnl_last_month': 0.47, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.014248678, 'volatility_last_month': 0.013723377, 'volatility_last_3months': 0.014534256, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.245748299', 'winloss_ratio_last_month': '1.38476109', 'winloss_ratio_last_3month': '1.344628732', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-13 14:00', 'high': 96.96, 'open': 96.23, 'close': 96.82, 'low': 96.05, 'volume': 1671, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': -0.63, 'pnl_last_month': 0.32, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.014216962, 'volatility_last_month': 0.013743971, 'volatility_last_3months': 0.01454106, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.541666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.22769416', 'winloss_ratio_last_month': '1.383693996', 'winloss_ratio_last_3month': '1.343337964', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-13 15:00', 'high': 97.25, 'open': 96.81, 'close': 97.09, 'low': 96.18, 'volume': 2487, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': -0.63, 'pnl_last_month': 0.12, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.014148547, 'volatility_last_month': 0.013745699, 'volatility_last_3months': 0.014470449, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.52173913, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.209954643', 'winloss_ratio_last_month': '1.382023838', 'winloss_ratio_last_3month': '1.342048348', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-13 16:00', 'high': 97.62, 'open': 97.1, 'close': 95.51, 'low': 95.4, 'volume': 3261, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': -0.63, 'pnl_last_month': -0.07, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.014733238, 'volatility_last_month': 0.013917085, 'volatility_last_3months': 0.014512186, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.192521595', 'winloss_ratio_last_month': '1.379785233', 'winloss_ratio_last_3month': '1.340759883', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-14 07:00', 'high': 96.9, 'open': 95.59, 'close': 96.49, 'low': 95.49, 'volume': 1155, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': -0.63, 'pnl_last_month': -0.09, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.014836996, 'volatility_last_month': 0.013979654, 'volatility_last_3months': 0.01442493, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.476190476, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.175387142', 'winloss_ratio_last_month': '1.377496671', 'winloss_ratio_last_3month': '1.339472567', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-14 08:00', 'high': 96.32, 'open': 96.32, 'close': 96.3, 'low': 95.2, 'volume': 1992, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '28', 'pnl_last_week': -0.63, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.01477978, 'volatility_last_month': 0.01395634, 'volatility_last_3months': 0.014425359, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.158543678', 'winloss_ratio_last_month': '1.37334702', 'winloss_ratio_last_3month': '1.338186399', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-14 09:00', 'high': 96.5, 'open': 96.3, 'close': 95.94, 'low': 95.94, 'volume': 1501, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': -0.63, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.014653884, 'volatility_last_month': 0.013957754, 'volatility_last_3months': 0.014425886, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.141983852', 'winloss_ratio_last_month': '1.369216867', 'winloss_ratio_last_3month': '1.336901377', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-14 10:00', 'high': 95.98, 'open': 95.98, 'close': 93.97, 'low': 93.51, 'volume': 5100, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': -0.63, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.015468811, 'volatility_last_month': 0.014188307, 'volatility_last_3months': 0.014508339, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.12570056', 'winloss_ratio_last_month': '1.365106076', 'winloss_ratio_last_3month': '1.335617499', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-14 11:00', 'high': 94.25, 'open': 93.93, 'close': 93.63, 'low': 93.2, 'volume': 1773, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': -0.63, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.015486441, 'volatility_last_month': 0.014030171, 'volatility_last_3months': 0.014510543, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.109686933', 'winloss_ratio_last_month': '1.36101451', 'winloss_ratio_last_3month': '1.334334765', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-14 12:00', 'high': 94.88, 'open': 93.61, 'close': 94.62, 'low': 93.51, 'volume': 1265, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': -0.63, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.015585676, 'volatility_last_month': 0.014085115, 'volatility_last_3months': 0.014531782, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.086966242', 'winloss_ratio_last_month': '1.356942035', 'winloss_ratio_last_3month': '1.333053172', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-14 13:00', 'high': 94.57, 'open': 94.55, 'close': 93.9, 'low': 93.68, 'volume': 1517, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': -0.63, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.015509955, 'volatility_last_month': 0.014106327, 'volatility_last_3months': 0.014542252, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.071837814', 'winloss_ratio_last_month': '1.352888518', 'winloss_ratio_last_3month': '1.331772719', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-14 14:00', 'high': 94.0, 'open': 93.86, 'close': 93.15, 'low': 93.0, 'volume': 2122, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': -0.63, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.015606025, 'volatility_last_month': 0.014140791, 'volatility_last_3months': 0.014534346, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.058222898', 'winloss_ratio_last_month': '1.348853826', 'winloss_ratio_last_3month': '1.330493405', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-14 15:00', 'high': 93.45, 'open': 93.1, 'close': 93.31, 'low': 92.78, 'volume': 1639, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '28', 'pnl_last_week': -0.63, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.015550442, 'volatility_last_month': 0.01409972, 'volatility_last_3months': 0.014531072, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.042753313', 'winloss_ratio_last_month': '1.344837828', 'winloss_ratio_last_3month': '1.329215227', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-14 16:00', 'high': 93.29, 'open': 93.27, 'close': 92.87, 'low': 92.4, 'volume': 3403, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': -0.63, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.015571882, 'volatility_last_month': 0.014103498, 'volatility_last_3months': 0.01453507, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.024001677', 'winloss_ratio_last_month': '1.340840396', 'winloss_ratio_last_3month': '1.327938185', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-15 07:00', 'high': 93.91, 'open': 92.98, 'close': 93.91, 'low': 92.74, 'volume': 969, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': -1.67, 'pnl_last_month': -1.75, 'pnl_last_3months': 0.86, 'volatility_last_week': 0.015798477, 'volatility_last_month': 0.014177974, 'volatility_last_3months': 0.01440884, 'win_ratio_last_week': 0.166666667, 'win_ratio_last_month': 0.428571429, 'win_ratio_last_3month': 0.490196078, 'winloss_ratio_last_week': '0.995320448', 'winloss_ratio_last_month': '1.334809305', 'winloss_ratio_last_3month': '1.326366418', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-15 08:00', 'high': 95.47, 'open': 93.86, 'close': 94.75, 'low': 93.78, 'volume': 2706, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': -2.51, 'pnl_last_month': -2.59, 'pnl_last_3months': 0.02, 'volatility_last_week': 0.015064542, 'volatility_last_month': 0.014199265, 'volatility_last_3months': 0.014308299, 'win_ratio_last_week': 0.142857143, 'win_ratio_last_month': 0.409090909, 'win_ratio_last_3month': 0.480769231, 'winloss_ratio_last_week': '0.960305792', 'winloss_ratio_last_month': '1.32718515', 'winloss_ratio_last_3month': '1.324558321', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-15 09:00', 'high': 94.9, 'open': 94.75, 'close': 93.67, 'low': 93.57, 'volume': 1856, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': -1.43, 'pnl_last_month': -1.51, 'pnl_last_3months': 1.1, 'volatility_last_week': 0.015248643, 'volatility_last_month': 0.014280927, 'volatility_last_3months': 0.014307605, 'win_ratio_last_week': 0.25, 'win_ratio_last_month': 0.434782609, 'win_ratio_last_3month': 0.490566038, 'winloss_ratio_last_week': '0.937972769', 'winloss_ratio_last_month': '1.321215663', 'winloss_ratio_last_3month': '1.322984478', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-15 10:00', 'high': 93.75, 'open': 93.75, 'close': 91.18, 'low': 91.1, 'volume': 5053, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': 1.06, 'pnl_last_month': 0.98, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.016323089, 'volatility_last_month': 0.014698778, 'volatility_last_3months': 0.01445271, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.458333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.939903188', 'winloss_ratio_last_month': '1.318925913', 'winloss_ratio_last_3month': '1.321945878', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-15 11:00', 'high': 92.11, 'open': 91.14, 'close': 90.66, 'low': 90.5, 'volume': 2890, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '29', 'pnl_last_week': 1.58, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016352951, 'volatility_last_month': 0.014694662, 'volatility_last_3months': 0.014452424, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '0.94629924', 'winloss_ratio_last_month': '1.317411179', 'winloss_ratio_last_3month': '1.321020159', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-15 12:00', 'high': 90.98, 'open': 90.66, 'close': 90.2, 'low': 90.0, 'volume': 3317, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 1.58, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.01608529, 'volatility_last_month': 0.014651912, 'volatility_last_3months': 0.014451893, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '0.952287302', 'winloss_ratio_last_month': '1.315911446', 'winloss_ratio_last_3month': '1.320096002', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-15 13:00', 'high': 90.67, 'open': 90.19, 'close': 90.22, 'low': 89.35, 'volume': 4369, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 1.58, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016083368, 'volatility_last_month': 0.01464163, 'volatility_last_3months': 0.014451784, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '0.957905204', 'winloss_ratio_last_month': '1.314426492', 'winloss_ratio_last_3month': '1.319173403', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-15 14:00', 'high': 90.57, 'open': 90.2, 'close': 89.67, 'low': 89.33, 'volume': 2433, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 1.58, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016100132, 'volatility_last_month': 0.014564407, 'volatility_last_3months': 0.014455496, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '0.963186239', 'winloss_ratio_last_month': '1.3129561', 'winloss_ratio_last_3month': '1.318252358', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-15 15:00', 'high': 90.27, 'open': 89.65, 'close': 89.5, 'low': 89.41, 'volume': 2515, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 1.58, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.015908218, 'volatility_last_month': 0.014479378, 'volatility_last_3months': 0.014448993, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '0.968159825', 'winloss_ratio_last_month': '1.311500057', 'winloss_ratio_last_3month': '1.317332862', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-15 16:00', 'high': 89.84, 'open': 89.51, 'close': 89.23, 'low': 89.0, 'volume': 4739, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 1.58, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.015909548, 'volatility_last_month': 0.014481626, 'volatility_last_3months': 0.014429373, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '0.972852051', 'winloss_ratio_last_month': '1.310058153', 'winloss_ratio_last_3month': '1.316414912', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-16 07:00', 'high': 91.8, 'open': 89.76, 'close': 90.9, 'low': 89.76, 'volume': 2505, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 1.8, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016629393, 'volatility_last_month': 0.014676158, 'volatility_last_3months': 0.014458049, 'win_ratio_last_week': 0.444444444, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '0.980467594', 'winloss_ratio_last_month': '1.308630183', 'winloss_ratio_last_3month': '1.315498504', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-16 08:00', 'high': 91.18, 'open': 90.9, 'close': 89.45, 'low': 89.4, 'volume': 2671, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.13, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016376018, 'volatility_last_month': 0.014674693, 'volatility_last_3months': 0.014509821, 'win_ratio_last_week': 0.375, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '0.963596966', 'winloss_ratio_last_month': '1.307215947', 'winloss_ratio_last_3month': '1.314583634', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-16 09:00', 'high': 89.45, 'open': 89.44, 'close': 88.3, 'low': 87.81, 'volume': 5130, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.36, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016577913, 'volatility_last_month': 0.014764738, 'volatility_last_3months': 0.014520744, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '0.950594564', 'winloss_ratio_last_month': '1.305107134', 'winloss_ratio_last_3month': '1.313670299', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-16 10:00', 'high': 88.33, 'open': 88.32, 'close': 87.53, 'low': 87.07, 'volume': 4423, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.18, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.0161838, 'volatility_last_month': 0.014805043, 'volatility_last_3months': 0.014535104, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '0.962411903', 'winloss_ratio_last_month': '1.301832302', 'winloss_ratio_last_3month': '1.312758493', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-16 11:00', 'high': 88.11, 'open': 87.54, 'close': 88.02, 'low': 87.24, 'volume': 1359, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016281629, 'volatility_last_month': 0.014826567, 'volatility_last_3months': 0.014537549, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '0.9747851', 'winloss_ratio_last_month': '1.299202109', 'winloss_ratio_last_3month': '1.311848215', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-16 12:00', 'high': 88.02, 'open': 88.02, 'close': 86.95, 'low': 86.88, 'volume': 1711, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016462674, 'volatility_last_month': 0.014886587, 'volatility_last_3months': 0.014567009, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '0.987313487', 'winloss_ratio_last_month': '1.296568761', 'winloss_ratio_last_3month': '1.310939459', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-16 13:00', 'high': 87.25, 'open': 86.97, 'close': 86.75, 'low': 86.25, 'volume': 3478, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016412916, 'volatility_last_month': 0.014861023, 'volatility_last_3months': 0.01455936, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1', 'winloss_ratio_last_month': '1.294320324', 'winloss_ratio_last_3month': '1.310032221', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-16 14:00', 'high': 87.97, 'open': 86.75, 'close': 87.56, 'low': 86.4, 'volume': 2317, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016491087, 'volatility_last_month': 0.014908883, 'volatility_last_3months': 0.014576133, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.012847653', 'winloss_ratio_last_month': '1.292099231', 'winloss_ratio_last_3month': '1.309126499', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-16 15:00', 'high': 87.61, 'open': 87.58, 'close': 87.27, 'low': 86.51, 'volume': 2069, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016482089, 'volatility_last_month': 0.014894055, 'volatility_last_3months': 0.014577936, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.025859536', 'winloss_ratio_last_month': '1.289904987', 'winloss_ratio_last_3month': '1.308222288', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-16 16:00', 'high': 88.56, 'open': 87.33, 'close': 87.86, 'low': 86.98, 'volume': 3458, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016213106, 'volatility_last_month': 0.014837234, 'volatility_last_3months': 0.014585076, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.041256932', 'winloss_ratio_last_month': '1.287737106', 'winloss_ratio_last_3month': '1.307319585', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-17 07:00', 'high': 89.0, 'open': 88.25, 'close': 88.84, 'low': 88.01, 'volume': 1441, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016483122, 'volatility_last_month': 0.014911637, 'volatility_last_3months': 0.014607783, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.058193184', 'winloss_ratio_last_month': '1.285595117', 'winloss_ratio_last_3month': '1.306418385', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-17 08:00', 'high': 89.46, 'open': 88.89, 'close': 89.35, 'low': 88.35, 'volume': 2512, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016461741, 'volatility_last_month': 0.014896423, 'volatility_last_3months': 0.014614209, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.071737832', 'winloss_ratio_last_month': '1.283478558', 'winloss_ratio_last_3month': '1.305518686', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-17 09:00', 'high': 89.62, 'open': 89.33, 'close': 89.23, 'low': 88.75, 'volume': 1766, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016434353, 'volatility_last_month': 0.014850079, 'volatility_last_3months': 0.014541201, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.091957466', 'winloss_ratio_last_month': '1.281386981', 'winloss_ratio_last_3month': '1.304620482', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-17 10:00', 'high': 89.95, 'open': 89.27, 'close': 89.0, 'low': 89.0, 'volume': 1516, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016345352, 'volatility_last_month': 0.014851034, 'volatility_last_3months': 0.014539133, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.113581731', 'winloss_ratio_last_month': '1.279319944', 'winloss_ratio_last_3month': '1.303723771', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-17 11:00', 'high': 89.35, 'open': 89.01, 'close': 88.75, 'low': 88.25, 'volume': 1456, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016330203, 'volatility_last_month': 0.014849992, 'volatility_last_3months': 0.014509707, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.135342879', 'winloss_ratio_last_month': '1.277277018', 'winloss_ratio_last_3month': '1.302828549', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-17 12:00', 'high': 88.85, 'open': 88.74, 'close': 87.76, 'low': 87.5, 'volume': 1587, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016426825, 'volatility_last_month': 0.014832804, 'volatility_last_3months': 0.014520531, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.157242213', 'winloss_ratio_last_month': '1.275257785', 'winloss_ratio_last_3month': '1.301934812', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-17 13:00', 'high': 88.05, 'open': 87.78, 'close': 87.3, 'low': 86.88, 'volume': 2478, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016405674, 'volatility_last_month': 0.014846039, 'volatility_last_3months': 0.01452478, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.179281056', 'winloss_ratio_last_month': '1.273261834', 'winloss_ratio_last_3month': '1.301042556', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-17 14:00', 'high': 87.27, 'open': 87.24, 'close': 86.69, 'low': 86.62, 'volume': 1913, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.01632082, 'volatility_last_month': 0.014869987, 'volatility_last_3months': 0.014533968, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.201460743', 'winloss_ratio_last_month': '1.271288765', 'winloss_ratio_last_3month': '1.300151778', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-17 15:00', 'high': 87.07, 'open': 86.69, 'close': 87.03, 'low': 86.01, 'volume': 3240, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '29', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016387205, 'volatility_last_month': 0.014878927, 'volatility_last_3months': 0.014537084, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.223782629', 'winloss_ratio_last_month': '1.269338186', 'winloss_ratio_last_3month': '1.299262474', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-17 16:00', 'high': 88.4, 'open': 87.12, 'close': 88.4, 'low': 87.02, 'volume': 1815, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '29', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016708039, 'volatility_last_month': 0.015026549, 'volatility_last_3months': 0.014585173, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.246248086', 'winloss_ratio_last_month': '1.267409716', 'winloss_ratio_last_3month': '1.298374641', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-20 07:00', 'high': 87.75, 'open': 87.75, 'close': 85.6, 'low': 85.15, 'volume': 3092, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '29', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.018108654, 'volatility_last_month': 0.015589587, 'volatility_last_3months': 0.01478024, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.268858504', 'winloss_ratio_last_month': '1.26550298', 'winloss_ratio_last_3month': '1.297488274', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-20 08:00', 'high': 85.92, 'open': 85.6, 'close': 85.11, 'low': 84.75, 'volume': 3390, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '29', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.017704232, 'volatility_last_month': 0.015533254, 'volatility_last_3months': 0.014782739, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.29161529', 'winloss_ratio_last_month': '1.263617613', 'winloss_ratio_last_3month': '1.29660337', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-20 09:00', 'high': 86.2, 'open': 85.13, 'close': 85.62, 'low': 85.01, 'volume': 2470, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '29', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.017693834, 'volatility_last_month': 0.015520663, 'volatility_last_3months': 0.014789672, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.31451987', 'winloss_ratio_last_month': '1.261753259', 'winloss_ratio_last_3month': '1.295719926', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-20 10:00', 'high': 86.69, 'open': 85.69, 'close': 86.19, 'low': 85.32, 'volume': 2486, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '29', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.017833151, 'volatility_last_month': 0.015364079, 'volatility_last_3months': 0.014769312, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.337573689', 'winloss_ratio_last_month': '1.259909567', 'winloss_ratio_last_3month': '1.294837937', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-20 11:00', 'high': 86.82, 'open': 86.2, 'close': 86.46, 'low': 86.13, 'volume': 1336, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.017882714, 'volatility_last_month': 0.015304882, 'volatility_last_3months': 0.014770952, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.36077821', 'winloss_ratio_last_month': '1.258086196', 'winloss_ratio_last_3month': '1.293957401', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-20 12:00', 'high': 86.81, 'open': 86.39, 'close': 86.62, 'low': 86.06, 'volume': 1111, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.017183216, 'volatility_last_month': 0.015308412, 'volatility_last_3months': 0.014759295, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.384134916', 'winloss_ratio_last_month': '1.256282812', 'winloss_ratio_last_3month': '1.293078314', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-20 13:00', 'high': 87.25, 'open': 86.67, 'close': 87.16, 'low': 86.11, 'volume': 1687, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.017176274, 'volatility_last_month': 0.015335119, 'volatility_last_3months': 0.014762504, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.407645308', 'winloss_ratio_last_month': '1.254499087', 'winloss_ratio_last_3month': '1.292200671', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-20 14:00', 'high': 89.2, 'open': 87.18, 'close': 87.52, 'low': 87.18, 'volume': 2879, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.017128699, 'volatility_last_month': 0.015312689, 'volatility_last_3months': 0.014764482, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.431310909', 'winloss_ratio_last_month': '1.252734704', 'winloss_ratio_last_3month': '1.291324471', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-20 15:00', 'high': 88.67, 'open': 87.54, 'close': 87.71, 'low': 87.05, 'volume': 1671, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.017119366, 'volatility_last_month': 0.01526629, 'volatility_last_3months': 0.014765227, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.45513326', 'winloss_ratio_last_month': '1.250989347', 'winloss_ratio_last_3month': '1.290449708', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-20 16:00', 'high': 88.17, 'open': 87.72, 'close': 87.85, 'low': 87.52, 'volume': 1832, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016779569, 'volatility_last_month': 0.015260805, 'volatility_last_3months': 0.014754921, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.479113924', 'winloss_ratio_last_month': '1.249262712', 'winloss_ratio_last_3month': '1.28957638', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-21 07:00', 'high': 89.01, 'open': 87.85, 'close': 88.82, 'low': 87.65, 'volume': 1159, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016812051, 'volatility_last_month': 0.015286617, 'volatility_last_3months': 0.014771419, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.503254485', 'winloss_ratio_last_month': '1.247554499', 'winloss_ratio_last_3month': '1.288704482', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-21 08:00', 'high': 89.99, 'open': 88.82, 'close': 89.66, 'low': 88.75, 'volume': 2293, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.017016108, 'volatility_last_month': 0.015120869, 'volatility_last_3months': 0.014787485, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.527556547', 'winloss_ratio_last_month': '1.245864414', 'winloss_ratio_last_3month': '1.287834012', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-21 09:00', 'high': 89.79, 'open': 89.64, 'close': 89.54, 'low': 89.25, 'volume': 1918, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.017006841, 'volatility_last_month': 0.015026703, 'volatility_last_3months': 0.014754238, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.552021736', 'winloss_ratio_last_month': '1.24419217', 'winloss_ratio_last_3month': '1.286964966', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-21 10:00', 'high': 90.35, 'open': 89.6, 'close': 90.01, 'low': 89.59, 'volume': 2028, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016412566, 'volatility_last_month': 0.014939749, 'volatility_last_3months': 0.014723878, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.5766517', 'winloss_ratio_last_month': '1.242537487', 'winloss_ratio_last_3month': '1.286097341', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-21 11:00', 'high': 90.25, 'open': 90.09, 'close': 89.92, 'low': 89.26, 'volume': 1256, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016398951, 'volatility_last_month': 0.014915057, 'volatility_last_3months': 0.014723132, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.601448109', 'winloss_ratio_last_month': '1.240900089', 'winloss_ratio_last_3month': '1.285231132', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-21 12:00', 'high': 90.98, 'open': 89.98, 'close': 90.5, 'low': 89.89, 'volume': 2406, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016264511, 'volatility_last_month': 0.014942555, 'volatility_last_3months': 0.014729122, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.626412657', 'winloss_ratio_last_month': '1.239279707', 'winloss_ratio_last_3month': '1.284366337', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-21 13:00', 'high': 91.5, 'open': 90.5, 'close': 91.18, 'low': 90.26, 'volume': 2308, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016299971, 'volatility_last_month': 0.014912556, 'volatility_last_3months': 0.014734509, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.65154706', 'winloss_ratio_last_month': '1.237676077', 'winloss_ratio_last_3month': '1.283502952', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-21 14:00', 'high': 91.22, 'open': 91.18, 'close': 89.79, 'low': 89.67, 'volume': 1735, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016583681, 'volatility_last_month': 0.014989017, 'volatility_last_3months': 0.014710311, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.676853056', 'winloss_ratio_last_month': '1.23608894', 'winloss_ratio_last_3month': '1.282640974', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-21 15:00', 'high': 90.42, 'open': 89.8, 'close': 90.02, 'low': 89.67, 'volume': 2206, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016592054, 'volatility_last_month': 0.014993216, 'volatility_last_3months': 0.014711548, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.702332409', 'winloss_ratio_last_month': '1.234518043', 'winloss_ratio_last_3month': '1.281780399', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-21 16:00', 'high': 90.49, 'open': 90.0, 'close': 90.44, 'low': 89.51, 'volume': 1818, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 2.21, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016611199, 'volatility_last_month': 0.015008312, 'volatility_last_3months': 0.014715703, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.727986907', 'winloss_ratio_last_month': '1.232963139', 'winloss_ratio_last_3month': '1.280921223', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-22 07:00', 'high': 90.95, 'open': 90.44, 'close': 90.35, 'low': 90.0, 'volume': 753, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 3.25, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016368722, 'volatility_last_month': 0.014979832, 'volatility_last_3months': 0.014715295, 'win_ratio_last_week': 0.75, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.784294069', 'winloss_ratio_last_month': '1.231423983', 'winloss_ratio_last_3month': '1.280063444', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-22 08:00', 'high': 90.43, 'open': 90.36, 'close': 90.39, 'low': 89.83, 'volume': 1223, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 4.09, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016203172, 'volatility_last_month': 0.014979514, 'volatility_last_3months': 0.014694667, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.869806094', 'winloss_ratio_last_month': '1.22990034', 'winloss_ratio_last_3month': '1.279207058', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-22 09:00', 'high': 90.61, 'open': 90.42, 'close': 89.61, 'low': 89.6, 'volume': 1432, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 3.01, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.016111921, 'volatility_last_month': 0.014981238, 'volatility_last_3months': 0.014699557, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.942338782', 'winloss_ratio_last_month': '1.228391974', 'winloss_ratio_last_3month': '1.278352061', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-22 10:00', 'high': 89.99, 'open': 89.63, 'close': 89.09, 'low': 88.3, 'volume': 3423, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.52, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.01487871, 'volatility_last_month': 0.014997323, 'volatility_last_3months': 0.014705528, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.974057484', 'winloss_ratio_last_month': '1.226898657', 'winloss_ratio_last_3month': '1.277498451', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-22 11:00', 'high': 89.55, 'open': 89.12, 'close': 89.43, 'low': 88.7, 'volume': 1293, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.014857055, 'volatility_last_month': 0.015007793, 'volatility_last_3months': 0.014706996, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '1.998252088', 'winloss_ratio_last_month': '1.225420166', 'winloss_ratio_last_3month': '1.276646223', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-22 12:00', 'high': 90.17, 'open': 89.46, 'close': 89.45, 'low': 89.03, 'volume': 2331, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.014811454, 'volatility_last_month': 0.015008001, 'volatility_last_3months': 0.014705162, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '2.024493927', 'winloss_ratio_last_month': '1.22395628', 'winloss_ratio_last_3month': '1.275795374', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-22 13:00', 'high': 89.46, 'open': 89.4, 'close': 89.0, 'low': 88.75, 'volume': 1988, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.014856253, 'volatility_last_month': 0.014999013, 'volatility_last_3months': 0.014707479, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '2.053054323', 'winloss_ratio_last_month': '1.222506784', 'winloss_ratio_last_3month': '1.274945902', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-22 14:00', 'high': 89.68, 'open': 89.03, 'close': 89.18, 'low': 88.98, 'volume': 1873, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.014797586, 'volatility_last_month': 0.014987118, 'volatility_last_3months': 0.01470823, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '2.084254755', 'winloss_ratio_last_month': '1.221071467', 'winloss_ratio_last_3month': '1.274097803', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-22 15:00', 'high': 89.71, 'open': 89.18, 'close': 89.63, 'low': 89.0, 'volume': 945, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.014840908, 'volatility_last_month': 0.01499995, 'volatility_last_3months': 0.014709213, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '2.118479017', 'winloss_ratio_last_month': '1.219650122', 'winloss_ratio_last_3month': '1.273251073', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-22 16:00', 'high': 89.95, 'open': 89.67, 'close': 89.71, 'low': 89.45, 'volume': 1505, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '28', 'pnl_last_week': 0.0, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.11, 'volatility_last_week': 0.014823693, 'volatility_last_month': 0.01496085, 'volatility_last_3months': 0.014708862, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.509090909, 'winloss_ratio_last_week': '2.156189084', 'winloss_ratio_last_month': '1.218242547', 'winloss_ratio_last_3month': '1.272405709', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-23 07:00', 'high': 90.44, 'open': 89.92, 'close': 89.75, 'low': 89.5, 'volume': 622, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '11', 'operations_last_3_months': '29', 'pnl_last_week': 0.0, 'pnl_last_month': 1.5, 'pnl_last_3months': 4.38, 'volatility_last_week': 0.014134887, 'volatility_last_month': 0.014957785, 'volatility_last_3months': 0.014707048, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.518518519, 'winloss_ratio_last_week': '2.191756272', 'winloss_ratio_last_month': '1.216848541', 'winloss_ratio_last_3month': '1.27186919', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-23 08:00', 'high': 91.76, 'open': 89.7, 'close': 91.39, 'low': 89.5, 'volume': 2075, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '11', 'operations_last_3_months': '29', 'pnl_last_week': 1.64, 'pnl_last_month': 3.14, 'pnl_last_3months': 5.11, 'volatility_last_week': 0.014263406, 'volatility_last_month': 0.015042679, 'volatility_last_3months': 0.014748422, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.518518519, 'winloss_ratio_last_week': '2.275755124', 'winloss_ratio_last_month': '1.21721233', 'winloss_ratio_last_3month': '1.271641371', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-23 09:00', 'high': 92.0, 'open': 91.36, 'close': 91.22, 'low': 91.05, 'volume': 2039, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '11', 'operations_last_3_months': '29', 'pnl_last_week': 1.47, 'pnl_last_month': 2.97, 'pnl_last_3months': 4.95, 'volatility_last_week': 0.013903636, 'volatility_last_month': 0.01500614, 'volatility_last_3months': 0.014749182, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.481481481, 'win_ratio_last_3month': 0.518518519, 'winloss_ratio_last_week': '2.355807365', 'winloss_ratio_last_month': '1.21735357', 'winloss_ratio_last_3month': '1.271548228', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-23 10:00', 'high': 92.99, 'open': 91.2, 'close': 92.41, 'low': 91.1, 'volume': 3076, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '11', 'operations_last_3_months': '29', 'pnl_last_week': 2.66, 'pnl_last_month': 4.16, 'pnl_last_3months': 6.29, 'volatility_last_week': 0.014014176, 'volatility_last_month': 0.015094382, 'volatility_last_3months': 0.014780929, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.537037037, 'winloss_ratio_last_week': '2.397037467', 'winloss_ratio_last_month': '1.218746709', 'winloss_ratio_last_3month': '1.271684611', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-23 11:00', 'high': 93.34, 'open': 92.38, 'close': 92.75, 'low': 92.21, 'volume': 1435, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '11', 'operations_last_3_months': '29', 'pnl_last_week': 3.0, 'pnl_last_month': 4.5, 'pnl_last_3months': 7.0, 'volatility_last_week': 0.01398619, 'volatility_last_month': 0.015101481, 'volatility_last_3months': 0.014779969, 'win_ratio_last_week': 0.75, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.555555556, 'winloss_ratio_last_week': '2.444378258', 'winloss_ratio_last_month': '1.220482509', 'winloss_ratio_last_3month': '1.272136493', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-23 12:00', 'high': 93.3, 'open': 92.75, 'close': 92.39, 'low': 92.3, 'volume': 1195, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '11', 'operations_last_3_months': '29', 'pnl_last_week': 2.64, 'pnl_last_month': 4.14, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013666817, 'volatility_last_month': 0.015101173, 'volatility_last_3months': 0.014770796, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '2.480547112', 'winloss_ratio_last_month': '1.221742666', 'winloss_ratio_last_3month': '1.272494666', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-23 13:00', 'high': 92.97, 'open': 92.32, 'close': 92.8, 'low': 92.24, 'volume': 1379, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.14, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013660618, 'volatility_last_month': 0.015070473, 'volatility_last_3months': 0.014773888, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '2.518231187', 'winloss_ratio_last_month': '1.22298965', 'winloss_ratio_last_3month': '1.272852369', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-23 14:00', 'high': 93.23, 'open': 92.8, 'close': 92.66, 'low': 92.5, 'volume': 1194, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.14, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013539282, 'volatility_last_month': 0.015062868, 'volatility_last_3months': 0.014773718, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '2.557527734', 'winloss_ratio_last_month': '1.224223667', 'winloss_ratio_last_3month': '1.273209603', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-23 15:00', 'high': 93.04, 'open': 92.65, 'close': 92.26, 'low': 92.0, 'volume': 1172, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.14, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013560482, 'volatility_last_month': 0.015071713, 'volatility_last_3months': 0.014765058, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '2.59854251', 'winloss_ratio_last_month': '1.225444917', 'winloss_ratio_last_3month': '1.273566368', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-23 16:00', 'high': 93.3, 'open': 92.27, 'close': 93.1, 'low': 92.27, 'volume': 2103, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.14, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013627149, 'volatility_last_month': 0.015083619, 'volatility_last_3months': 0.014772497, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '2.641390728', 'winloss_ratio_last_month': '1.226653597', 'winloss_ratio_last_3month': '1.273922666', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-24 07:00', 'high': 93.65, 'open': 92.75, 'close': 92.99, 'low': 92.59, 'volume': 1036, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.14, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013422268, 'volatility_last_month': 0.014982266, 'volatility_last_3months': 0.014772821, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '2.686198137', 'winloss_ratio_last_month': '1.227849901', 'winloss_ratio_last_3month': '1.274278497', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-24 08:00', 'high': 94.14, 'open': 92.95, 'close': 92.5, 'low': 92.5, 'volume': 3588, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.14, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013449759, 'volatility_last_month': 0.014996816, 'volatility_last_3months': 0.014773131, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '2.733102253', 'winloss_ratio_last_month': '1.229034019', 'winloss_ratio_last_3month': '1.274633863', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-24 09:00', 'high': 93.08, 'open': 92.5, 'close': 91.3, 'low': 91.3, 'volume': 2444, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.14, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013834386, 'volatility_last_month': 0.015061689, 'volatility_last_3months': 0.014807099, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '2.782253771', 'winloss_ratio_last_month': '1.23002061', 'winloss_ratio_last_3month': '1.274988763', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-24 10:00', 'high': 91.3, 'open': 91.3, 'close': 87.92, 'low': 87.66, 'volume': 6230, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.14, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.016535085, 'volatility_last_month': 0.015721355, 'volatility_last_3months': 0.015076407, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '2.833818182', 'winloss_ratio_last_month': '1.231957113', 'winloss_ratio_last_3month': '1.2753432', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-24 11:00', 'high': 87.95, 'open': 87.95, 'close': 87.53, 'low': 86.62, 'volume': 3932, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.14, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.016554235, 'volatility_last_month': 0.015709564, 'volatility_last_3months': 0.015080125, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '2.887977633', 'winloss_ratio_last_month': '1.233615853', 'winloss_ratio_last_3month': '1.275697174', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-24 12:00', 'high': 87.96, 'open': 87.36, 'close': 87.59, 'low': 87.05, 'volume': 1459, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.14, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.016341298, 'volatility_last_month': 0.015636439, 'volatility_last_3months': 0.015030946, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '2.944933078', 'winloss_ratio_last_month': '1.234255433', 'winloss_ratio_last_3month': '1.276050686', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-24 13:00', 'high': 87.94, 'open': 87.62, 'close': 87.71, 'low': 86.68, 'volume': 2058, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.14, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.01629522, 'volatility_last_month': 0.015628077, 'volatility_last_3months': 0.01502011, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '3.004906771', 'winloss_ratio_last_month': '1.235339203', 'winloss_ratio_last_3month': '1.276403737', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-24 14:00', 'high': 88.5, 'open': 87.74, 'close': 87.9, 'low': 87.5, 'volume': 1555, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.14, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.016210362, 'volatility_last_month': 0.015627894, 'volatility_last_3months': 0.014990625, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '3.068145161', 'winloss_ratio_last_month': '1.236409806', 'winloss_ratio_last_3month': '1.276756327', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-24 15:00', 'high': 87.89, 'open': 87.85, 'close': 87.7, 'low': 87.07, 'volume': 2076, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 3.69, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.01619709, 'volatility_last_month': 0.015620447, 'volatility_last_3months': 0.01494499, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.482758621, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '3.13492228', 'winloss_ratio_last_month': '1.236593795', 'winloss_ratio_last_3month': '1.277108458', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-24 16:00', 'high': 88.05, 'open': 87.7, 'close': 87.84, 'low': 87.48, 'volume': 1643, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 3.82, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.015762667, 'volatility_last_month': 0.015621039, 'volatility_last_3months': 0.014897043, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '3.20554371', 'winloss_ratio_last_month': '1.237085939', 'winloss_ratio_last_3month': '1.277460131', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-27 07:00', 'high': 89.0, 'open': 88.02, 'close': 88.4, 'low': 88.02, 'volume': 879, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 3.81, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013782901, 'volatility_last_month': 0.015647246, 'volatility_last_3months': 0.014892926, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.481481481, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '3.280351262', 'winloss_ratio_last_month': '1.237553261', 'winloss_ratio_last_3month': '1.277811346', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-27 08:00', 'high': 89.18, 'open': 88.44, 'close': 88.61, 'low': 88.22, 'volume': 1776, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.3, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.01370098, 'volatility_last_month': 0.015635007, 'volatility_last_3months': 0.014886876, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '3.359728507', 'winloss_ratio_last_month': '1.239174037', 'winloss_ratio_last_3month': '1.278162104', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-27 09:00', 'high': 89.0, 'open': 88.62, 'close': 88.33, 'low': 88.02, 'volume': 1648, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.91, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013674439, 'volatility_last_month': 0.015613644, 'volatility_last_3months': 0.014885875, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '3.444107351', 'winloss_ratio_last_month': '1.242220065', 'winloss_ratio_last_3month': '1.278512407', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-27 10:00', 'high': 88.86, 'open': 88.33, 'close': 88.65, 'low': 87.55, 'volume': 1399, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.91, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013616675, 'volatility_last_month': 0.015622808, 'volatility_last_3months': 0.014887803, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '3.533975904', 'winloss_ratio_last_month': '1.245244372', 'winloss_ratio_last_3month': '1.278862254', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-27 11:00', 'high': 89.19, 'open': 88.66, 'close': 88.21, 'low': 87.8, 'volume': 1104, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.91, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013664778, 'volatility_last_month': 0.015614794, 'volatility_last_3months': 0.014863102, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '3.62988792', 'winloss_ratio_last_month': '1.248247189', 'winloss_ratio_last_3month': '1.279211648', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-27 12:00', 'high': 88.5, 'open': 88.25, 'close': 87.97, 'low': 87.29, 'volume': 1069, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.91, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013680054, 'volatility_last_month': 0.015615254, 'volatility_last_3months': 0.014855317, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '3.732474227', 'winloss_ratio_last_month': '1.251228744', 'winloss_ratio_last_3month': '1.279560589', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-27 13:00', 'high': 88.05, 'open': 87.99, 'close': 86.97, 'low': 86.73, 'volume': 1583, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.91, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013882283, 'volatility_last_month': 0.015680192, 'volatility_last_3months': 0.014870355, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '3.842456609', 'winloss_ratio_last_month': '1.254189263', 'winloss_ratio_last_3month': '1.279909077', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-27 14:00', 'high': 87.67, 'open': 86.92, 'close': 87.17, 'low': 86.91, 'volume': 1270, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.91, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013857492, 'volatility_last_month': 0.015684586, 'volatility_last_3months': 0.014854838, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '3.96066482', 'winloss_ratio_last_month': '1.257128967', 'winloss_ratio_last_3month': '1.280257114', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-27 15:00', 'high': 87.7, 'open': 87.16, 'close': 87.08, 'low': 87.05, 'volume': 1050, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.91, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.013848441, 'volatility_last_month': 0.015626759, 'volatility_last_3months': 0.014854177, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '4.088057554', 'winloss_ratio_last_month': '1.260048074', 'winloss_ratio_last_3month': '1.280604701', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-27 16:00', 'high': 88.26, 'open': 87.08, 'close': 88.13, 'low': 87.06, 'volume': 1859, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.91, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.01414057, 'volatility_last_month': 0.015707316, 'volatility_last_3months': 0.014879599, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '4.225748503', 'winloss_ratio_last_month': '1.262946802', 'winloss_ratio_last_3month': '1.280951837', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-28 07:00', 'high': 89.32, 'open': 87.62, 'close': 89.0, 'low': 87.62, 'volume': 1735, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.91, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.014091678, 'volatility_last_month': 0.015754223, 'volatility_last_3months': 0.01488467, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '4.375039002', 'winloss_ratio_last_month': '1.265825361', 'winloss_ratio_last_3month': '1.281298526', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-28 08:00', 'high': 90.69, 'open': 89.07, 'close': 90.02, 'low': 89.07, 'volume': 3467, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.91, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.014175257, 'volatility_last_month': 0.0158317, 'volatility_last_3months': 0.014864392, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '4.537459283', 'winloss_ratio_last_month': '1.268683963', 'winloss_ratio_last_3month': '1.281644766', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-28 09:00', 'high': 90.03, 'open': 89.99, 'close': 89.79, 'low': 89.66, 'volume': 1187, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.64, 'pnl_last_month': 4.91, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.014185243, 'volatility_last_month': 0.015831798, 'volatility_last_3months': 0.014864775, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '4.714821124', 'winloss_ratio_last_month': '1.271522814', 'winloss_ratio_last_3month': '1.281990559', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-28 10:00', 'high': 89.8, 'open': 89.78, 'close': 89.45, 'low': 88.92, 'volume': 1872, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '31', 'pnl_last_week': 2.64, 'pnl_last_month': 4.91, 'pnl_last_3months': 6.64, 'volatility_last_week': 0.014157524, 'volatility_last_month': 0.015807931, 'volatility_last_3months': 0.014867712, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.545454545, 'winloss_ratio_last_week': '4.909285714', 'winloss_ratio_last_month': '1.274342117', 'winloss_ratio_last_3month': '1.282335906', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-28 11:00', 'high': 89.5, 'open': 89.45, 'close': 88.84, 'low': 88.7, 'volume': 803, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 3.25, 'pnl_last_month': 5.52, 'pnl_last_3months': 7.25, 'volatility_last_week': 0.014246107, 'volatility_last_month': 0.015829535, 'volatility_last_3months': 0.014867939, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.538461538, 'win_ratio_last_3month': 0.553571429, 'winloss_ratio_last_week': '5.146341463', 'winloss_ratio_last_month': '1.277698695', 'winloss_ratio_last_3month': '1.282802558', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-28 12:00', 'high': 89.73, 'open': 88.83, 'close': 89.55, 'low': 88.83, 'volume': 1501, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 2.54, 'pnl_last_month': 5.38, 'pnl_last_3months': 6.54, 'volatility_last_week': 0.014291962, 'volatility_last_month': 0.015854665, 'volatility_last_3months': 0.014877794, 'win_ratio_last_week': 0.571428571, 'win_ratio_last_month': 0.538461538, 'win_ratio_last_3month': 0.543859649, 'winloss_ratio_last_week': '5.261053441', 'winloss_ratio_last_month': '1.281533437', 'winloss_ratio_last_3month': '1.283086901', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-28 13:00', 'high': 89.73, 'open': 89.55, 'close': 88.93, 'low': 88.7, 'volume': 1282, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 3.16, 'pnl_last_month': 5.37, 'pnl_last_3months': 7.16, 'volatility_last_week': 0.014255591, 'volatility_last_month': 0.015851181, 'volatility_last_3months': 0.014887358, 'win_ratio_last_week': 0.625, 'win_ratio_last_month': 0.538461538, 'win_ratio_last_3month': 0.551724138, 'winloss_ratio_last_week': '5.405991328', 'winloss_ratio_last_month': '1.284764548', 'winloss_ratio_last_3month': '1.283494349', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-28 14:00', 'high': 89.4, 'open': 88.89, 'close': 89.28, 'low': 88.76, 'volume': 975, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 2.81, 'pnl_last_month': 4.92, 'pnl_last_3months': 6.81, 'volatility_last_week': 0.013827486, 'volatility_last_month': 0.01586095, 'volatility_last_3months': 0.014888788, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.542372881, 'winloss_ratio_last_week': '5.480861244', 'winloss_ratio_last_month': '1.287387909', 'winloss_ratio_last_3month': '1.283811686', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-28 15:00', 'high': 89.73, 'open': 89.19, 'close': 89.11, 'low': 88.77, 'volume': 1224, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.98, 'pnl_last_month': 5.29, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013818469, 'volatility_last_month': 0.015860807, 'volatility_last_3months': 0.0148574, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.538461538, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '5.56434046', 'winloss_ratio_last_month': '1.290612157', 'winloss_ratio_last_3month': '1.2841623', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-28 16:00', 'high': 89.73, 'open': 89.1, 'close': 89.4, 'low': 89.0, 'volume': 1584, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '11', 'operations_last_3_months': '31', 'pnl_last_week': 2.98, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013794019, 'volatility_last_month': 0.015863945, 'volatility_last_3months': 0.014843983, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '5.649795918', 'winloss_ratio_last_month': '1.294631778', 'winloss_ratio_last_3month': '1.284512317', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-29 07:00', 'high': 90.36, 'open': 89.47, 'close': 89.89, 'low': 89.39, 'volume': 817, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 2.98, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013858676, 'volatility_last_month': 0.015844038, 'volatility_last_3months': 0.014833396, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '5.500990099', 'winloss_ratio_last_month': '1.298632791', 'winloss_ratio_last_3month': '1.284861737', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-29 08:00', 'high': 90.15, 'open': 89.89, 'close': 90.1, 'low': 89.32, 'volume': 1407, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 2.98, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013870233, 'volatility_last_month': 0.015841212, 'volatility_last_3months': 0.014775638, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '5.192995529', 'winloss_ratio_last_month': '1.302615327', 'winloss_ratio_last_3month': '1.285210562', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-29 09:00', 'high': 90.49, 'open': 90.1, 'close': 90.12, 'low': 89.9, 'volume': 1517, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 2.98, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013712569, 'volatility_last_month': 0.015815105, 'volatility_last_3months': 0.01477562, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '4.957439325', 'winloss_ratio_last_month': '1.306579511', 'winloss_ratio_last_3month': '1.285558794', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-29 10:00', 'high': 91.1, 'open': 90.18, 'close': 90.82, 'low': 89.85, 'volume': 1955, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 2.98, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013752926, 'volatility_last_month': 0.015610748, 'volatility_last_3months': 0.014762507, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '4.829780147', 'winloss_ratio_last_month': '1.310525472', 'winloss_ratio_last_3month': '1.285906434', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-29 11:00', 'high': 91.1, 'open': 90.78, 'close': 90.7, 'low': 90.55, 'volume': 733, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.98, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013733352, 'volatility_last_month': 0.015608019, 'volatility_last_3months': 0.014751423, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '4.731414109', 'winloss_ratio_last_month': '1.314453333', 'winloss_ratio_last_3month': '1.286253483', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-29 12:00', 'high': 91.0, 'open': 90.7, 'close': 90.04, 'low': 89.89, 'volume': 1238, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.98, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013851126, 'volatility_last_month': 0.015620063, 'volatility_last_3months': 0.014758195, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '4.64246988', 'winloss_ratio_last_month': '1.31836322', 'winloss_ratio_last_3month': '1.286599944', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-29 13:00', 'high': 90.42, 'open': 90.0, 'close': 90.07, 'low': 89.43, 'volume': 1363, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.98, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013794209, 'volatility_last_month': 0.015576165, 'volatility_last_3months': 0.014748198, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '4.561655648', 'winloss_ratio_last_month': '1.322255256', 'winloss_ratio_last_3month': '1.286945818', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-29 14:00', 'high': 90.3, 'open': 90.08, 'close': 89.6, 'low': 89.53, 'volume': 1031, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.98, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013847772, 'volatility_last_month': 0.015584537, 'volatility_last_3months': 0.014730885, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '4.487905443', 'winloss_ratio_last_month': '1.326129562', 'winloss_ratio_last_3month': '1.287291105', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-29 15:00', 'high': 90.2, 'open': 89.62, 'close': 90.01, 'low': 89.62, 'volume': 1178, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.98, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013838604, 'volatility_last_month': 0.015598175, 'volatility_last_3months': 0.014718889, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '4.420331841', 'winloss_ratio_last_month': '1.329986259', 'winloss_ratio_last_3month': '1.287592632', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-29 16:00', 'high': 90.52, 'open': 90.01, 'close': 90.02, 'low': 89.9, 'volume': 1121, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.98, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013837222, 'volatility_last_month': 0.015594713, 'volatility_last_3months': 0.014675622, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '4.358190091', 'winloss_ratio_last_month': '1.333825468', 'winloss_ratio_last_3month': '1.2878784', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-30 07:00', 'high': 90.9, 'open': 90.43, 'close': 90.72, 'low': 90.13, 'volume': 646, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 2.98, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013956857, 'volatility_last_month': 0.015613895, 'volatility_last_3months': 0.01468214, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '4.300850547', 'winloss_ratio_last_month': '1.337647306', 'winloss_ratio_last_3month': '1.288167597', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-30 08:00', 'high': 91.33, 'open': 90.74, 'close': 90.93, 'low': 90.72, 'volume': 1131, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 1.34, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013278497, 'volatility_last_month': 0.015616808, 'volatility_last_3months': 0.014595697, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '4.17103416', 'winloss_ratio_last_month': '1.341451892', 'winloss_ratio_last_3month': '1.288645131', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-30 09:00', 'high': 91.0, 'open': 90.9, 'close': 90.1, 'low': 90.0, 'volume': 1262, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 1.51, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013446771, 'volatility_last_month': 0.015609638, 'volatility_last_3months': 0.014613169, 'win_ratio_last_week': 0.625, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '4.081836781', 'winloss_ratio_last_month': '1.345239342', 'winloss_ratio_last_3month': '1.288972345', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-30 10:00', 'high': 90.49, 'open': 90.1, 'close': 89.8, 'low': 89.55, 'volume': 1662, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.32, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013077097, 'volatility_last_month': 0.015590333, 'volatility_last_3months': 0.014610775, 'win_ratio_last_week': 0.571428571, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '3.944960212', 'winloss_ratio_last_month': '1.349009772', 'winloss_ratio_last_3month': '1.289298903', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-30 11:00', 'high': 90.91, 'open': 89.78, 'close': 90.67, 'low': 89.71, 'volume': 1170, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.02, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013264277, 'volatility_last_month': 0.015645407, 'volatility_last_3months': 0.014627536, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '3.80081738', 'winloss_ratio_last_month': '1.352763295', 'winloss_ratio_last_3month': '1.289624806', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-30 12:00', 'high': 90.9, 'open': 90.67, 'close': 90.21, 'low': 90.2, 'volume': 922, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.34, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013285172, 'volatility_last_month': 0.015611899, 'volatility_last_3months': 0.014632105, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '3.720331774', 'winloss_ratio_last_month': '1.356500026', 'winloss_ratio_last_3month': '1.289950057', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-30 13:00', 'high': 91.0, 'open': 90.27, 'close': 90.73, 'low': 90.05, 'volume': 1248, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.34, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013316907, 'volatility_last_month': 0.01550202, 'volatility_last_3months': 0.014632805, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '3.641640379', 'winloss_ratio_last_month': '1.360220077', 'winloss_ratio_last_3month': '1.290274657', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-30 14:00', 'high': 91.29, 'open': 90.69, 'close': 91.25, 'low': 90.32, 'volume': 2418, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.34, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013394228, 'volatility_last_month': 0.015517526, 'volatility_last_3months': 0.014621017, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '3.56468386', 'winloss_ratio_last_month': '1.363923559', 'winloss_ratio_last_3month': '1.290598608', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-30 15:00', 'high': 91.76, 'open': 91.28, 'close': 90.9, 'low': 90.82, 'volume': 3434, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 0.34, 'pnl_last_month': 5.64, 'pnl_last_3months': 6.98, 'volatility_last_week': 0.013386365, 'volatility_last_month': 0.015497619, 'volatility_last_3months': 0.014598189, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.56, 'win_ratio_last_3month': 0.55, 'winloss_ratio_last_week': '3.489405472', 'winloss_ratio_last_month': '1.367610583', 'winloss_ratio_last_3month': '1.290921913', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-30 16:00', 'high': 91.17, 'open': 90.9, 'close': 90.8, 'low': 90.68, 'volume': 1672, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 0.24, 'pnl_last_month': 5.24, 'pnl_last_3months': 6.88, 'volatility_last_week': 0.013193411, 'volatility_last_month': 0.015492778, 'volatility_last_3months': 0.014596868, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.540983607, 'winloss_ratio_last_week': '3.408813972', 'winloss_ratio_last_month': '1.370647878', 'winloss_ratio_last_3month': '1.291219374', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-31 07:00', 'high': 91.59, 'open': 90.93, 'close': 91.59, 'low': 90.71, 'volume': 870, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 1.03, 'pnl_last_month': 5.85, 'pnl_last_3months': 7.67, 'volatility_last_week': 0.013370261, 'volatility_last_month': 0.015533831, 'volatility_last_3months': 0.014610048, 'win_ratio_last_week': 0.571428571, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.548387097, 'winloss_ratio_last_week': '3.34609986', 'winloss_ratio_last_month': '1.374039846', 'winloss_ratio_last_3month': '1.291670241', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-31 08:00', 'high': 92.19, 'open': 91.65, 'close': 91.45, 'low': 91.41, 'volume': 1578, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 0.89, 'pnl_last_month': 6.16, 'pnl_last_3months': 7.53, 'volatility_last_week': 0.013319378, 'volatility_last_month': 0.015521458, 'volatility_last_3months': 0.014610291, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.53968254, 'winloss_ratio_last_week': '3.275868878', 'winloss_ratio_last_month': '1.378312222', 'winloss_ratio_last_3month': '1.292084964', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-31 09:00', 'high': 91.43, 'open': 91.43, 'close': 91.13, 'low': 90.78, 'volume': 1172, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 0.57, 'pnl_last_month': 4.96, 'pnl_last_3months': 7.21, 'volatility_last_week': 0.012978385, 'volatility_last_month': 0.015478511, 'volatility_last_3months': 0.014603082, 'win_ratio_last_week': 0.444444444, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.53125, 'winloss_ratio_last_week': '3.187898705', 'winloss_ratio_last_month': '1.380689196', 'winloss_ratio_last_3month': '1.292418365', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-31 10:00', 'high': 91.55, 'open': 91.23, 'close': 91.02, 'low': 90.85, 'volume': 1102, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009229026, 'volatility_last_month': 0.015477473, 'volatility_last_3months': 0.014586113, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '3.09710939', 'winloss_ratio_last_month': '1.382637533', 'winloss_ratio_last_3month': '1.292723401', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-31 11:00', 'high': 91.61, 'open': 90.98, 'close': 91.58, 'low': 90.85, 'volume': 805, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '32', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009232006, 'volatility_last_month': 0.015492033, 'volatility_last_3months': 0.014584651, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '3.010345465', 'winloss_ratio_last_month': '1.38457808', 'winloss_ratio_last_3month': '1.293027752', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-31 12:00', 'high': 92.18, 'open': 91.52, 'close': 92.05, 'low': 91.45, 'volume': 1836, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '32', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009285879, 'volatility_last_month': 0.015494295, 'volatility_last_3months': 0.01456153, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.927345021', 'winloss_ratio_last_month': '1.386510881', 'winloss_ratio_last_3month': '1.29333142', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-31 13:00', 'high': 92.9, 'open': 92.04, 'close': 92.81, 'low': 92.02, 'volume': 1802, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '32', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.00944328, 'volatility_last_month': 0.015520562, 'volatility_last_3months': 0.014565377, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.847868388', 'winloss_ratio_last_month': '1.388435985', 'winloss_ratio_last_3month': '1.293634409', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-31 14:00', 'high': 92.9, 'open': 92.85, 'close': 92.31, 'low': 92.07, 'volume': 2129, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '32', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009565872, 'volatility_last_month': 0.015532445, 'volatility_last_3months': 0.014541733, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.771695825', 'winloss_ratio_last_month': '1.390353437', 'winloss_ratio_last_3month': '1.293936721', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-31 15:00', 'high': 92.37, 'open': 92.32, 'close': 91.76, 'low': 91.2, 'volume': 1739, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '32', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009678089, 'volatility_last_month': 0.015531183, 'volatility_last_3months': 0.014549098, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.698625488', 'winloss_ratio_last_month': '1.392263281', 'winloss_ratio_last_3month': '1.294238357', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-03-31 16:00', 'high': 92.29, 'open': 91.74, 'close': 91.75, 'low': 91.56, 'volume': 1152, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '32', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.00967956, 'volatility_last_month': 0.01553093, 'volatility_last_3months': 0.014549124, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.628471645', 'winloss_ratio_last_month': '1.394165565', 'winloss_ratio_last_3month': '1.29453932', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-03 07:00', 'high': 92.6, 'open': 91.75, 'close': 92.16, 'low': 91.69, 'volume': 803, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '32', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009628026, 'volatility_last_month': 0.015529952, 'volatility_last_3months': 0.01455251, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.561063101', 'winloss_ratio_last_month': '1.396060331', 'winloss_ratio_last_3month': '1.294839613', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-03 08:00', 'high': 92.55, 'open': 92.27, 'close': 92.44, 'low': 92.0, 'volume': 897, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.00963539, 'volatility_last_month': 0.01549029, 'volatility_last_3months': 0.014552373, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.496241804', 'winloss_ratio_last_month': '1.397947625', 'winloss_ratio_last_3month': '1.295139237', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-03 09:00', 'high': 92.45, 'open': 92.45, 'close': 91.96, 'low': 91.93, 'volume': 1185, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009696346, 'volatility_last_month': 0.015493875, 'volatility_last_3months': 0.014554147, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.433861604', 'winloss_ratio_last_month': '1.399827491', 'winloss_ratio_last_3month': '1.295438194', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-03 10:00', 'high': 93.0, 'open': 92.15, 'close': 92.56, 'low': 91.97, 'volume': 1583, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009768547, 'volatility_last_month': 0.015515063, 'volatility_last_3months': 0.014492446, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.373787155', 'winloss_ratio_last_month': '1.401699972', 'winloss_ratio_last_3month': '1.295736488', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-03 11:00', 'high': 93.93, 'open': 92.62, 'close': 93.74, 'low': 92.54, 'volume': 1787, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010056279, 'volatility_last_month': 0.015604822, 'volatility_last_3months': 0.014482648, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.315892938', 'winloss_ratio_last_month': '1.403565113', 'winloss_ratio_last_3month': '1.296034119', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-03 12:00', 'high': 94.1, 'open': 93.75, 'close': 93.91, 'low': 93.55, 'volume': 1283, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010011547, 'volatility_last_month': 0.015550604, 'volatility_last_3months': 0.014482931, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.260062379', 'winloss_ratio_last_month': '1.405422956', 'winloss_ratio_last_3month': '1.296331091', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-03 13:00', 'high': 94.06, 'open': 93.94, 'close': 94.06, 'low': 93.35, 'volume': 1447, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '31', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009523248, 'volatility_last_month': 0.015551786, 'volatility_last_3months': 0.014478054, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.206187071', 'winloss_ratio_last_month': '1.407273543', 'winloss_ratio_last_3month': '1.296627406', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-03 14:00', 'high': 95.43, 'open': 94.04, 'close': 95.4, 'low': 93.76, 'volume': 2286, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.00998063, 'volatility_last_month': 0.015642364, 'volatility_last_3months': 0.014494301, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.154166069', 'winloss_ratio_last_month': '1.409116918', 'winloss_ratio_last_3month': '1.296923065', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-03 15:00', 'high': 95.9, 'open': 95.4, 'close': 95.85, 'low': 94.76, 'volume': 1743, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009979791, 'volatility_last_month': 0.015627676, 'volatility_last_3months': 0.014491314, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.103905259', 'winloss_ratio_last_month': '1.410953122', 'winloss_ratio_last_3month': '1.297218071', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-03 16:00', 'high': 96.16, 'open': 95.85, 'close': 96.0, 'low': 95.37, 'volume': 2328, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009676848, 'volatility_last_month': 0.015628577, 'volatility_last_3months': 0.01448518, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.055316789', 'winloss_ratio_last_month': '1.412782197', 'winloss_ratio_last_3month': '1.297512426', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-04 07:00', 'high': 96.03, 'open': 96.0, 'close': 95.44, 'low': 95.32, 'volume': 866, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009636412, 'volatility_last_month': 0.015623258, 'volatility_last_3months': 0.01445904, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '2.00831856', 'winloss_ratio_last_month': '1.414604185', 'winloss_ratio_last_3month': '1.297806133', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-04 08:00', 'high': 96.73, 'open': 95.5, 'close': 96.63, 'low': 95.1, 'volume': 1566, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009698639, 'volatility_last_month': 0.015684348, 'volatility_last_3months': 0.014464537, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.962833758', 'winloss_ratio_last_month': '1.416419126', 'winloss_ratio_last_3month': '1.298099192', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-04 09:00', 'high': 97.0, 'open': 96.65, 'close': 96.37, 'low': 96.2, 'volume': 1768, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009701904, 'volatility_last_month': 0.01564169, 'volatility_last_3months': 0.014454111, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.91879044', 'winloss_ratio_last_month': '1.418227062', 'winloss_ratio_last_3month': '1.298391608', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-04 10:00', 'high': 96.95, 'open': 96.44, 'close': 96.07, 'low': 95.15, 'volume': 2106, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.46, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009682312, 'volatility_last_month': 0.015644948, 'volatility_last_3months': 0.014447254, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.876121149', 'winloss_ratio_last_month': '1.420028033', 'winloss_ratio_last_3month': '1.29868338', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-04 11:00', 'high': 96.21, 'open': 96.12, 'close': 95.73, 'low': 95.31, 'volume': 1133, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.15, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009550872, 'volatility_last_month': 0.015651447, 'volatility_last_3months': 0.014438374, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.819147575', 'winloss_ratio_last_month': '1.421822079', 'winloss_ratio_last_3month': '1.298974513', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-04 12:00', 'high': 96.97, 'open': 95.72, 'close': 96.9, 'low': 95.57, 'volume': 831, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.56, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009763631, 'volatility_last_month': 0.015700536, 'volatility_last_3months': 0.014460632, 'win_ratio_last_week': 0.375, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.796046721', 'winloss_ratio_last_month': '1.42360924', 'winloss_ratio_last_3month': '1.299265007', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-04 13:00', 'high': 97.41, 'open': 96.91, 'close': 95.59, 'low': 95.47, 'volume': 1999, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': -0.06, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010216632, 'volatility_last_month': 0.015755689, 'volatility_last_3months': 0.014499216, 'win_ratio_last_week': 0.285714286, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.756854164', 'winloss_ratio_last_month': '1.426440866', 'winloss_ratio_last_3month': '1.299554865', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-04 14:00', 'high': 96.16, 'open': 95.59, 'close': 95.72, 'low': 95.22, 'volume': 1477, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.29, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010198877, 'volatility_last_month': 0.01575402, 'volatility_last_3months': 0.014495748, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.733098867', 'winloss_ratio_last_month': '1.42834382', 'winloss_ratio_last_3month': '1.299844089', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-04 15:00', 'high': 95.96, 'open': 95.78, 'close': 94.9, 'low': 94.64, 'volume': 2186, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010444836, 'volatility_last_month': 0.01577104, 'volatility_last_3months': 0.014508069, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.704284773', 'winloss_ratio_last_month': '1.429958818', 'winloss_ratio_last_3month': '1.300132682', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-04 16:00', 'high': 95.37, 'open': 94.89, 'close': 94.98, 'low': 94.63, 'volume': 1842, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010434069, 'volatility_last_month': 0.015769649, 'volatility_last_3months': 0.01450352, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.674763112', 'winloss_ratio_last_month': '1.431264145', 'winloss_ratio_last_3month': '1.300420644', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-05 07:00', 'high': 96.2, 'open': 94.97, 'close': 96.0, 'low': 94.72, 'volume': 887, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010628367, 'volatility_last_month': 0.015827327, 'volatility_last_3months': 0.014524338, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.644507499', 'winloss_ratio_last_month': '1.432884175', 'winloss_ratio_last_3month': '1.300707979', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-05 08:00', 'high': 97.15, 'open': 96.0, 'close': 96.36, 'low': 95.85, 'volume': 2042, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010641413, 'volatility_last_month': 0.015580906, 'volatility_last_3months': 0.01452387, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.613490218', 'winloss_ratio_last_month': '1.434494729', 'winloss_ratio_last_3month': '1.300994687', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-05 09:00', 'high': 97.35, 'open': 96.4, 'close': 97.1, 'low': 96.37, 'volume': 2318, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010742194, 'volatility_last_month': 0.015601152, 'volatility_last_3months': 0.014534578, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.581682139', 'winloss_ratio_last_month': '1.436095888', 'winloss_ratio_last_3month': '1.301280773', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-05 10:00', 'high': 97.95, 'open': 97.1, 'close': 97.6, 'low': 97.1, 'volume': 1865, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010672635, 'volatility_last_month': 0.015585783, 'volatility_last_3months': 0.014526914, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.549052632', 'winloss_ratio_last_month': '1.437687736', 'winloss_ratio_last_3month': '1.301566236', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-05 11:00', 'high': 98.0, 'open': 97.58, 'close': 97.25, 'low': 97.16, 'volume': 2123, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010720005, 'volatility_last_month': 0.015591722, 'volatility_last_3months': 0.014525457, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.515569458', 'winloss_ratio_last_month': '1.439270352', 'winloss_ratio_last_3month': '1.30185108', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-05 12:00', 'high': 97.48, 'open': 97.19, 'close': 97.11, 'low': 96.61, 'volume': 1409, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010534908, 'volatility_last_month': 0.01547843, 'volatility_last_3months': 0.014409533, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.481198675', 'winloss_ratio_last_month': '1.440843818', 'winloss_ratio_last_3month': '1.302135306', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-05 13:00', 'high': 97.27, 'open': 97.11, 'close': 96.49, 'low': 96.38, 'volume': 1744, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010698695, 'volatility_last_month': 0.015501204, 'volatility_last_3months': 0.014418258, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.445904512', 'winloss_ratio_last_month': '1.442408211', 'winloss_ratio_last_3month': '1.302418917', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-05 14:00', 'high': 96.6, 'open': 96.5, 'close': 96.36, 'low': 95.75, 'volume': 2670, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010599844, 'volatility_last_month': 0.015500162, 'volatility_last_3months': 0.014404201, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.409649253', 'winloss_ratio_last_month': '1.443963611', 'winloss_ratio_last_3month': '1.302701914', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-05 15:00', 'high': 96.9, 'open': 96.41, 'close': 96.8, 'low': 96.01, 'volume': 1244, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010599681, 'volatility_last_month': 0.015470037, 'volatility_last_3months': 0.014407661, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.372393098', 'winloss_ratio_last_month': '1.445510095', 'winloss_ratio_last_3month': '1.302984299', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-05 16:00', 'high': 97.11, 'open': 96.81, 'close': 97.01, 'low': 96.37, 'volume': 1528, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 4.68, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010595926, 'volatility_last_month': 0.015469011, 'volatility_last_3months': 0.014406869, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.44, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.334094021', 'winloss_ratio_last_month': '1.447047739', 'winloss_ratio_last_3month': '1.303266075', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-06 07:00', 'high': 97.3, 'open': 97.0, 'close': 96.86, 'low': 96.63, 'volume': 860, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 4.9, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010509867, 'volatility_last_month': 0.015467541, 'volatility_last_3months': 0.014407216, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.458333333, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.294707607', 'winloss_ratio_last_month': '1.449075815', 'winloss_ratio_last_3month': '1.303547242', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-06 08:00', 'high': 97.5, 'open': 96.82, 'close': 96.06, 'low': 96.06, 'volume': 1672, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 3.23, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.01075091, 'volatility_last_month': 0.015341063, 'volatility_last_3months': 0.014421549, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.434782609, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.279856003', 'winloss_ratio_last_month': '1.448484375', 'winloss_ratio_last_3month': '1.303827805', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-06 09:00', 'high': 96.76, 'open': 96.07, 'close': 96.46, 'low': 95.9, 'volume': 1450, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 3.46, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.01048064, 'volatility_last_month': 0.015348887, 'volatility_last_3months': 0.014414724, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.454545455, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.261165664', 'winloss_ratio_last_month': '1.448415111', 'winloss_ratio_last_3month': '1.304107763', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-06 10:00', 'high': 97.5, 'open': 96.47, 'close': 97.01, 'low': 96.24, 'volume': 1570, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 5.28, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010466791, 'volatility_last_month': 0.015173386, 'volatility_last_3months': 0.014416767, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.476190476, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.261099535', 'winloss_ratio_last_month': '1.452458965', 'winloss_ratio_last_3month': '1.30438712', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-06 11:00', 'high': 97.25, 'open': 97.08, 'close': 96.89, 'low': 96.71, 'volume': 497, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010299852, 'volatility_last_month': 0.015174195, 'volatility_last_3months': 0.014412647, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.266547696', 'winloss_ratio_last_month': '1.456576728', 'winloss_ratio_last_3month': '1.304665877', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-06 12:00', 'high': 97.44, 'open': 96.9, 'close': 97.36, 'low': 96.69, 'volume': 945, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010213466, 'volatility_last_month': 0.015185165, 'volatility_last_3months': 0.014413038, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.264653061', 'winloss_ratio_last_month': '1.460700669', 'winloss_ratio_last_3month': '1.304944035', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-06 13:00', 'high': 97.9, 'open': 97.36, 'close': 96.91, 'low': 96.83, 'volume': 1105, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010263932, 'volatility_last_month': 0.015191983, 'volatility_last_3months': 0.014417856, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.262734144', 'winloss_ratio_last_month': '1.4648308', 'winloss_ratio_last_3month': '1.305221598', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-06 14:00', 'high': 97.09, 'open': 96.92, 'close': 96.9, 'low': 96.62, 'volume': 859, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010213324, 'volatility_last_month': 0.015162239, 'volatility_last_3months': 0.014415685, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.260790475', 'winloss_ratio_last_month': '1.468967136', 'winloss_ratio_last_3month': '1.305498567', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-06 15:00', 'high': 97.17, 'open': 96.9, 'close': 96.54, 'low': 96.15, 'volume': 1230, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.12, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010209925, 'volatility_last_month': 0.015169858, 'volatility_last_3months': 0.014418103, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.258821571', 'winloss_ratio_last_month': '1.473109691', 'winloss_ratio_last_3month': '1.305774943', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-06 16:00', 'high': 96.95, 'open': 96.52, 'close': 96.75, 'low': 96.41, 'volume': 1231, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.22, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010196978, 'volatility_last_month': 0.015074536, 'volatility_last_3months': 0.014418621, 'win_ratio_last_week': 0.25, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.261052278', 'winloss_ratio_last_month': '1.477258479', 'winloss_ratio_last_3month': '1.306050729', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-11 07:00', 'high': 97.44, 'open': 96.75, 'close': 96.86, 'low': 96.72, 'volume': 614, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': -0.57, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010038206, 'volatility_last_month': 0.015045774, 'volatility_last_3months': 0.014418003, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.236502547', 'winloss_ratio_last_month': '1.481413514', 'winloss_ratio_last_3month': '1.306325927', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-11 08:00', 'high': 97.9, 'open': 96.89, 'close': 97.5, 'low': 96.89, 'volume': 1606, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': -0.43, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010099856, 'volatility_last_month': 0.01504665, 'volatility_last_3months': 0.014425797, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.217301396', 'winloss_ratio_last_month': '1.48557481', 'winloss_ratio_last_3month': '1.306600538', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-11 09:00', 'high': 98.5, 'open': 97.5, 'close': 97.77, 'low': 97.5, 'volume': 2417, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': -0.11, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010038377, 'volatility_last_month': 0.015030472, 'volatility_last_3months': 0.014417243, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.211075725', 'winloss_ratio_last_month': '1.489742381', 'winloss_ratio_last_3month': '1.306874565', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-11 10:00', 'high': 98.35, 'open': 97.8, 'close': 98.08, 'low': 97.67, 'volume': 1285, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.01002654, 'volatility_last_month': 0.015021369, 'volatility_last_3months': 0.014399986, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.209344771', 'winloss_ratio_last_month': '1.493916242', 'winloss_ratio_last_3month': '1.307148009', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-11 11:00', 'high': 98.3, 'open': 98.08, 'close': 97.95, 'low': 97.8, 'volume': 774, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009984274, 'volatility_last_month': 0.015021867, 'volatility_last_3months': 0.014400178, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.207500942', 'winloss_ratio_last_month': '1.498096407', 'winloss_ratio_last_3month': '1.307420871', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-11 12:00', 'high': 98.35, 'open': 98.02, 'close': 98.35, 'low': 97.96, 'volume': 713, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009964611, 'volatility_last_month': 0.015001218, 'volatility_last_3months': 0.014401517, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.205532827', 'winloss_ratio_last_month': '1.502282889', 'winloss_ratio_last_3month': '1.307693155', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-11 13:00', 'high': 98.35, 'open': 98.35, 'close': 97.69, 'low': 97.63, 'volume': 1367, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.010001706, 'volatility_last_month': 0.015024546, 'volatility_last_3months': 0.01441047, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.203427419', 'winloss_ratio_last_month': '1.506475704', 'winloss_ratio_last_3month': '1.307983201', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-11 14:00', 'high': 98.09, 'open': 97.74, 'close': 97.79, 'low': 97.52, 'volume': 1173, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009879649, 'volatility_last_month': 0.015006283, 'volatility_last_3months': 0.014410553, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.201169835', 'winloss_ratio_last_month': '1.510674866', 'winloss_ratio_last_3month': '1.308229492', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-11 15:00', 'high': 97.97, 'open': 97.82, 'close': 97.43, 'low': 97.3, 'volume': 1240, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009799178, 'volatility_last_month': 0.015013783, 'volatility_last_3months': 0.014353447, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.198742956', 'winloss_ratio_last_month': '1.514880389', 'winloss_ratio_last_3month': '1.308444119', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-11 16:00', 'high': 97.82, 'open': 97.39, 'close': 97.47, 'low': 97.31, 'volume': 1267, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 7.1, 'volatility_last_week': 0.009795961, 'volatility_last_month': 0.01496001, 'volatility_last_3months': 0.014351675, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '1.196126998', 'winloss_ratio_last_month': '1.519092287', 'winloss_ratio_last_3month': '1.308727727', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-12 07:00', 'high': 98.0, 'open': 97.47, 'close': 97.95, 'low': 97.41, 'volume': 734, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.88, 'volatility_last_week': 0.009805219, 'volatility_last_month': 0.014945287, 'volatility_last_3months': 0.01435393, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.515625, 'winloss_ratio_last_week': '1.193298969', 'winloss_ratio_last_month': '1.523310576', 'winloss_ratio_last_3month': '1.30890408', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-12 08:00', 'high': 98.23, 'open': 97.98, 'close': 96.9, 'low': 96.58, 'volume': 2433, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.0, 'volatility_last_week': 0.01019912, 'volatility_last_month': 0.014803122, 'volatility_last_3months': 0.014352264, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.507936508, 'winloss_ratio_last_week': '1.19023199', 'winloss_ratio_last_month': '1.527535269', 'winloss_ratio_last_3month': '1.308759055', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-12 09:00', 'high': 97.38, 'open': 96.91, 'close': 96.95, 'low': 96.65, 'volume': 1666, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 5.51, 'volatility_last_week': 0.010090457, 'volatility_last_month': 0.014779365, 'volatility_last_3months': 0.014343462, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.186894442', 'winloss_ratio_last_month': '1.531766382', 'winloss_ratio_last_3month': '1.308435833', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-12 10:00', 'high': 97.14, 'open': 97.0, 'close': 95.29, 'low': 95.09, 'volume': 4097, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 5.38, 'volatility_last_week': 0.010899764, 'volatility_last_month': 0.014949539, 'volatility_last_3months': 0.014405012, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.491803279, 'winloss_ratio_last_week': '1.183248866', 'winloss_ratio_last_month': '1.536003928', 'winloss_ratio_last_3month': '1.308066031', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-12 11:00', 'high': 95.84, 'open': 95.31, 'close': 95.84, 'low': 95.25, 'volume': 1132, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.05, 'volatility_last_week': 0.010577204, 'volatility_last_month': 0.014968619, 'volatility_last_3months': 0.014397326, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.179250559', 'winloss_ratio_last_month': '1.540247923', 'winloss_ratio_last_3month': '1.308015635', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-12 12:00', 'high': 95.96, 'open': 95.79, 'close': 95.75, 'low': 95.35, 'volume': 873, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.05, 'volatility_last_week': 0.010576895, 'volatility_last_month': 0.014652864, 'volatility_last_3months': 0.014394846, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.174845724', 'winloss_ratio_last_month': '1.544498382', 'winloss_ratio_last_3month': '1.307965328', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-12 13:00', 'high': 96.22, 'open': 95.72, 'close': 95.47, 'low': 95.2, 'volume': 1981, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.05, 'volatility_last_week': 0.01060173, 'volatility_last_month': 0.014632973, 'volatility_last_3months': 0.014374214, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.16996904', 'winloss_ratio_last_month': '1.548755318', 'winloss_ratio_last_3month': '1.30791511', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-12 14:00', 'high': 95.95, 'open': 95.48, 'close': 95.76, 'low': 95.19, 'volume': 1381, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.05, 'volatility_last_week': 0.010081639, 'volatility_last_month': 0.014616061, 'volatility_last_3months': 0.014360892, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.164540399', 'winloss_ratio_last_month': '1.553137909', 'winloss_ratio_last_3month': '1.307864981', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-12 15:00', 'high': 96.38, 'open': 95.71, 'close': 96.1, 'low': 95.66, 'volume': 1284, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.05, 'volatility_last_week': 0.010054648, 'volatility_last_month': 0.014618967, 'volatility_last_3months': 0.014362761, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.158460472', 'winloss_ratio_last_month': '1.557686192', 'winloss_ratio_last_3month': '1.30781494', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-12 16:00', 'high': 96.29, 'open': 96.1, 'close': 96.27, 'low': 95.75, 'volume': 1310, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.05, 'volatility_last_week': 0.010056528, 'volatility_last_month': 0.014460017, 'volatility_last_3months': 0.014363048, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.151604574', 'winloss_ratio_last_month': '1.562392591', 'winloss_ratio_last_3month': '1.307764987', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-13 07:00', 'high': 96.5, 'open': 96.08, 'close': 95.39, 'low': 95.21, 'volume': 710, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.05, 'volatility_last_week': 0.010199426, 'volatility_last_month': 0.014447852, 'volatility_last_3months': 0.014381225, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.143814027', 'winloss_ratio_last_month': '1.56712211', 'winloss_ratio_last_3month': '1.307395937', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-13 08:00', 'high': 95.6, 'open': 95.46, 'close': 93.83, 'low': 93.19, 'volume': 3236, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.05, 'volatility_last_week': 0.010480891, 'volatility_last_month': 0.014606589, 'volatility_last_3months': 0.014434614, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.134883721', 'winloss_ratio_last_month': '1.572352904', 'winloss_ratio_last_3month': '1.307034938', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-13 09:00', 'high': 94.0, 'open': 93.74, 'close': 93.68, 'low': 93.34, 'volume': 1914, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.05, 'volatility_last_week': 0.010471444, 'volatility_last_month': 0.014600081, 'volatility_last_3months': 0.01442472, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.124543796', 'winloss_ratio_last_month': '1.577591707', 'winloss_ratio_last_3month': '1.306674748', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-13 10:00', 'high': 94.0, 'open': 93.73, 'close': 93.9, 'low': 93.1, 'volume': 1858, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.05, 'volatility_last_week': 0.010475214, 'volatility_last_month': 0.014346565, 'volatility_last_3months': 0.014382017, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.112431897', 'winloss_ratio_last_month': '1.582838537', 'winloss_ratio_last_3month': '1.306315365', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-13 11:00', 'high': 94.55, 'open': 93.86, 'close': 94.29, 'low': 93.77, 'volume': 1032, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.05, 'volatility_last_week': 0.010503918, 'volatility_last_month': 0.014348865, 'volatility_last_3months': 0.014384309, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.131094258', 'winloss_ratio_last_month': '1.588093413', 'winloss_ratio_last_3month': '1.305956784', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-13 12:00', 'high': 94.49, 'open': 94.26, 'close': 94.3, 'low': 93.88, 'volume': 694, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '30', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.05, 'volatility_last_week': 0.010062078, 'volatility_last_month': 0.014281812, 'volatility_last_3months': 0.014383716, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.106651376', 'winloss_ratio_last_month': '1.593356353', 'winloss_ratio_last_3month': '1.305599005', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-13 13:00', 'high': 94.86, 'open': 94.28, 'close': 94.59, 'low': 94.16, 'volume': 1148, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '31', 'pnl_last_week': 0.0, 'pnl_last_month': 5.31, 'pnl_last_3months': 6.05, 'volatility_last_week': 0.009583416, 'volatility_last_month': 0.014251879, 'volatility_last_3months': 0.014371975, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.116930572', 'winloss_ratio_last_month': '1.598627376', 'winloss_ratio_last_3month': '1.305242023', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-13 14:00', 'high': 95.17, 'open': 94.58, 'close': 94.55, 'low': 94.47, 'volume': 1905, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '31', 'pnl_last_week': 0.04, 'pnl_last_month': 5.35, 'pnl_last_3months': 6.09, 'volatility_last_week': 0.009575957, 'volatility_last_month': 0.014212194, 'volatility_last_3months': 0.014336376, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.523809524, 'win_ratio_last_3month': 0.508196721, 'winloss_ratio_last_week': '1.106031746', 'winloss_ratio_last_month': '1.603938521', 'winloss_ratio_last_3month': '1.304893012', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-13 15:00', 'high': 94.85, 'open': 94.53, 'close': 94.28, 'low': 93.96, 'volume': 1109, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '31', 'pnl_last_week': 0.31, 'pnl_last_month': 5.62, 'pnl_last_3months': 6.36, 'volatility_last_week': 0.00938249, 'volatility_last_month': 0.014215748, 'volatility_last_3months': 0.014337808, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.516129032, 'winloss_ratio_last_week': '1.123342175', 'winloss_ratio_last_month': '1.60947414', 'winloss_ratio_last_3month': '1.304881654', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-13 16:00', 'high': 94.63, 'open': 94.3, 'close': 94.39, 'low': 94.2, 'volume': 1328, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '31', 'pnl_last_week': 0.2, 'pnl_last_month': 5.51, 'pnl_last_3months': 6.25, 'volatility_last_week': 0.009384738, 'volatility_last_month': 0.014202433, 'volatility_last_3months': 0.014333785, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.52173913, 'win_ratio_last_3month': 0.507936508, 'winloss_ratio_last_week': '1.133608815', 'winloss_ratio_last_month': '1.614875861', 'winloss_ratio_last_3month': '1.304878638', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-14 07:00', 'high': 94.75, 'open': 94.39, 'close': 94.26, 'low': 93.89, 'volume': 395, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '31', 'pnl_last_week': 0.33, 'pnl_last_month': 6.68, 'pnl_last_3months': 6.38, 'volatility_last_week': 0.009014974, 'volatility_last_month': 0.014128264, 'volatility_last_3months': 0.014325208, 'win_ratio_last_week': 0.75, 'win_ratio_last_month': 0.565217391, 'win_ratio_last_3month': 0.515625, 'winloss_ratio_last_week': '1.154011461', 'winloss_ratio_last_month': '1.623097568', 'winloss_ratio_last_3month': '1.304900658', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-14 08:00', 'high': 95.0, 'open': 94.32, 'close': 94.54, 'low': 94.28, 'volume': 1288, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '31', 'pnl_last_week': 0.05, 'pnl_last_month': 7.24, 'pnl_last_3months': 6.1, 'volatility_last_week': 0.008996449, 'volatility_last_month': 0.014084849, 'volatility_last_3months': 0.014325294, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.565217391, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.152046784', 'winloss_ratio_last_month': '1.633199745', 'winloss_ratio_last_3month': '1.304806121', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-14 09:00', 'high': 94.88, 'open': 94.53, 'close': 94.6, 'low': 94.44, 'volume': 920, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 6.16, 'pnl_last_3months': 6.1, 'volatility_last_week': 0.008787678, 'volatility_last_month': 0.01400347, 'volatility_last_3months': 0.014320975, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.15', 'winloss_ratio_last_month': '1.641624858', 'winloss_ratio_last_3month': '1.305130935', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-14 10:00', 'high': 94.99, 'open': 94.63, 'close': 94.69, 'low': 94.2, 'volume': 1025, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.67, 'pnl_last_3months': 6.1, 'volatility_last_week': 0.008688165, 'volatility_last_month': 0.013541705, 'volatility_last_3months': 0.014179703, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.523809524, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.147865854', 'winloss_ratio_last_month': '1.646065272', 'winloss_ratio_last_3month': '1.30545496', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-14 11:00', 'high': 94.9, 'open': 94.69, 'close': 94.41, 'low': 94.38, 'volume': 505, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.1, 'volatility_last_week': 0.008676953, 'volatility_last_month': 0.013525635, 'volatility_last_3months': 0.01418102, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.145638629', 'winloss_ratio_last_month': '1.649689522', 'winloss_ratio_last_3month': '1.305778198', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-14 12:00', 'high': 94.45, 'open': 94.45, 'close': 93.4, 'low': 93.15, 'volume': 1858, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.1, 'volatility_last_week': 0.009005406, 'volatility_last_month': 0.013584596, 'volatility_last_3months': 0.014205603, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.143312102', 'winloss_ratio_last_month': '1.653339439', 'winloss_ratio_last_3month': '1.306100653', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-14 13:00', 'high': 94.14, 'open': 93.4, 'close': 93.92, 'low': 93.17, 'volume': 1217, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.1, 'volatility_last_week': 0.009023208, 'volatility_last_month': 0.013602996, 'volatility_last_3months': 0.014211044, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.140879479', 'winloss_ratio_last_month': '1.657015298', 'winloss_ratio_last_3month': '1.306422328', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-14 14:00', 'high': 94.2, 'open': 93.92, 'close': 94.07, 'low': 93.82, 'volume': 755, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.1, 'volatility_last_week': 0.009035121, 'volatility_last_month': 0.013578538, 'volatility_last_3months': 0.014146342, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.138333333', 'winloss_ratio_last_month': '1.660717374', 'winloss_ratio_last_3month': '1.306743224', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-14 15:00', 'high': 94.15, 'open': 94.05, 'close': 93.61, 'low': 93.48, 'volume': 1402, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.1, 'volatility_last_week': 0.009010817, 'volatility_last_month': 0.0135925, 'volatility_last_3months': 0.014151621, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.135665529', 'winloss_ratio_last_month': '1.664445951', 'winloss_ratio_last_3month': '1.307063346', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-14 16:00', 'high': 94.11, 'open': 93.67, 'close': 94.11, 'low': 93.62, 'volume': 2187, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.1, 'volatility_last_week': 0.009098976, 'volatility_last_month': 0.013602187, 'volatility_last_3months': 0.014140781, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.132867133', 'winloss_ratio_last_month': '1.668201312', 'winloss_ratio_last_3month': '1.307382695', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-17 07:00', 'high': 94.75, 'open': 94.27, 'close': 94.44, 'low': 94.0, 'volume': 386, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.1, 'volatility_last_week': 0.009147831, 'volatility_last_month': 0.01339308, 'volatility_last_3months': 0.014142739, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.129928315', 'winloss_ratio_last_month': '1.671983749', 'winloss_ratio_last_3month': '1.307701275', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-17 08:00', 'high': 94.6, 'open': 94.5, 'close': 92.85, 'low': 92.55, 'volume': 1274, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.1, 'volatility_last_week': 0.00978436, 'volatility_last_month': 0.013412581, 'volatility_last_3months': 0.014167707, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.126838235', 'winloss_ratio_last_month': '1.675793555', 'winloss_ratio_last_3month': '1.308019088', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-17 09:00', 'high': 93.0, 'open': 92.91, 'close': 92.27, 'low': 92.15, 'volume': 2272, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.49, 'volatility_last_week': 0.009800999, 'volatility_last_month': 0.013327488, 'volatility_last_3months': 0.014171485, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.515625, 'winloss_ratio_last_week': '1.123584906', 'winloss_ratio_last_month': '1.679631027', 'winloss_ratio_last_3month': '1.308515981', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-17 10:00', 'high': 93.5, 'open': 92.32, 'close': 93.44, 'low': 92.32, 'volume': 1440, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 7.15, 'volatility_last_week': 0.010197806, 'volatility_last_month': 0.013373316, 'volatility_last_3months': 0.01418992, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523809524, 'winloss_ratio_last_week': '1.120155039', 'winloss_ratio_last_month': '1.683496469', 'winloss_ratio_last_3month': '1.309316072', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-17 11:00', 'high': 93.74, 'open': 93.38, 'close': 93.3, 'low': 93.22, 'volume': 622, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.71, 'volatility_last_week': 0.010198716, 'volatility_last_month': 0.013357357, 'volatility_last_3months': 0.014185279, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516129032, 'winloss_ratio_last_week': '1.116533865', 'winloss_ratio_last_month': '1.687390188', 'winloss_ratio_last_3month': '1.309960009', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-17 12:00', 'high': 93.8, 'open': 93.33, 'close': 93.79, 'low': 93.25, 'volume': 904, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.73, 'volatility_last_week': 0.010211755, 'volatility_last_month': 0.013269961, 'volatility_last_3months': 0.014190463, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.524590164, 'winloss_ratio_last_week': '1.112704918', 'winloss_ratio_last_month': '1.691312494', 'winloss_ratio_last_3month': '1.310612094', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-17 13:00', 'high': 94.0, 'open': 93.74, 'close': 93.62, 'low': 93.41, 'volume': 942, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.01017186, 'volatility_last_month': 0.0132684, 'volatility_last_3months': 0.014189548, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.108649789', 'winloss_ratio_last_month': '1.695263704', 'winloss_ratio_last_3month': '1.311171768', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-17 14:00', 'high': 93.67, 'open': 93.6, 'close': 93.0, 'low': 92.6, 'volume': 1120, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010268365, 'volatility_last_month': 0.013247847, 'volatility_last_3months': 0.014184739, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.104347826', 'winloss_ratio_last_month': '1.699244139', 'winloss_ratio_last_3month': '1.311730524', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-17 15:00', 'high': 93.39, 'open': 92.94, 'close': 92.78, 'low': 92.75, 'volume': 988, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010251363, 'volatility_last_month': 0.013243927, 'volatility_last_3months': 0.014185838, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.099775785', 'winloss_ratio_last_month': '1.703254123', 'winloss_ratio_last_3month': '1.312288362', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-17 16:00', 'high': 93.26, 'open': 92.8, 'close': 92.91, 'low': 92.68, 'volume': 1453, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010240117, 'volatility_last_month': 0.013217442, 'volatility_last_3months': 0.014182699, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.082379863', 'winloss_ratio_last_month': '1.707293988', 'winloss_ratio_last_3month': '1.312845286', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-18 07:00', 'high': 93.18, 'open': 92.98, 'close': 92.82, 'low': 92.71, 'volume': 533, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.01022928, 'volatility_last_month': 0.013140875, 'volatility_last_3months': 0.014181646, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.156542056', 'winloss_ratio_last_month': '1.711364068', 'winloss_ratio_last_3month': '1.313401297', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-18 08:00', 'high': 92.8, 'open': 92.77, 'close': 92.7, 'low': 92.33, 'volume': 896, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010069328, 'volatility_last_month': 0.013122166, 'volatility_last_3months': 0.014181637, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.213615023', 'winloss_ratio_last_month': '1.715464704', 'winloss_ratio_last_3month': '1.313956397', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-18 09:00', 'high': 93.33, 'open': 92.68, 'close': 93.07, 'low': 92.66, 'volume': 1226, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010099743, 'volatility_last_month': 0.013130085, 'volatility_last_3months': 0.014184476, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.225', 'winloss_ratio_last_month': '1.719596241', 'winloss_ratio_last_3month': '1.314510589', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-18 10:00', 'high': 94.52, 'open': 93.13, 'close': 93.99, 'low': 92.64, 'volume': 2561, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010378183, 'volatility_last_month': 0.013185639, 'volatility_last_3months': 0.014198991, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.220892274', 'winloss_ratio_last_month': '1.723759029', 'winloss_ratio_last_3month': '1.315063876', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-18 11:00', 'high': 94.43, 'open': 93.94, 'close': 94.21, 'low': 93.71, 'volume': 851, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010405015, 'volatility_last_month': 0.013182086, 'volatility_last_3months': 0.01419432, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.217118998', 'winloss_ratio_last_month': '1.727953426', 'winloss_ratio_last_3month': '1.315616259', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-18 12:00', 'high': 94.5, 'open': 94.21, 'close': 93.7, 'low': 93.57, 'volume': 892, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010394631, 'volatility_last_month': 0.013115982, 'volatility_last_3months': 0.0141965, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.213640923', 'winloss_ratio_last_month': '1.732179791', 'winloss_ratio_last_3month': '1.31616774', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-18 13:00', 'high': 94.3, 'open': 93.7, 'close': 93.88, 'low': 93.6, 'volume': 1076, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010321257, 'volatility_last_month': 0.013096821, 'volatility_last_3months': 0.014195599, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.21042471', 'winloss_ratio_last_month': '1.736438492', 'winloss_ratio_last_3month': '1.316718322', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-18 14:00', 'high': 95.2, 'open': 93.92, 'close': 94.66, 'low': 93.9, 'volume': 2554, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010536389, 'volatility_last_month': 0.013101478, 'volatility_last_3months': 0.014205968, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.20744186', 'winloss_ratio_last_month': '1.740729901', 'winloss_ratio_last_3month': '1.317268007', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-18 15:00', 'high': 95.13, 'open': 94.66, 'close': 95.12, 'low': 94.37, 'volume': 1142, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010589686, 'volatility_last_month': 0.013106355, 'volatility_last_3months': 0.014206011, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.204667864', 'winloss_ratio_last_month': '1.745054397', 'winloss_ratio_last_3month': '1.317816797', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-18 16:00', 'high': 95.6, 'open': 95.13, 'close': 95.15, 'low': 95.0, 'volume': 2640, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.01058925, 'volatility_last_month': 0.01295196, 'volatility_last_3months': 0.0142057, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.202081526', 'winloss_ratio_last_month': '1.749412364', 'winloss_ratio_last_3month': '1.318364694', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-19 07:00', 'high': 95.44, 'open': 95.3, 'close': 94.89, 'low': 94.73, 'volume': 370, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010520527, 'volatility_last_month': 0.01222378, 'volatility_last_3months': 0.014198213, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.19966443', 'winloss_ratio_last_month': '1.753804192', 'winloss_ratio_last_3month': '1.318911701', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-19 08:00', 'high': 96.25, 'open': 94.83, 'close': 95.9, 'low': 94.83, 'volume': 1717, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010558608, 'volatility_last_month': 0.012267071, 'volatility_last_3months': 0.014208938, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.197400487', 'winloss_ratio_last_month': '1.758230277', 'winloss_ratio_last_3month': '1.319457819', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-19 09:00', 'high': 96.21, 'open': 95.88, 'close': 96.11, 'low': 95.49, 'volume': 1306, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010572663, 'volatility_last_month': 0.012248307, 'volatility_last_3months': 0.014206715, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.195275591', 'winloss_ratio_last_month': '1.762691021', 'winloss_ratio_last_3month': '1.320003051', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-19 10:00', 'high': 96.47, 'open': 96.1, 'close': 95.91, 'low': 95.8, 'volume': 1460, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.009741127, 'volatility_last_month': 0.012227005, 'volatility_last_3months': 0.014205265, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.193277311', 'winloss_ratio_last_month': '1.767186833', 'winloss_ratio_last_3month': '1.320547399', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-19 11:00', 'high': 96.25, 'open': 95.9, 'close': 95.88, 'low': 95.82, 'volume': 978, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.009645495, 'volatility_last_month': 0.01222269, 'volatility_last_3months': 0.014204989, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.191394659', 'winloss_ratio_last_month': '1.771718129', 'winloss_ratio_last_3month': '1.321090866', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-19 12:00', 'high': 95.97, 'open': 95.88, 'close': 95.39, 'low': 95.37, 'volume': 966, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.009720118, 'volatility_last_month': 0.012243866, 'volatility_last_3months': 0.014210704, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.18961788', 'winloss_ratio_last_month': '1.77628533', 'winloss_ratio_last_3month': '1.321633452', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-19 13:00', 'high': 96.28, 'open': 95.39, 'close': 96.12, 'low': 95.33, 'volume': 1312, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.00986396, 'volatility_last_month': 0.012256729, 'volatility_last_3months': 0.014217277, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.187938289', 'winloss_ratio_last_month': '1.780888864', 'winloss_ratio_last_3month': '1.322175161', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-19 14:00', 'high': 96.13, 'open': 96.13, 'close': 94.93, 'low': 94.92, 'volume': 1621, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010279722, 'volatility_last_month': 0.012364797, 'volatility_last_3months': 0.014248234, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.186348123', 'winloss_ratio_last_month': '1.785529168', 'winloss_ratio_last_3month': '1.322715994', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-19 15:00', 'high': 95.25, 'open': 94.98, 'close': 94.78, 'low': 94.6, 'volume': 1467, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010245003, 'volatility_last_month': 0.012365342, 'volatility_last_3months': 0.014248664, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.184840426', 'winloss_ratio_last_month': '1.790206682', 'winloss_ratio_last_3month': '1.323255954', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-19 16:00', 'high': 94.98, 'open': 94.83, 'close': 94.39, 'low': 94.18, 'volume': 1599, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010272865, 'volatility_last_month': 0.0123785, 'volatility_last_3months': 0.014239556, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.183408944', 'winloss_ratio_last_month': '1.794921857', 'winloss_ratio_last_3month': '1.323795043', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-20 07:00', 'high': 94.69, 'open': 94.69, 'close': 94.31, 'low': 94.09, 'volume': 522, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010050602, 'volatility_last_month': 0.01229955, 'volatility_last_3months': 0.014239169, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.18204804', 'winloss_ratio_last_month': '1.799675149', 'winloss_ratio_last_3month': '1.324333263', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-20 08:00', 'high': 94.31, 'open': 94.23, 'close': 93.41, 'low': 93.31, 'volume': 1829, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.00952764, 'volatility_last_month': 0.012308687, 'volatility_last_3months': 0.014257423, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.180752622', 'winloss_ratio_last_month': '1.804467022', 'winloss_ratio_last_3month': '1.324870615', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-20 09:00', 'high': 93.81, 'open': 93.41, 'close': 93.51, 'low': 93.3, 'volume': 1774, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.00952438, 'volatility_last_month': 0.012307511, 'volatility_last_3months': 0.014256261, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.179518072', 'winloss_ratio_last_month': '1.809297948', 'winloss_ratio_last_3month': '1.325407103', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-20 10:00', 'high': 93.7, 'open': 93.56, 'close': 92.25, 'low': 92.2, 'volume': 4014, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010031913, 'volatility_last_month': 0.012422114, 'volatility_last_3months': 0.014210191, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.1783402', 'winloss_ratio_last_month': '1.814168407', 'winloss_ratio_last_3month': '1.325942728', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-20 11:00', 'high': 92.71, 'open': 92.28, 'close': 92.2, 'low': 92.18, 'volume': 1179, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.00997246, 'volatility_last_month': 0.01242154, 'volatility_last_3months': 0.014185957, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.17721519', 'winloss_ratio_last_month': '1.819078885', 'winloss_ratio_last_3month': '1.326477491', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-20 12:00', 'high': 92.59, 'open': 92.2, 'close': 92.37, 'low': 92.18, 'volume': 989, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '32', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.009986539, 'volatility_last_month': 0.01239569, 'volatility_last_3months': 0.014180132, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.176139561', 'winloss_ratio_last_month': '1.824029878', 'winloss_ratio_last_3month': '1.327011397', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-20 13:00', 'high': 92.91, 'open': 92.28, 'close': 91.44, 'low': 91.1, 'volume': 2792, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '33', 'pnl_last_week': 0.05, 'pnl_last_month': 3.15, 'pnl_last_3months': 6.47, 'volatility_last_week': 0.010210642, 'volatility_last_month': 0.012429594, 'volatility_last_3months': 0.014201224, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.175110132', 'winloss_ratio_last_month': '1.829021889', 'winloss_ratio_last_3month': '1.327544445', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-20 14:00', 'high': 92.26, 'open': 91.43, 'close': 91.67, 'low': 91.26, 'volume': 2257, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '33', 'pnl_last_week': -0.22, 'pnl_last_month': 2.92, 'pnl_last_3months': 6.24, 'volatility_last_week': 0.01023863, 'volatility_last_month': 0.012266427, 'volatility_last_3months': 0.014175606, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.476190476, 'win_ratio_last_3month': 0.508196721, 'winloss_ratio_last_week': '1.155484558', 'winloss_ratio_last_month': '1.833650281', 'winloss_ratio_last_3month': '1.328024321', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-20 15:00', 'high': 92.41, 'open': 91.68, 'close': 92.18, 'low': 91.61, 'volume': 1238, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '33', 'pnl_last_week': -1.0, 'pnl_last_month': 2.41, 'pnl_last_3months': 5.73, 'volatility_last_week': 0.010327112, 'volatility_last_month': 0.012283119, 'volatility_last_3months': 0.014180801, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.454545455, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.080863887', 'winloss_ratio_last_month': '1.837411694', 'winloss_ratio_last_3month': '1.328387452', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-20 16:00', 'high': 92.31, 'open': 92.18, 'close': 91.74, 'low': 91.6, 'volume': 1822, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '33', 'pnl_last_week': -0.45, 'pnl_last_month': 2.85, 'pnl_last_3months': 6.17, 'volatility_last_week': 0.010370146, 'volatility_last_month': 0.012285255, 'volatility_last_3months': 0.0141853, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.47826087, 'win_ratio_last_3month': 0.507936508, 'winloss_ratio_last_week': '1.04610951', 'winloss_ratio_last_month': '1.841624758', 'winloss_ratio_last_3month': '1.328825132', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-21 07:00', 'high': 92.44, 'open': 91.95, 'close': 91.84, 'low': 91.6, 'volume': 839, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '33', 'pnl_last_week': -0.68, 'pnl_last_month': 2.75, 'pnl_last_3months': 6.07, 'volatility_last_week': 0.010375634, 'volatility_last_month': 0.012285168, 'volatility_last_3months': 0.014184646, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.458333333, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.997709574', 'winloss_ratio_last_month': '1.845688057', 'winloss_ratio_last_3month': '1.329239299', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-21 08:00', 'high': 92.11, 'open': 91.77, 'close': 90.97, 'low': 90.79, 'volume': 2122, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '33', 'pnl_last_week': 0.47, 'pnl_last_month': 3.62, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.010556403, 'volatility_last_month': 0.012349763, 'volatility_last_3months': 0.014203734, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.016606822', 'winloss_ratio_last_month': '1.850626083', 'winloss_ratio_last_3month': '1.329801139', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-21 09:00', 'high': 91.62, 'open': 90.95, 'close': 91.36, 'low': 90.36, 'volume': 1953, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '34', 'pnl_last_week': 0.47, 'pnl_last_month': 3.62, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.010619625, 'volatility_last_month': 0.012308534, 'volatility_last_3months': 0.014203976, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.034755829', 'winloss_ratio_last_month': '1.855597784', 'winloss_ratio_last_3month': '1.330361922', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-21 10:00', 'high': 92.15, 'open': 91.44, 'close': 91.26, 'low': 90.97, 'volume': 1492, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '35', 'pnl_last_week': 0.47, 'pnl_last_month': 3.62, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.010612461, 'volatility_last_month': 0.012284715, 'volatility_last_3months': 0.014203974, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.052200173', 'winloss_ratio_last_month': '1.860603506', 'winloss_ratio_last_3month': '1.330921651', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-21 11:00', 'high': 91.22, 'open': 91.22, 'close': 90.95, 'low': 90.71, 'volume': 1070, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 3.62, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.010618303, 'volatility_last_month': 0.012283685, 'volatility_last_3months': 0.014206032, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.06898011', 'winloss_ratio_last_month': '1.865643598', 'winloss_ratio_last_3month': '1.331480328', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-21 12:00', 'high': 91.15, 'open': 90.95, 'close': 90.94, 'low': 90.86, 'volume': 1049, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 3.62, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.010337837, 'volatility_last_month': 0.012283697, 'volatility_last_3months': 0.014201768, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.08513289', 'winloss_ratio_last_month': '1.870718417', 'winloss_ratio_last_3month': '1.332037957', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-21 13:00', 'high': 91.04, 'open': 90.94, 'close': 90.87, 'low': 90.71, 'volume': 1142, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 3.62, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.010232043, 'volatility_last_month': 0.012265539, 'volatility_last_3months': 0.014197471, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.100693029', 'winloss_ratio_last_month': '1.875828323', 'winloss_ratio_last_3month': '1.33259454', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-21 14:00', 'high': 90.87, 'open': 90.87, 'close': 90.73, 'low': 90.41, 'volume': 1731, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 3.62, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.010219341, 'volatility_last_month': 0.012264811, 'volatility_last_3months': 0.01415501, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.115692554', 'winloss_ratio_last_month': '1.88097368', 'winloss_ratio_last_3month': '1.333150081', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-21 15:00', 'high': 90.72, 'open': 90.72, 'close': 89.62, 'low': 89.25, 'volume': 3254, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 3.62, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.010540327, 'volatility_last_month': 0.012354968, 'volatility_last_3months': 0.014184757, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.130161227', 'winloss_ratio_last_month': '1.886154859', 'winloss_ratio_last_3month': '1.333704581', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-21 16:00', 'high': 90.25, 'open': 89.59, 'close': 89.86, 'low': 89.54, 'volume': 1698, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 3.62, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.010469077, 'volatility_last_month': 0.012359429, 'volatility_last_3months': 0.014119117, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.144126739', 'winloss_ratio_last_month': '1.891372235', 'winloss_ratio_last_3month': '1.334258046', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-24 07:00', 'high': 90.11, 'open': 90.11, 'close': 89.12, 'low': 89.0, 'volume': 941, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '11', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 3.62, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.010555648, 'volatility_last_month': 0.012407124, 'volatility_last_3months': 0.014127146, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.157614888', 'winloss_ratio_last_month': '1.896626189', 'winloss_ratio_last_3month': '1.334810476', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-24 08:00', 'high': 89.5, 'open': 89.13, 'close': 89.41, 'low': 89.0, 'volume': 1553, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '11', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 1.98, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.009882023, 'volatility_last_month': 0.012181227, 'volatility_last_3months': 0.014077218, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.458333333, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.170649739', 'winloss_ratio_last_month': '1.898611055', 'winloss_ratio_last_3month': '1.335361875', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-24 09:00', 'high': 89.4, 'open': 89.4, 'close': 88.6, 'low': 88.28, 'volume': 2774, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '11', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 2.15, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.009995371, 'volatility_last_month': 0.012236411, 'volatility_last_3months': 0.014044468, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.47826087, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.183253764', 'winloss_ratio_last_month': '1.901263799', 'winloss_ratio_last_3month': '1.335912246', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-24 10:00', 'high': 88.7, 'open': 88.59, 'close': 88.31, 'low': 87.76, 'volume': 2924, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '11', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.96, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.009466043, 'volatility_last_month': 0.012119858, 'volatility_last_3months': 0.014013676, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.454545455, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.195447977', 'winloss_ratio_last_month': '1.901519615', 'winloss_ratio_last_3month': '1.336461592', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-24 11:00', 'high': 88.55, 'open': 88.26, 'close': 88.17, 'low': 87.94, 'volume': 987, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '11', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.62, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.009466262, 'volatility_last_month': 0.012110175, 'volatility_last_3months': 0.014010169, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.428571429, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.207252044', 'winloss_ratio_last_month': '1.901083997', 'winloss_ratio_last_3month': '1.337009915', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-24 12:00', 'high': 88.26, 'open': 88.14, 'close': 88.16, 'low': 87.88, 'volume': 1027, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '11', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.009343454, 'volatility_last_month': 0.012100646, 'volatility_last_3months': 0.014009657, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.218684395', 'winloss_ratio_last_month': '1.902046963', 'winloss_ratio_last_3month': '1.33755722', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-24 13:00', 'high': 88.21, 'open': 88.19, 'close': 88.0, 'low': 87.57, 'volume': 2117, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.009343462, 'volatility_last_month': 0.012086682, 'volatility_last_3months': 0.014007087, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.229762315', 'winloss_ratio_last_month': '1.903018949', 'winloss_ratio_last_3month': '1.338103507', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-24 14:00', 'high': 88.5, 'open': 88.05, 'close': 88.08, 'low': 87.71, 'volume': 1480, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.009263716, 'volatility_last_month': 0.012086539, 'volatility_last_3months': 0.013998943, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.240502035', 'winloss_ratio_last_month': '1.904000083', 'winloss_ratio_last_3month': '1.33864878', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-24 15:00', 'high': 88.9, 'open': 88.1, 'close': 87.96, 'low': 87.96, 'volume': 2078, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.009258785, 'volatility_last_month': 0.012075459, 'volatility_last_3months': 0.01397471, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.250918811', 'winloss_ratio_last_month': '1.904990493', 'winloss_ratio_last_3month': '1.339143563', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-24 16:00', 'high': 88.71, 'open': 87.97, 'close': 88.56, 'low': 87.92, 'volume': 1912, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.009428895, 'volatility_last_month': 0.012048767, 'volatility_last_3months': 0.013984071, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.261026991', 'winloss_ratio_last_month': '1.905990312', 'winloss_ratio_last_3month': '1.339652494', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-25 07:00', 'high': 88.83, 'open': 88.58, 'close': 88.21, 'low': 88.06, 'volume': 597, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.009455817, 'volatility_last_month': 0.012058039, 'volatility_last_3months': 0.013986423, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.270840091', 'winloss_ratio_last_month': '1.906999675', 'winloss_ratio_last_3month': '1.340361587', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-25 08:00', 'high': 88.28, 'open': 88.21, 'close': 87.91, 'low': 87.61, 'volume': 1676, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.94, 'volatility_last_week': 0.009472667, 'volatility_last_month': 0.012046979, 'volatility_last_3months': 0.013988663, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.507692308, 'winloss_ratio_last_week': '1.280370844', 'winloss_ratio_last_month': '1.908018719', 'winloss_ratio_last_3month': '1.340900909', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-25 09:00', 'high': 88.07, 'open': 87.9, 'close': 87.58, 'low': 87.25, 'volume': 2531, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 7.09, 'volatility_last_week': 0.009413629, 'volatility_last_month': 0.011936754, 'volatility_last_3months': 0.013990974, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.515625, 'winloss_ratio_last_week': '1.289631264', 'winloss_ratio_last_month': '1.909047584', 'winloss_ratio_last_3month': '1.341499641', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-25 10:00', 'high': 88.03, 'open': 87.66, 'close': 87.79, 'low': 87.15, 'volume': 1810, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.13, 'volatility_last_week': 0.009070138, 'volatility_last_month': 0.010862607, 'volatility_last_3months': 0.013968428, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.507936508, 'winloss_ratio_last_week': '1.298632691', 'winloss_ratio_last_month': '1.910086412', 'winloss_ratio_last_3month': '1.341776319', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-25 11:00', 'high': 88.38, 'open': 87.77, 'close': 88.12, 'low': 87.58, 'volume': 1407, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.34, 'volatility_last_week': 0.009109522, 'volatility_last_month': 0.010857883, 'volatility_last_3months': 0.013970324, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516129032, 'winloss_ratio_last_week': '1.307385841', 'winloss_ratio_last_month': '1.91113535', 'winloss_ratio_last_3month': '1.342146512', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-25 12:00', 'high': 88.38, 'open': 88.1, 'close': 88.16, 'low': 88.0, 'volume': 894, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 7.16, 'volatility_last_week': 0.009064429, 'volatility_last_month': 0.010857684, 'volatility_last_3months': 0.013953193, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.524590164, 'winloss_ratio_last_week': '1.315900846', 'winloss_ratio_last_month': '1.912194545', 'winloss_ratio_last_3month': '1.342883261', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-25 13:00', 'high': 88.3, 'open': 88.16, 'close': 87.9, 'low': 87.62, 'volume': 1653, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.009041163, 'volatility_last_month': 0.010863315, 'volatility_last_3months': 0.013949438, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.324187295', 'winloss_ratio_last_month': '1.913264149', 'winloss_ratio_last_3month': '1.343465628', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-25 14:00', 'high': 88.19, 'open': 87.89, 'close': 88.13, 'low': 87.5, 'volume': 1579, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008791883, 'volatility_last_month': 0.010865024, 'volatility_last_3months': 0.013950207, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.332254267', 'winloss_ratio_last_month': '1.914344316', 'winloss_ratio_last_3month': '1.344047189', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-25 15:00', 'high': 88.92, 'open': 88.18, 'close': 87.23, 'low': 87.11, 'volume': 4731, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008902844, 'volatility_last_month': 0.010944787, 'volatility_last_3months': 0.013959689, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.340110369', 'winloss_ratio_last_month': '1.91592597', 'winloss_ratio_last_3month': '1.344627944', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-25 16:00', 'high': 87.5, 'open': 87.17, 'close': 86.81, 'low': 86.53, 'volume': 4061, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008918543, 'volatility_last_month': 0.010960818, 'volatility_last_3months': 0.013961355, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.347763761', 'winloss_ratio_last_month': '1.917250222', 'winloss_ratio_last_3month': '1.345207895', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-26 07:00', 'high': 87.0, 'open': 86.9, 'close': 86.5, 'low': 86.05, 'volume': 1317, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008925677, 'volatility_last_month': 0.010937594, 'volatility_last_3months': 0.013962836, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.355222191', 'winloss_ratio_last_month': '1.918598377', 'winloss_ratio_last_3month': '1.345787044', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-26 08:00', 'high': 86.8, 'open': 86.51, 'close': 85.84, 'low': 85.61, 'volume': 2774, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008503793, 'volatility_last_month': 0.010977538, 'volatility_last_3months': 0.013974572, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.362493013', 'winloss_ratio_last_month': '1.918919218', 'winloss_ratio_last_3month': '1.346365393', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-26 09:00', 'high': 86.1, 'open': 85.85, 'close': 85.7, 'low': 85.68, 'volume': 2883, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008438025, 'volatility_last_month': 0.010972091, 'volatility_last_3months': 0.013915763, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.369583218', 'winloss_ratio_last_month': '1.917943253', 'winloss_ratio_last_3month': '1.346942943', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-26 10:00', 'high': 86.23, 'open': 85.75, 'close': 85.97, 'low': 85.68, 'volume': 1594, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008536382, 'volatility_last_month': 0.010969456, 'volatility_last_3months': 0.013909427, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.376499455', 'winloss_ratio_last_month': '1.916960068', 'winloss_ratio_last_3month': '1.347519695', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-26 11:00', 'high': 86.61, 'open': 85.93, 'close': 86.34, 'low': 85.86, 'volume': 1349, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008663922, 'volatility_last_month': 0.010966366, 'volatility_last_3months': 0.013879756, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.383248047', 'winloss_ratio_last_month': '1.915969582', 'winloss_ratio_last_3month': '1.348095653', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-26 12:00', 'high': 86.45, 'open': 86.33, 'close': 86.19, 'low': 85.94, 'volume': 1111, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.00863306, 'volatility_last_month': 0.010963042, 'volatility_last_3months': 0.013877945, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.389835019', 'winloss_ratio_last_month': '1.914971713', 'winloss_ratio_last_3month': '1.348670816', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-26 13:00', 'high': 86.24, 'open': 86.14, 'close': 85.84, 'low': 85.62, 'volume': 1412, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008321415, 'volatility_last_month': 0.010873146, 'volatility_last_3months': 0.01388056, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.396266106', 'winloss_ratio_last_month': '1.91396638', 'winloss_ratio_last_3month': '1.349245187', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-26 14:00', 'high': 86.17, 'open': 85.83, 'close': 85.52, 'low': 85.42, 'volume': 2233, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.007955873, 'volatility_last_month': 0.010879302, 'volatility_last_3months': 0.013883167, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.402546778', 'winloss_ratio_last_month': '1.912953497', 'winloss_ratio_last_3month': '1.349818768', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-26 15:00', 'high': 85.69, 'open': 85.48, 'close': 85.26, 'low': 85.25, 'volume': 2135, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '35', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.007958105, 'volatility_last_month': 0.010885532, 'volatility_last_3months': 0.013883411, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.40868225', 'winloss_ratio_last_month': '1.911932979', 'winloss_ratio_last_3month': '1.350391559', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-26 16:00', 'high': 86.35, 'open': 85.25, 'close': 86.24, 'low': 85.15, 'volume': 2677, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '35', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008566788, 'volatility_last_month': 0.010875016, 'volatility_last_3months': 0.013910908, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.414677501', 'winloss_ratio_last_month': '1.91090474', 'winloss_ratio_last_3month': '1.350963564', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-27 07:00', 'high': 86.14, 'open': 85.99, 'close': 86.01, 'low': 85.4, 'volume': 804, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '35', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008565991, 'volatility_last_month': 0.01080054, 'volatility_last_3months': 0.01391102, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.420537283', 'winloss_ratio_last_month': '1.909868692', 'winloss_ratio_last_3month': '1.351534783', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-27 08:00', 'high': 86.48, 'open': 86.0, 'close': 86.07, 'low': 85.98, 'volume': 1456, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '35', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008376726, 'volatility_last_month': 0.010692477, 'volatility_last_3months': 0.013910851, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.426266137', 'winloss_ratio_last_month': '1.908824745', 'winloss_ratio_last_3month': '1.352105217', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-27 09:00', 'high': 87.35, 'open': 86.07, 'close': 86.86, 'low': 85.45, 'volume': 3451, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '35', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.00873831, 'volatility_last_month': 0.010758527, 'volatility_last_3months': 0.013927202, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.431868402', 'winloss_ratio_last_month': '1.907772809', 'winloss_ratio_last_3month': '1.352674869', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-27 10:00', 'high': 87.68, 'open': 86.95, 'close': 87.27, 'low': 86.85, 'volume': 1871, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '9', 'operations_last_3_months': '35', 'pnl_last_week': 0.47, 'pnl_last_month': 0.98, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008352017, 'volatility_last_month': 0.0107668, 'volatility_last_3months': 0.013929806, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.437348227', 'winloss_ratio_last_month': '1.906712792', 'winloss_ratio_last_3month': '1.353243741', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-27 11:00', 'high': 87.9, 'open': 87.27, 'close': 87.25, 'low': 87.23, 'volume': 2000, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '9', 'operations_last_3_months': '34', 'pnl_last_week': 0.47, 'pnl_last_month': 0.37, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008353573, 'volatility_last_month': 0.010730117, 'volatility_last_3months': 0.013929278, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.421052632, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.442709584', 'winloss_ratio_last_month': '1.904227642', 'winloss_ratio_last_3month': '1.353811833', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-27 12:00', 'high': 87.49, 'open': 87.25, 'close': 86.81, 'low': 86.81, 'volume': 1174, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '9', 'operations_last_3_months': '34', 'pnl_last_week': 0.47, 'pnl_last_month': 1.08, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008374199, 'volatility_last_month': 0.010696851, 'volatility_last_3months': 0.013818478, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.444444444, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.447956274', 'winloss_ratio_last_month': '1.903609818', 'winloss_ratio_last_3month': '1.354379147', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-27 13:00', 'high': 86.81, 'open': 86.81, 'close': 85.81, 'low': 85.5, 'volume': 3005, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '34', 'pnl_last_week': 0.47, 'pnl_last_month': 0.46, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.00847048, 'volatility_last_month': 0.010765237, 'volatility_last_3months': 0.013839588, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.411764706, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.453091935', 'winloss_ratio_last_month': '1.902270542', 'winloss_ratio_last_3month': '1.354945686', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-27 14:00', 'high': 86.24, 'open': 85.8, 'close': 85.85, 'low': 85.5, 'volume': 1885, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '34', 'pnl_last_week': 0.7, 'pnl_last_month': 0.81, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008431704, 'volatility_last_month': 0.010751899, 'volatility_last_3months': 0.013837731, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.4375, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.474835372', 'winloss_ratio_last_month': '1.902605598', 'winloss_ratio_last_3month': '1.355511449', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-27 15:00', 'high': 86.21, 'open': 85.85, 'close': 85.83, 'low': 85.81, 'volume': 1438, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '34', 'pnl_last_week': 1.21, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008271133, 'volatility_last_month': 0.010749524, 'volatility_last_3months': 0.013833471, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.53988913', 'winloss_ratio_last_month': '1.902089857', 'winloss_ratio_last_3month': '1.35607644', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-27 16:00', 'high': 86.76, 'open': 85.86, 'close': 86.68, 'low': 85.81, 'volume': 1457, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.77, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008651426, 'volatility_last_month': 0.010821105, 'volatility_last_3months': 0.013843932, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.582203599', 'winloss_ratio_last_month': '1.900774917', 'winloss_ratio_last_3month': '1.35664066', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-28 07:00', 'high': 87.23, 'open': 86.34, 'close': 86.86, 'low': 86.34, 'volume': 635, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.87, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.00866916, 'volatility_last_month': 0.010799653, 'volatility_last_3months': 0.013842899, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.638022814', 'winloss_ratio_last_month': '1.899446362', 'winloss_ratio_last_3month': '1.35720411', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-28 08:00', 'high': 87.28, 'open': 86.83, 'close': 87.2, 'low': 86.27, 'volume': 911, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008504819, 'volatility_last_month': 0.010807975, 'volatility_last_3months': 0.013845217, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.63973064', 'winloss_ratio_last_month': '1.89810398', 'winloss_ratio_last_3month': '1.357766792', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-28 09:00', 'high': 87.5, 'open': 87.19, 'close': 86.45, 'low': 86.13, 'volume': 2010, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008609531, 'volatility_last_month': 0.01086515, 'volatility_last_3months': 0.013718505, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.641514429', 'winloss_ratio_last_month': '1.896747554', 'winloss_ratio_last_3month': '1.358328708', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-28 10:00', 'high': 86.86, 'open': 86.48, 'close': 86.7, 'low': 86.28, 'volume': 1206, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008661633, 'volatility_last_month': 0.010822132, 'volatility_last_3months': 0.0136779, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.643379366', 'winloss_ratio_last_month': '1.895376862', 'winloss_ratio_last_3month': '1.358889858', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-28 11:00', 'high': 87.37, 'open': 86.68, 'close': 87.16, 'low': 86.59, 'volume': 1365, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.00876981, 'volatility_last_month': 0.010845485, 'volatility_last_3months': 0.013630281, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.645331117', 'winloss_ratio_last_month': '1.893991679', 'winloss_ratio_last_3month': '1.359450245', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-28 12:00', 'high': 87.5, 'open': 87.16, 'close': 87.4, 'low': 87.15, 'volume': 1123, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008809962, 'volatility_last_month': 0.010811544, 'volatility_last_3months': 0.013627589, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.647375887', 'winloss_ratio_last_month': '1.892591772', 'winloss_ratio_last_3month': '1.36000987', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-28 13:00', 'high': 88.16, 'open': 87.4, 'close': 87.9, 'low': 87.24, 'volume': 1345, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008944254, 'volatility_last_month': 0.010838754, 'volatility_last_3months': 0.013634577, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.649520488', 'winloss_ratio_last_month': '1.891176907', 'winloss_ratio_last_3month': '1.360568735', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-28 14:00', 'high': 87.92, 'open': 87.9, 'close': 87.24, 'low': 87.21, 'volume': 1133, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.00909034, 'volatility_last_month': 0.010861861, 'volatility_last_3months': 0.013620747, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.651772416', 'winloss_ratio_last_month': '1.889746842', 'winloss_ratio_last_3month': '1.361126841', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-28 15:00', 'high': 87.78, 'open': 87.22, 'close': 87.38, 'low': 87.18, 'volume': 712, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008665386, 'volatility_last_month': 0.01084656, 'volatility_last_3months': 0.013600922, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.654139933', 'winloss_ratio_last_month': '1.88830133', 'winloss_ratio_last_3month': '1.361684189', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-04-28 16:00', 'high': 88.0, 'open': 87.44, 'close': 87.65, 'low': 87.25, 'volume': 1475, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008674616, 'volatility_last_month': 0.010854846, 'volatility_last_3months': 0.013588383, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.656632173', 'winloss_ratio_last_month': '1.886840121', 'winloss_ratio_last_3month': '1.362240782', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-01 07:00', 'high': 88.37, 'open': 87.39, 'close': 88.28, 'low': 87.17, 'volume': 322, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008653086, 'volatility_last_month': 0.010847746, 'volatility_last_3months': 0.013592114, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.659259259', 'winloss_ratio_last_month': '1.885362956', 'winloss_ratio_last_3month': '1.362796621', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-01 08:00', 'high': 88.3, 'open': 88.23, 'close': 87.8, 'low': 87.8, 'volume': 201, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008700751, 'volatility_last_month': 0.010865329, 'volatility_last_3months': 0.013593149, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.66203244', 'winloss_ratio_last_month': '1.883869574', 'winloss_ratio_last_3month': '1.363200846', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-01 09:00', 'high': 88.1, 'open': 87.8, 'close': 88.09, 'low': 87.65, 'volume': 189, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008479435, 'volatility_last_month': 0.010809487, 'volatility_last_3months': 0.013595683, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.664964249', 'winloss_ratio_last_month': '1.882359706', 'winloss_ratio_last_3month': '1.363724758', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-01 10:00', 'high': 88.1, 'open': 88.09, 'close': 87.17, 'low': 87.03, 'volume': 282, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008801962, 'volatility_last_month': 0.010887392, 'volatility_last_3months': 0.013609157, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.6680687', 'winloss_ratio_last_month': '1.880833076', 'winloss_ratio_last_3month': '1.364480181', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-01 11:00', 'high': 87.35, 'open': 87.25, 'close': 86.6, 'low': 86.54, 'volume': 460, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.008923631, 'volatility_last_month': 0.01084255, 'volatility_last_3months': 0.01361553, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.671361502', 'winloss_ratio_last_month': '1.879289406', 'winloss_ratio_last_3month': '1.36520782', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-01 12:00', 'high': 86.79, 'open': 86.6, 'close': 85.72, 'low': 85.68, 'volume': 909, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.00922516, 'volatility_last_month': 0.010903279, 'volatility_last_3months': 0.013623805, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.674860335', 'winloss_ratio_last_month': '1.877728407', 'winloss_ratio_last_3month': '1.366086766', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-01 13:00', 'high': 86.27, 'open': 85.74, 'close': 85.8, 'low': 85.71, 'volume': 421, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.009226739, 'volatility_last_month': 0.010875547, 'volatility_last_3months': 0.013480618, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.67858516', 'winloss_ratio_last_month': '1.876149786', 'winloss_ratio_last_3month': '1.366964299', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-01 14:00', 'high': 86.42, 'open': 85.81, 'close': 85.81, 'low': 85.68, 'volume': 615, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.00922166, 'volatility_last_month': 0.01084679, 'volatility_last_3months': 0.013476494, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.682558602', 'winloss_ratio_last_month': '1.874553243', 'winloss_ratio_last_3month': '1.367840422', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-01 15:00', 'high': 86.18, 'open': 85.86, 'close': 85.99, 'low': 85.82, 'volume': 323, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '7', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.64, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.009240084, 'volatility_last_month': 0.010841256, 'volatility_last_3months': 0.013476728, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.686806412', 'winloss_ratio_last_month': '1.872938471', 'winloss_ratio_last_3month': '1.368715138', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-01 16:00', 'high': 86.2, 'open': 85.84, 'close': 85.63, 'low': 85.5, 'volume': 802, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '7', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.74, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.009111424, 'volatility_last_month': 0.010852975, 'volatility_last_3months': 0.013463133, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.428571429, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.691358025', 'winloss_ratio_last_month': '1.872201542', 'winloss_ratio_last_3month': '1.369588451', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-02 07:00', 'high': 86.21, 'open': 85.66, 'close': 86.16, 'low': 85.42, 'volume': 371, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '7', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': -0.05, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.009218182, 'volatility_last_month': 0.010822124, 'volatility_last_3months': 0.013468637, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.384615385, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.696247241', 'winloss_ratio_last_month': '1.869583244', 'winloss_ratio_last_3month': '1.370460365', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-02 08:00', 'high': 86.55, 'open': 86.12, 'close': 85.92, 'low': 85.85, 'volume': 895, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '7', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.09, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.009208015, 'volatility_last_month': 0.010825869, 'volatility_last_3months': 0.01346743, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.416666667, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.701513067', 'winloss_ratio_last_month': '1.866506128', 'winloss_ratio_last_3month': '1.371330883', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-02 09:00', 'high': 86.25, 'open': 85.94, 'close': 85.71, 'low': 85.58, 'volume': 1721, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '7', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.41, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.009186135, 'volatility_last_month': 0.010821336, 'volatility_last_3months': 0.013467725, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.454545455, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.707200763', 'winloss_ratio_last_month': '1.866199854', 'winloss_ratio_last_3month': '1.372200007', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-02 10:00', 'high': 89.72, 'open': 85.76, 'close': 89.25, 'low': 85.58, 'volume': 5365, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '7', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.52, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.01336508, 'volatility_last_month': 0.012076644, 'volatility_last_3months': 0.013795445, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.71336314', 'winloss_ratio_last_month': '1.866681216', 'winloss_ratio_last_3month': '1.373067742', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-02 11:00', 'high': 90.67, 'open': 89.2, 'close': 89.89, 'low': 88.99, 'volume': 3717, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '6', 'operations_last_3_months': '34', 'pnl_last_week': 0.0, 'pnl_last_month': 0.52, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.013439236, 'volatility_last_month': 0.012086449, 'volatility_last_3months': 0.013803708, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.720062208', 'winloss_ratio_last_month': '1.867169469', 'winloss_ratio_last_3month': '1.373934091', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-02 12:00', 'high': 90.85, 'open': 89.84, 'close': 90.6, 'low': 89.59, 'volume': 1539, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '35', 'pnl_last_week': 0.0, 'pnl_last_month': 0.52, 'pnl_last_3months': 6.7, 'volatility_last_week': 0.01355594, 'volatility_last_month': 0.012112432, 'volatility_last_3months': 0.013807784, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '1.727371274', 'winloss_ratio_last_month': '1.867664762', 'winloss_ratio_last_3month': '1.374799057', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-02 13:00', 'high': 90.68, 'open': 90.54, 'close': 89.86, 'low': 89.5, 'volume': 1968, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '35', 'pnl_last_week': -0.74, 'pnl_last_month': -0.22, 'pnl_last_3months': 5.96, 'volatility_last_week': 0.013690642, 'volatility_last_month': 0.012109343, 'volatility_last_3months': 0.013786093, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.454545455, 'win_ratio_last_3month': 0.508196721, 'winloss_ratio_last_week': '1.665395095', 'winloss_ratio_last_month': '1.866242482', 'winloss_ratio_last_3month': '1.375498983', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-02 14:00', 'high': 90.3, 'open': 89.84, 'close': 89.75, 'low': 89.6, 'volume': 987, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '35', 'pnl_last_week': -0.85, 'pnl_last_month': -0.33, 'pnl_last_3months': 5.85, 'volatility_last_week': 0.013685934, 'volatility_last_month': 0.012090364, 'volatility_last_3months': 0.013785391, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.416666667, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.593137255', 'winloss_ratio_last_month': '1.864515045', 'winloss_ratio_last_3month': '1.376173313', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-02 15:00', 'high': 90.19, 'open': 89.82, 'close': 88.74, 'low': 88.6, 'volume': 2458, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '35', 'pnl_last_week': -1.86, 'pnl_last_month': -1.34, 'pnl_last_3months': 4.84, 'volatility_last_week': 0.013735217, 'volatility_last_month': 0.012155416, 'volatility_last_3months': 0.013811785, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.384615385, 'win_ratio_last_3month': 0.492063492, 'winloss_ratio_last_week': '1.441692466', 'winloss_ratio_last_month': '1.860118503', 'winloss_ratio_last_3month': '1.376623272', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-02 16:00', 'high': 88.89, 'open': 88.75, 'close': 88.52, 'low': 88.05, 'volume': 1990, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '35', 'pnl_last_week': -2.08, 'pnl_last_month': -1.56, 'pnl_last_3months': 4.62, 'volatility_last_week': 0.01369563, 'volatility_last_month': 0.012159225, 'volatility_last_3months': 0.013812434, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.357142857, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '1.291464597', 'winloss_ratio_last_month': '1.855103195', 'winloss_ratio_last_3month': '1.377023696', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-03 07:00', 'high': 89.4, 'open': 89.0, 'close': 88.58, 'low': 88.25, 'volume': 431, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '7', 'operations_last_3_months': '35', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.013661899, 'volatility_last_month': 0.012144312, 'volatility_last_3months': 0.013808778, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.161024703', 'winloss_ratio_last_month': '1.850128502', 'winloss_ratio_last_3month': '1.377432822', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-03 08:00', 'high': 88.72, 'open': 88.61, 'close': 88.22, 'low': 87.1, 'volume': 2359, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '36', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.013565118, 'volatility_last_month': 0.012147399, 'volatility_last_3months': 0.013793062, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.044588745', 'winloss_ratio_last_month': '1.845109393', 'winloss_ratio_last_3month': '1.377841027', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-03 09:00', 'high': 88.45, 'open': 88.27, 'close': 88.0, 'low': 87.8, 'volume': 747, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '36', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.013574671, 'volatility_last_month': 0.012133337, 'volatility_last_3months': 0.013781525, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.940016434', 'winloss_ratio_last_month': '1.840045272', 'winloss_ratio_last_3month': '1.378248314', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-03 10:00', 'high': 88.12, 'open': 88.05, 'close': 86.8, 'low': 86.78, 'volume': 2115, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '36', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.013973621, 'volatility_last_month': 0.012229863, 'volatility_last_3months': 0.013796299, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.845582486', 'winloss_ratio_last_month': '1.834935528', 'winloss_ratio_last_3month': '1.378654686', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-03 11:00', 'high': 86.8, 'open': 86.75, 'close': 85.94, 'low': 85.63, 'volume': 3406, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '36', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.014141856, 'volatility_last_month': 0.012173994, 'volatility_last_3months': 0.013815005, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.759880686', 'winloss_ratio_last_month': '1.829779545', 'winloss_ratio_last_3month': '1.379060146', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-03 12:00', 'high': 86.31, 'open': 85.96, 'close': 85.47, 'low': 85.4, 'volume': 2090, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '36', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.014194856, 'volatility_last_month': 0.012188399, 'volatility_last_3months': 0.013788441, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.681753386', 'winloss_ratio_last_month': '1.82457669', 'winloss_ratio_last_3month': '1.379464698', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-03 13:00', 'high': 85.95, 'open': 85.47, 'close': 85.88, 'low': 85.4, 'volume': 1514, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '36', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.014210566, 'volatility_last_month': 0.012205009, 'volatility_last_3months': 0.013733776, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.610238908', 'winloss_ratio_last_month': '1.819326321', 'winloss_ratio_last_3month': '1.379868344', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-03 14:00', 'high': 85.91, 'open': 85.88, 'close': 84.58, 'low': 84.0, 'volume': 6649, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '36', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.014642314, 'volatility_last_month': 0.012206069, 'volatility_last_3months': 0.013763433, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.544531762', 'winloss_ratio_last_month': '1.814027786', 'winloss_ratio_last_3month': '1.380271087', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-03 15:00', 'high': 85.08, 'open': 84.52, 'close': 85.02, 'low': 84.41, 'volume': 3279, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '36', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.014681473, 'volatility_last_month': 0.012209884, 'volatility_last_3months': 0.013762503, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.483952171', 'winloss_ratio_last_month': '1.808680418', 'winloss_ratio_last_3month': '1.38067293', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-03 16:00', 'high': 85.35, 'open': 85.01, 'close': 85.15, 'low': 84.84, 'volume': 2324, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '36', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.014421493, 'volatility_last_month': 0.012209774, 'volatility_last_3months': 0.013757218, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.427922471', 'winloss_ratio_last_month': '1.803283538', 'winloss_ratio_last_3month': '1.381073877', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-04 07:00', 'high': 85.71, 'open': 85.3, 'close': 84.81, 'low': 84.31, 'volume': 1377, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '36', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.014437786, 'volatility_last_month': 0.012198365, 'volatility_last_3months': 0.013753292, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.375948628', 'winloss_ratio_last_month': '1.797836456', 'winloss_ratio_last_3month': '1.38147393', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-04 08:00', 'high': 84.84, 'open': 84.78, 'close': 83.89, 'low': 83.25, 'volume': 3965, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '36', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.014654118, 'volatility_last_month': 0.012152093, 'volatility_last_3months': 0.013767426, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.327605634', 'winloss_ratio_last_month': '1.792338469', 'winloss_ratio_last_3month': '1.381873093', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-04 09:00', 'high': 84.22, 'open': 83.88, 'close': 83.9, 'low': 83.55, 'volume': 2024, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '36', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.014467512, 'volatility_last_month': 0.012149727, 'volatility_last_3months': 0.013767427, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.282525857', 'winloss_ratio_last_month': '1.786788859', 'winloss_ratio_last_3month': '1.382271368', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-04 10:00', 'high': 85.1, 'open': 84.03, 'close': 84.83, 'low': 83.85, 'volume': 2195, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '37', 'pnl_last_week': -2.02, 'pnl_last_month': -1.5, 'pnl_last_3months': 4.68, 'volatility_last_week': 0.014679566, 'volatility_last_month': 0.012242663, 'volatility_last_3months': 0.013793423, 'win_ratio_last_week': 0.2, 'win_ratio_last_month': 0.4, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.240389679', 'winloss_ratio_last_month': '1.781186895', 'winloss_ratio_last_3month': '1.382668758', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-04 11:00', 'high': 85.1, 'open': 84.83, 'close': 84.6, 'low': 84.55, 'volume': 1069, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '37', 'pnl_last_week': -1.79, 'pnl_last_month': -1.27, 'pnl_last_3months': 4.91, 'volatility_last_week': 0.014688148, 'volatility_last_month': 0.012239703, 'volatility_last_3months': 0.013794675, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.4375, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.206782254', 'winloss_ratio_last_month': '1.775880082', 'winloss_ratio_last_3month': '1.383101513', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-04 12:00', 'high': 84.73, 'open': 84.59, 'close': 84.33, 'low': 84.3, 'volume': 983, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '37', 'pnl_last_week': -1.52, 'pnl_last_month': -1.0, 'pnl_last_3months': 5.18, 'volatility_last_week': 0.014662404, 'volatility_last_month': 0.012127337, 'volatility_last_3months': 0.013792603, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.470588235, 'win_ratio_last_3month': 0.507462687, 'winloss_ratio_last_week': '0.181908057', 'winloss_ratio_last_month': '1.770933487', 'winloss_ratio_last_3month': '1.383575812', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-04 13:00', 'high': 85.27, 'open': 84.33, 'close': 85.02, 'low': 84.3, 'volume': 1459, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '37', 'pnl_last_week': -2.21, 'pnl_last_month': -1.69, 'pnl_last_3months': 3.2, 'volatility_last_week': 0.014558429, 'volatility_last_month': 0.012061937, 'volatility_last_3months': 0.013764254, 'win_ratio_last_week': 0.375, 'win_ratio_last_month': 0.444444444, 'win_ratio_last_3month': 0.492537313, 'winloss_ratio_last_week': '0.155933003', 'winloss_ratio_last_month': '1.764078915', 'winloss_ratio_last_3month': '1.38349326', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-04 14:00', 'high': 85.44, 'open': 85.11, 'close': 84.95, 'low': 84.61, 'volume': 1481, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '37', 'pnl_last_week': -2.14, 'pnl_last_month': -1.62, 'pnl_last_3months': 4.06, 'volatility_last_week': 0.014558332, 'volatility_last_month': 0.012059234, 'volatility_last_3months': 0.013747721, 'win_ratio_last_week': 0.444444444, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.507462687, 'winloss_ratio_last_week': '0.133108866', 'winloss_ratio_last_month': '1.757280363', 'winloss_ratio_last_3month': '1.38376524', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-04 15:00', 'high': 85.11, 'open': 84.98, 'close': 84.69, 'low': 84.67, 'volume': 1012, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '37', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.56, 'volatility_last_week': 0.014574125, 'volatility_last_month': 0.012017233, 'volatility_last_3months': 0.013747962, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.52238806, 'winloss_ratio_last_week': '0.116687844', 'winloss_ratio_last_month': '1.750830769', 'winloss_ratio_last_3month': '1.384181757', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-04 16:00', 'high': 84.84, 'open': 84.76, 'close': 84.5, 'low': 84.26, 'volume': 1962, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.82, 'volatility_last_week': 0.014372213, 'volatility_last_month': 0.012017791, 'volatility_last_3months': 0.013747046, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.53030303, 'winloss_ratio_last_week': '0.110843856', 'winloss_ratio_last_month': '1.744332571', 'winloss_ratio_last_3month': '1.384710435', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-05 07:00', 'high': 85.66, 'open': 84.66, 'close': 85.47, 'low': 84.3, 'volume': 741, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014637767, 'volatility_last_month': 0.012030101, 'volatility_last_3months': 0.013770944, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.105393088', 'winloss_ratio_last_month': '1.737785218', 'winloss_ratio_last_3month': '1.385112914', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-05 08:00', 'high': 85.95, 'open': 85.44, 'close': 85.6, 'low': 84.71, 'volume': 1386, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014609, 'volatility_last_month': 0.012019759, 'volatility_last_3months': 0.013755559, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.116182573', 'winloss_ratio_last_month': '1.73118815', 'winloss_ratio_last_3month': '1.385514743', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-05 09:00', 'high': 86.9, 'open': 85.55, 'close': 86.37, 'low': 85.51, 'volume': 2555, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014633462, 'volatility_last_month': 0.012036528, 'volatility_last_3months': 0.01375786, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.125945017', 'winloss_ratio_last_month': '1.724540797', 'winloss_ratio_last_3month': '1.385915925', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-05 10:00', 'high': 86.85, 'open': 86.41, 'close': 86.1, 'low': 86.0, 'volume': 685, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.01463448, 'volatility_last_month': 0.012017304, 'volatility_last_3months': 0.013759587, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.134820403', 'winloss_ratio_last_month': '1.717842584', 'winloss_ratio_last_3month': '1.386316461', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-05 11:00', 'high': 86.59, 'open': 86.06, 'close': 86.46, 'low': 85.99, 'volume': 583, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014612959, 'volatility_last_month': 0.01202738, 'volatility_last_3months': 0.013738084, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.14292438', 'winloss_ratio_last_month': '1.711092924', 'winloss_ratio_last_3month': '1.386716352', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-05 12:00', 'high': 86.54, 'open': 86.39, 'close': 85.97, 'low': 85.95, 'volume': 494, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014653498, 'volatility_last_month': 0.012045541, 'volatility_last_3months': 0.013718157, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.15035333', 'winloss_ratio_last_month': '1.704291222', 'winloss_ratio_last_3month': '1.387115601', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-05 13:00', 'high': 86.03, 'open': 85.99, 'close': 85.53, 'low': 85.33, 'volume': 1465, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014622588, 'volatility_last_month': 0.012036047, 'volatility_last_3months': 0.013723312, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.157188222', 'winloss_ratio_last_month': '1.697436873', 'winloss_ratio_last_3month': '1.387514209', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-05 14:00', 'high': 85.81, 'open': 85.52, 'close': 84.94, 'low': 84.81, 'volume': 1516, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.01460638, 'volatility_last_month': 0.01206434, 'volatility_last_3months': 0.013719723, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.163497571', 'winloss_ratio_last_month': '1.690529266', 'winloss_ratio_last_3month': '1.387912177', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-05 15:00', 'high': 85.14, 'open': 84.93, 'close': 85.07, 'low': 84.71, 'volume': 1359, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014605765, 'volatility_last_month': 0.012048276, 'volatility_last_3months': 0.013718084, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.169339749', 'winloss_ratio_last_month': '1.683567775', 'winloss_ratio_last_3month': '1.388309507', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-05 16:00', 'high': 85.2, 'open': 85.08, 'close': 84.96, 'low': 84.8, 'volume': 1226, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014580265, 'volatility_last_month': 0.012042826, 'volatility_last_3months': 0.013714267, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.174764789', 'winloss_ratio_last_month': '1.676551768', 'winloss_ratio_last_3month': '1.3887062', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-08 07:00', 'high': 85.95, 'open': 85.0, 'close': 85.87, 'low': 85.0, 'volume': 366, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014710689, 'volatility_last_month': 0.012133826, 'volatility_last_3months': 0.013733657, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.179815829', 'winloss_ratio_last_month': '1.668889104', 'winloss_ratio_last_3month': '1.389102258', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-08 08:00', 'high': 85.99, 'open': 85.79, 'close': 85.18, 'low': 85.18, 'volume': 760, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014773852, 'volatility_last_month': 0.012131374, 'volatility_last_3months': 0.013743141, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.184530254', 'winloss_ratio_last_month': '1.663870572', 'winloss_ratio_last_3month': '1.389497683', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-08 09:00', 'high': 85.29, 'open': 85.19, 'close': 84.75, 'low': 84.5, 'volume': 1020, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014780115, 'volatility_last_month': 0.012129111, 'volatility_last_3months': 0.013728071, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.188940629', 'winloss_ratio_last_month': '1.658197266', 'winloss_ratio_last_3month': '1.389892475', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-08 10:00', 'high': 85.8, 'open': 84.95, 'close': 85.34, 'low': 84.95, 'volume': 842, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014699582, 'volatility_last_month': 0.012141586, 'volatility_last_3months': 0.013603631, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.193075448', 'winloss_ratio_last_month': '1.647609316', 'winloss_ratio_last_3month': '1.390286638', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-08 11:00', 'high': 85.94, 'open': 85.18, 'close': 85.8, 'low': 85.17, 'volume': 454, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014686135, 'volatility_last_month': 0.012167063, 'volatility_last_3months': 0.013598912, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.196959755', 'winloss_ratio_last_month': '1.636935855', 'winloss_ratio_last_3month': '1.390680171', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-08 12:00', 'high': 85.89, 'open': 85.84, 'close': 85.71, 'low': 85.63, 'volume': 301, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014485733, 'volatility_last_month': 0.012145864, 'volatility_last_3months': 0.013586324, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.200615646', 'winloss_ratio_last_month': '1.626257545', 'winloss_ratio_last_3month': '1.391073076', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-08 13:00', 'high': 85.7, 'open': 85.7, 'close': 85.08, 'low': 85.06, 'volume': 592, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014589023, 'volatility_last_month': 0.012166908, 'volatility_last_3months': 0.013596197, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.204062693', 'winloss_ratio_last_month': '1.615574382', 'winloss_ratio_last_3month': '1.391465356', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-08 14:00', 'high': 85.7, 'open': 85.08, 'close': 85.34, 'low': 85.06, 'volume': 498, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014608974, 'volatility_last_month': 0.012176447, 'volatility_last_3months': 0.013598151, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.207318296', 'winloss_ratio_last_month': '1.604886364', 'winloss_ratio_last_3month': '1.391857011', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-08 15:00', 'high': 85.52, 'open': 85.35, 'close': 85.22, 'low': 85.06, 'volume': 500, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014602219, 'volatility_last_month': 0.012170049, 'volatility_last_3months': 0.013597935, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.210397971', 'winloss_ratio_last_month': '1.594193486', 'winloss_ratio_last_3month': '1.392248043', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-08 16:00', 'high': 85.8, 'open': 85.14, 'close': 85.59, 'low': 85.14, 'volume': 1001, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014607705, 'volatility_last_month': 0.012181983, 'volatility_last_3months': 0.013599508, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.213315605', 'winloss_ratio_last_month': '1.583495745', 'winloss_ratio_last_3month': '1.392638453', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-09 07:00', 'high': 86.05, 'open': 85.45, 'close': 85.58, 'low': 85.3, 'volume': 528, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014530675, 'volatility_last_month': 0.01217997, 'volatility_last_3months': 0.013599252, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.216083657', 'winloss_ratio_last_month': '1.572793138', 'winloss_ratio_last_3month': '1.393028244', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-09 08:00', 'high': 86.8, 'open': 85.61, 'close': 85.85, 'low': 85.3, 'volume': 1643, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014536701, 'volatility_last_month': 0.01215299, 'volatility_last_3months': 0.013533022, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.218713345', 'winloss_ratio_last_month': '1.562085662', 'winloss_ratio_last_3month': '1.393417416', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-09 09:00', 'high': 86.37, 'open': 85.84, 'close': 85.97, 'low': 85.2, 'volume': 2087, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.014528362, 'volatility_last_month': 0.012147662, 'volatility_last_3months': 0.013532947, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.221214789', 'winloss_ratio_last_month': '1.551373314', 'winloss_ratio_last_3month': '1.39380597', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-09 10:00', 'high': 86.32, 'open': 85.97, 'close': 85.9, 'low': 85.6, 'volume': 1322, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.010727934, 'volatility_last_month': 0.012137241, 'volatility_last_3months': 0.013529118, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.223597147', 'winloss_ratio_last_month': '1.540656089', 'winloss_ratio_last_3month': '1.39419391', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-09 11:00', 'high': 86.19, 'open': 85.9, 'close': 85.74, 'low': 85.51, 'volume': 870, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.010557478, 'volatility_last_month': 0.012137959, 'volatility_last_3months': 0.013529635, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.225868726', 'winloss_ratio_last_month': '1.529933986', 'winloss_ratio_last_3month': '1.394581235', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-09 12:00', 'high': 85.91, 'open': 85.72, 'close': 85.66, 'low': 85.52, 'volume': 685, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.88, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.010339126, 'volatility_last_month': 0.012121812, 'volatility_last_3months': 0.013529771, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.228037077', 'winloss_ratio_last_month': '1.519207', 'winloss_ratio_last_3month': '1.394967947', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-09 13:00', 'high': 86.5, 'open': 85.66, 'close': 86.35, 'low': 85.63, 'volume': 1761, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': -1.14, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.010417454, 'volatility_last_month': 0.012149263, 'volatility_last_3months': 0.013540702, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.232873377', 'winloss_ratio_last_month': '1.508475128', 'winloss_ratio_last_3month': '1.395354048', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-09 14:00', 'high': 86.45, 'open': 86.35, 'close': 86.35, 'low': 86.12, 'volume': 1037, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '37', 'pnl_last_week': -1.03, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.010418621, 'volatility_last_month': 0.012147607, 'volatility_last_3months': 0.013540544, 'win_ratio_last_week': 0.625, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.238030096', 'winloss_ratio_last_month': '1.497738366', 'winloss_ratio_last_3month': '1.395739539', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-09 15:00', 'high': 86.8, 'open': 86.3, 'close': 86.62, 'low': 85.98, 'volume': 1378, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '37', 'pnl_last_week': -0.02, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.010137859, 'volatility_last_month': 0.012150744, 'volatility_last_3months': 0.013528743, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.247080765', 'winloss_ratio_last_month': '1.486996713', 'winloss_ratio_last_3month': '1.396124422', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-09 16:00', 'high': 87.75, 'open': 86.63, 'close': 87.51, 'low': 86.49, 'volume': 2925, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '37', 'pnl_last_week': 0.2, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.010438492, 'volatility_last_month': 0.012233011, 'volatility_last_3months': 0.013546966, 'win_ratio_last_week': 0.833333333, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.257196752', 'winloss_ratio_last_month': '1.476250163', 'winloss_ratio_last_3month': '1.396508698', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-10 07:00', 'high': 87.76, 'open': 87.69, 'close': 87.66, 'low': 87.2, 'volume': 806, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '37', 'pnl_last_week': 0.14, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.010446566, 'volatility_last_month': 0.012215447, 'volatility_last_3months': 0.013545618, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.266550523', 'winloss_ratio_last_month': '1.465498714', 'winloss_ratio_last_3month': '1.396892368', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-10 08:00', 'high': 87.88, 'open': 87.71, 'close': 87.6, 'low': 87.41, 'volume': 831, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '37', 'pnl_last_week': 0.14, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.010405323, 'volatility_last_month': 0.012140503, 'volatility_last_3months': 0.013477982, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.276122535', 'winloss_ratio_last_month': '1.454742362', 'winloss_ratio_last_3month': '1.397296835', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-10 09:00', 'high': 88.12, 'open': 87.59, 'close': 87.75, 'low': 87.53, 'volume': 1665, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '37', 'pnl_last_week': 0.14, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.010398472, 'volatility_last_month': 0.012143266, 'volatility_last_3months': 0.013474269, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.285920516', 'winloss_ratio_last_month': '1.443981104', 'winloss_ratio_last_3month': '1.397711357', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-10 10:00', 'high': 88.58, 'open': 87.75, 'close': 88.0, 'low': 87.73, 'volume': 1972, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.14, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.009875623, 'volatility_last_month': 0.01194687, 'volatility_last_3months': 0.013476023, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.295952565', 'winloss_ratio_last_month': '1.433214937', 'winloss_ratio_last_3month': '1.398132858', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-10 11:00', 'high': 88.27, 'open': 88.0, 'close': 87.59, 'low': 87.5, 'volume': 1121, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '36', 'pnl_last_week': 0.14, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.009637105, 'volatility_last_month': 0.011932196, 'volatility_last_3months': 0.013479694, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.30622717', 'winloss_ratio_last_month': '1.422443858', 'winloss_ratio_last_3month': '1.398543033', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-10 12:00', 'high': 88.74, 'open': 87.58, 'close': 88.52, 'low': 87.57, 'volume': 1022, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '37', 'pnl_last_week': 0.14, 'pnl_last_month': -1.36, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.009826857, 'volatility_last_month': 0.012019556, 'volatility_last_3months': 0.013499361, 'win_ratio_last_week': 0.8, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.523076923, 'winloss_ratio_last_week': '0.316753235', 'winloss_ratio_last_month': '1.411667862', 'winloss_ratio_last_3month': '1.398752209', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-10 13:00', 'high': 89.39, 'open': 88.5, 'close': 89.22, 'low': 88.33, 'volume': 2374, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '37', 'pnl_last_week': 0.84, 'pnl_last_month': -0.66, 'pnl_last_3months': 5.12, 'volatility_last_week': 0.009927786, 'volatility_last_month': 0.012063853, 'volatility_last_3months': 0.013499664, 'win_ratio_last_week': 0.833333333, 'win_ratio_last_month': 0.523809524, 'win_ratio_last_3month': 0.53030303, 'winloss_ratio_last_week': '0.333778966', 'winloss_ratio_last_month': '1.402028239', 'winloss_ratio_last_3month': '1.399067543', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-10 14:00', 'high': 89.46, 'open': 89.22, 'close': 89.23, 'low': 89.07, 'volume': 2072, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '37', 'pnl_last_week': 0.85, 'pnl_last_month': -0.65, 'pnl_last_3months': 4.98, 'volatility_last_week': 0.009139904, 'volatility_last_month': 0.012055776, 'volatility_last_3months': 0.013499065, 'win_ratio_last_week': 0.857142857, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.53030303, 'winloss_ratio_last_week': '0.351322083', 'winloss_ratio_last_month': '1.392400522', 'winloss_ratio_last_3month': '1.399338187', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-10 15:00', 'high': 89.67, 'open': 89.23, 'close': 89.6, 'low': 88.85, 'volume': 2089, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '37', 'pnl_last_week': 1.22, 'pnl_last_month': -0.28, 'pnl_last_3months': 5.15, 'volatility_last_week': 0.009116013, 'volatility_last_month': 0.012059365, 'volatility_last_3months': 0.013501762, 'win_ratio_last_week': 0.875, 'win_ratio_last_month': 0.565217391, 'win_ratio_last_3month': 0.53030303, 'winloss_ratio_last_week': '0.372692378', 'winloss_ratio_last_month': '1.383371937', 'winloss_ratio_last_3month': '1.399603769', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-10 16:00', 'high': 89.75, 'open': 89.55, 'close': 89.06, 'low': 89.01, 'volume': 2408, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '37', 'pnl_last_week': 0.68, 'pnl_last_month': -0.82, 'pnl_last_3months': 4.42, 'volatility_last_week': 0.009269384, 'volatility_last_month': 0.012079273, 'volatility_last_3months': 0.013508479, 'win_ratio_last_week': 0.777777778, 'win_ratio_last_month': 0.541666667, 'win_ratio_last_3month': 0.515151515, 'winloss_ratio_last_week': '0.392649903', 'winloss_ratio_last_month': '1.373129462', 'winloss_ratio_last_3month': '1.39969645', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-11 07:00', 'high': 89.68, 'open': 89.22, 'close': 88.87, 'low': 88.71, 'volume': 782, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '37', 'pnl_last_week': 0.49, 'pnl_last_month': -1.01, 'pnl_last_3months': 4.21, 'volatility_last_week': 0.00922308, 'volatility_last_month': 0.012025522, 'volatility_last_3months': 0.01350938, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.412195348', 'winloss_ratio_last_month': '1.362478626', 'winloss_ratio_last_3month': '1.399742554', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-11 08:00', 'high': 90.0, 'open': 88.81, 'close': 89.1, 'low': 88.72, 'volume': 2843, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '38', 'pnl_last_week': 0.49, 'pnl_last_month': -1.01, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008771228, 'volatility_last_month': 0.011840847, 'volatility_last_3months': 0.013501136, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.431981352', 'winloss_ratio_last_month': '1.351848236', 'winloss_ratio_last_3month': '1.399600754', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-11 09:00', 'high': 89.65, 'open': 89.07, 'close': 89.4, 'low': 89.05, 'volume': 1767, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '38', 'pnl_last_week': 0.49, 'pnl_last_month': -1.01, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008781926, 'volatility_last_month': 0.011849053, 'volatility_last_3months': 0.013501211, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.452012384', 'winloss_ratio_last_month': '1.341238236', 'winloss_ratio_last_3month': '1.399459248', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-11 10:00', 'high': 89.59, 'open': 89.45, 'close': 89.44, 'low': 88.85, 'volume': 2638, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '38', 'pnl_last_week': 0.49, 'pnl_last_month': -1.01, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008458874, 'volatility_last_month': 0.011844498, 'volatility_last_3months': 0.013488453, 'win_ratio_last_week': 0.7, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.472293024', 'winloss_ratio_last_month': '1.330648566', 'winloss_ratio_last_3month': '1.399318035', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-11 11:00', 'high': 90.12, 'open': 89.44, 'close': 89.96, 'low': 89.32, 'volume': 1434, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '38', 'pnl_last_week': 0.26, 'pnl_last_month': -1.01, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008481886, 'volatility_last_month': 0.011857127, 'volatility_last_3months': 0.013438805, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.48845825', 'winloss_ratio_last_month': '1.320079168', 'winloss_ratio_last_3month': '1.399177116', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-11 12:00', 'high': 90.01, 'open': 89.86, 'close': 88.85, 'low': 88.3, 'volume': 1883, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '38', 'pnl_last_week': -0.01, 'pnl_last_month': -1.01, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009024388, 'volatility_last_month': 0.011964745, 'volatility_last_3months': 0.013468421, 'win_ratio_last_week': 0.625, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.499665424', 'winloss_ratio_last_month': '1.309529984', 'winloss_ratio_last_3month': '1.399036489', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-11 13:00', 'high': 89.07, 'open': 88.85, 'close': 88.68, 'low': 88.44, 'volume': 1279, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '38', 'pnl_last_week': 0.68, 'pnl_last_month': -1.01, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008883692, 'volatility_last_month': 0.011958312, 'volatility_last_3months': 0.013462814, 'win_ratio_last_week': 0.714285714, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.517890221', 'winloss_ratio_last_month': '1.299000955', 'winloss_ratio_last_3month': '1.398896153', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-11 14:00', 'high': 88.75, 'open': 88.69, 'close': 87.79, 'low': 87.67, 'volume': 1486, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '38', 'pnl_last_week': 0.61, 'pnl_last_month': -1.05, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009248891, 'volatility_last_month': 0.01202707, 'volatility_last_3months': 0.013481846, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.535462051', 'winloss_ratio_last_month': '1.288362613', 'winloss_ratio_last_3month': '1.398756107', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-11 15:00', 'high': 88.5, 'open': 87.8, 'close': 88.3, 'low': 87.73, 'volume': 1006, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.32, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009282883, 'volatility_last_month': 0.012049756, 'volatility_last_3months': 0.01347375, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.47826087, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.548482079', 'winloss_ratio_last_month': '1.276871859', 'winloss_ratio_last_3month': '1.398616351', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-11 16:00', 'high': 88.61, 'open': 88.3, 'close': 88.57, 'low': 88.24, 'volume': 1306, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.21, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009266946, 'volatility_last_month': 0.012056377, 'volatility_last_3months': 0.013472425, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.562052877', 'winloss_ratio_last_month': '1.265852634', 'winloss_ratio_last_3month': '1.398476883', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-12 07:00', 'high': 88.94, 'open': 88.54, 'close': 88.78, 'low': 88.28, 'volume': 298, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.34, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008920847, 'volatility_last_month': 0.012060719, 'volatility_last_3months': 0.013473626, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.476190476, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.576210147', 'winloss_ratio_last_month': '1.252324551', 'winloss_ratio_last_3month': '1.398337702', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-12 08:00', 'high': 89.25, 'open': 88.78, 'close': 88.59, 'low': 88.59, 'volume': 1483, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008945277, 'volatility_last_month': 0.012055385, 'volatility_last_3months': 0.013466286, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.590992747', 'winloss_ratio_last_month': '1.238300334', 'winloss_ratio_last_3month': '1.398198809', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-12 09:00', 'high': 88.97, 'open': 88.58, 'close': 88.45, 'low': 88.15, 'volume': 1183, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008732038, 'volatility_last_month': 0.012055842, 'volatility_last_3months': 0.013466255, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.606443042', 'winloss_ratio_last_month': '1.226083054', 'winloss_ratio_last_3month': '1.398060201', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-12 10:00', 'high': 89.75, 'open': 88.45, 'close': 89.14, 'low': 88.45, 'volume': 1745, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008855922, 'volatility_last_month': 0.012101779, 'volatility_last_3months': 0.013471994, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.622607317', 'winloss_ratio_last_month': '1.217911831', 'winloss_ratio_last_3month': '1.397921877', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-12 11:00', 'high': 89.5, 'open': 89.13, 'close': 89.25, 'low': 89.12, 'volume': 364, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.00881687, 'volatility_last_month': 0.012098328, 'volatility_last_3months': 0.01346394, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.639536232', 'winloss_ratio_last_month': '1.210613572', 'winloss_ratio_last_3month': '1.397783838', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-12 12:00', 'high': 89.33, 'open': 89.24, 'close': 88.89, 'low': 88.86, 'volume': 530, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008757529, 'volatility_last_month': 0.0120293, 'volatility_last_3months': 0.013446743, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.657285358', 'winloss_ratio_last_month': '1.203354531', 'winloss_ratio_last_3month': '1.397646082', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-12 13:00', 'high': 89.01, 'open': 88.9, 'close': 88.92, 'low': 88.65, 'volume': 615, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008644594, 'volatility_last_month': 0.012005121, 'volatility_last_3months': 0.013442908, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.675915784', 'winloss_ratio_last_month': '1.196134392', 'winloss_ratio_last_3month': '1.397508609', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-12 14:00', 'high': 89.0, 'open': 88.92, 'close': 88.71, 'low': 88.14, 'volume': 1213, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008476971, 'volatility_last_month': 0.0120057, 'volatility_last_3months': 0.013409049, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.695494821', 'winloss_ratio_last_month': '1.188952845', 'winloss_ratio_last_3month': '1.397371416', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-12 15:00', 'high': 88.91, 'open': 88.77, 'close': 88.59, 'low': 88.5, 'volume': 522, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008491687, 'volatility_last_month': 0.011991081, 'volatility_last_3months': 0.013378958, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.716096811', 'winloss_ratio_last_month': '1.181809579', 'winloss_ratio_last_3month': '1.397234505', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-12 16:00', 'high': 88.93, 'open': 88.66, 'close': 88.93, 'low': 88.3, 'volume': 1424, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008505795, 'volatility_last_month': 0.011980536, 'volatility_last_3months': 0.013381345, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.737804076', 'winloss_ratio_last_month': '1.174704291', 'winloss_ratio_last_3month': '1.397097872', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-15 07:00', 'high': 89.19, 'open': 88.5, 'close': 87.85, 'low': 87.71, 'volume': 938, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008730068, 'volatility_last_month': 0.012072264, 'volatility_last_3months': 0.013407369, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.760708012', 'winloss_ratio_last_month': '1.167636677', 'winloss_ratio_last_3month': '1.396961519', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-15 08:00', 'high': 88.6, 'open': 87.83, 'close': 88.28, 'low': 87.25, 'volume': 1664, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008541939, 'volatility_last_month': 0.011890135, 'volatility_last_3months': 0.013359674, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.784910379', 'winloss_ratio_last_month': '1.16060644', 'winloss_ratio_last_3month': '1.396825444', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-15 09:00', 'high': 88.78, 'open': 88.25, 'close': 88.47, 'low': 88.12, 'volume': 989, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.008432193, 'volatility_last_month': 0.011867634, 'volatility_last_3months': 0.013360112, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.810524811', 'winloss_ratio_last_month': '1.153613283', 'winloss_ratio_last_3month': '1.396689646', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-15 10:00', 'high': 88.67, 'open': 88.62, 'close': 87.06, 'low': 86.55, 'volume': 4243, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009212179, 'volatility_last_month': 0.011928403, 'volatility_last_3months': 0.013414611, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.837678598', 'winloss_ratio_last_month': '1.146656913', 'winloss_ratio_last_3month': '1.396554124', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-15 11:00', 'high': 87.14, 'open': 87.02, 'close': 86.51, 'low': 86.13, 'volume': 1236, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009267881, 'volatility_last_month': 0.011953428, 'volatility_last_3months': 0.013422773, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.866514806', 'winloss_ratio_last_month': '1.139737042', 'winloss_ratio_last_3month': '1.396455857', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-15 12:00', 'high': 86.9, 'open': 86.48, 'close': 86.78, 'low': 86.16, 'volume': 1521, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009289263, 'volatility_last_month': 0.011939346, 'volatility_last_3months': 0.013416669, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.897194797', 'winloss_ratio_last_month': '1.132853383', 'winloss_ratio_last_3month': '1.396375522', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-15 13:00', 'high': 87.57, 'open': 86.79, 'close': 87.3, 'low': 86.5, 'volume': 1139, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009199142, 'volatility_last_month': 0.011967088, 'volatility_last_3months': 0.01341569, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.929901247', 'winloss_ratio_last_month': '1.126005652', 'winloss_ratio_last_3month': '1.396301255', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-15 14:00', 'high': 87.57, 'open': 87.25, 'close': 86.94, 'low': 86.5, 'volume': 1490, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009243287, 'volatility_last_month': 0.01194866, 'volatility_last_3months': 0.013415481, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '0.964841788', 'winloss_ratio_last_month': '1.119193568', 'winloss_ratio_last_3month': '1.396306772', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-15 15:00', 'high': 87.2, 'open': 86.94, 'close': 87.0, 'low': 86.8, 'volume': 947, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009233466, 'volatility_last_month': 0.01194639, 'volatility_last_3months': 0.013409222, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.002253423', 'winloss_ratio_last_month': '1.112416855', 'winloss_ratio_last_3month': '1.396123055', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-15 16:00', 'high': 87.14, 'open': 87.0, 'close': 87.14, 'low': 86.7, 'volume': 1824, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009189447, 'volatility_last_month': 0.011946942, 'volatility_last_3months': 0.013379365, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.042407907', 'winloss_ratio_last_month': '1.105675237', 'winloss_ratio_last_3month': '1.395939758', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-16 07:00', 'high': 87.31, 'open': 87.0, 'close': 87.1, 'low': 86.92, 'volume': 354, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009190828, 'volatility_last_month': 0.011946649, 'volatility_last_3months': 0.013377461, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.085618355', 'winloss_ratio_last_month': '1.098968442', 'winloss_ratio_last_3month': '1.395756882', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-16 08:00', 'high': 87.75, 'open': 87.16, 'close': 87.11, 'low': 86.66, 'volume': 1185, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009165863, 'volatility_last_month': 0.011946086, 'volatility_last_3months': 0.0133645, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.132247431', 'winloss_ratio_last_month': '1.092296201', 'winloss_ratio_last_3month': '1.395574424', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-16 09:00', 'high': 87.7, 'open': 87.1, 'close': 87.42, 'low': 87.1, 'volume': 1578, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009195099, 'volatility_last_month': 0.011943526, 'volatility_last_3months': 0.01334917, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.182717545', 'winloss_ratio_last_month': '1.085658249', 'winloss_ratio_last_3month': '1.395392382', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-16 10:00', 'high': 87.96, 'open': 87.46, 'close': 87.62, 'low': 87.15, 'volume': 1511, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009202276, 'volatility_last_month': 0.01187305, 'volatility_last_3months': 0.013349629, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.237523689', 'winloss_ratio_last_month': '1.07905432', 'winloss_ratio_last_3month': '1.395210756', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-16 11:00', 'high': 87.68, 'open': 87.59, 'close': 87.4, 'low': 87.25, 'volume': 467, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009212758, 'volatility_last_month': 0.011871096, 'volatility_last_3months': 0.013349525, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.297249725', 'winloss_ratio_last_month': '1.072484155', 'winloss_ratio_last_3month': '1.395029544', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-16 12:00', 'high': 88.0, 'open': 87.38, 'close': 87.81, 'low': 87.37, 'volume': 1044, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009262939, 'volatility_last_month': 0.011870739, 'volatility_last_3months': 0.013321641, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.362589265', 'winloss_ratio_last_month': '1.065947495', 'winloss_ratio_last_3month': '1.394848744', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-16 13:00', 'high': 89.17, 'open': 87.79, 'close': 89.04, 'low': 87.79, 'volume': 2741, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009636586, 'volatility_last_month': 0.012014041, 'volatility_last_3months': 0.013364184, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.409166469', 'winloss_ratio_last_month': '1.059444085', 'winloss_ratio_last_3month': '1.394668356', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-16 14:00', 'high': 89.19, 'open': 89.03, 'close': 88.83, 'low': 88.5, 'volume': 1371, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009661543, 'volatility_last_month': 0.011963955, 'volatility_last_3months': 0.013365102, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.454789834', 'winloss_ratio_last_month': '1.052973671', 'winloss_ratio_last_3month': '1.394488377', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-16 15:00', 'high': 89.23, 'open': 88.79, 'close': 88.76, 'low': 88.56, 'volume': 1347, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009646622, 'volatility_last_month': 0.011944598, 'volatility_last_3months': 0.013364576, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.465881198', 'winloss_ratio_last_month': '1.046536003', 'winloss_ratio_last_3month': '1.394308807', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-16 16:00', 'high': 89.11, 'open': 88.76, 'close': 88.93, 'low': 88.19, 'volume': 1510, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009361458, 'volatility_last_month': 0.011947985, 'volatility_last_3months': 0.013364163, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.469102501', 'winloss_ratio_last_month': '1.040130832', 'winloss_ratio_last_3month': '1.394129644', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-17 07:00', 'high': 89.5, 'open': 88.94, 'close': 89.41, 'low': 88.7, 'volume': 733, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '38', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009433205, 'volatility_last_month': 0.011967357, 'volatility_last_3months': 0.013368588, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.473787359', 'winloss_ratio_last_month': '1.033757914', 'winloss_ratio_last_3month': '1.393950886', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-17 08:00', 'high': 89.75, 'open': 89.42, 'close': 89.25, 'low': 89.22, 'volume': 1394, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '39', 'pnl_last_week': 0.35, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.009444158, 'volatility_last_month': 0.011882056, 'volatility_last_3months': 0.013344097, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.478463045', 'winloss_ratio_last_month': '1.027417006', 'winloss_ratio_last_3month': '1.393772532', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-17 09:00', 'high': 89.23, 'open': 89.21, 'close': 87.8, 'low': 87.55, 'volume': 2700, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '39', 'pnl_last_week': -1.1, 'pnl_last_month': -2.51, 'pnl_last_3months': 2.14, 'volatility_last_week': 0.010241019, 'volatility_last_month': 0.012062443, 'volatility_last_3months': 0.013384956, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.476190476, 'win_ratio_last_3month': 0.484848485, 'winloss_ratio_last_week': '1.432349469', 'winloss_ratio_last_month': '1.01891788', 'winloss_ratio_last_3month': '1.393301698', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-17 10:00', 'high': 88.5, 'open': 87.87, 'close': 88.07, 'low': 87.7, 'volume': 1193, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '39', 'pnl_last_week': -0.83, 'pnl_last_month': -2.24, 'pnl_last_3months': 2.41, 'volatility_last_week': 0.010244753, 'volatility_last_month': 0.012069371, 'volatility_last_3months': 0.013319203, 'win_ratio_last_week': 0.571428571, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492537313, 'winloss_ratio_last_week': '1.395529197', 'winloss_ratio_last_month': '1.01089485', 'winloss_ratio_last_3month': '1.392871204', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-17 11:00', 'high': 88.36, 'open': 88.07, 'close': 88.14, 'low': 87.55, 'volume': 848, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '39', 'pnl_last_week': -0.76, 'pnl_last_month': -2.17, 'pnl_last_3months': 2.48, 'volatility_last_week': 0.010182864, 'volatility_last_month': 0.01207043, 'volatility_last_3months': 0.013295846, 'win_ratio_last_week': 0.625, 'win_ratio_last_month': 0.52173913, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.362673726', 'winloss_ratio_last_month': '1.003048244', 'winloss_ratio_last_3month': '1.392451979', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-17 12:00', 'high': 88.2, 'open': 88.08, 'close': 87.82, 'low': 87.75, 'volume': 898, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '39', 'pnl_last_week': -1.08, 'pnl_last_month': -2.49, 'pnl_last_3months': 2.16, 'volatility_last_week': 0.009899496, 'volatility_last_month': 0.012061942, 'volatility_last_3months': 0.013298233, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.492753623, 'winloss_ratio_last_week': '1.322868053', 'winloss_ratio_last_month': '0.994809134', 'winloss_ratio_last_3month': '1.39196957', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-17 13:00', 'high': 87.87, 'open': 87.87, 'close': 87.67, 'low': 87.6, 'volume': 805, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '39', 'pnl_last_week': -1.93, 'pnl_last_month': -2.64, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.009711513, 'volatility_last_month': 0.012016202, 'volatility_last_3months': 0.013298585, 'win_ratio_last_week': 0.444444444, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.253564155', 'winloss_ratio_last_month': '0.986438072', 'winloss_ratio_last_3month': '1.391458403', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-17 14:00', 'high': 88.37, 'open': 87.67, 'close': 88.02, 'low': 87.6, 'volume': 1282, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '40', 'pnl_last_week': -1.94, 'pnl_last_month': -2.64, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.009765563, 'volatility_last_month': 0.011925041, 'volatility_last_3months': 0.013290338, 'win_ratio_last_week': 0.375, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.189189189', 'winloss_ratio_last_month': '0.978154219', 'winloss_ratio_last_3month': '1.390948664', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-17 15:00', 'high': 88.25, 'open': 88.03, 'close': 88.1, 'low': 87.85, 'volume': 418, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '40', 'pnl_last_week': -2.31, 'pnl_last_month': -2.64, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.009711532, 'volatility_last_month': 0.011925169, 'volatility_last_3months': 0.013269384, 'win_ratio_last_week': 0.285714286, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.115616748', 'winloss_ratio_last_month': '0.969956219', 'winloss_ratio_last_3month': '1.390440349', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-17 16:00', 'high': 88.5, 'open': 88.04, 'close': 88.04, 'low': 87.97, 'volume': 1170, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '40', 'pnl_last_week': -1.77, 'pnl_last_month': -2.64, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.009613077, 'volatility_last_month': 0.011914901, 'volatility_last_3months': 0.013265637, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.068274583', 'winloss_ratio_last_month': '0.961842744', 'winloss_ratio_last_3month': '1.38993345', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-18 07:00', 'high': 88.62, 'open': 88.49, 'close': 88.45, 'low': 88.06, 'volume': 275, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '40', 'pnl_last_week': -1.58, 'pnl_last_month': -2.64, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.009670519, 'volatility_last_month': 0.011932793, 'volatility_last_3months': 0.013253089, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '1.029595588', 'winloss_ratio_last_month': '0.953812494', 'winloss_ratio_last_3month': '1.389427963', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-18 08:00', 'high': 88.75, 'open': 88.42, 'close': 88.59, 'low': 88.24, 'volume': 499, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '40', 'pnl_last_week': -1.58, 'pnl_last_month': -2.64, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.009657438, 'volatility_last_month': 0.011872249, 'volatility_last_3months': 0.013166359, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '0.991621129', 'winloss_ratio_last_month': '0.945864194', 'winloss_ratio_last_3month': '1.388923881', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-18 09:00', 'high': 88.97, 'open': 88.58, 'close': 88.64, 'low': 88.51, 'volume': 575, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '40', 'pnl_last_week': -1.58, 'pnl_last_month': -2.64, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.009622246, 'volatility_last_month': 0.011871446, 'volatility_last_3months': 0.013131955, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '0.95433213', 'winloss_ratio_last_month': '0.937996597', 'winloss_ratio_last_3month': '1.388421199', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-18 10:00', 'high': 88.88, 'open': 88.64, 'close': 88.26, 'low': 88.19, 'volume': 581, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '40', 'pnl_last_week': -1.58, 'pnl_last_month': -2.64, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.009670575, 'volatility_last_month': 0.011752929, 'volatility_last_3months': 0.013097227, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '0.917710197', 'winloss_ratio_last_month': '0.930208479', 'winloss_ratio_last_3month': '1.38791991', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-18 11:00', 'high': 88.46, 'open': 88.25, 'close': 88.24, 'low': 87.92, 'volume': 608, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '40', 'pnl_last_week': -1.58, 'pnl_last_month': -2.64, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.009558301, 'volatility_last_month': 0.011752852, 'volatility_last_3months': 0.013088724, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '0.885815603', 'winloss_ratio_last_month': '0.922498643', 'winloss_ratio_last_3month': '1.387420008', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-18 12:00', 'high': 88.54, 'open': 88.21, 'close': 88.53, 'low': 88.18, 'volume': 238, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '40', 'pnl_last_week': -1.58, 'pnl_last_month': -2.64, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.009138748, 'volatility_last_month': 0.011758763, 'volatility_last_3months': 0.013090988, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '0.859226714', 'winloss_ratio_last_month': '0.914865913', 'winloss_ratio_last_3month': '1.386921489', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-18 13:00', 'high': 89.17, 'open': 88.59, 'close': 88.87, 'low': 88.55, 'volume': 768, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '40', 'pnl_last_week': -1.58, 'pnl_last_month': -2.64, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.009173957, 'volatility_last_month': 0.011697428, 'volatility_last_3months': 0.013070514, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.48, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '0.823205371', 'winloss_ratio_last_month': '0.90730914', 'winloss_ratio_last_3month': '1.386424346', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-18 14:00', 'high': 89.43, 'open': 88.87, 'close': 89.11, 'low': 88.8, 'volume': 916, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '40', 'pnl_last_week': -1.58, 'pnl_last_month': -2.41, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.008858257, 'volatility_last_month': 0.011698182, 'volatility_last_3months': 0.013051447, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '0.789811066', 'winloss_ratio_last_month': '0.900395235', 'winloss_ratio_last_3month': '1.385928574', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-18 15:00', 'high': 90.2, 'open': 89.16, 'close': 90.2, 'low': 89.15, 'volume': 2239, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '40', 'pnl_last_week': -1.58, 'pnl_last_month': -1.9, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.009207854, 'volatility_last_month': 0.011786044, 'volatility_last_3months': 0.013084806, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.52173913, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '0.762030759', 'winloss_ratio_last_month': '0.894787975', 'winloss_ratio_last_3month': '1.385434167', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-18 16:00', 'high': 90.21, 'open': 90.2, 'close': 89.65, 'low': 89.52, 'volume': 2433, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '41', 'pnl_last_week': -1.58, 'pnl_last_month': -2.34, 'pnl_last_3months': 2.01, 'volatility_last_week': 0.009314252, 'volatility_last_month': 0.011796525, 'volatility_last_3months': 0.013092254, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.485714286, 'winloss_ratio_last_week': '0.735322738', 'winloss_ratio_last_month': '0.888012661', 'winloss_ratio_last_3month': '1.384941119', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-19 07:00', 'high': 90.76, 'open': 89.66, 'close': 90.57, 'low': 89.56, 'volume': 1042, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '41', 'pnl_last_week': -0.66, 'pnl_last_month': -1.32, 'pnl_last_3months': 2.93, 'volatility_last_week': 0.009599307, 'volatility_last_month': 0.011873453, 'volatility_last_3months': 0.013104095, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.492957746, 'winloss_ratio_last_week': '0.724264121', 'winloss_ratio_last_month': '0.882768381', 'winloss_ratio_last_3month': '1.384579495', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-19 08:00', 'high': 90.93, 'open': 90.55, 'close': 90.76, 'low': 90.25, 'volume': 1456, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '41', 'pnl_last_week': -0.47, 'pnl_last_month': -2.0, 'pnl_last_3months': 3.12, 'volatility_last_week': 0.009587474, 'volatility_last_month': 0.011810869, 'volatility_last_3months': 0.013105048, 'win_ratio_last_week': 0.571428571, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.716583385', 'winloss_ratio_last_month': '0.875447883', 'winloss_ratio_last_3month': '1.38424569', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-19 09:00', 'high': 91.25, 'open': 90.8, 'close': 90.67, 'low': 90.5, 'volume': 1450, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '11', 'operations_last_3_months': '41', 'pnl_last_week': -0.56, 'pnl_last_month': -2.09, 'pnl_last_3months': 3.03, 'volatility_last_week': 0.00958118, 'volatility_last_month': 0.011797926, 'volatility_last_3months': 0.01308988, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52173913, 'win_ratio_last_3month': 0.493150685, 'winloss_ratio_last_week': '0.708205756', 'winloss_ratio_last_month': '0.868059879', 'winloss_ratio_last_3month': '1.383895237', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-19 10:00', 'high': 90.78, 'open': 90.67, 'close': 90.44, 'low': 90.0, 'volume': 1258, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '10', 'operations_last_3_months': '41', 'pnl_last_week': -0.79, 'pnl_last_month': -2.32, 'pnl_last_3months': 2.8, 'volatility_last_week': 0.009442397, 'volatility_last_month': 0.011801706, 'volatility_last_3months': 0.013091104, 'win_ratio_last_week': 0.444444444, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.486486486, 'winloss_ratio_last_week': '0.697740536', 'winloss_ratio_last_month': '0.860445032', 'winloss_ratio_last_3month': '1.383500948', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-19 11:00', 'high': 90.75, 'open': 90.45, 'close': 90.7, 'low': 90.42, 'volume': 681, 'dateload': '07/06/2023 7: 22', 'position': 1, 'operations_last_week': 3, 'operations_last_month': '9', 'operations_last_3_months': '41', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009459822, 'volatility_last_month': 0.01179952, 'volatility_last_3months': 0.01309308, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.691542289', 'winloss_ratio_last_month': '0.853226067', 'winloss_ratio_last_3month': '1.383144327', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-19 12:00', 'high': 90.81, 'open': 90.67, 'close': 90.63, 'low': 90.31, 'volume': 724, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009404674, 'volatility_last_month': 0.011799935, 'volatility_last_3months': 0.013093168, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.685612026', 'winloss_ratio_last_month': '0.846050673', 'winloss_ratio_last_3month': '1.382788709', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-19 13:00', 'high': 91.03, 'open': 90.65, 'close': 90.77, 'low': 90.51, 'volume': 883, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009408667, 'volatility_last_month': 0.011801292, 'volatility_last_3months': 0.013084823, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.679932735', 'winloss_ratio_last_month': '0.838918456', 'winloss_ratio_last_3month': '1.382434092', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-19 14:00', 'high': 91.03, 'open': 90.74, 'close': 90.85, 'low': 90.55, 'volume': 1502, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009385073, 'volatility_last_month': 0.01180011, 'volatility_last_3months': 0.013078353, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.674488816', 'winloss_ratio_last_month': '0.831829029', 'winloss_ratio_last_3month': '1.382080471', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-19 15:00', 'high': 90.96, 'open': 90.87, 'close': 89.8, 'low': 89.69, 'volume': 1981, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009808184, 'volatility_last_month': 0.011788023, 'volatility_last_3months': 0.013105461, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.669265932', 'winloss_ratio_last_month': '0.824782008', 'winloss_ratio_last_3month': '1.381727841', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-19 16:00', 'high': 90.14, 'open': 89.79, 'close': 89.43, 'low': 89.33, 'volume': 1808, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009824103, 'volatility_last_month': 0.011795247, 'volatility_last_3months': 0.013092846, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.664250889', 'winloss_ratio_last_month': '0.817777012', 'winloss_ratio_last_3month': '1.381376199', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-22 07:00', 'high': 89.93, 'open': 89.93, 'close': 88.1, 'low': 88.02, 'volume': 1756, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.010043514, 'volatility_last_month': 0.011909352, 'volatility_last_3months': 0.013140294, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.659431525', 'winloss_ratio_last_month': '0.810813668', 'winloss_ratio_last_3month': '1.381025541', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-22 08:00', 'high': 88.61, 'open': 88.19, 'close': 87.48, 'low': 87.2, 'volume': 2276, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.010113636, 'volatility_last_month': 0.011936767, 'volatility_last_3months': 0.01310912, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.654796604', 'winloss_ratio_last_month': '0.806052143', 'winloss_ratio_last_3month': '1.380675862', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-22 09:00', 'high': 87.82, 'open': 87.51, 'close': 87.14, 'low': 86.9, 'volume': 2550, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.010135286, 'volatility_last_month': 0.011888128, 'volatility_last_3months': 0.013097208, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.650335737', 'winloss_ratio_last_month': '0.801139871', 'winloss_ratio_last_3month': '1.380327158', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-22 10:00', 'high': 87.41, 'open': 87.1, 'close': 87.17, 'low': 86.77, 'volume': 1809, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009387538, 'volatility_last_month': 0.011880745, 'volatility_last_3months': 0.013092215, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.646039302', 'winloss_ratio_last_month': '0.797816497', 'winloss_ratio_last_3month': '1.379979425', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-22 11:00', 'high': 87.49, 'open': 87.19, 'close': 87.41, 'low': 87.05, 'volume': 935, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009280425, 'volatility_last_month': 0.011884777, 'volatility_last_3months': 0.013093515, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.64189837', 'winloss_ratio_last_month': '0.794957786', 'winloss_ratio_last_3month': '1.379632659', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-22 12:00', 'high': 87.8, 'open': 87.37, 'close': 87.59, 'low': 87.12, 'volume': 1035, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.0092647, 'volatility_last_month': 0.011887983, 'volatility_last_3months': 0.013091621, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.63790465', 'winloss_ratio_last_month': '0.791746304', 'winloss_ratio_last_3month': '1.379286856', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-22 13:00', 'high': 87.83, 'open': 87.6, 'close': 87.45, 'low': 87.28, 'volume': 867, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009166365, 'volatility_last_month': 0.011887451, 'volatility_last_3months': 0.013075756, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.634050428', 'winloss_ratio_last_month': '0.788557955', 'winloss_ratio_last_3month': '1.378942013', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-22 14:00', 'high': 87.86, 'open': 87.44, 'close': 87.45, 'low': 87.26, 'volume': 1116, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009110581, 'volatility_last_month': 0.011886802, 'volatility_last_3months': 0.013072379, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.630328521', 'winloss_ratio_last_month': '0.78539249', 'winloss_ratio_last_3month': '1.378598124', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-22 15:00', 'high': 87.8, 'open': 87.46, 'close': 87.6, 'low': 87.4, 'volume': 1220, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009117535, 'volatility_last_month': 0.011887718, 'volatility_last_3months': 0.01307295, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.626732231', 'winloss_ratio_last_month': '0.782249662', 'winloss_ratio_last_3month': '1.378255187', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-22 16:00', 'high': 87.95, 'open': 87.57, 'close': 87.25, 'low': 87.25, 'volume': 1781, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009162766, 'volatility_last_month': 0.011864872, 'volatility_last_3months': 0.013061447, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.623255303', 'winloss_ratio_last_month': '0.779129231', 'winloss_ratio_last_3month': '1.377913196', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-23 07:00', 'high': 87.76, 'open': 87.4, 'close': 86.98, 'low': 86.98, 'volume': 991, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.00919223, 'volatility_last_month': 0.011860512, 'volatility_last_3months': 0.013024551, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.619891892', 'winloss_ratio_last_month': '0.776030957', 'winloss_ratio_last_3month': '1.377572149', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-23 08:00', 'high': 87.44, 'open': 86.98, 'close': 86.82, 'low': 86.68, 'volume': 1691, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009202227, 'volatility_last_month': 0.011854641, 'volatility_last_3months': 0.013025139, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.616636528', 'winloss_ratio_last_month': '0.772954606', 'winloss_ratio_last_3month': '1.377232042', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-23 09:00', 'high': 87.11, 'open': 86.83, 'close': 86.72, 'low': 86.51, 'volume': 1594, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009163457, 'volatility_last_month': 0.011845454, 'volatility_last_3months': 0.013014702, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.613484087', 'winloss_ratio_last_month': '0.769899945', 'winloss_ratio_last_3month': '1.376892869', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-23 10:00', 'high': 86.64, 'open': 86.64, 'close': 85.91, 'low': 85.77, 'volume': 3283, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009400408, 'volatility_last_month': 0.01190436, 'volatility_last_3months': 0.012987018, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.610429764', 'winloss_ratio_last_month': '0.766866747', 'winloss_ratio_last_3month': '1.376554629', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-23 11:00', 'high': 86.54, 'open': 85.91, 'close': 86.21, 'low': 85.91, 'volume': 644, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009430512, 'volatility_last_month': 0.011902912, 'volatility_last_3months': 0.012981066, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.607469048', 'winloss_ratio_last_month': '0.763854784', 'winloss_ratio_last_3month': '1.376217316', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-23 12:00', 'high': 86.5, 'open': 86.18, 'close': 86.5, 'low': 86.13, 'volume': 716, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.009395678, 'volatility_last_month': 0.011911429, 'volatility_last_3months': 0.012952218, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.604597701', 'winloss_ratio_last_month': '0.760863837', 'winloss_ratio_last_3month': '1.375880927', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-23 13:00', 'high': 86.71, 'open': 86.5, 'close': 85.88, 'low': 85.85, 'volume': 1319, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.008880876, 'volatility_last_month': 0.011942131, 'volatility_last_3months': 0.012959524, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.601811737', 'winloss_ratio_last_month': '0.757893684', 'winloss_ratio_last_3month': '1.375545459', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-23 14:00', 'high': 85.95, 'open': 85.93, 'close': 85.71, 'low': 85.48, 'volume': 1821, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 3.06, 'volatility_last_week': 0.008877297, 'volatility_last_month': 0.011939164, 'volatility_last_3months': 0.012958028, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.493333333, 'winloss_ratio_last_week': '0.599107403', 'winloss_ratio_last_month': '0.75494411', 'winloss_ratio_last_3month': '1.375210906', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-23 15:00', 'high': 86.21, 'open': 85.78, 'close': 85.71, 'low': 85.6, 'volume': 1542, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 5, 'operations_last_month': '11', 'operations_last_3_months': '43', 'pnl_last_week': -0.53, 'pnl_last_month': -2.06, 'pnl_last_3months': 2.61, 'volatility_last_week': 0.008878899, 'volatility_last_month': 0.011863912, 'volatility_last_3months': 0.012953779, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52, 'win_ratio_last_3month': 0.486486486, 'winloss_ratio_last_week': '0.596481163', 'winloss_ratio_last_month': '0.752014902', 'winloss_ratio_last_3month': '1.374754873', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-23 16:00', 'high': 85.92, 'open': 85.68, 'close': 85.8, 'low': 85.35, 'volume': 2520, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 5, 'operations_last_month': '11', 'operations_last_3_months': '43', 'pnl_last_week': -0.62, 'pnl_last_month': -2.15, 'pnl_last_3months': 2.65, 'volatility_last_week': 0.008866619, 'volatility_last_month': 0.011848272, 'volatility_last_3months': 0.012953603, 'win_ratio_last_week': 0.454545455, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.486486486, 'winloss_ratio_last_week': '0.593426257', 'winloss_ratio_last_month': '0.749022422', 'winloss_ratio_last_3month': '1.374331812', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-24 07:00', 'high': 86.11, 'open': 85.81, 'close': 85.95, 'low': 85.43, 'volume': 580, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 5, 'operations_last_month': '11', 'operations_last_3_months': '43', 'pnl_last_week': -0.77, 'pnl_last_month': -2.3, 'pnl_last_3months': 2.49, 'volatility_last_week': 0.008764029, 'volatility_last_month': 0.011841472, 'volatility_last_3months': 0.01295446, 'win_ratio_last_week': 0.416666667, 'win_ratio_last_month': 0.481481481, 'win_ratio_last_3month': 0.472972973, 'winloss_ratio_last_week': '0.589641434', 'winloss_ratio_last_month': '0.745913159', 'winloss_ratio_last_3month': '1.373879219', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-24 08:00', 'high': 86.26, 'open': 85.96, 'close': 85.55, 'low': 85.05, 'volume': 1960, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 4, 'operations_last_month': '11', 'operations_last_3_months': '43', 'pnl_last_week': -0.37, 'pnl_last_month': -1.9, 'pnl_last_3months': 3.38, 'volatility_last_week': 0.008809425, 'volatility_last_month': 0.011814457, 'volatility_last_3months': 0.012952782, 'win_ratio_last_week': 0.461538462, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.486486486, 'winloss_ratio_last_week': '0.589624362', 'winloss_ratio_last_month': '0.743318267', 'winloss_ratio_last_3month': '1.373664362', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-24 09:00', 'high': 85.69, 'open': 85.53, 'close': 85.55, 'low': 85.05, 'volume': 1040, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 4, 'operations_last_month': '11', 'operations_last_3_months': '43', 'pnl_last_week': 1.08, 'pnl_last_month': -1.9, 'pnl_last_3months': 3.99, 'volatility_last_week': 0.007964961, 'volatility_last_month': 0.011812528, 'volatility_last_3months': 0.012943598, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.493150685, 'winloss_ratio_last_week': '0.605362573', 'winloss_ratio_last_month': '0.740742554', 'winloss_ratio_last_3month': '1.373676768', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-24 10:00', 'high': 85.78, 'open': 85.56, 'close': 85.18, 'low': 84.63, 'volume': 2719, 'dateload': '07/06/2023 7: 22', 'position': -1, 'operations_last_week': 4, 'operations_last_month': '11', 'operations_last_3_months': '43', 'pnl_last_week': 1.18, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.36, 'volatility_last_week': 0.007967032, 'volatility_last_month': 0.011818767, 'volatility_last_3months': 0.012946968, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.619854722', 'winloss_ratio_last_month': '0.738636918', 'winloss_ratio_last_3month': '1.373739162', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-24 11:00', 'high': 85.37, 'open': 85.18, 'close': 85.17, 'low': 84.94, 'volume': 751, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '12', 'operations_last_3_months': '44', 'pnl_last_week': 1.11, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.36, 'volatility_last_week': 0.007960337, 'volatility_last_month': 0.011804833, 'volatility_last_3months': 0.012940219, 'win_ratio_last_week': 0.454545455, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.633342747', 'winloss_ratio_last_month': '0.736546731', 'winloss_ratio_last_3month': '1.373801425', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-24 12:00', 'high': 85.31, 'open': 85.17, 'close': 85.11, 'low': 85.03, 'volume': 689, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '12', 'operations_last_3_months': '44', 'pnl_last_week': 1.43, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.36, 'volatility_last_week': 0.007928131, 'volatility_last_month': 0.011803072, 'volatility_last_3months': 0.012939118, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.651091536', 'winloss_ratio_last_month': '0.734471824', 'winloss_ratio_last_3month': '1.373863557', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-24 13:00', 'high': 85.19, 'open': 85.1, 'close': 85.01, 'low': 84.55, 'volume': 1321, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 5, 'operations_last_month': '12', 'operations_last_3_months': '44', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.36, 'volatility_last_week': 0.007924946, 'volatility_last_month': 0.011792129, 'volatility_last_3months': 0.01293928, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.67826087', 'winloss_ratio_last_month': '0.73241203', 'winloss_ratio_last_3month': '1.373925557', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-24 14:00', 'high': 85.89, 'open': 85.0, 'close': 85.23, 'low': 84.89, 'volume': 1574, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '44', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.36, 'volatility_last_week': 0.00788502, 'volatility_last_month': 0.011787075, 'volatility_last_3months': 0.01294103, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.706662677', 'winloss_ratio_last_month': '0.730367184', 'winloss_ratio_last_3month': '1.373987428', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-24 15:00', 'high': 85.68, 'open': 85.27, 'close': 85.27, 'low': 85.25, 'volume': 1237, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '44', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.36, 'volatility_last_week': 0.007880727, 'volatility_last_month': 0.011780441, 'volatility_last_3months': 0.012919673, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.740042783', 'winloss_ratio_last_month': '0.728337124', 'winloss_ratio_last_3month': '1.374049168', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-24 16:00', 'high': 85.35, 'open': 85.3, 'close': 84.79, 'low': 84.77, 'volume': 1932, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '44', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.36, 'volatility_last_week': 0.007969497, 'volatility_last_month': 0.011706284, 'volatility_last_3months': 0.012923436, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.770544401', 'winloss_ratio_last_month': '0.726321691', 'winloss_ratio_last_3month': '1.374110779', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-25 07:00', 'high': 85.15, 'open': 85.03, 'close': 84.56, 'low': 84.43, 'volume': 899, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '44', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.36, 'volatility_last_week': 0.007873625, 'volatility_last_month': 0.011706461, 'volatility_last_3months': 0.012918671, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.800440899', 'winloss_ratio_last_month': '0.724320726', 'winloss_ratio_last_3month': '1.374172261', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-25 08:00', 'high': 84.82, 'open': 84.52, 'close': 83.75, 'low': 83.65, 'volume': 3788, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '44', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.36, 'volatility_last_week': 0.008116662, 'volatility_last_month': 0.011773003, 'volatility_last_3months': 0.012937653, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.831203408', 'winloss_ratio_last_month': '0.722334076', 'winloss_ratio_last_3month': '1.374233614', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-25 09:00', 'high': 83.9, 'open': 83.72, 'close': 83.5, 'low': 83.01, 'volume': 4023, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '44', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.36, 'volatility_last_week': 0.00811801, 'volatility_last_month': 0.011714833, 'volatility_last_3months': 0.012938176, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.86287011', 'winloss_ratio_last_month': '0.720361585', 'winloss_ratio_last_3month': '1.374294839', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-25 10:00', 'high': 83.69, 'open': 83.51, 'close': 83.22, 'low': 83.01, 'volume': 2557, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '44', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.36, 'volatility_last_week': 0.008100419, 'volatility_last_month': 0.011704142, 'volatility_last_3months': 0.012928945, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.895481465', 'winloss_ratio_last_month': '0.718403104', 'winloss_ratio_last_3month': '1.374355936', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-25 11:00', 'high': 83.32, 'open': 83.28, 'close': 83.02, 'low': 82.75, 'volume': 1885, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '44', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.36, 'volatility_last_week': 0.008102257, 'volatility_last_month': 0.011707616, 'volatility_last_3months': 0.012929909, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.929080383', 'winloss_ratio_last_month': '0.717173256', 'winloss_ratio_last_3month': '1.374416905', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-25 12:00', 'high': 83.5, 'open': 83.01, 'close': 83.15, 'low': 82.95, 'volume': 1045, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '44', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.93, 'volatility_last_week': 0.008057477, 'volatility_last_month': 0.011692727, 'volatility_last_3months': 0.012924132, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.506849315, 'winloss_ratio_last_week': '0.963712412', 'winloss_ratio_last_month': '0.715359039', 'winloss_ratio_last_3month': '1.374686934', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-25 13:00', 'high': 83.2, 'open': 83.16, 'close': 82.82, 'low': 82.68, 'volume': 1449, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '44', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.007986808, 'volatility_last_month': 0.01160659, 'volatility_last_3months': 0.012917332, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.999425947', 'winloss_ratio_last_month': '0.714280736', 'winloss_ratio_last_3month': '1.374788418', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-25 14:00', 'high': 82.98, 'open': 82.82, 'close': 82.88, 'low': 82.67, 'volume': 1617, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '43', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.2, 'volatility_last_week': 0.007942003, 'volatility_last_month': 0.011606889, 'volatility_last_3months': 0.012917187, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.492957746, 'winloss_ratio_last_week': '1.036272452', 'winloss_ratio_last_month': '0.712923141', 'winloss_ratio_last_3month': '1.374863069', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-25 15:00', 'high': 83.27, 'open': 82.85, 'close': 83.06, 'low': 82.84, 'volume': 1435, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 4, 'operations_last_month': '12', 'operations_last_3_months': '43', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.4, 'volatility_last_week': 0.007283513, 'volatility_last_month': 0.011610984, 'volatility_last_3months': 0.012917925, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.074306708', 'winloss_ratio_last_month': '0.711773911', 'winloss_ratio_last_3month': '1.375010819', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-25 16:00', 'high': 83.2, 'open': 83.09, 'close': 82.63, 'low': 82.63, 'volume': 2037, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '43', 'pnl_last_week': 1.58, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.00725407, 'volatility_last_month': 0.011553767, 'volatility_last_3months': 0.012920961, 'win_ratio_last_week': 0.555555556, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.113587087', 'winloss_ratio_last_month': '0.710635422', 'winloss_ratio_last_3month': '1.375286404', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-26 07:00', 'high': 83.12, 'open': 82.69, 'close': 82.83, 'low': 82.5, 'volume': 974, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '43', 'pnl_last_week': 0.66, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.006739438, 'volatility_last_month': 0.011555049, 'volatility_last_3months': 0.012905762, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.131643399', 'winloss_ratio_last_month': '0.709507524', 'winloss_ratio_last_3month': '1.37556158', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-26 08:00', 'high': 83.3, 'open': 82.84, 'close': 82.82, 'low': 82.56, 'volume': 2074, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '43', 'pnl_last_week': 0.47, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.006686259, 'volatility_last_month': 0.011542071, 'volatility_last_3months': 0.012903134, 'win_ratio_last_week': 0.428571429, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.145579078', 'winloss_ratio_last_month': '0.708390069', 'winloss_ratio_last_3month': '1.375836349', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-26 09:00', 'high': 83.03, 'open': 82.79, 'close': 82.88, 'low': 82.62, 'volume': 1725, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '43', 'pnl_last_week': 0.56, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.006711051, 'volatility_last_month': 0.011489455, 'volatility_last_3months': 0.012893174, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.162646013', 'winloss_ratio_last_month': '0.707282913', 'winloss_ratio_last_3month': '1.376110712', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-26 10:00', 'high': 83.37, 'open': 82.92, 'close': 83.08, 'low': 82.9, 'volume': 1134, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.79, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.00678215, 'volatility_last_month': 0.011487388, 'volatility_last_3months': 0.012795439, 'win_ratio_last_week': 0.6, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.187467431', 'winloss_ratio_last_month': '0.706185915', 'winloss_ratio_last_3month': '1.376384669', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-26 11:00', 'high': 83.25, 'open': 83.09, 'close': 83.04, 'low': 82.89, 'volume': 1032, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 3, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.006698334, 'volatility_last_month': 0.011464363, 'volatility_last_3months': 0.012794236, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.206661316', 'winloss_ratio_last_month': '0.705098935', 'winloss_ratio_last_3month': '1.376658222', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-26 12:00', 'high': 83.11, 'open': 83.01, 'close': 82.86, 'low': 82.7, 'volume': 912, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.006694635, 'volatility_last_month': 0.011460301, 'volatility_last_3months': 0.01278946, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.22691039', 'winloss_ratio_last_month': '0.704021836', 'winloss_ratio_last_3month': '1.376931371', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-26 13:00', 'high': 82.87, 'open': 82.86, 'close': 82.0, 'low': 81.92, 'volume': 2405, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.006950693, 'volatility_last_month': 0.011510554, 'volatility_last_3months': 0.012794307, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.248304127', 'winloss_ratio_last_month': '0.702954485', 'winloss_ratio_last_3month': '1.377204118', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-26 14:00', 'high': 82.56, 'open': 82.03, 'close': 82.29, 'low': 81.94, 'volume': 1263, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.007042548, 'volatility_last_month': 0.011482379, 'volatility_last_3months': 0.012795527, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.270942408', 'winloss_ratio_last_month': '0.70189675', 'winloss_ratio_last_3month': '1.377476462', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-26 15:00', 'high': 82.42, 'open': 82.26, 'close': 82.35, 'low': 82.15, 'volume': 758, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.006669715, 'volatility_last_month': 0.011480445, 'volatility_last_3months': 0.012795753, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.294937088', 'winloss_ratio_last_month': '0.700848501', 'winloss_ratio_last_3month': '1.377748406', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-26 16:00', 'high': 83.31, 'open': 82.35, 'close': 83.13, 'low': 82.21, 'volume': 2484, 'dateload': '07/06/2023 7: 22', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.007149511, 'volatility_last_month': 0.011543051, 'volatility_last_3months': 0.012815606, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.320413836', 'winloss_ratio_last_month': '0.699809611', 'winloss_ratio_last_3month': '1.378019949', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-29 07:00', 'high': 83.52, 'open': 83.01, 'close': 83.46, 'low': 83.01, 'volume': 342, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.006470163, 'volatility_last_month': 0.011514896, 'volatility_last_3months': 0.01281281, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.34751434', 'winloss_ratio_last_month': '0.698779956', 'winloss_ratio_last_3month': '1.378291093', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-29 08:00', 'high': 84.39, 'open': 83.45, 'close': 84.39, 'low': 83.2, 'volume': 991, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.006924293, 'volatility_last_month': 0.011590881, 'volatility_last_3months': 0.012841365, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.376398947', 'winloss_ratio_last_month': '0.697759413', 'winloss_ratio_last_3month': '1.378561839', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-29 09:00', 'high': 84.46, 'open': 84.4, 'close': 84.46, 'low': 83.95, 'volume': 539, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.006890399, 'volatility_last_month': 0.011582483, 'volatility_last_3months': 0.012820718, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.40724983', 'winloss_ratio_last_month': '0.696747862', 'winloss_ratio_last_3month': '1.378832187', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-29 10:00', 'high': 84.45, 'open': 84.45, 'close': 83.99, 'low': 83.8, 'volume': 244, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.006985527, 'volatility_last_month': 0.011524448, 'volatility_last_3months': 0.012816995, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.440274841', 'winloss_ratio_last_month': '0.695745184', 'winloss_ratio_last_3month': '1.379102139', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-29 11:00', 'high': 84.13, 'open': 83.95, 'close': 83.32, 'low': 83.3, 'volume': 417, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.007141601, 'volatility_last_month': 0.011539944, 'volatility_last_3months': 0.012830594, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.475712199', 'winloss_ratio_last_month': '0.694751263', 'winloss_ratio_last_3month': '1.379371695', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-29 12:00', 'high': 83.4, 'open': 83.33, 'close': 83.11, 'low': 83.05, 'volume': 339, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.007113058, 'volatility_last_month': 0.011467952, 'volatility_last_3months': 0.012812807, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.51383624', 'winloss_ratio_last_month': '0.693765985', 'winloss_ratio_last_3month': '1.379640856', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-29 13:00', 'high': 83.28, 'open': 83.06, 'close': 83.03, 'low': 83.0, 'volume': 469, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.007111833, 'volatility_last_month': 0.011467538, 'volatility_last_3months': 0.012760474, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.554964539', 'winloss_ratio_last_month': '0.692789237', 'winloss_ratio_last_3month': '1.379909623', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-29 14:00', 'high': 83.18, 'open': 83.02, 'close': 82.88, 'low': 82.77, 'volume': 386, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.007109617, 'volatility_last_month': 0.011469513, 'volatility_last_3months': 0.012759459, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.599466776', 'winloss_ratio_last_month': '0.691820909', 'winloss_ratio_last_3month': '1.380177997', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-29 15:00', 'high': 82.96, 'open': 82.88, 'close': 82.7, 'low': 82.65, 'volume': 540, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.007081775, 'volatility_last_month': 0.011468574, 'volatility_last_3months': 0.012749399, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.507246377, 'winloss_ratio_last_week': '1.647775877', 'winloss_ratio_last_month': '0.690860894', 'winloss_ratio_last_3month': '1.380445978', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-29 16:00', 'high': 83.12, 'open': 82.7, 'close': 83.0, 'low': 82.7, 'volume': 477, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.45, 'volatility_last_week': 0.007136371, 'volatility_last_month': 0.011467251, 'volatility_last_3months': 0.012750625, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.700402145', 'winloss_ratio_last_month': '0.689836828', 'winloss_ratio_last_3month': '1.380634901', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-30 07:00', 'high': 83.35, 'open': 83.05, 'close': 83.1, 'low': 82.8, 'volume': 593, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.27, 'volatility_last_week': 0.007136651, 'volatility_last_month': 0.011438185, 'volatility_last_3months': 0.012750369, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.492537313, 'winloss_ratio_last_week': '1.757951356', 'winloss_ratio_last_month': '0.689645464', 'winloss_ratio_last_3month': '1.380776381', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-30 08:00', 'high': 83.19, 'open': 83.19, 'close': 81.72, 'low': 81.6, 'volume': 3680, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 4.72, 'volatility_last_week': 0.008066355, 'volatility_last_month': 0.011639043, 'volatility_last_3months': 0.012806483, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '1.821148184', 'winloss_ratio_last_month': '0.689355562', 'winloss_ratio_last_3month': '1.381080387', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-30 09:00', 'high': 81.96, 'open': 81.72, 'close': 81.59, 'low': 81.51, 'volume': 2304, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 3.84, 'volatility_last_week': 0.008066848, 'volatility_last_month': 0.011636781, 'volatility_last_3months': 0.012787537, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.492307692, 'winloss_ratio_last_week': '1.890866873', 'winloss_ratio_last_month': '0.688840448', 'winloss_ratio_last_3month': '1.381153714', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-30 10:00', 'high': 81.77, 'open': 81.57, 'close': 81.15, 'low': 81.05, 'volume': 2803, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.00788836, 'volatility_last_month': 0.010337845, 'volatility_last_3months': 0.012793034, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '1.968171926', 'winloss_ratio_last_month': '0.688252408', 'winloss_ratio_last_3month': '1.381182486', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-30 11:00', 'high': 81.25, 'open': 81.14, 'close': 80.81, 'low': 80.71, 'volume': 1740, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '12', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.007839925, 'volatility_last_month': 0.010300053, 'volatility_last_3months': 0.012793545, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '2.054372842', 'winloss_ratio_last_month': '0.687670084', 'winloss_ratio_last_3month': '1.381211224', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-30 12:00', 'high': 82.12, 'open': 80.79, 'close': 81.54, 'low': 80.72, 'volume': 2686, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -1.53, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.008141028, 'volatility_last_month': 0.010317059, 'volatility_last_3months': 0.012807997, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.517241379, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '2.151098901', 'winloss_ratio_last_month': '0.687093391', 'winloss_ratio_last_3month': '1.381239926', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-30 13:00', 'high': 81.92, 'open': 81.5, 'close': 81.46, 'low': 81.09, 'volume': 2154, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -0.79, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.008009493, 'volatility_last_month': 0.01026727, 'volatility_last_3months': 0.012803028, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.535714286, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '2.260403121', 'winloss_ratio_last_month': '0.687553274', 'winloss_ratio_last_3month': '1.381268593', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-30 14:00', 'high': 81.51, 'open': 81.46, 'close': 80.52, 'low': 80.4, 'volume': 3611, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '11', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': -0.68, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.00839087, 'volatility_last_month': 0.010370448, 'volatility_last_3months': 0.01283015, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.555555556, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '2.384909597', 'winloss_ratio_last_month': '0.688163232', 'winloss_ratio_last_3month': '1.381297226', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-30 15:00', 'high': 80.75, 'open': 80.51, 'close': 80.45, 'low': 80.4, 'volume': 2446, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '11', 'operations_last_3_months': '42', 'pnl_last_week': 0.53, 'pnl_last_month': 0.33, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.008385954, 'volatility_last_month': 0.010272307, 'volatility_last_3months': 0.012822323, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.576923077, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '2.528026906', 'winloss_ratio_last_month': '0.690175432', 'winloss_ratio_last_3month': '1.381325824', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-30 16:00', 'high': 80.74, 'open': 80.45, 'close': 80.38, 'low': 80.35, 'volume': 2530, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '11', 'operations_last_3_months': '41', 'pnl_last_week': 0.62, 'pnl_last_month': 0.55, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.008367796, 'volatility_last_month': 0.01026906, 'volatility_last_3months': 0.012822241, 'win_ratio_last_week': 0.666666667, 'win_ratio_last_month': 0.6, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '2.713995118', 'winloss_ratio_last_month': '0.692491037', 'winloss_ratio_last_3month': '1.381354386', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-31 07:00', 'high': 80.72, 'open': 80.48, 'close': 79.91, 'low': 79.8, 'volume': 1235, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '11', 'operations_last_3_months': '41', 'pnl_last_week': 0.77, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.008403224, 'volatility_last_month': 0.010292234, 'volatility_last_3months': 0.012823988, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '2.9760181', 'winloss_ratio_last_month': '0.694682762', 'winloss_ratio_last_3month': '1.381382914', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-31 08:00', 'high': 80.18, 'open': 79.85, 'close': 79.77, 'low': 79.41, 'volume': 3228, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '41', 'pnl_last_week': 0.37, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.00836761, 'volatility_last_month': 0.010282814, 'volatility_last_3months': 0.012805543, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.263506626', 'winloss_ratio_last_month': '0.696871879', 'winloss_ratio_last_3month': '1.381411408', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-31 09:00', 'high': 79.98, 'open': 79.72, 'close': 79.56, 'low': 79.31, 'volume': 1904, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '41', 'pnl_last_week': 0.37, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.008365661, 'volatility_last_month': 0.010283299, 'volatility_last_3months': 0.012801938, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.350726197', 'winloss_ratio_last_month': '0.699058392', 'winloss_ratio_last_3month': '1.381439866', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-31 10:00', 'high': 81.41, 'open': 79.6, 'close': 80.69, 'low': 79.57, 'volume': 3969, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 1, 'operations_last_month': '10', 'operations_last_3_months': '41', 'pnl_last_week': 0.0, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009113223, 'volatility_last_month': 0.010312481, 'volatility_last_3months': 0.012847334, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.421412301', 'winloss_ratio_last_month': '0.701242305', 'winloss_ratio_last_3month': '1.381468291', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-31 11:00', 'high': 80.88, 'open': 80.72, 'close': 80.57, 'low': 80.39, 'volume': 1362, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '41', 'pnl_last_week': 0.0, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009110676, 'volatility_last_month': 0.010235397, 'volatility_last_3months': 0.012847623, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.505142166', 'winloss_ratio_last_month': '0.703423623', 'winloss_ratio_last_3month': '1.38149668', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-31 12:00', 'high': 81.1, 'open': 80.55, 'close': 80.89, 'low': 80.21, 'volume': 1830, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009189828, 'volatility_last_month': 0.010227996, 'volatility_last_3months': 0.012830248, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.527180784', 'winloss_ratio_last_month': '0.705602351', 'winloss_ratio_last_3month': '1.381525036', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-31 13:00', 'high': 80.98, 'open': 80.9, 'close': 80.84, 'low': 80.45, 'volume': 1847, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009190228, 'volatility_last_month': 0.010206181, 'volatility_last_3months': 0.012830149, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.5163827', 'winloss_ratio_last_month': '0.707778493', 'winloss_ratio_last_3month': '1.381553356', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-31 14:00', 'high': 81.88, 'open': 80.84, 'close': 81.52, 'low': 80.54, 'volume': 2728, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009420701, 'volatility_last_month': 0.010076761, 'volatility_last_3months': 0.012839929, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.504761905', 'winloss_ratio_last_month': '0.709952054', 'winloss_ratio_last_3month': '1.381581643', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-31 15:00', 'high': 82.0, 'open': 81.57, 'close': 81.35, 'low': 81.35, 'volume': 1626, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009419036, 'volatility_last_month': 0.010054731, 'volatility_last_3months': 0.012828938, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.492220651', 'winloss_ratio_last_month': '0.712123039', 'winloss_ratio_last_3month': '1.381609895', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-05-31 16:00', 'high': 81.47, 'open': 81.38, 'close': 80.77, 'low': 80.45, 'volume': 2187, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009469176, 'volatility_last_month': 0.010093262, 'volatility_last_3months': 0.01283952, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.478645066', 'winloss_ratio_last_month': '0.714291451', 'winloss_ratio_last_3month': '1.381638114', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-01 07:00', 'high': 81.02, 'open': 81.02, 'close': 79.7, 'low': 79.64, 'volume': 1255, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009907879, 'volatility_last_month': 0.010227134, 'volatility_last_3months': 0.012866623, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.46390169', 'winloss_ratio_last_month': '0.716457297', 'winloss_ratio_last_3month': '1.381666298', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-01 08:00', 'high': 80.54, 'open': 79.71, 'close': 80.0, 'low': 79.46, 'volume': 2483, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009760203, 'volatility_last_month': 0.010144517, 'volatility_last_3months': 0.012859984, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.447833066', 'winloss_ratio_last_month': '0.718620579', 'winloss_ratio_last_3month': '1.381694448', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-01 09:00', 'high': 80.15, 'open': 79.99, 'close': 80.0, 'low': 79.7, 'volume': 1707, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '10', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009749462, 'volatility_last_month': 0.010144456, 'volatility_last_3months': 0.012839383, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.430252101', 'winloss_ratio_last_month': '0.720781303', 'winloss_ratio_last_3month': '1.381722564', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-01 10:00', 'high': 81.11, 'open': 80.0, 'close': 80.55, 'low': 80.0, 'volume': 2711, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.49, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009899155, 'volatility_last_month': 0.010078562, 'volatility_last_3months': 0.012850153, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.583333333, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.410934744', 'winloss_ratio_last_month': '0.722939474', 'winloss_ratio_last_3month': '1.381750646', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-01 11:00', 'high': 80.81, 'open': 80.55, 'close': 80.27, 'low': 80.24, 'volume': 1239, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.26, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009913427, 'volatility_last_month': 0.010082317, 'volatility_last_3months': 0.012851999, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.565217391, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.38961039', 'winloss_ratio_last_month': '0.724634635', 'winloss_ratio_last_3month': '1.381778694', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-01 12:00', 'high': 80.75, 'open': 80.31, 'close': 80.22, 'low': 80.05, 'volume': 1290, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': -0.01, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009898589, 'volatility_last_month': 0.010074976, 'volatility_last_3months': 0.012837841, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.545454545, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.365949119', 'winloss_ratio_last_month': '0.725787573', 'winloss_ratio_last_3month': '1.381806709', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-01 13:00', 'high': 80.29, 'open': 80.24, 'close': 79.98, 'low': 79.68, 'volume': 2164, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.68, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009882724, 'volatility_last_month': 0.01001994, 'volatility_last_3months': 0.012818903, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.571428571, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.339544513', 'winloss_ratio_last_month': '0.727943532', 'winloss_ratio_last_3month': '1.381834689', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-01 14:00', 'high': 80.4, 'open': 79.98, 'close': 79.71, 'low': 79.68, 'volume': 2088, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.61, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.009896827, 'volatility_last_month': 0.010027884, 'volatility_last_3months': 0.012820152, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.30989011', 'winloss_ratio_last_month': '0.729962626', 'winloss_ratio_last_3month': '1.381862636', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-01 15:00', 'high': 79.8, 'open': 79.71, 'close': 78.87, 'low': 78.87, 'volume': 3937, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '9', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.010139346, 'volatility_last_month': 0.010112272, 'volatility_last_3months': 0.012835158, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.276346604', 'winloss_ratio_last_month': '0.731463468', 'winloss_ratio_last_3month': '1.38189055', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-01 16:00', 'high': 79.16, 'open': 78.84, 'close': 78.95, 'low': 78.45, 'volume': 3607, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.010099967, 'volatility_last_month': 0.010110768, 'volatility_last_3months': 0.012834571, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.238095238', 'winloss_ratio_last_month': '0.732966691', 'winloss_ratio_last_3month': '1.38191843', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-02 07:00', 'high': 79.25, 'open': 78.96, 'close': 79.02, 'low': 78.61, 'volume': 795, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.010077426, 'volatility_last_month': 0.00999212, 'volatility_last_3months': 0.012832226, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.318059299', 'winloss_ratio_last_month': '0.734472299', 'winloss_ratio_last_3month': '1.381946276', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-02 08:00', 'high': 79.66, 'open': 79.04, 'close': 79.24, 'low': 78.77, 'volume': 1822, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.010114541, 'volatility_last_month': 0.009997687, 'volatility_last_3months': 0.012727768, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.43877551', 'winloss_ratio_last_month': '0.735980299', 'winloss_ratio_last_3month': '1.381974089', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-02 09:00', 'high': 79.79, 'open': 79.2, 'close': 79.77, 'low': 78.82, 'volume': 2527, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.010266339, 'volatility_last_month': 0.009964987, 'volatility_last_3months': 0.01273425, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.530516432', 'winloss_ratio_last_month': '0.737490695', 'winloss_ratio_last_3month': '1.382001868', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-02 10:00', 'high': 80.55, 'open': 79.79, 'close': 80.08, 'low': 79.77, 'volume': 2075, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.010298376, 'volatility_last_month': 0.009974201, 'volatility_last_3months': 0.01272493, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.500813008', 'winloss_ratio_last_month': '0.739003494', 'winloss_ratio_last_3month': '1.382029615', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-02 11:00', 'high': 80.17, 'open': 80.12, 'close': 79.58, 'low': 79.58, 'volume': 1133, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.010381443, 'volatility_last_month': 0.009986143, 'volatility_last_3months': 0.012732603, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.512690355', 'winloss_ratio_last_month': '0.740518701', 'winloss_ratio_last_3month': '1.382057328', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-02 12:00', 'high': 80.32, 'open': 79.55, 'close': 80.17, 'low': 79.4, 'volume': 1159, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.010558303, 'volatility_last_month': 0.010014169, 'volatility_last_3months': 0.012701696, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.525573192', 'winloss_ratio_last_month': '0.742036322', 'winloss_ratio_last_3month': '1.382085007', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-02 13:00', 'high': 80.3, 'open': 80.14, 'close': 79.68, 'low': 79.68, 'volume': 1194, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.010376836, 'volatility_last_month': 0.010023326, 'volatility_last_3months': 0.012709201, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.539594843', 'winloss_ratio_last_month': '0.743556363', 'winloss_ratio_last_3month': '1.382112654', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-02 14:00', 'high': 79.82, 'open': 79.68, 'close': 79.51, 'low': 79.42, 'volume': 1408, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.010334985, 'volatility_last_month': 0.00998851, 'volatility_last_3months': 0.012708855, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.554913295', 'winloss_ratio_last_month': '0.74507883', 'winloss_ratio_last_3month': '1.382140267', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-02 15:00', 'high': 79.57, 'open': 79.51, 'close': 79.16, 'low': 78.96, 'volume': 1469, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.010366528, 'volatility_last_month': 0.009999836, 'volatility_last_3months': 0.012694413, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.571717172', 'winloss_ratio_last_month': '0.746603728', 'winloss_ratio_last_3month': '1.382167848', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-02 16:00', 'high': 79.25, 'open': 79.16, 'close': 78.52, 'low': 78.5, 'volume': 1934, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 3.67, 'volatility_last_week': 0.010207348, 'volatility_last_month': 0.010051059, 'volatility_last_3months': 0.012706896, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.484375, 'winloss_ratio_last_week': '3.590233546', 'winloss_ratio_last_month': '0.748131063', 'winloss_ratio_last_3month': '1.382195395', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-05 07:00', 'high': 79.95, 'open': 79.05, 'close': 78.64, 'low': 78.62, 'volume': 1423, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 3.89, 'volatility_last_week': 0.010153488, 'volatility_last_month': 0.009947799, 'volatility_last_3months': 0.012706898, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.492063492, 'winloss_ratio_last_week': '3.610738255', 'winloss_ratio_last_month': '0.749660842', 'winloss_ratio_last_3month': '1.382300693', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-05 08:00', 'high': 79.11, 'open': 78.65, 'close': 79.11, 'low': 78.01, 'volume': 2299, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 2.22, 'volatility_last_week': 0.009865751, 'volatility_last_month': 0.009931857, 'volatility_last_3months': 0.012647579, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.483870968, 'winloss_ratio_last_week': '3.63356974', 'winloss_ratio_last_month': '0.75119307', 'winloss_ratio_last_3month': '1.38197894', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-05 09:00', 'high': 80.15, 'open': 79.05, 'close': 80.06, 'low': 78.76, 'volume': 2446, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 2.45, 'volatility_last_week': 0.010344683, 'volatility_last_month': 0.010043261, 'volatility_last_3months': 0.012681228, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.491803279, 'winloss_ratio_last_week': '3.65914787', 'winloss_ratio_last_month': '0.752727752', 'winloss_ratio_last_3month': '1.381738736', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-05 10:00', 'high': 80.32, 'open': 80.13, 'close': 79.55, 'low': 79.45, 'volume': 2127, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.27, 'volatility_last_week': 0.0103668, 'volatility_last_month': 0.010029607, 'volatility_last_3months': 0.012613935, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '3.688', 'winloss_ratio_last_month': '0.754264895', 'winloss_ratio_last_3month': '1.382141233', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-05 11:00', 'high': 81.7, 'open': 79.55, 'close': 81.56, 'low': 79.52, 'volume': 3163, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.011937927, 'volatility_last_month': 0.010541411, 'volatility_last_3months': 0.012759253, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '3.720797721', 'winloss_ratio_last_month': '0.755804505', 'winloss_ratio_last_3month': '1.382554314', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-05 12:00', 'high': 83.28, 'open': 81.56, 'close': 82.38, 'low': 81.25, 'volume': 4111, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.012179779, 'volatility_last_month': 0.010626794, 'volatility_last_3months': 0.012782463, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '3.758409786', 'winloss_ratio_last_month': '0.757346588', 'winloss_ratio_last_3month': '1.382967388', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-05 13:00', 'high': 82.89, 'open': 82.39, 'close': 82.88, 'low': 81.8, 'volume': 2158, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.012267378, 'volatility_last_month': 0.010615834, 'volatility_last_3months': 0.012788941, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '3.801980198', 'winloss_ratio_last_month': '0.75889115', 'winloss_ratio_last_3month': '1.383380455', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-05 14:00', 'high': 82.92, 'open': 82.88, 'close': 82.28, 'low': 82.1, 'volume': 1796, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.012380789, 'volatility_last_month': 0.010648737, 'volatility_last_3months': 0.012787437, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '3.853046595', 'winloss_ratio_last_month': '0.760438195', 'winloss_ratio_last_3month': '1.383793514', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-05 15:00', 'high': 82.43, 'open': 82.29, 'close': 82.15, 'low': 81.88, 'volume': 1845, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.012375992, 'volatility_last_month': 0.010649109, 'volatility_last_3months': 0.012787756, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '3.91372549', 'winloss_ratio_last_month': '0.761987732', 'winloss_ratio_last_3month': '1.384206566', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-05 16:00', 'high': 83.0, 'open': 82.21, 'close': 82.86, 'low': 82.21, 'volume': 2597, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.01251893, 'volatility_last_month': 0.010695343, 'volatility_last_3months': 0.01276586, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '3.987012987', 'winloss_ratio_last_month': '0.763539765', 'winloss_ratio_last_3month': '1.38461961', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-06 07:00', 'high': 83.48, 'open': 83.0, 'close': 82.66, 'low': 82.5, 'volume': 1524, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.012527861, 'volatility_last_month': 0.01069945, 'volatility_last_3months': 0.012755852, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '4.077294686', 'winloss_ratio_last_month': '0.765094301', 'winloss_ratio_last_3month': '1.385032647', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-06 08:00', 'high': 82.74, 'open': 82.66, 'close': 81.93, 'low': 81.3, 'volume': 2263, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.012056202, 'volatility_last_month': 0.010751214, 'volatility_last_3months': 0.012762057, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '4.191256831', 'winloss_ratio_last_month': '0.766651346', 'winloss_ratio_last_3month': '1.385383732', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-06 09:00', 'high': 82.73, 'open': 81.9, 'close': 82.63, 'low': 81.86, 'volume': 1736, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.012214656, 'volatility_last_month': 0.010810475, 'volatility_last_3months': 0.01277213, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '4.339622642', 'winloss_ratio_last_month': '0.768210905', 'winloss_ratio_last_3month': '1.385774378', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-06 10:00', 'high': 82.63, 'open': 82.63, 'close': 81.05, 'low': 80.89, 'volume': 2753, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.013006409, 'volatility_last_month': 0.011098551, 'volatility_last_3months': 0.012846935, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '4.540740741', 'winloss_ratio_last_month': '0.769772985', 'winloss_ratio_last_3month': '1.386138424', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-06 11:00', 'high': 81.84, 'open': 81.12, 'close': 81.57, 'low': 81.12, 'volume': 953, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.013054039, 'volatility_last_month': 0.011131306, 'volatility_last_3months': 0.01285681, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '4.828828829', 'winloss_ratio_last_month': '0.771337593', 'winloss_ratio_last_3month': '1.386406797', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-06 12:00', 'high': 81.81, 'open': 81.61, 'close': 81.46, 'low': 80.96, 'volume': 1030, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.012882326, 'volatility_last_month': 0.011131885, 'volatility_last_3months': 0.012846152, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '5.275862069', 'winloss_ratio_last_month': '0.772904733', 'winloss_ratio_last_3month': '1.386652076', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-06 13:00', 'high': 81.74, 'open': 81.48, 'close': 81.04, 'low': 80.68, 'volume': 2169, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.012938636, 'volatility_last_month': 0.011096575, 'volatility_last_3months': 0.01285008, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '6.063492063', 'winloss_ratio_last_month': '0.774474413', 'winloss_ratio_last_3month': '1.386897276', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-06 14:00', 'high': 81.18, 'open': 81.01, 'close': 80.66, 'low': 80.21, 'volume': 1925, 'dateload': '07/06/2023 7: 42', 'position': 0, 'operations_last_week': 0, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.012686555, 'volatility_last_month': 0.011111447, 'volatility_last_3months': 0.01284628, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '7.820512821', 'winloss_ratio_last_month': '0.776046638', 'winloss_ratio_last_3month': '1.387142399', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-06 15:00', 'high': 81.33, 'open': 80.68, 'close': 81.19, 'low': 80.65, 'volume': 1382, 'dateload': '07/06/2023 7: 42', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '41', 'pnl_last_week': 0.0, 'pnl_last_month': 0.35, 'pnl_last_3months': 4.3, 'volatility_last_week': 0.01277821, 'volatility_last_month': 0.011139125, 'volatility_last_3months': 0.012856944, 'win_ratio_last_week': 0.0, 'win_ratio_last_month': 0.526315789, 'win_ratio_last_3month': 0.508474576, 'winloss_ratio_last_week': '15.2', 'winloss_ratio_last_month': '0.777621415', 'winloss_ratio_last_3month': '1.387387445', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-06 16:00', 'high': 81.93, 'open': 81.21, 'close': 81.64, 'low': 81.21, 'volume': 2178, 'dateload': '07/06/2023 7: 42', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '41', 'pnl_last_week': 0.45, 'pnl_last_month': 0.8, 'pnl_last_3months': 4.75, 'volatility_last_week': 0.012838092, 'volatility_last_month': 0.0110788, 'volatility_last_3months': 0.012842796, 'win_ratio_last_week': 1.0, 'win_ratio_last_month': 0.55, 'win_ratio_last_3month': 0.516666667, 'winloss_ratio_last_week': '-', 'winloss_ratio_last_month': '0.779661365', 'winloss_ratio_last_3month': '1.387689838', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-07 07:00', 'high': 81.87, 'open': 81.64, 'close': 80.79, 'low': 80.67, 'volume': 908, 'dateload': '12/06/2023 18: 30', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '41', 'pnl_last_week': -0.4, 'pnl_last_month': -0.05, 'pnl_last_3months': 3.9, 'volatility_last_week': 0.013010984, 'volatility_last_month': 0.011154965, 'volatility_last_3months': 0.012855217, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.523809524, 'win_ratio_last_3month': 0.508196721, 'winloss_ratio_last_week': '1.929411765', 'winloss_ratio_last_month': '0.781021598', 'winloss_ratio_last_3month': '1.38784162', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-07 08:00', 'high': 81.14, 'open': 80.82, 'close': 80.48, 'low': 80.22, 'volume': 1530, 'dateload': '12/06/2023 18: 30', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '41', 'pnl_last_week': -0.71, 'pnl_last_month': -0.36, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013038699, 'volatility_last_month': 0.011164065, 'volatility_last_3months': 0.012779639, 'win_ratio_last_week': 0.333333333, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.855721393', 'winloss_ratio_last_month': '0.782132435', 'winloss_ratio_last_3month': '1.387938445', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-07 09:00', 'high': 80.75, 'open': 80.49, 'close': 80.55, 'low': 80.24, 'volume': 1285, 'dateload': '12/06/2023 18: 30', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '41', 'pnl_last_week': -0.64, 'pnl_last_month': -0.29, 'pnl_last_3months': 3.66, 'volatility_last_week': 0.013021721, 'volatility_last_month': 0.011161816, 'volatility_last_3months': 0.012769937, 'win_ratio_last_week': 0.5, 'win_ratio_last_month': 0.52173913, 'win_ratio_last_3month': 0.507936508, 'winloss_ratio_last_week': '0.589905363', 'winloss_ratio_last_month': '0.78331433', 'winloss_ratio_last_3month': '1.388044135', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-07 10:00', 'high': 80.74, 'open': 80.55, 'close': 80.48, 'low': 80.08, 'volume': 2032, 'dateload': '12/06/2023 18: 30', 'position': 1, 'operations_last_week': 1, 'operations_last_month': '9', 'operations_last_3_months': '41', 'pnl_last_week': -0.71, 'pnl_last_month': -0.36, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.012582546, 'volatility_last_month': 0.011153569, 'volatility_last_3months': 0.012769342, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.543181818', 'winloss_ratio_last_month': '0.784438946', 'winloss_ratio_last_3month': '1.388137375', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-07 11:00', 'high': 81.26, 'open': 80.45, 'close': 80.91, 'low': 80.45, 'volume': 1214, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '10', 'operations_last_3_months': '42', 'pnl_last_week': -0.71, 'pnl_last_month': -0.36, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.012641829, 'volatility_last_month': 0.011165267, 'volatility_last_3months': 0.012776286, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.51687389', 'winloss_ratio_last_month': '0.785562546', 'winloss_ratio_last_3month': '1.388230557', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-07 12:00', 'high': 82.61, 'open': 80.9, 'close': 82.22, 'low': 80.5, 'volume': 2721, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '42', 'pnl_last_week': -0.71, 'pnl_last_month': -0.36, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.01317131, 'volatility_last_month': 0.011281922, 'volatility_last_3months': 0.012717216, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.5, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.5', 'winloss_ratio_last_month': '0.786685131', 'winloss_ratio_last_3month': '1.38832368', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-07 13:00', 'high': 83.12, 'open': 82.15, 'close': 82.25, 'low': 82.11, 'volume': 2309, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '42', 'pnl_last_week': -0.71, 'pnl_last_month': -1.06, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013169321, 'volatility_last_month': 0.01122961, 'volatility_last_3months': 0.012707044, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.47826087, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.488257108', 'winloss_ratio_last_month': '0.786371233', 'winloss_ratio_last_3month': '1.388416745', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-07 14:00', 'high': 82.83, 'open': 82.27, 'close': 82.72, 'low': 82.19, 'volume': 1676, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '42', 'pnl_last_week': -0.71, 'pnl_last_month': -1.07, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013090379, 'volatility_last_month': 0.011258092, 'volatility_last_3months': 0.012705882, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.454545455, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.479613734', 'winloss_ratio_last_month': '0.786037121', 'winloss_ratio_last_3month': '1.38850975', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-07 15:00', 'high': 84.19, 'open': 82.72, 'close': 83.42, 'low': 82.59, 'volume': 2877, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '42', 'pnl_last_week': -0.71, 'pnl_last_month': -1.44, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.01321801, 'volatility_last_month': 0.01130205, 'volatility_last_3months': 0.01272091, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.428571429, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.472985782', 'winloss_ratio_last_month': '0.784945245', 'winloss_ratio_last_3month': '1.388602698', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-07 16:00', 'high': 84.07, 'open': 83.44, 'close': 83.86, 'low': 83.39, 'volume': 2059, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '41', 'pnl_last_week': -0.71, 'pnl_last_month': -0.9, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013132574, 'volatility_last_month': 0.011300952, 'volatility_last_3months': 0.012668282, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.45, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.467741935', 'winloss_ratio_last_month': '0.784722151', 'winloss_ratio_last_3month': '1.388695587', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-08 07:00', 'high': 83.99, 'open': 83.99, 'close': 82.73, 'low': 82.55, 'volume': 1320, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '9', 'operations_last_3_months': '41', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013146917, 'volatility_last_month': 0.011432453, 'volatility_last_3months': 0.012683907, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.463489623', 'winloss_ratio_last_month': '0.784804671', 'winloss_ratio_last_3month': '1.388788418', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-08 08:00', 'high': 83.45, 'open': 82.71, 'close': 83.28, 'low': 82.68, 'volume': 1343, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '41', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013202318, 'volatility_last_month': 0.011462854, 'volatility_last_3months': 0.01269399, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.45997191', 'winloss_ratio_last_month': '0.784887364', 'winloss_ratio_last_3month': '1.38888119', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-08 09:00', 'high': 83.91, 'open': 83.25, 'close': 83.68, 'low': 83.15, 'volume': 1767, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '41', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013234561, 'volatility_last_month': 0.011472417, 'volatility_last_3months': 0.012696934, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.457013575', 'winloss_ratio_last_month': '0.78497023', 'winloss_ratio_last_3month': '1.388973904', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-08 10:00', 'high': 84.8, 'open': 83.73, 'close': 84.28, 'low': 83.11, 'volume': 2452, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '41', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013242335, 'volatility_last_month': 0.011514051, 'volatility_last_3months': 0.012612959, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.454491018', 'winloss_ratio_last_month': '0.785053271', 'winloss_ratio_last_3month': '1.38906656', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-08 11:00', 'high': 84.7, 'open': 84.25, 'close': 84.51, 'low': 84.25, 'volume': 1202, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '41', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013205947, 'volatility_last_month': 0.011492854, 'volatility_last_3months': 0.012612164, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.452314557', 'winloss_ratio_last_month': '0.785136486', 'winloss_ratio_last_3month': '1.389159158', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-08 12:00', 'high': 84.95, 'open': 84.41, 'close': 83.87, 'low': 83.65, 'volume': 1606, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013359448, 'volatility_last_month': 0.011421944, 'volatility_last_3months': 0.012598515, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.450417537', 'winloss_ratio_last_month': '0.785219876', 'winloss_ratio_last_3month': '1.389251697', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-08 13:00', 'high': 84.24, 'open': 83.93, 'close': 83.86, 'low': 83.69, 'volume': 984, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013328694, 'volatility_last_month': 0.01141994, 'volatility_last_3months': 0.012585809, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.448749387', 'winloss_ratio_last_month': '0.785303442', 'winloss_ratio_last_3month': '1.389344179', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-08 14:00', 'high': 83.95, 'open': 83.94, 'close': 83.58, 'low': 83.01, 'volume': 1602, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013328003, 'volatility_last_month': 0.011353512, 'volatility_last_3months': 0.01257401, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.447271045', 'winloss_ratio_last_month': '0.785428533', 'winloss_ratio_last_3month': '1.389436603', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-08 15:00', 'high': 84.01, 'open': 83.65, 'close': 83.81, 'low': 83.41, 'volume': 937, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013036236, 'volatility_last_month': 0.011332404, 'volatility_last_3months': 0.012575153, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.44595186', 'winloss_ratio_last_month': '0.78583329', 'winloss_ratio_last_3month': '1.389528969', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-08 16:00', 'high': 84.34, 'open': 83.89, 'close': 83.35, 'low': 83.3, 'volume': 1887, 'dateload': '12/06/2023 18: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 3.59, 'volatility_last_week': 0.013133634, 'volatility_last_month': 0.011344776, 'volatility_last_3months': 0.012576915, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.444767442', 'winloss_ratio_last_month': '0.786149321', 'winloss_ratio_last_3month': '1.389621276', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-09 07:00', 'high': 84.14, 'open': 83.44, 'close': 84.04, 'low': 83.44, 'volume': 781, 'dateload': '12/06/2023 21:00', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 4.63, 'volatility_last_week': 0.013243121, 'volatility_last_month': 0.011395048, 'volatility_last_3months': 0.012563796, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.507936508, 'winloss_ratio_last_week': '0.443698143', 'winloss_ratio_last_month': '0.786600728', 'winloss_ratio_last_3month': '1.390080096', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-09 08:00', 'high': 84.57, 'open': 84.05, 'close': 84.32, 'low': 84.05, 'volume': 949, 'dateload': '12/06/2023 21:00', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 5.47, 'volatility_last_week': 0.013247433, 'volatility_last_month': 0.011402159, 'volatility_last_3months': 0.012547615, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.516129032, 'winloss_ratio_last_week': '0.442727958', 'winloss_ratio_last_month': '0.786824359', 'winloss_ratio_last_3month': '1.390835169', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-09 09:00', 'high': 84.84, 'open': 84.3, 'close': 84.56, 'low': 84.16, 'volume': 1492, 'dateload': '12/06/2023 21:00', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 4.39, 'volatility_last_week': 0.01318828, 'volatility_last_month': 0.011408, 'volatility_last_3months': 0.0125204, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.508196721, 'winloss_ratio_last_week': '0.441843716', 'winloss_ratio_last_month': '0.787048283', 'winloss_ratio_last_3month': '1.391316482', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-09 10:00', 'high': 85.32, 'open': 84.62, 'close': 85.06, 'low': 84.6, 'volume': 1717, 'dateload': '12/06/2023 21:00', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.9, 'volatility_last_week': 0.013220838, 'volatility_last_month': 0.0113878, 'volatility_last_3months': 0.01236216, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.5, 'winloss_ratio_last_week': '0.441034483', 'winloss_ratio_last_month': '0.787272501', 'winloss_ratio_last_3month': '1.391166108', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-09 11:00', 'high': 85.63, 'open': 85.1, 'close': 85.49, 'low': 85.09, 'volume': 1528, 'dateload': '12/06/2023 21:00', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.013125424, 'volatility_last_month': 0.011407338, 'volatility_last_3months': 0.012360995, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.440291102', 'winloss_ratio_last_month': '0.787497011', 'winloss_ratio_last_3month': '1.390883711', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-09 12:00', 'high': 85.54, 'open': 85.43, 'close': 84.86, 'low': 84.5, 'volume': 1286, 'dateload': '12/06/2023 21:00', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.013208841, 'volatility_last_month': 0.011435401, 'volatility_last_3months': 0.012367622, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.439605849', 'winloss_ratio_last_month': '0.787721817', 'winloss_ratio_last_3month': '1.390601223', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-09 13:00', 'high': 86.16, 'open': 84.87, 'close': 86.0, 'low': 84.82, 'volume': 1656, 'dateload': '12/06/2023 21:00', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.013400657, 'volatility_last_month': 0.011573782, 'volatility_last_3months': 0.012409675, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.438972163', 'winloss_ratio_last_month': '0.787946916', 'winloss_ratio_last_3month': '1.390318641', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-09 14:00', 'high': 86.09, 'open': 86.0, 'close': 85.87, 'low': 85.55, 'volume': 1927, 'dateload': '12/06/2023 21:00', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.013391663, 'volatility_last_month': 0.011571512, 'volatility_last_3months': 0.012401667, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.438384434', 'winloss_ratio_last_month': '0.788172312', 'winloss_ratio_last_3month': '1.390035968', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-09 15:00', 'high': 87.3, 'open': 85.92, 'close': 87.15, 'low': 85.88, 'volume': 3587, 'dateload': '12/06/2023 21:00', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.013674625, 'volatility_last_month': 0.011736388, 'volatility_last_3months': 0.012452157, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.437837838', 'winloss_ratio_last_month': '0.788398003', 'winloss_ratio_last_3month': '1.389753201', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-09 16:00', 'high': 87.3, 'open': 87.18, 'close': 86.14, 'low': 86.03, 'volume': 2484, 'dateload': '12/06/2023 21:00', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.01384905, 'volatility_last_month': 0.011823073, 'volatility_last_3months': 0.012481313, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.437328202', 'winloss_ratio_last_month': '0.788623991', 'winloss_ratio_last_3month': '1.389470342', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-12 07:00', 'high': 86.95, 'open': 86.53, 'close': 86.1, 'low': 85.78, 'volume': 627, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.013859824, 'volatility_last_month': 0.011715221, 'volatility_last_3months': 0.012400895, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.436851901', 'winloss_ratio_last_month': '0.788850276', 'winloss_ratio_last_3month': '1.38918739', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-12 08:00', 'high': 86.05, 'open': 86.05, 'close': 85.54, 'low': 85.2, 'volume': 1833, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.013961709, 'volatility_last_month': 0.011726932, 'volatility_last_3months': 0.012350863, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.436405767', 'winloss_ratio_last_month': '0.789076859', 'winloss_ratio_last_3month': '1.388904346', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-12 09:00', 'high': 85.92, 'open': 85.46, 'close': 85.48, 'low': 85.2, 'volume': 1350, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.013741964, 'volatility_last_month': 0.011723174, 'volatility_last_3months': 0.012312136, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.435987023', 'winloss_ratio_last_month': '0.78930374', 'winloss_ratio_last_3month': '1.388621208', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-12 10:00', 'high': 85.85, 'open': 85.48, 'close': 85.63, 'low': 84.88, 'volume': 1876, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.013614726, 'volatility_last_month': 0.011535937, 'volatility_last_3months': 0.012295076, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.43559322', 'winloss_ratio_last_month': '0.78953092', 'winloss_ratio_last_3month': '1.388337978', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-12 11:00', 'high': 86.05, 'open': 85.5, 'close': 86.0, 'low': 85.38, 'volume': 967, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.012392661, 'volatility_last_month': 0.011520572, 'volatility_last_3months': 0.0122921, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.435222196', 'winloss_ratio_last_month': '0.7897584', 'winloss_ratio_last_3month': '1.388054655', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-12 12:00', 'high': 86.1, 'open': 85.96, 'close': 85.99, 'low': 85.7, 'volume': 807, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.01220379, 'volatility_last_month': 0.011513069, 'volatility_last_3months': 0.012257072, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.434872029', 'winloss_ratio_last_month': '0.789986179', 'winloss_ratio_last_3month': '1.387771239', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-12 13:00', 'high': 87.6, 'open': 85.96, 'close': 86.8, 'low': 85.96, 'volume': 3288, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.012310376, 'volatility_last_month': 0.011552611, 'volatility_last_3months': 0.012276565, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.434541009', 'winloss_ratio_last_month': '0.79021426', 'winloss_ratio_last_3month': '1.38748773', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-12 14:00', 'high': 87.88, 'open': 86.86, 'close': 87.58, 'low': 86.78, 'volume': 2064, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.012291685, 'volatility_last_month': 0.011599807, 'volatility_last_3months': 0.012275073, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.434227607', 'winloss_ratio_last_month': '0.790442641', 'winloss_ratio_last_3month': '1.387204128', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-12 15:00', 'high': 88.1, 'open': 87.56, 'close': 87.78, 'low': 87.08, 'volume': 1449, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.012274711, 'volatility_last_month': 0.011603251, 'volatility_last_3months': 0.012273694, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.433930453', 'winloss_ratio_last_month': '0.790671325', 'winloss_ratio_last_3month': '1.386920433', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-12 16:00', 'high': 88.46, 'open': 87.83, 'close': 88.22, 'low': 87.55, 'volume': 3189, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.012181075, 'volatility_last_month': 0.011619762, 'volatility_last_3months': 0.012268901, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.433648316', 'winloss_ratio_last_month': '0.790900311', 'winloss_ratio_last_3month': '1.386636645', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-13 07:00', 'high': 89.5, 'open': 88.0, 'close': 88.21, 'low': 87.5, 'volume': 1967, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.012153361, 'volatility_last_month': 0.011619581, 'volatility_last_3months': 0.012239895, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.433380084', 'winloss_ratio_last_month': '0.7911296', 'winloss_ratio_last_3month': '1.386352764', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-13 08:00', 'high': 89.25, 'open': 88.21, 'close': 88.4, 'low': 88.0, 'volume': 2166, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.011901605, 'volatility_last_month': 0.011622826, 'volatility_last_3months': 0.01223323, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.433124756', 'winloss_ratio_last_month': '0.791359193', 'winloss_ratio_last_3month': '1.386068789', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-13 09:00', 'high': 88.71, 'open': 88.41, 'close': 88.09, 'low': 87.88, 'volume': 2712, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.011837592, 'volatility_last_month': 0.011623207, 'volatility_last_3months': 0.012235696, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.432881421', 'winloss_ratio_last_month': '0.79158909', 'winloss_ratio_last_3month': '1.385784722', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    },
    {
        'symbol': 'EUA_F_ICEYc1', 'timeframe': '60m', 'timestamp': '2023-06-13 10:00', 'high': 88.65, 'open': 88.09, 'close': 88.07, 'low': 87.93, 'volume': 1097, 'dateload': '13/06/2023 11: 30', 'position': 0, 'operations_last_week': 2, 'operations_last_month': '8', 'operations_last_3_months': '40', 'pnl_last_week': -0.71, 'pnl_last_month': -0.71, 'pnl_last_3months': 1.38, 'volatility_last_week': 0.010743808, 'volatility_last_month': 0.01161946, 'volatility_last_3months': 0.012234158, 'win_ratio_last_week': 0.4, 'win_ratio_last_month': 0.473684211, 'win_ratio_last_3month': 0.491525424, 'winloss_ratio_last_week': '0.432649254', 'winloss_ratio_last_month': '0.791819292', 'winloss_ratio_last_3month': '1.385500561', 'reward_to_risk_ratio_last_week': 0.0, 'reward_to_risk_ratio_last_month': 0.0, 'reward_to_risk_ratio_last_3month': 0.0, 'profit_factor_last_week': 0.0, 'profit_factor_last_month': 0.0, 'profit_factor_last_3month': 0.0, 'accuracy_ratio_last_week': 0.0, 'accuracy_ratio_last_month': 0.0, 'accuracy_ratio_last_3month': 0.0
    }
]

export const DUMMY_SIGNALS = [
    { 'uuid': '15427856-87ae-4303-a4e5-2ee958d5ac7f', 'created': '2023-06-07 11:00', 'side': 'Short', 'amount': '80.91' },
    { 'uuid': '6bb25b8d-2d2d-4470-88a4-3353446fb823', 'created': '2023-06-06 15:00', 'side': 'Long', 'amount': '81.19' },
    { 'uuid': '5a8870a2-5542-4566-890a-8059744605ea', 'created': '2023-05-24 11:00', 'side': 'Long', 'amount': '85.17' },
    { 'uuid': '9886cee7-3763-4c96-a128-d54dc774761d', 'created': '2023-05-23 15:00', 'side': 'Short', 'amount': '85.71' },
    { 'uuid': 'a184675e-85cb-4ada-ad7c-7bb1f465b28b', 'created': '2023-05-19 12:00', 'side': 'Short', 'amount': '90.63' },
    { 'uuid': 'f741e12d-185e-4049-9989-6ad63441a061', 'created': '2023-05-18 16:00', 'side': 'Long', 'amount': '89.65' },
    { 'uuid': 'a829d7f2-2af7-4b66-be31-0159ea097c52', 'created': '2023-05-17 14:00', 'side': 'Short', 'amount': '88.02' },
    { 'uuid': '32cff7bc-41ea-423f-8b68-ea25f331521b', 'created': '2023-05-17 08:00', 'side': 'Long', 'amount': '89.25' },
    { 'uuid': 'd8793b7e-0131-4372-a534-b5ef149fc681', 'created': '2023-05-11 08:00', 'side': 'Short', 'amount': '89.1' },
    { 'uuid': 'eed506de-a7b8-4cdd-ad67-dd4bbf3796fd', 'created': '2023-05-10 12:00', 'side': 'Long', 'amount': '88.52' },
    { 'uuid': '778d6692-f74a-4d90-9860-db9b031a5f28', 'created': '2023-05-04 16:00', 'side': 'Long', 'amount': '84.5' },
    { 'uuid': '5b47da59-595a-4487-b517-3dc0bd6d6e1a', 'created': '2023-05-04 10:00', 'side': 'Short', 'amount': '84.83' },
    { 'uuid': 'ae69c75b-d0f7-43ea-966c-3d7267f15b10', 'created': '2023-05-03 08:00', 'side': 'Short', 'amount': '88.22' },
    { 'uuid': 'eb0940fb-9099-4fc2-8d2f-0937e8c79ef9', 'created': '2023-05-02 12:00', 'side': 'Long', 'amount': '90.6' },
    { 'uuid': '3e5e706d-4129-46b4-acf4-5180592b903e', 'created': '2023-04-21 11:00', 'side': 'Long', 'amount': '90.95' },
    { 'uuid': '3dc5f2ec-a600-4dbf-b65d-d2c972b1d60c', 'created': '2023-04-21 10:00', 'side': 'Short', 'amount': '91.26' },
    { 'uuid': '4e3b0856-2df3-4be1-a4f3-a984b62a7800', 'created': '2023-04-21 09:00', 'side': 'Long', 'amount': '91.36' },
    { 'uuid': 'd8e9d872-a3f0-4e47-815b-a7a974c924d7', 'created': '2023-04-20 13:00', 'side': 'Short', 'amount': '91.44' },
    { 'uuid': '6d20fc54-2a4c-4d3c-aed1-e29976dceb37', 'created': '2023-04-14 09:00', 'side': 'Long', 'amount': '94.6' },
    { 'uuid': '1b50ae97-4bf5-4cfb-85e5-77629dc18a19', 'created': '2023-04-13 13:00', 'side': 'Short', 'amount': '94.59' },
    { 'uuid': '0a13174f-a520-4884-a681-727b0e16cc26', 'created': '2023-03-31 11:00', 'side': 'Short', 'amount': '91.58' },
    { 'uuid': 'e262b3ef-2a3b-40fc-8bb6-4370e447561c', 'created': '2023-03-30 15:00', 'side': 'Long', 'amount': '90.9' },
    { 'uuid': '7e829bd7-4ac3-46d9-9e00-f804a3482eae', 'created': '2023-03-28 16:00', 'side': 'Long', 'amount': '89.4' },
    { 'uuid': '5f797255-b2c8-4d15-b860-856c1f41ab2a', 'created': '2023-03-28 10:00', 'side': 'Short', 'amount': '89.45' },
    { 'uuid': '76a116eb-fbc1-4947-9e27-c4beb8aff191', 'created': '2023-03-23 13:00', 'side': 'Short', 'amount': '92.8' },
    { 'uuid': '4d140e02-7304-44e1-9a7f-57147ffbaec7', 'created': '2023-03-23 07:00', 'side': 'Long', 'amount': '89.75' },
    { 'uuid': 'e976bfe5-7831-42e4-b1af-4078857a2da1', 'created': '2023-03-15 12:00', 'side': 'Long', 'amount': '90.2' },
    { 'uuid': '907e1f1c-7d98-454a-a240-2ae2a77e00bf', 'created': '2023-03-14 16:00', 'side': 'Short', 'amount': '92.87' },
    { 'uuid': '8a1de879-7326-4cc6-ba19-f459031111ca', 'created': '2023-03-09 12:00', 'side': 'Short', 'amount': '97' },
    { 'uuid': '3cef2f6b-5758-4ec2-b342-9bfdadcc2d36', 'created': '2023-03-08 16:00', 'side': 'Long', 'amount': '97.83' },
    { 'uuid': '0866c4b6-0145-4008-b97b-fd888367ae00', 'created': '2023-03-03 11:00', 'side': 'Long', 'amount': '92.54' },
    { 'uuid': '2be5858c-1787-4207-9959-99f9df26090a', 'created': '2023-03-02 15:00', 'side': 'Short', 'amount': '93.3' },
    { 'uuid': '70ef6b02-748d-492a-a976-fd7f5304d8b7', 'created': '2023-03-01 07:00', 'side': 'Short', 'amount': '98.65' },
    { 'uuid': '1d6bd216-ac39-4e5d-a6e7-29d146c446c1', 'created': '2023-02-28 11:00', 'side': 'Long', 'amount': '99.93' },
    { 'uuid': 'e289ac7a-ac2a-40d9-b050-9a1541ea8ef2', 'created': '2023-02-27 10:00', 'side': 'Long', 'amount': '98.79' },
    { 'uuid': '7626c37e-4cf9-4333-8f10-1996043912a7', 'created': '2023-02-24 14:00', 'side': 'Short', 'amount': '98.17' },
    { 'uuid': 'aa0c8504-480c-497c-a464-fb2939f42525', 'created': '2023-02-14 09:00', 'side': 'Short', 'amount': '92.76' },
    { 'uuid': '4b439264-f707-4904-a381-224ca2b510b8', 'created': '2023-02-13 13:00', 'side': 'Long', 'amount': '91.9' },
    { 'uuid': '0268709a-17b5-428a-b850-76612bdbda43', 'created': '2023-02-08 08:00', 'side': 'Long', 'amount': '90.88' },
    { 'uuid': 'ec74ba52-ec68-48eb-a3fb-ba88c259ae0d', 'created': '2023-02-07 12:00', 'side': 'Short', 'amount': '90.5' },
    { 'uuid': 'c0877c58-fe8b-4d88-86b0-a5d1171918b9', 'created': '2023-01-27 14:00', 'side': 'Short', 'amount': '89.9' },
    { 'uuid': '90b798d9-65d3-4ad0-8afc-d2a174ba323c', 'created': '2023-01-27 08:00', 'side': 'Long', 'amount': '89.5' },
    { 'uuid': '2e0916d1-4783-4789-8eb0-8177b3a43a9c', 'created': '2023-01-19 14:00', 'side': 'Short', 'amount': '83.8' },
    { 'uuid': '5efc1803-3a0e-4feb-8caa-3365a6e5dcd4', 'created': '2023-01-19 08:00', 'side': 'Long', 'amount': '84.86' },
    { 'uuid': '0d0cccbb-adf6-4c83-a5e0-4477baf59edd', 'created': '2023-01-16 12:00', 'side': 'Long', 'amount': '78.9' },
    { 'uuid': 'c586bf3c-ebc4-471c-9c47-72af827e5c94', 'created': '2023-01-13 16:00', 'side': 'Short', 'amount': '79.63' },
    { 'uuid': 'eab0caa7-59e7-4a52-9dcb-2368e8ad3db6', 'created': '2022-12-29 12:00', 'side': 'Long', 'amount': '85.41' },
    { 'uuid': 'e6b3e632-2b08-43fe-910e-f070aaf54ba0', 'created': '2022-12-28 16:00', 'side': 'Short', 'amount': '86.19' },
    { 'uuid': 'a8a54084-cf4c-4207-be0d-408d625182a8', 'created': '2022-12-15 13:00', 'side': 'Long', 'amount': '86.87' },
    { 'uuid': 'a7825e5c-0831-4eca-85ed-4d5cb401bcd7', 'created': '2022-12-15 07:00', 'side': 'Short', 'amount': '86.33' },
    { 'uuid': '2c119f73-50e2-4331-b1b9-86e0476e77d3', 'created': '2022-11-23 16:00', 'side': 'Short', 'amount': '75.87' },
    { 'uuid': '76ef590d-9d89-4e2c-bb14-4329977b4daf', 'created': '2022-11-23 10:00', 'side': 'Long', 'amount': '75.79' },
    { 'uuid': 'ed57ced3-79a8-4750-baa0-f14a72a2c062', 'created': '2022-11-18 13:00', 'side': 'Long', 'amount': '73.42' },
    { 'uuid': 'ff2586e9-3037-4fc0-8f52-caeaed4176a0', 'created': '2022-11-18 07:00', 'side': 'Short', 'amount': '72.81' },
    { 'uuid': 'e25de0b7-1c34-45c1-a052-5af38967f261', 'created': '2022-11-09 13:00', 'side': 'Long', 'amount': '73.44' },
    { 'uuid': '0964f034-3845-4a03-a38f-d0c4ae151a6c', 'created': '2022-11-09 07:00', 'side': 'Short', 'amount': '75.86' },
    { 'uuid': 'cb913f84-6433-41c2-a597-7c299b58aef8', 'created': '2022-11-03 10:00', 'side': 'Long', 'amount': '77.64' },
    { 'uuid': 'bd787d82-a669-423b-b87c-72b0243469d3', 'created': '2022-11-02 14:00', 'side': 'Short', 'amount': '76.76' },
    { 'uuid': '12d17e6b-a183-47f9-a570-86e367b7733a', 'created': '2022-10-25 10:00', 'side': 'Short', 'amount': '74.76' },
    { 'uuid': '32600d8f-1a9f-4142-a7d7-bab1abbd65c8', 'created': '2022-10-24 14:00', 'side': 'Long', 'amount': '70.61' },
    { 'uuid': '8ff17ce9-680c-4535-bd1e-e39faf6d2665', 'created': '2022-10-24 09:00', 'side': 'Short', 'amount': '67.89' },
    { 'uuid': '4dda099b-cf87-42d5-8abf-061de3891a62', 'created': '2022-10-21 16:00', 'side': 'Long', 'amount': '69.1' },
    { 'uuid': '8a2d4f2c-bb07-4f15-b861-be67b8a903f1', 'created': '2022-10-21 08:00', 'side': 'Long', 'amount': '67.5' },
    { 'uuid': 'a28bcb6e-207b-4453-9100-8fe34282084b', 'created': '2022-10-20 12:00', 'side': 'Short', 'amount': '66.9' },
    { 'uuid': 'd1c35c3b-0741-4de6-a679-56c049606f9d', 'created': '2022-10-12 10:00', 'side': 'Long', 'amount': '67.89' },
    { 'uuid': 'aa833fab-dc18-43b2-8f8e-adbd078c2dc8', 'created': '2022-10-11 14:00', 'side': 'Short', 'amount': '66.18' },
    { 'uuid': '36546d71-9c4b-4790-98fb-ddc3c57d5130', 'created': '2022-10-05 12:00', 'side': 'Short', 'amount': '69.96' },
    { 'uuid': '7fe169be-a82f-4d36-9373-84c5af9969d1', 'created': '2022-10-04 16:00', 'side': 'Long', 'amount': '67.42' },
    { 'uuid': '9972068a-a7cb-4c67-a45a-8b85f399816e', 'created': '2022-09-22 10:00', 'side': 'Long', 'amount': '70.1' },
    { 'uuid': 'a0811e71-4a4c-4dbc-89a0-e56500e192dc', 'created': '2022-09-21 14:00', 'side': 'Short', 'amount': '69.73' },
    { 'uuid': '75e347ae-1e82-4f91-b10f-04ee8a2964e5', 'created': '2022-09-14 16:00', 'side': 'Short', 'amount': '72.05' },
    { 'uuid': '2d182432-d60f-4528-82ce-a4753c3dd9ac', 'created': '2022-09-14 10:00', 'side': 'Long', 'amount': '69.91' },
    { 'uuid': 'b735c851-e01c-4873-8705-b9b13a0c570a', 'created': '2022-08-24 14:00', 'side': 'Long', 'amount': '89.61' },
    { 'uuid': 'c850a674-badb-4dae-8dea-70650f046abd', 'created': '2022-08-24 08:00', 'side': 'Short', 'amount': '91.08' }
].reverse()