<template>
    <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text color="accent">
                2FA DISABLE
            </v-btn>
        </template>

        <v-card>
            <v-card-title> 2FA Disable </v-card-title>

            <v-card-text>
                Do you want to disable the 2FA auth?
                <br />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading" :loading="loading" color="warning" @click="disable">
                    Disable
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { backend_api } from "@/utils/api";
export default {
    data: () => ({
        loading: false,
        dialog: false,
    }),
    computed: {
        user() {
            return this.$store.state.session.user_object;
        },
        opt_enabled() {
            return this.user.auth.otp_enabled;
        },
    },
    methods: {
        disable() {
            this.loading = true;
            return backend_api
                .get("/my_user/new_otp_secret/")
                .then(() => {
                    this.dialog = false;
                    this.$store.dispatch("session/getUserObject");
                })
                .catch((e) => console.log(`Cant disable OTP: ${e}`))
                .finally(() => (this.loading = false));
        },
    },
};
</script>