<template>
    <v-chip :text-color="textcolor" :color="color" class="ma-2" large> {{ text }} <v-icon right> {{ icon }}</v-icon> </v-chip>
</template>

<script>

export default {
    props: ["side"],
    computed: {
        textcolor() {
            if (this.$vuetify.theme.dark) {
                if (this.side == -1) return "#3d3b3b"
                if (this.side == 1) return "#3d3b3b"
                else return "#00897B"
            } else {
                if (this.side == -1) return "white"
                if (this.side == 1) return "white"
                else return "blue lighten-5"
            }
        },
        text() {
            return "Sideways"
        },
        color() {
            if (this.$vuetify.theme.dark) {
                if (this.side == -1) return "#302f2f"
                if (this.side == 1) return "#302f2f"
                else return "#B2EBF2"
            } else {
                if (this.side == -1) return "#ECEFF1"
                if (this.side == 1) return "#ECEFF1"
                else return "blue-grey darken-1"
            }
        },
        icon() {
            return "mdi-trending-neutral"
        },
    }
}
</script>
