<template>
  <v-app>
    <NavigationMenu v-if="show_drawer" />
    <v-main>
      <router-view></router-view>
      <NotiBox />
    </v-main>
  </v-app>
</template>

<style lang="scss">
.app {
  height: 100hv;
  // touch-action: none;
}

::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #ff770054;
  /* color of the scroll thumb */
  border-radius: 8px;
  /* roundness of the scroll thumb */
}
</style>

<script>
import NavigationMenu from "./components/NavigationMenu.vue"
import NotiBox from "@/components/NotiBox";
export default {
  components: {
    NavigationMenu,
    NotiBox
  },
  computed: {
    show_drawer() {
      return !["login"].includes(this.$route.name);
    },
  },
  mounted() {
    if (this.$store.state.session.refresh_token) {
      this.$store.dispatch("session/refresh");
    }
    console.log("[i] Main component mounted.")
  }
}
</script>
