<template>
    <v-container fluid>
        <v-row>
            <v-col align-self="end">
                <ButtonSimulate />
            </v-col>
        </v-row>
        <v-row v-for="symbol in symbols" :key="symbol">
            <v-col> <v-card outlined>
                    <v-card-title> {{ symbol }} (Last candle: {{ last_candle }}) </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="7">
                                <ChartSignals :ohlc="ohlc_by_symbol[symbol]" />
                            </v-col>
                            <v-col cols="3">
                                <TableStats />
                            </v-col>
                            <v-col cols="2">
                                <TableSignals />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card> </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ChartSignals from "./ChartSignals"
import TableStats from "./TableStats"
import TableSignals from "./TableSignals"
import ButtonSimulate from "./ButtonSimulate"
export default {
    components: { ChartSignals, TableStats, TableSignals, ButtonSimulate },
    data: () => ({
    }),
    computed: {
        symbols() {
            return this.$store.getters["signals/symbols"]
        },
        ohlc_by_symbol() {
            return this.$store.state.signals.ohlc_by_symbol
        },
        last_candle() {
            return this.$store.state.signals.last_row.timestamp
        },
    },
}
</script>