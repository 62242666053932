// import { backend_api } from '@/utils/api'
import { DUMMY_OHLC, POPPING_ROWS, DUMMY_SIGNALS } from "./signals_placeholders"


export default {
    namespaced: true,
    state: {
        ohlc_by_symbol: {
            "EUA_F_ICEYc1": DUMMY_OHLC,
        },

        // Not segregated by simbol (TO BE DONE)
        signals: [],
        future_signals: DUMMY_SIGNALS,  // ONLY FOR MOCK
        last_row: {}
    },
    mutations: {
        tickDummyData(state) {
            // ADD OHLC CANDLE
            let popped = POPPING_ROWS.shift()
            if (!(popped.symbol in state.ohlc_by_symbol)) {
                state.ohlc_by_symbol[popped.symbol] = { "x": [], "open": [], "high": [], "low": [], "close": [] }
            }
            let ohlc = state.ohlc_by_symbol[popped.symbol]
            ohlc.x.push(popped.timestamp)
            ohlc.x = [...ohlc.x]
            ohlc.open.push(popped.open)
            ohlc.high.push(popped.high)
            ohlc.low.push(popped.low)
            ohlc.close.push(popped.close)
            state.ohlc_by_symbol[popped.symbol] = ohlc
            // ohlc.volume.push(popped.volume)

            // ADD LAST DB ROW
            state.last_row = popped
            // PUSH SIGNAL ONLY BASED ON TIMESTAMP
            let now = new Date(popped.timestamp)
            let past_signals = state.future_signals.filter(sig => new Date(sig.created) <= now)
            if (past_signals) state.signals.push(...past_signals)
            state.future_signals = state.future_signals.filter(sig => new Date(sig.created) > now)
        }
    },
    actions: {

    },
    getters: {
        symbols: state => Object.keys(state.ohlc_by_symbol)
    }
}


// {
//     'symbol': 'EUA_F_ICEYc1',
//     'timeframe': '60m',
//     'timestamp': '2022-10-20 13:00',
//     'high': 67.0,
//     'open': 66.84,
//     'close': 66.8,
//     'low': 66.54,
//     'volume': 912,
//     'dateload': '07/06/2023 7: 22',
//     'position': -1,
//     'operations_last_week': 1,
//     'operations_last_month': '5',
//     'operations_last_3_months': '-',
//     'pnl_last_week': 0.1,
//     'pnl_last_month': 3.54,
//     'pnl_last_3months': 6.61,
//     'volatility_last_week': 0.011793111,
//     'volatility_last_month': 0.020206954,
//     'volatility_last_3months': 0.017344308,
//     'win_ratio_last_week': 1.0,
//     'win_ratio_last_month': 0.636363636,
//     'win_ratio_last_3month': 0.615384615,
//     'winloss_ratio_last_week': '1.199712644',
//     'winloss_ratio_last_month': '2.494089227',
//     'winloss_ratio_last_3month': '2.921110355',
//     'reward_to_risk_ratio_last_week': 0.0,
//     'reward_to_risk_ratio_last_month': 0.0,
//     'reward_to_risk_ratio_last_3month': 0.0,
//     'profit_factor_last_week': 0.0,
//     'profit_factor_last_month': 0.0,
//     'profit_factor_last_3month': 0.0,
//     'accuracy_ratio_last_week': 0.0,
//     'accuracy_ratio_last_month': 0.0,
//     'accuracy_ratio_last_3month': 0.0
// }