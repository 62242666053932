<template>
    <v-select outlined label="Allowed symbols" multiple :items="symbols" :value="symbols">
    </v-select>
</template>


<script>
const SYMBOLS = [
    "EUA_F_ICEYc1",
    "G_TTF_F_TRMc1",
    "G_TTF_F_TRMc2",
    "G_TTF_F_TRMc3",
    "BRENT_F_ICE_Mc1",
    "BRENT_F_ICE_Mc2",
    "BRENT_F_ICE_Mc3",
]

export default {
    data: () => ({ symbols: SYMBOLS }),
}
</script>