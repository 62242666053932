<template>
  <div>

    <div class="text-center">
      <ChipSideLong :side="side" />
      <ChipSideSideways :side="side" />
      <ChipSideShort :side="side" />
    </div>
    <v-flex xs12>
      <!-- <div class="text-center">Sideways</div> -->
    </v-flex>
    <v-simple-table height="325px" dense>
      <thead>
        <tr>
          <th class="text-left">

          </th>
          <th class="text-right">
            Last Week
          </th>
          <th class="text-right">
            Last Month
          </th>
          <th class="text-right">
            Last 3 Months
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in stats" :key="item.name">
          <td>{{ item.name }}</td>
          <td class="text-right">{{ item.last_week }}</td>
          <td class="text-right">{{ item.last_month }}</td>
          <td class="text-right">{{ item.last_3months }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { round, perc } from "/src/utils/math.js"

import ChipSideSideways from './ChipSideSideways.vue'
import ChipSideShort from './ChipSideShort.vue'
import ChipSideLong from './ChipSideLong.vue'
export default {
  components: { ChipSideSideways, ChipSideShort, ChipSideLong },
  data: () => ({
  }),
  computed: {
    lr() { return this.$store.state.signals.last_row },
    side() { return this.lr.position },
    stats() {
      return [
        {
          "name": "PnL",
          "last_week": round(this.lr.pnl_last_week, 2),
          "last_month": round(this.lr.pnl_last_month, 2),
          "last_3months": round(this.lr.pnl_last_3months, 2)
        },
        {
          "name": "Market Volatility",
          "last_week": perc(this.lr.volatility_last_week, 2),
          "last_month": perc(this.lr.volatility_last_month, 2),
          "last_3months": perc(this.lr.volatility_last_3months, 2)
        },
        {
          "name": "Number of Operations",
          "last_week": this.lr.operations_last_week,
          "last_month": this.lr.operations_last_month,
          "last_3months": this.lr.operations_last_3_months
        },
        {
          "name": "Win Ratio",
          "last_week": perc(this.lr.win_ratio_last_week),
          "last_month": perc(this.lr.win_ratio_last_month),
          "last_3months": perc(this.lr.win_ratio_last_3month)
        },
        {
          "name": "Win/Loss Ratio",
          "last_week": round(this.lr.winloss_ratio_last_week, 1),
          "last_month": round(this.lr.winloss_ratio_last_month, 1),
          "last_3months": round(this.lr.winloss_ratio_last_3month, 1)
        },
        {
          "name": "Reward to Risk Ratio",
          "last_week": round(this.lr.reward_to_risk_ratio_last_week, 1),
          "last_month": round(this.lr.reward_to_risk_ratio_last_month, 1),
          "last_3months": round(this.lr.reward_to_risk_ratio_last_3month, 1)
        },
        {
          "name": "Profit Factor",
          "last_week": round(this.lr.profit_factor_last_week, 1),
          "last_month": round(this.lr.profit_factor_last_month, 1),
          "last_3months": round(this.lr.profit_factor_last_3month, 1)
        },
        {
          "name": "Accuracy Ratio",
          "last_week": round(this.lr.accuracy_ratio_last_week, 1),
          "last_month": round(this.lr.accuracy_ratio_last_month, 1),
          "last_3months": round(this.lr.accuracy_ratio_last_3month, 1)
        },
      ]
    }
  },
}
</script>