<template>
    <v-chip 
        :text-color="textcolor"
        :color="color"
        class="ma-2"
        large> {{ text }} <v-icon right> {{ icon }}</v-icon> </v-chip>
</template>

<script>

export default {
    props: ["side"],
    computed: {
        textcolor() {
            if (this.$vuetify.theme.dark) {
                if (this.side == -1) return "#260303"
                else return "#3d2d31"
            } else {
                if (this.side == -1) return "#DD2C00"
                else return "white"
            }
        },
        text() {
            return "Short"
        },
        color() {
            if (this.$vuetify.theme.dark) {
                if (this.side == -1) return "#e87272"
                else return "#4a373b"
            } else {
                if (this.side == -1) return "#FFCDD2"
                else return "#FFEBEE"
            }
        },
        icon() {
            return "mdi-trending-down"
        },
    }
}
</script>
