<template>
    <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" permanent app>
        <MenuBannerUser @mini_switch="mini=!mini" />

        <v-divider></v-divider>

        <v-list dense>
            <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import MenuBannerUser from './account/MenuBannerUser.vue';
export default {
    components: { MenuBannerUser },
    data() {
        return {
            drawer: true,
            items: [
                { title: 'Signals', icon: 'mdi-finance', to: "signals" },
                { title: 'My Account', icon: 'mdi-account', to: "account" },
                { title: 'Admin', icon: 'mdi-account-group-outline', to: "admin" },
                { title: 'Logout', icon: 'mdi-logout', to: "logout" },
            ],
            mini: true,
        }
    },
}
</script>