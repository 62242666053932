<template>
    <v-card outlined width="300">
        <v-card-subtitle> Simulation
            <span v-if="simulating">ON (candle each {{ seconds }} seconds)</span>
            <span v-else>OFF</span>
        </v-card-subtitle>
        <v-card-actions>
            <v-switch class="ma-0 ml-2" dense v-model="simulating" toggle> SIMULATE </v-switch>
            <v-slider class="mt-1" :disabled="!simulating" max="60" min="1" v-model="seconds"></v-slider>
        </v-card-actions>
    </v-card>
</template>

<script>

export default {
    data: () => ({
        seconds: 1,
        simulating: false,
        interval: null,
    }),
    watch: {
        seconds() {
            this.setSimulation()
        },
        simulating() {
            this.setSimulation()
        },
    },
    methods: {
        tick() {
            this.$store.commit("signals/tickDummyData")
        },
        setSimulation() {
            this.stopSimulation()
            if (this.simulating) {
                this.interval = setInterval(() => {
                    console.log("INTERVALING", this.seconds)
                    this.tick()
                }, this.seconds * 1000);
            }
        },
        stopSimulation() {
            clearInterval(this.interval)
            this.interval = null
        },
    },
    beforeDestroy() {
        this.stopSimulation()
    }
}
</script>