<template>
    <v-chip 
        :text-color="textcolor"
        :color="color"
        class="ma-2"
        large> {{ text }} <v-icon right> {{ icon }}</v-icon> </v-chip>
</template>

<script>

export default {
    props: ["side"],
    computed: {
        textcolor() {
            if (this.$vuetify.theme.dark) {
                // Dark
                if (this.side == 1) return "#077525"
                else return "#262e27"
            } else {
                // Light
                if (this.side == 1) return "#B2FF59"
                else return "white"
            }
        },
        text() {
            return "Long"
        },
        color() {
            if (this.$vuetify.theme.dark) {
                // Dark
                if (this.side == 1) return "#bbedc8"
                else return "#38473a"
            } else {
                // Light
                if (this.side == 1) return "#00C853"
                else return "#E8F5E9"
            }
        },
        icon() {
            return "mdi-trending-up"
        },
    }
}
</script>
