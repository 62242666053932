import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

function determine_theme() {
    const web_dark = window.matchMedia('(prefers-color-scheme: dark)').matches
    const theme = localStorage.getItem("theme");
    if (theme == "dark") {
        return true;
    } else if (theme == "light") {
        return false;
    } else {
        return web_dark;
    }
}

export default new Vuetify({
    theme: {
        dark: determine_theme(),
    }
});
