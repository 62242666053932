<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card outlined class="fill-height">
                    <v-card-title>
                        Admin
                    </v-card-title>

                    <!-- <v-card-text>

                        <br />
                    </v-card-text> -->
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- <ButtonTheme /> -->
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <TableUsers />
            </v-col>
            <v-col cols="4">
                <v-card outlined class="fill-height">
                    <v-card-title> Signals setup </v-card-title>
                    <v-card-text>
                        <SelectSymbols />
                    </v-card-text>
                    <br><br><br>
                    <!-- <v-card-actions>
                        <v-spacer></v-spacer>
                        <ButtonNotificationsSetup />
                    </v-card-actions> -->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SelectSymbols from "./signals/SelectSymbols.vue";
import TableUsers from "./TableUsers.vue";
export default {
    components: {
        SelectSymbols,
        TableUsers,
    },
}
</script>